import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SezioneHome } from '../shared/models/SezioneHome.model';
import { HomeService } from '../shared/services/home.service';
import { AuthService } from '../shared/services/auth.service';
import { UserService } from '../shared/services/User.service';
import { ShoppingCartManagerService } from '../shared/services/shopping-cart-manager.service';
import { BuyerUserProfile } from '../shared/models/UserProfile.models';
import { AnimalPopover } from '../user/user-profile/animal-popover/animal-popover.component';
import { IonSearchbar, Platform, PopoverController } from '@ionic/angular';
import { AutoCompleteService } from '../shared/services/autocomplete.service';
import { VendorOrderItem } from '../shared/models/Ordini.models';
import { VendorOrderService } from '../shared/services/vendor-orders.service';
import { InfoForVendorPopoverComponent } from './info-for-vendor-popover/info-for-vendor-popover.component';
import { MenuService } from '../shared/services/menu.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {
  ZERO_CONSTANT: Number = Number(0);
  isFetching: boolean = false;
  isFetchingUserProfile: boolean = false;
  hasUserProfileInfo: boolean = false;
  isNotLoggedIn: boolean = false;
  private userProfile: BuyerUserProfile;
  private homeServiceSubscription: Subscription;
  sezioni: SezioneHome[];
  errorMsg: string;
  cartBadge: Number;
  shoppingCartResArray: any = [];
  localStorageKeys: string[] = [];
  local_cart: any[] = [];
  error: any;
  private SCManagerTrigger: boolean = true;
  hasRemoteCart: boolean = false;

  totaleDonazioni: number = 0;
  private RIFORESTAZIONE: number = 0;
  private PLASTICA: number = 1;
  private SOCIALI: number = 2;

  pop: any;
  email: string = 'mailto:assistenza@emplacestore.it?Subject=Suggerimento nuovo venditore';
  emailAssistenza: string = 'mailto:assistenza@emplacestore.it';
  emplace_bsn_numero: string = '+39 351 6122276';
  showEmplaceBsnNumero: boolean = false;

  searchInput: string;
  hasSuggestion: boolean = false;
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  autocompleteResult: string;
  suggestions: string[];

  private innerWidth: any;
  private SCREEN_SIZE_XS: number = 390;
  private SCREEN_SIZE_XS_SM: number = 576;
  private SCREEN_SIZE_SM_MD: number = 768;
  smallScreen: boolean = false;
  xsScreen: boolean = false;

  // Ordini venditore:
  orders: VendorOrderItem[];
  closedOrdersArray: VendorOrderItem[];
  pendingOrdersArray: VendorOrderItem[];
  hasOrders: boolean = false;
  closedOrders: number = 0;
  pendingOrders: number = 0;
  //firstCallVendorOrders: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, 
              private autoCompleteService: AutoCompleteService,
              public platform: Platform, private logger: NGXLogger,
              private homeService: HomeService, 
              private router: Router, public vendorOrdersService: VendorOrderService,
              public authService: AuthService,
              public userService: UserService, 
              public shoppingCartManagerService: ShoppingCartManagerService,
              private popoverController: PopoverController) { }

  ngOnInit() {
    this.getHomeData();
  }

  toShowWAppNumber(){
    if(this.showEmplaceBsnNumero){
      this.showEmplaceBsnNumero = false;
    }else{
      this.showEmplaceBsnNumero = true;
    }
  }

  emailToAssistenza(){
    window.location.href = this.emailAssistenza;
  }

  ionViewWillEnter(){
    this.logger.log('HomePage: ----- ionViewWillEnter ----- ');
    this.onResize(null); 
    this.SCManagerTrigger = false;
    this.initializeShoppingCartBadge();
  }

  ionViewDidEnter(){
    this.logger.log('HomePage: ----- ionViewDidEnter ----- ');

    if(!this.authService.isLoggedIn()){
      // Se l'utente non è loggato
      this.isNotLoggedIn = true;
    }else if(this.userService.userIsClient() && !this.userProfile){
      // Se l'utente è loggato ed è un cliente
      this.isNotLoggedIn = false;
      
      // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
      // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
      if(this.authService.hasToRefreshBasicAuth()){
        this.authService.refreshBasicAuthToken().then(
          go => {
            this.getUserProfile();
          }
        )
      }else{
        // ..Altrimenti chiedo i dati senza eseguire il refresh
        this.getUserProfile();
      }
    }else if(this.userService.userIsClient() && this.userProfile){
      // Se l'utente è loggato ed è un cliente e abbiamo già un profilo
      this.isNotLoggedIn = false;
    }else if(this.userService.userIsVendor()){
      // Se l'utente è un venditore allora vedo quanti ordini ha, al fine di lanciare un badge
      if(this.vendorOrdersService.firstCall){
        // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
        // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
        if(this.authService.hasToRefreshBasicAuth()){
          this.authService.refreshBasicAuthToken().then(
            go => {
              this.fetchingOrders();
            }
          )
        }else{
          // ..Altrimenti chiedo i dati senza eseguire il refresh
          this.fetchingOrders();
        }
      }
    }
  }

  async ionViewWillLeave(){
    this.logger.log('HomePage: ----- ionViewWillLeave  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popoverTemp = await this.popoverController.getTop();
      if(popoverTemp){
        await popoverTemp.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  // Tiene conto dei cambi di screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //this.logger.log('HomePage: cambio di screen size -> '+this.innerWidth);  // DEBUG
    this.screenManager();
  }

  private screenManager(){
    // Gestisco il cambio di dimensione dello schermo 
    if(this.innerWidth<this.SCREEN_SIZE_XS){
      this.smallScreen = true;
      this.xsScreen = true;
    }else if(this.innerWidth<this.SCREEN_SIZE_SM_MD){
      this.smallScreen = true;
      this.xsScreen = false;
    }else{
      this.smallScreen = false;
      this.xsScreen = false;
    }
  }

  private getHomeData(){
    this.isFetching = true;
    
    this.homeService.fetchHomeSections().subscribe(
      (response: SezioneHome[]) => {
        this.sezioni = response;
        //this.logger.log('URL = '+this.sezioni[0].url);
        this.isFetching = false;
        this.errorMsg = ""
      },
      error => {
        this.logger.log("fetchHomeSections returned an error:", error);
        this.isFetching = false;
        this.errorMsg = error.message;
      }
    )
  }

  ngOnDestroy(){
    if(this.homeServiceSubscription){
      this.homeServiceSubscription.unsubscribe();
    }
  }

  toUserProfile(){
    this.logger.log("Navigation from Home to UserProfile...");
    this.router.navigateByUrl("/myprofile");
  }

  toShoppingCart(){
    this.logger.log("Navigation from Home to ShoppingCart...");
    this.router.navigateByUrl("/shopping-cart");
  }

  initializeShoppingCartBadge(){
    // Controllo quali items ho nel carrello locale
    // Se l'utente è loggato e nel carrello locale ci sono items, allora li fondo
    // Se l'utente è loggato e nel carrello locale non ci sono items, inizializzo il badge
    // Se l'utente non è loggato inizializzo il badge con i dati del carrello locale
    if(!this.userService.userIsVendor() && this.userService.userIsClient()){
      
      this.shoppingCartManagerService.localCart_storageValues('HomePage').then(
        res => {
          this.SCManagerTrigger = true;
          this.logger.log('HomePage: local storage keys returned = '+res);
          this.localStorageKeys = res;
          this.cartBadge = this.localStorageKeys.length;

          var index = 0;
          for(let key of this.localStorageKeys){
            const temp_index = index;
            this.shoppingCartManagerService.localCart_getObject(key).then(
              value => { 
                this.local_cart[temp_index] = value;
                this.local_cart[temp_index]['key'] = key;

                if(temp_index==this.localStorageKeys.length-1 && this.authService.isLoggedIn()){
                  // Carrello locale contiene items, e l'utente è loggato -> fusione dei carrelli
                  this.logger.log('HomePage: Fusione del carrello locale con il carrello remoto.. Invio tramite POST del carrello locale');
                  
                  this.shoppingCartManagerService.mergeRemoteAndLocalShoppingCart('HomePage', this.local_cart).subscribe(
                    posts => {
                      this.logger.log(posts);
    
                      this.shoppingCartRetriever();
                    });
                }else{
                  // DEBUG prints
                  this.logger.log('Carrello locale vuoto (riga 186), temp index errato (next print), oppure isloggedin ritorna false (riga 189)');
                  this.logger.log(temp_index);
                }

              });
            index = index + 1;
            }

            if(this.localStorageKeys==null || this.localStorageKeys.length==0){
              this.logger.log('HomePage: carrello locale vuoto');
            }

            this.logger.log('HomePage: isLoggedIn = '+this.authService.isLoggedIn());

            if((this.localStorageKeys==null || this.localStorageKeys.length==0) && this.authService.isLoggedIn()){
              // Utente loggato normalmente e nessun elemento nel carrello locale
              this.shoppingCartRetriever();
            }else if(!this.authService.isLoggedIn()){
              // Utente non loggato
              this.shoppingCartManagerService.cartBadge = this.localStorageKeys.length;
            }
        }
      );
    }else if(this.userService.userIsVendor()){
      this.logger.log('HomePage: utente è un venditore -> NESSUN aggiornamento carrello');
    }else{
      // Utente non è loggato e non è venditore -> l'utente è un cliente non loggato
      this.logger.log('HomePage: utente NON loggato e cliente -> [carrello]');
      this.shoppingCartManagerService.localCart_storageValues('HomePage').then(
        res => {
          this.SCManagerTrigger = true;
          this.logger.log('HomePage: local storage keys returned = '+res);
          this.localStorageKeys = res;
          this.cartBadge = this.localStorageKeys.length;

          var index = 0;
          for(let key of this.localStorageKeys){
            const temp_index = index;
            this.shoppingCartManagerService.localCart_getObject(key).then(
              value => { 
                this.local_cart[temp_index] = value;
                this.local_cart[temp_index]['key'] = key;
              });
            index = index + 1;
          }

          if(this.localStorageKeys==null || this.localStorageKeys.length==0){
            this.logger.log('HomePage: carrello locale vuoto');
          }

          this.logger.log('HomePage: isLoggedIn = '+this.authService.isLoggedIn());

          if((this.localStorageKeys==null || this.localStorageKeys.length==0) && this.authService.isLoggedIn()){
            // Utente loggato normalmente e nessun elemento nel carrello locale
            this.logger.log('PROBLEMONE: utente risulta loggato, qui però non dovrebbe arrivarci......');
          }else if(!this.authService.isLoggedIn()){
            // Utente non loggato
            this.shoppingCartManagerService.cartBadge = this.localStorageKeys.length;
          }
      });
    }

  }

  shoppingCartRetriever(){
    if(this.authService.isLoggedIn()){
      this.userService.fetchMyProfile().then(
        ended => {
          if(this.userService.userIsClient()){
            this.logger.log('HomePage: utente loggato, inizializzazione badge del carrello tramite API');
            this.shoppingCartManagerService.getShoppingCartBadge('HomePage').subscribe(
              posts => {
                this.logger.log('HomePage: cartBadge returned = '+posts);
                this.cartBadge = posts;
                this.hasRemoteCart = true;
              },
              error => {
                this.logger.log('HomePage: error returned... cartBadge = null');
                this.cartBadge = null;
                this.hasRemoteCart = false;
                this.logger.log(error);
              }
            )
          }else{
            // utente loggato non è cliente 
            this.logger.log('HomePage: utente venditore -> aggiornamento carrello evitato');
          }
        }
      );
    }else{
      // Badge del carrello inizializzato in storageValues 
      this.logger.log('HomePage: utente non loggato, inizializzazione badge del carrello tramite local storage');
    }
  }


  onReloadHomeData(){
    this.logger.log('HomePage: onReloadHomeData() called');
    this.authService.initUserToken();
    this.ngOnInit();
    this.ionViewWillEnter();
  }

  onMoreInfo(){
    this.logger.log('HomePage: onMoreInfo() called');
    this.router.navigate(['how-it-works']);
  }


  //                                                USER ANIMAL PART ----->

  getUserProfile(){
    this.logger.log("HomePage: --------->   START getUserProfile  <---------");
    
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.userProfile = < BuyerUserProfile >userProfile;
        this.logger.log('HomePage: GET results:');
        this.logger.log(this.userProfile);
        if(this.userProfile!=null){
          this.hasUserProfileInfo = true;
          // Il metodo qui differisce rispetto all'implementazione nel profilo utente
          this.computeProgressValueAndName();
        }else{
          this.hasUserProfileInfo = false;
        }
      },
      error => {
        this.hasUserProfileInfo = false;
        this.logger.log('HomePage: GET..... an error occurred');
        this.logger.log(error);
      }
    );
    this.logger.log("HomePage: --------->   END getUserProfile  <---------");
  }

  onAnimalPressed(){
    this.CreatePopover(null);
  }

  async CreatePopover(ev: any) {
    var donazioni = 0;
    if(this.authService.isLoggedIn() && this.userProfile){
      donazioni = this.roundTo(this.userProfile.totale_donazioni,0);
    }
    this.logger.log(this.hasUserProfileInfo);

    
    this.pop = await this.popoverController.create({
      component: AnimalPopover,
      cssClass: 'animal-popover-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        "user": this.hasUserProfileInfo,
        "index": this.animalIndex,
        "progress": this.progressValue,
        "progressValueUnbounded": this.progressValueUnbounded,
        "progressBound": this.progressBound,
        "donazioni": donazioni
      }
    });
    return await this.pop.present();
  }

  private basePath: string = 'assets/icon/animal/';
  public animalPicture: string = 'assets/icon/animal/kiwi.JPG';
  private animalName: string = 'Kiwi';
  private progressValue: number = 0.1;
  private progressValueUnbounded: number = 0;
  private progressBound: number = 20;
  private valueRangeVector = [20,50,100,200,350,500,750,1000,1500,3000,4500,6000,8000,10000,20000,99999];
  private animalIndex: number = 0;
  private picPathVector = ['kiwi.JPG','colocolo.JPG','pappagallo.JPG','caretta.JPG','pangolino.JPG',
                              'mosco.JPG','pinguino.JPG','aquila.JPG','delfino.JPG',
                              'tigre.JPG','panda.JPG','squalo.JPG','rinoceronte.JPG','elefante.JPG',
                              'balenottera.JPG','drago_blu.png'];

  setTotaleDonazioni(){
    this.logger.log("HomePage.setTotaleDonazioni(): START");
    this.totaleDonazioni = 0;

    // NEW VERSION
    if(this.userProfile.assegnazioni){
      if(this.userProfile.assegnazioni[this.RIFORESTAZIONE]){
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.RIFORESTAZIONE].somma_punti_foglia;
      }
      if(this.userProfile.assegnazioni[this.PLASTICA]){
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.PLASTICA].somma_punti_foglia;
      }
      if(this.userProfile.assegnazioni[this.SOCIALI]){
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.SOCIALI].somma_punti_foglia;
      }
      // Solo alla fine, arrotondo (in difetto) il computo dei punti foglia
      this.totaleDonazioni = Math.floor(this.totaleDonazioni);
    }

    this.logger.log("HomePage.setTotaleDonazioni(): END");
  }

  computeProgressValueAndName(){
    this.setTotaleDonazioni();

    var resultIndex = 0;
    for(let range of this.valueRangeVector){
      // OLD: this.roundTo(this.userProfile.totale_donazioni,0) invece di this.totaleDonazioni
      if(this.totaleDonazioni>range){
        resultIndex = resultIndex+1;
      }else{
        break;
      }
    }
    if(resultIndex!=0){
      this.logger.log('AnimalPopover: resultIndex = '+resultIndex);
      this.progressValue = (this.totaleDonazioni - this.valueRangeVector[resultIndex-1]) / 
                      (this.valueRangeVector[resultIndex] - this.valueRangeVector[resultIndex-1]);
      this.progressValueUnbounded = (this.totaleDonazioni - this.valueRangeVector[resultIndex-1]);
      this.progressBound = (this.valueRangeVector[resultIndex] - this.valueRangeVector[resultIndex-1]);
      this.animalIndex = resultIndex;
      this.animalPicture = this.basePath+this.picPathVector[resultIndex];
    }else if(resultIndex==0){
      this.logger.log('AnimalPopover: resultIndex = ZERO   --> si tratta del primo animale');
      this.progressValue = this.totaleDonazioni / this.valueRangeVector[resultIndex];
      this.progressValueUnbounded = this.totaleDonazioni;
      this.progressBound = this.valueRangeVector[resultIndex];
      this.animalIndex = resultIndex;
      this.animalPicture = this.basePath+this.picPathVector[resultIndex];
    }
    this.logger.log('AnimalPopover: progress calcolato = '+this.progressValue);
    this.logger.log('AnimalPopover: nome animale calcolato = '+this.animalName);
    this.logger.log('AnimalPopover: path foto animale calcolato = '+this.animalPicture);
    }

    // <-----

    roundTo(num: number, places: number) {
      const factor = 10 ** places;
      return Math.round(num * factor) / factor;
    }

    // SEARCHBAR MANAGER
    onInput($event){
      this.logger.log('HomePage: SEARCH ------> onInput');
      this.searchbar.getInputElement().then(input => {
        var inputValue = input.value; 
        this.searchInput = inputValue;
        if(this.searchInput!=null && this.searchInput!=' ' && this.searchInput.length>1){
          this.autocompleteRequest(this.searchInput);
        }else{
          this.hasSuggestion = false;
        }
        this.logger.log('searchInput: '+this.searchInput);
      });
    }
  
    onCancel($event){
      this.logger.log('HomePage: SEARCH - onCancel');
      this.searchInput = '';
      this.logger.log('searchInput: '+this.searchInput);
      this.hasSuggestion = false; // Closing suggestions list
    }
  
    onSearch($event){  
      if($event && $event.key=='Enter'){  
        this.logger.log('HomePage: SEARCH - onSearch');
        this.logger.log('searchInput: '+this.searchInput);
        
        this.startSearch();
      }
    }

    startSearch(){
      this.logger.log('HomePage: starting search for searchInput = '+this.searchInput);
      if(this.searchInput){
        let navigationExtras: NavigationExtras = { state: { search: this.searchInput } };
        let destinationURL = '/products';
        this.router.navigate([destinationURL], navigationExtras);
      }
    }

    autocompleteRequest(input: string){
      this.logger.log("HomePage: autocompleteRequest started with input = "+input);
      var requestParams = {'term':input};
      this.autocompleteResult = null;
      this.autoCompleteService.fetchProductsByUrlAndParams(null, requestParams).subscribe(
        posts => {
          this.autocompleteResult = ""+posts;
          this.logger.log('HomePage: autocompleteRequest ended with result = '+this.autocompleteResult);
          if(this.autocompleteResult!=null && this.autocompleteResult!='[]'){
            this.hasSuggestion = true;
            var result_cut = this.autocompleteResult.substring(1,this.autocompleteResult.length-1);
            this.suggestions = result_cut.split(", ");
            if(this.suggestions.length>7){
              this.suggestions = this.suggestions.slice(0,6);
            }
          }else{
            this.hasSuggestion = false;
          }
        },
        error => {
          this.error = error.message;
          this.logger.log('HomePage: autocompleteRequest ended with error = '+this.error);
        }
      );
    }

    pickSuggestion(suggestion: string){
      this.logger.log("HomePage: suggestion ("+suggestion+") picked");
      this.searchbar.value = suggestion;
      //this.hasSuggestion = false;
      this.searchInput = suggestion;
      this.startSearch();
    }

    toHowItWorks(){
      this.logger.log('HomePage: verso Come Funziona...');
      this.router.navigate(['how-it-works']);
    }

    toUs(){
      this.router.navigate(['about-us']);
    }

    toSpedizioniEResi(){
      this.router.navigate(['shipping-policy']);
    }

    toSaveThePlanet(){
      this.router.navigate(['save-the-planet']);
    }

    toMission(){
      this.router.navigate(['mission']);
    }

    toPlasticService(){
      this.logger.log('Navigating to our Plastic Service...');
      window.location.href='https://www.4ocean.com/pages/our-story';
    }

    toSocialService(){
      this.logger.log('Navigating to our Social Service...');
      window.location.href = 'https://www.emergency.it/';
    }

    toTreeService(){
      this.logger.log('Navigating to our Tree Service...');
      window.location.href = 'https://tree-nation.com/profile/emplace';
    }

    toCategoryPage(){
      this.logger.log('HomePage: toCategoryPage()');
      this.router.navigate(['/categories']);
    }

    toPaginaProdotti(n: string){
      this.logger.log('HomePage: navigating to PaginaProdotti');
      var nome = 'Top sostenibili';
      var tipo = 'punti_foglia';
      var ordering = null;
      if(n=='NOVITA'){
        nome = 'Novità';
        tipo = 'data_inserimento';
        ordering = 'desc';
      }else if(n=='RECENSITI'){
        tipo = 'top_venduti';
        ordering = 'desc';
        nome = 'I più venduti';
      }else if(n=='TOP_SOSTENIBILI'){
        tipo = 'tagsost';
        ordering = 'desc';
        nome = 'Top sostenibili';
      }
  
      let navigationExtras: NavigationExtras = { state: { name: nome, type: tipo, ordering: ordering, index: null } };
      let destinationURL = '/products';
      this.router.navigate([destinationURL], navigationExtras);
    }

    fetchingOrders(){      
      this.vendorOrdersService.firstCall = false;
      this.hasOrders = false;
      this.vendorOrdersService.fetchOrders(null).subscribe(
        (vendorOrdersResult: VendorOrderItem[]) => {
          this.isFetching = false;
          this.logger.log('HomePage: GET of orders retrieved something...');
          this.logger.log(vendorOrdersResult);

          // Versione con risposta API che ritorna direttamente un array di oggetti 
          this.orders = vendorOrdersResult;
          
          this.logger.log(this.orders);
          if(this.orders.length>0){
            this.hasOrders = true;

            this.howManyPendingOrders();
          }else{
            this.closedOrders = 0;
            this.pendingOrders = 0;
          }
        },
        error => {
          this.hasOrders = false;
          this.error = error.message;
          this.logger.log('HomePage: GET..... an error occurred');
        }
      );
    }

    howManyPendingOrders(){
      this.logger.log('HomePage: howManyPendingOrders launched');
      this.pendingOrdersArray = [];
      this.closedOrdersArray = [];
      this.pendingOrders = 0;
      this.closedOrders = 0;
      for(let item of this.orders){
        if(this.itemPending(item)){
          this.pendingOrders = this.pendingOrders + 1;
          this.pendingOrdersArray.push(item);
        }else{
          this.closedOrders = this.closedOrders + 1;
          this.closedOrdersArray.push(item);
        }
      }
      this.vendorOrdersService.ordersCounter = this.pendingOrders;
      this.logger.log('..howManyPendingOrders returns: '+this.vendorOrdersService.ordersCounter);
    }
  
    itemPending(item: VendorOrderItem){
      if(item){
        var isWholeItemPending = false;
        for(let orderItem of item.elementi_ordine){
          if(orderItem.stato=="Consegnato"){
            return false;
          }else{
            isWholeItemPending = true;
          }
        }
        return isWholeItemPending;
      }else{
        return false;
      }
    }

    toScopriDiPiu(){
      this.logger.log('HomePage: toScopriDiPiu()...');
      this.CreateInfoForVendorPopover(null);
    }

    async CreateInfoForVendorPopover(ev: any) {
      this.pop = await this.popoverController.create({
        component: InfoForVendorPopoverComponent,
        cssClass: 'vendor-popover-style-class',
        event: ev,
        translucent: true,
        componentProps: {
          "text": 'testo'
        }
      });
      return await this.pop.present();
    }
}
