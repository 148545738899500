<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="8" size-sm="10" size-md="10" size-lg="10" size-xl="10" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 12px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              In cosa crediamo
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="8" size-sm="10" size-md="10" size-lg="10" size-xl="10" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              In cosa crediamo
            </ion-title>
          </div>
        </ion-col>

        <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1"></ion-col>

      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

<ion-content>

  <img src="assets/images/mani_pacco_dark.png" style="width: 100%; object-fit: cover; position: absolute;" class="img">
  <img src="assets/icon/emplace_bianco.png" style="position: absolute; top: 2%; right: 2%; max-width: 100px;">
  <swiper-container pagination="true" loop="true" autoplay="true" slidesPerView="1" initialSlide="0" speed="{{SLOW_SLIDING_TIME}}"
  disableOnInteraction="false" class="home-slides">
    <!-- PRIMA SLIDE -->
    <swiper-slide>
      <div style="width: 100%; height: 100%; color: #fff; position: absolute;" class="div-top">
        <ion-grid style="max-width: 900px; margin: auto;">
          <ion-row>
            <ion-col size="12" style="text-align: left;">
              <p style="margin-bottom: 0px;"><strong>Donazioni</strong></p>
            </ion-col>
            <ion-col size="12" style="text-align: left;">
              <p style="margin-top: 0px;" class="text-size">{{testo1}}</p>
            </ion-col>
          </ion-row>

          <ion-row style="position: absolute; bottom: 20%;">
            <ion-col size="12" style="text-align: center;">
              <p class="message-size">{{testo3}}</p>
            </ion-col>
          </ion-row>

        </ion-grid>
      </div>
    </swiper-slide>

    <!-- SECONDA SLIDE -->
    <swiper-slide>
      <div style="width: 100%; height: 100%; color: #fff; position: absolute;" class="div-top">
        <ion-grid style="max-width: 900px; margin: auto;">
          <ion-row>
            <ion-col size="12" style="text-align: left;">
              <p style="margin-bottom: 0px;"><strong>Mission</strong></p>
            </ion-col>
            <ion-col size="12" style="text-align: left;">
              <p style="margin-top: 0px;" class="text-size">{{testo4}}</p>
            </ion-col>
          </ion-row>

          <ion-row style="position: absolute; bottom: 20%;">
            <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6"></ion-col>
            <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" style="text-align: left;">
              <p style="margin-bottom: 0px;"><strong>Vision</strong></p>
            </ion-col>
            <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6"></ion-col>
            <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" style="text-align: left;">
              <p style="margin-top: 0px;" class="text-size">{{testo5}}</p>
            </ion-col>
          </ion-row>

        </ion-grid>
      </div>
    </swiper-slide>

  </swiper-container>

</ion-content>