<ion-card style="background-image: linear-gradient(to bottom right, #22577A, #38A3A5); margin:0px;">
    <ion-card-header style="color:#ffffff; font-size:16px;"><strong>Istruzioni</strong></ion-card-header>
    <ion-card-content style="text-align: left;">

        <p style="font-size:16px; color:#ffffff;">Sarai tu il responsabile della spedizione. 
            Potrai scegliere il corriere che preferisci <i>(recandoti in un ufficio postale o online sui comparatori di spedizione 
            come <a href="https://www.iospedisco.it" style="color:white;">iospedisco.it</a>, dove puoi trovare quello che fa più per te)</i> ricordando che:
        </p>
        
            <ul style="color: white; margin-top: 16px;">
                <li><p style="font-size:16px; color:#ffffff;">
                    La spedizione deve essere <strong>tracciata</strong>. 
                    Devi perciò richiedere un tipo di corriere che ti garantisca il tracciamento del pacco tramite un <strong>codice di tracciamento</strong> 
                    (anche detto <i>codice di tracking</i> o indicato come <i>lettera di voltura</i> o come numero vicino al <i>codice a barre</i>);</p>
                </li>
                <li style="margin-top: 16px;"><p style="font-size:16px; color:#ffffff;">
                    Non appena avrai effettuato la spedizione, 
                    cambia lo stato dell'ordine da "In preparazione" a <strong>"Spedito"</strong> cliccando su <u>Spedisci</u> ed inserisci:</p>
                    <ul style="color: white;">
                        <li><p style="font-size:16px; color:#ffffff;">
                        Codice di tracking</p>
                        </li>
                        <li><p style="font-size:16px; color:#ffffff;">
                        Corriere</p>
                        </li>
                    </ul>
                    <p style="font-size:16px; color:#ffffff;"><i>
                    N.B.: verifica tu stesso che il codice funzioni prima di inserirlo. 
                    Ti basta cercare su internet il nome del corriere scelto + "traccia spedizione";</i></p>
                </li>
                <li style="margin-top: 16px;"><p style="font-size:16px; color:#ffffff;">
                    Monitora costantemente la spedizione e non appena il pacco risulta consegnato, 
                    ricordati di <u>cambiare lo stato dell'ordine</u> in <strong>"Consegnato"</strong>.</p>
                </li>
            </ul> 
        <p style="font-size:16px; color:#ffffff;"><i>
            N.B.: Emplace <strong>ti bonificherà il ricavato della vendita</strong> di quest'ordine 
            SOLO dopo che il pacco sarà consegnato ed avrai cambiato lo stato dell'ordine in <strong>"Consegnato"</strong>.</i>
        </p>

        <p style="font-size:16px; color:#ffffff; margin-top: 16px;">
            Grazie alla spedizione tracciata sia il cliente che Emplace potranno monitorare la spedizione. 
            <i>Ricorda quindi che ogni comportamento fraudolento durante il processo potrà pregiudicare 
            il tuo futuro come venditore di Emplace.</i>
        </p>
            
        
        <p style="font-size:16px; color:#ffffff; margin-top: 16px;">
            Per qualunque dubbio non esitare a contattarci a: <a [href]="email" style="color: white;"><strong>assistenza@emplacestore.it</strong></a>
        </p>
        

        <p style="font-size:16px; color:#ffffff; text-align: right; cursor: pointer;" (click)="closePopup()"><strong>OK, chiudi</strong></p>
    </ion-card-content>
</ion-card>