import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as AUTH_CONST from 'src/app/shared/constants/auth.constants';
import { AuthResult } from 'src/app/shared/models/Auth.models';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-bimming',
  templateUrl: './bimming.component.html',
  styleUrls: ['./bimming.component.scss'],
})
export class BimmingComponent implements OnInit {

  // Questo passerà alla storia come il gesto più inutile della storia della programmazione:
  // Chiamare un component BIMMING invece di BILLING solo perchè la URL nascosta sarà BIMMING-qualcosa-qualcosa
  // ..invece di BILLING-qualcosa-qualcosa, per il solo terrore infondato che la parola BILLING venga in qualche modo individuata

  isFetching = false;
  errorOccurred = false;
  next = null;
  private activateDidLeave = false;
  basicLoginForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService, private logger: NGXLogger,
    private router: Router, private shoppingCartManagerService: ShoppingCartManagerService,
    public alertController: AlertController,
    public platform: Platform,
    private formBuilder: FormBuilder
  ) { 
    // Recupero l'extra dalla pagina sorgente
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        var state = this.router.getCurrentNavigation().extras.state;
        this.getParameters(state);
      }
    });
  }

  getParameters(state: any){
    this.logger.log('BimmingLoginPage: getParameters launched');
    if(state.error){
      this.logger.log('Ottenuto un errore: '+state.error);
      this.launchLoginErrorAlert(state.where, state.error);
    }
  }

  ngOnInit() {
    this.initManualLoginForm();
   }

  ionViewWillEnter(){
    this.activatedRoute.queryParams.subscribe(params => {
      this.next = params['next'];
      this.logger.log("BimmingLoginPage", "next = ", this.next);
    });
    this.isFetching = false;
    this.errorOccurred = false;
    this.activateDidLeave = false;
  }

  async launchLoginErrorAlert(header: string, text: string){
    if(!text || !header){
      header = 'Errore'
      text = 'Torna indietro e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Login',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  ngOnDestroy(){
  }

  ionViewDidLeave(){
    this.logger.log('BimmingLoginPage: ---> IonViewDidLeave  <---');
    if(this.activateDidLeave){
      // Preparo l'aggiornamento del carrello
      this.logger.log('BimmingLoginPage: preparo aggiornamento del carrello');

      this.activateDidLeave = false;
    }
  }

  onLogin(){
    // Loging out as client or vendor before to log in as superuser
    this.logoutBeforeBillingLogin();
    this.logger.log('BimmingLoginPage: User logged in = '+this.authService.isLoggedIn());

    // Trying to log in as super user
    this.onBasicLogin();
  }

  onBasicLogin(){
    this.logger.log('BimmingLoginPage: basic login procedure started... with email = '+this.basicLoginForm.get('email').value);
    this.authService.manuallyLogin(null, this.basicLoginForm.get('email').value, this.basicLoginForm.get('password').value, true).subscribe(
      res => {
        this.logger.log(res);
        if(res && !res.resultSuccess){
          this.basicAuthErrorAlert(null,null);
        }

        // Se il login avviene con successo, allora vai alla pagina del BILLING (lista di ordini)
        this.router.navigate(['billing/order-list']);
      },
      error => {
        this.logger.log(error);
        // Con parametri null viene lanciato l'alert di default
        this.basicAuthErrorAlert(null,null);
      }
    )
  }

  initManualLoginForm(){
    // psw validation: at least 8 chars and using both upper case, lower case and numbers
    this.basicLoginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.maxLength(41)])],
      password: ['', Validators.compose([Validators.required, /*Validators.maxLength(20), Validators.minLength(8), 
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?+\-_&,:;.=])*[a-zA-Z0-9\d$@$!%*#?+\-_&,:;.=]+$')*/])]
    });  
  }

  async basicAuthErrorAlert(header: string, text: string){
    if(!text || !header){
      header = 'Email o password errate..'
      text = 'Inseriscile di nuovo e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Errore durante il Login',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  logoutBeforeBillingLogin(){
    this.shoppingCartManagerService.resetShoppingCart();
    this.authService.resetUserData(); // Richiamato il metodo presente in authservice 
  }

}