import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomePage } from './home.page';
import { GetProdottiService } from '../shared/services/getProdotti.service';
import { GrigliaProdottiModule } from '../products/griglia-prodotti/griglia-prodotti.module';
import { SezioneHomeComponent } from './sezione-home/sezione-home.component';
import { HomeService } from '../shared/services/home.service';
import { RouterModule } from '@angular/router';
import { CategoryProductsService } from '../shared/services/categoryProducts.service';
import { ShoppingCartItemsService } from '../shared/services/shopping-cart-items.service';
import { UserService } from '../shared/services/User.service';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { InfoForVendorPopoverComponent } from './info-for-vendor-popover/info-for-vendor-popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GrigliaProdottiModule,
    RouterModule.forChild([
      { path: '', component: HomePage, pathMatch: 'full' },
      { path: '**', redirectTo: '404' },
      { path: '404', component: PageNotFoundComponent}
    ])
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [GetProdottiService, HomeService, CategoryProductsService, ShoppingCartItemsService, 
    UserService],
  declarations: [HomePage, SezioneHomeComponent, InfoForVendorPopoverComponent]
})
export class HomePageModule {}
