import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UserAddressPageComponent } from './user-address-page.component';
import { UserPositionsService } from 'src/app/shared/services/user-positions.service';
import { ShippingAddressModule } from '../shipping-address/shipping-address.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ShippingAddressModule
  ],
  providers: [UserPositionsService],
  declarations: [UserAddressPageComponent],
  exports: [UserAddressPageComponent]
})
export class UserAddressPageModule {}