
<ion-grid style="width: 100%;">
  <ion-row style="padding: 0px;">
    <ion-col size="12" style="padding: 0px;">
      <ion-grid style="padding: 0px; width: 100%;">
        <ion-row>
          <ion-col size="9" style="padding: 0px; padding-left: 8px;">
            <p class="msg-title" style="margin-bottom: 3px;" *ngIf="smallScreen">
              <ion-text><strong>{{titolo1}}</strong></ion-text>
            </p>
            <p class="msg-title" style="margin-top: 3px; margin-bottom: 3px;" *ngIf="smallScreen">
              <ion-text><strong>{{titolo1_11}}</strong></ion-text>
            </p>
            <p class="msg-title" style="margin-top: 3px; margin-bottom: 3px;" *ngIf="smallScreen">
              <ion-text color="primary" (click)="toInfo()" style="cursor: pointer; font-size: 16px;"><strong>{{titolo1_1}}</strong></ion-text>
              <ion-icon name="information-circle" style="color: var(--ion-color-sea); cursor: pointer; width: 20px; height: 20px; margin-bottom: -2px;"></ion-icon>
            </p>
            <p class="msg-title" style="margin-bottom: 3px;" *ngIf="!smallScreen">
              <ion-text><strong>{{titolo1}}</strong></ion-text><ion-text><strong>{{titolo1_11}}</strong></ion-text>
              <ion-text color="primary" (click)="toInfo()" style="cursor: pointer; font-size: 16px;"><strong>{{titolo1_1}}</strong></ion-text>
              <ion-icon name="information-circle" style="color: var(--ion-color-sea); cursor: pointer; width: 20px; height: 20px; margin-bottom: -2px;"></ion-icon>
            </p>
          </ion-col>
          <ion-col size="3" style="padding-right: 0px;">
            <ion-button style="float: right; margin-right: 0px;" fill="clear" (click)="onClose()">
              <ion-icon name="close-circle" color="danger"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="modeInfo">
          <ion-col size="12" style="margin: auto;">
            <p class="msg">
              <ion-text style="font-size: 14px;">{{testoInfo1}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo2}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo3}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo4}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo5}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo6}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo7}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo8}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo9}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo10}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo10_1}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo10_2}}</strong></ion-text>
              <ion-text style="font-size: 14px;">{{testoInfo11}}</ion-text>
              <ion-text style="font-size: 14px;"><strong>{{testoInfo12}}</strong></ion-text>
              <ion-text style="font-size: 14px;" *ngIf="!user_logged_in"><strong>{{testoInfo12_1}}</strong></ion-text>
            </p>
            <p class="msg" style="margin-top: 0px;" *ngIf="!user_logged_in">
              <ion-text style="font-size: 14px;">{{testoInfo13}}</ion-text>
            </p>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="3" style="text-align: center; margin: auto; align-items: center;">
            <!-- IMG INIZIALE -->
            <ion-button fill="clear">
              <ion-icon name="happy" style="color: var(--ion-color-sea); height: 35px; width: 35px;"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="9">
            <p class="msg"><strong>{{testo1}}</strong></p>
            <p class="msg">{{testo2}}</p>
          </ion-col>
        </ion-row>
        <ion-row >
          <ion-col size="3" style="text-align: center; margin: auto; align-items: center;">
            <!-- IMG INIZIALE -->
            <ion-button fill="clear">
              <ion-icon name="megaphone" style="color: var(--ion-color-sea); height: 35px; width: 35px;"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="9">
            <p class="msg"><strong>{{testo3}}</strong></p>
            <p class="msg">{{testo4}}</p>
          </ion-col>
        </ion-row>
        <ion-row >
          <ion-col size="3" style="text-align: center; margin: auto; align-items: center;">
            <!-- IMG INIZIALE -->
            <ion-button fill="clear">
              <ion-icon name="earth" style="color: var(--ion-color-sea); height: 35px; width: 35px;"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="9">
            <p class="msg"><strong>{{testo5}}</strong></p>
            <p class="msg">{{testo6}}</p>
          </ion-col>
        </ion-row>
        
      </ion-grid>
    </ion-col>
  </ion-row>
  <ion-row style="padding: 0px; margin-bottom: 4px;" *ngIf="!user_logged_in">
    <ion-col size="12" style="text-align: center;">
      <ion-button (click)="toRegistrati()">Registrati</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
