<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
      {{title}}
    </ion-title>

    <ion-title class="ion-margin" *ngIf="!platform.is('ios')" style="max-width: 130px; margin: auto;"> {{ title }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="padding-top: 0px; background-color: #def7df;">

  <!--<div class="error-div" style="padding: 12px; padding-top: 36px;">
    <img src="assets/images/floating.png" class="error-resize">
    <p class="error-msg"> Ordine effettuato con successo! </p>
    <p class="error-msg"> Guarda il frutto delle tue <a (click)="toUserProfile()">donazioni!</a>
      <ion-button (click)="toUserProfile()" color="#ffffff" icon-only fill="clear" class="home" style="margin-top: -12px;">
        <ion-icon color="primary" slot="icon-only" name="person-circle-outline">
        </ion-icon>
      </ion-button>
    </p>
    <p class="error-msg"> Visualizza i tuoi <a (click)="toOrders()">ordini</a> </p>
    <p class="error-msg"> ..oppure torna alla <a (click)="toHome()">Home</a> 
      <ion-button (click)="toHome()" color="#ffffff" icon-only fill="clear" class="home">
        <ion-icon color="primary" slot="icon-only" name="home">
        </ion-icon>
      </ion-button></p>
  </div>-->
  <ion-grid style="margin: auto; margin-top: 24px; padding: 0px;">
    <ion-row>

      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" style="text-align: center;">
        <div style="width: 100%;">
          <p style="color:var(--ion-color-primary); font-size: 20px;"><strong>Ordine effettuato con successo!</strong></p>

          <p style="margin-top: 0px; margin-bottom: 2px;"> Guarda il frutto delle tue <a (click)="toUserProfile()">donazioni!</a>
            <ion-button (click)="toUserProfile()" color="#ffffff" icon-only fill="clear" class="home" style="margin-top: -12px;">
              <ion-icon color="primary" slot="icon-only" name="person-circle-outline">
              </ion-icon>
            </ion-button>
          </p>
        
          <p style="margin-top: 0px; margin-bottom: 8px;"> Visualizza i tuoi <a (click)="toOrders()">ordini</a> </p>
        
          <p style="margin-top: 0px; margin-bottom: 8px;"> ..oppure torna alla <a (click)="toHome()">Home</a> 
            <ion-button (click)="toHome()" color="#ffffff" icon-only fill="clear" class="home">
              <ion-icon color="primary" slot="icon-only" name="home">
              </ion-icon>
            </ion-button>
          </p>
        </div>
      </ion-col>

      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" style="text-align: center;">
        <!--<img src="assets/images/floating.png" style="width: 100%;">-->
        <img src="assets/images/hot-air-balloon.png" style="width: 100%; max-width: 400px;" class="balloon">
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>