import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'popover-donation',
  templateUrl: 'donation-popover.component.html',
  styleUrls: ['./donation-popover.component.scss']
})
export class DonationPopover {
  constructor (private popoverProp: PopoverController, private navParams: NavParams, private router: Router) {
      this.modDonation = this.roundTo(navParams.get('modDonation'),2);
      //this.logger.log(this.modDonation); // DEBUG
      this.categoria = navParams.get('categoria');
      if(this.categoria=='Tree'){
        this.text = this.treeText;
        this.service = this.treeService;
        this.textShort = this.treeTextShort;
      }else if(this.categoria=='Plastic'){
        this.text = this.plasticText;
        this.service = this.plasticService;
        this.textShort = this.plasticTextShort;
      }else if(this.categoria=='Social'){
        this.text = this.socialText;
        this.service = this.socialService; 
      }else{
        this.router.navigate(['myprofile/user']);
      }
   }

  modDonation: number = 0;
  categoria: string = 'Tree';
  TASSO: number = 20;
  service: string = 'TreeNation';
  text: string = 'piantare un altro albero tramite il nostro servizio di fiducia: TreeNation';
  textShort: string = 'piantare un altro albero';

  private treeService = 'TreeNation';
  private treeText: string = 'piantare un altro albero tramite il nostro servizio di fiducia:';
  private plasticText: string = 'rimuovere un\'altra bottiglia di plastica dai fondali marini tramite il nostro servizio di fiducia:';
  private treeTextShort: string = 'piantare un altro albero';
  private plasticTextShort: string = 'smaltire un\'altra bottiglia di plastica dai fondali marini';
  private plasticService: string = '4Ocean';
  private socialService: string = 'Emergency';
  private socialText: string = 'finanziare il nostro progetto sociale di fiducia:';

  ClosePopover() {
    this.popoverProp.dismiss();
  }

  close(){
    this.ClosePopover();
  }

  toService(){
    if(this.service=='Tree'){
      window.location.href = 'https://tree-nation.com/profile/emplace';
    }else if(this.service=='Plastic'){
      window.location.href = 'https://www.4ocean.com/pages/our-story';
    }else if(this.service=='Social'){
      window.location.href = 'https://www.emergency.it/';
    }
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

}