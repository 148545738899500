import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Prodotti } from '../models/Prodotti.model';
import { ConstantUtilsService } from './constant-utils.service';
import { BillingElementDetail, BillingElementForVendor, BillingListItem, OrderList, OrderListDetail } from '../models/Billing.models';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class BillingService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private ordersToBePayedUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/emplace-administration/orders-to-be-payed/');
  private ordersPayedUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/emplace-administration/orders-payed/');
  private orderListUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/emplace-administration/orders/');
  error = new Subject<string>();

  // HTTP GET
  fetchOrderToBePayed(ID: string){
    const url = this.ordersToBePayedUrl + ID + "/";
    if(ID){
      return this.getOrderToBePayed(url);
    }else{
      this.logger.log('ID NON VALIDO');
      return null;
    }
  }

  fetchOrdersToBePayed(){
    return this.getOrdersToBePayed();
  }

  // HTTP GET
  fetchOrderAlreadyPayed(ID: string){
    const url = this.ordersPayedUrl + ID + "/";
    if(ID){
      return this.getOrderAlreadyPayed(url);
    }else{
      this.logger.log('ID NON VALIDO');
      return null;
    }
  }

  fetchOrdersAlreadyPayed(){
    return this.getOrdersAlreadyPayed();
  }

  // Fetch orders regardless of whether they are payed or not
  fetchOrders(next: string){
    if(!next){
      next = this.orderListUrl
    }
    return this.getOrders(next);
  }

  private getOrders(url: string){
    
    return this.http
    .get<OrderList>(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  // Fetch order detail regardless of whether it is payed or not
  fetchOrderDetail(id: string){
    return this.getOrderDetail(id);
  }

  private getOrderDetail(id: string){

    return this.http
    .get<OrderListDetail[]>(
      this.orderListUrl+id+'/',
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  private getOrdersToBePayed(){

    return this.http
    .get<BillingListItem[]>(
      this.ordersToBePayedUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  private getOrderToBePayed(url: string){

    var request_url = this.ordersToBePayedUrl;
    if(url){
      request_url = url;
    }else{
      this.logger.log('URL NON VALIDA');
      return null;
    }

    return this.http
    .get<BillingElementDetail[]>(
      request_url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  // HTTP POST
  onUpdateOrderToBePayed(ID: string, venditore_ID: string, pagato: boolean, fattura: boolean, dataPagamento: string, dataFattura: string){
    const url = this.ordersToBePayedUrl + ID + "/";
    if(ID){
      var param = {};
      if(pagato && fattura){
        param = {
          venditore: venditore_ID,
          pagato: pagato,
          fattura: fattura,
          data_pagamento: dataPagamento,
          data_fattura: dataFattura
        }
      }else if(pagato){
        param = {
          venditore: venditore_ID,
          pagato: pagato,
          data_pagamento: dataPagamento
        }
      }else if(fattura){
        param = {
          venditore: venditore_ID,
          fattura: fattura,
          data_fattura: dataFattura
        }
      }
      return this.updateOrderAlreadyPayed(url, param);
    }else{
      this.logger.log('ID NON VALIDO');
      return null;
    }
  }

  private updateOrderAlreadyPayed(url: string, reviewFormData: any){

    var request_url = this.ordersToBePayedUrl;
    if(url){
      request_url = url;
    }else{
      this.logger.log('URL NON VALIDA');
      return null;
    }

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    return this.http.put(request_url, 
        reviewFormData, 
        {headers: httpHeaders, responseType: 'json'}
        ).pipe(
          map(responseData => {
            return responseData;
          }),
          catchError(errorRes => {
            // Send to analytics server
            return throwError(errorRes);
          })
        );
  }

  private getOrdersAlreadyPayed(){

    return this.http
    .get<BillingElementForVendor[]>(
      this.ordersPayedUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  private getOrderAlreadyPayed(url: string){

    var request_url = this.ordersPayedUrl;
    if(url){
      request_url = url;
    }else{
      this.logger.log('URL NON VALIDA');
      return null;
    }

    return this.http
    .get<BillingElementForVendor>(
      request_url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

}
