import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ProdottoItem } from "src/app/shared/models/Prodotti.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ConstantUtilsService } from "src/app/shared/services/constant-utils.service";
import { UserService } from "src/app/shared/services/User.service";
import { SwipeProdottiComponent } from "../swipe-prodotti.component";
import { Platform } from "@ionic/angular";
import { NGXLogger } from "ngx-logger";


@Component({
    selector: 'app-scheda-prodotto',
    templateUrl: './scheda-prodotto.component.html',
    styleUrls: ['./scheda-prodotto.component.scss'],
})
export class SchedaProdottoComponent implements OnInit {
    @Input() swiper = null;
    @Input() prodotto: ProdottoItem = null;
    @Input() index: number;
    @Input() isLoading: boolean;

    TAGNameToFullName = {
        ProdCert: 'Prodotto Certificato',
        AzCert: 'Azienda Certificata',
        MatRic: 'Materiali Riciclati',
        MatCert: 'Materiali Certificati',
        Ric: 'Riciclabile',
        Km0: 'Km Zero',
        MatIta: 'Materiali Italiani',
        MiI: 'Made in Italy',
        Biod: 'Biodegradabile',
        Comp: 'Compostabile',
        MinWaste: 'Minimum Waste',
        EnSost: 'Energia Sostenibile',
        EcoDes: 'Eco Design',
        EcoLif: 'Eco Lifestyle',
        CulEco: 'Cultura Ecosostenibile',
        Altro: 'Altro',
        SostSoc: 'Sostenibilità Sociale',
        CausaGiusta: 'Causa Giusta',
        CulSost: 'Cultura Sostenibile',
        /*ComDeb: 'Comunità Deboli',*/
        Int: 'Integrazione'
      }

    constructor(
        private userService: UserService, private logger: NGXLogger,
        public constantUtilsService: ConstantUtilsService,
        private router: Router, public platform: Platform
    ){ }

    ngOnInit() { 
        this.logger.log('SchedaProdotto: ngOnInit');
    }

    hasPreviousProduct(){
        return this.index !== 0;
    }

    moveToPreviousProduct(){
        if(this.swiper){
            this.swiper.moveToPreviousProduct();
        }
    }

    hasNextProduct(){
        if(this.swiper){
            return this.swiper.hasNextProduct(this.index);
        }
        return false;
    }

    moveToNextProduct(){
        if(this.swiper){
            this.swiper.moveToNextProduct();
        }
    }

    onInsertToCart(){
        if(this.swiper){
            this.swiper.insertToCart(this.prodotto);
        }
    }

    moveToReviewsPage(){
        this.logger.log("Navigation to reviews page for product with id", this.prodotto.id);
        const destinationURL = '/products/' + this.prodotto.id + '/reviews' 
        // const destinationURL = '/products/reviews' 
        this.router.navigateByUrl(destinationURL);
        this.swiper.close();
    }
    
    toProductDetail(){
        this.logger.log('SchedaProdotto: toProductDetail() called with product id = '+this.prodotto.id);
        this.router.navigateByUrl("/products/" + this.prodotto.id + "/detail");
        this.swiper.close();
    }

    toVendor(){
        this.logger.log('SchedaProdotto: toVendor() called');
        this.router.navigateByUrl("vendors/vendor/"+this.prodotto.venditore.id);
        this.swiper.close();
    }
}