import { Component, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { EventEmitter } from '@angular/core';
import { Position } from 'src/app/shared/models/Position.models';
import { UserPositionsService } from 'src/app/shared/services/user-positions.service';
import { SADeleteAddressPopover } from './shipping-address-popover/sa-delete-address-popover.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss'],
})
export class ShippingAddressComponent implements OnInit {

  @Input() positions: Position[];
  @Input() type: string;
  hasPositions: boolean = false;
  isFetching: boolean = true;
  private currentSettings: Number;
  private firstTime: boolean = true;
  initialButton: Number;
  buttons: number;
  private array_id_to_index: any = [];
  @Output() id_changes = new EventEmitter();
  @Output() id_removed = new EventEmitter();
  @Output() index_changes = new EventEmitter();
  pop: any;

  constructor(private userPositionsService: UserPositionsService, private router: Router, private logger: NGXLogger,
              private popover: PopoverController, private alertController: AlertController) { }

  ngOnInit() {
    this.logger.log('ShippingAddressComponent: ngOnInit()');
    this.isFetching = true;
    this.initAddressList();
  }

  sendChanges() {
    // Method used to communicate changes with Parent component
    this.id_changes.emit(this.currentSettings);
    this.index_changes.emit(this.array_id_to_index[this.currentSettings.valueOf()]);
  }

  initAddressList(){
    this.logger.log('ShippingAddressComponent: initAddressList()');
    this.delay(700).then(
      resolve => {
        this.logger.log('ShippingAddressComponent: do il tempo a positions di inizializzarsi...');
        if(this.positions!=null){
          var i = 0;
          for(let x of this.positions){
            if(x && x.preferito){
              this.currentSettings = x.id;
              this.buttons = x.id.valueOf();
              this.logger.log('ID iniziale preferito = '+x.id);
              this.logger.log('Con buttons = '+this.buttons);
              if(this.firstTime){
                this.initialButton = this.currentSettings;
                this.firstTime = false;
              }
            }
            if(x){
              this.array_id_to_index[x.id.valueOf()] = i;
            }
            i += 1;
          }
          if(this.positions!=null && this.positions.length > 0){
            // Se l'utente non ha indirizzi non serve settare l'indirizzo preferito
            this.setNewPreferito();
            this.hasPositions = true;
          }
          this.logger.log('ShippingAddressComponent: type = '+this.type);
        }else{
          this.logger.log('ShippingAddressComponent: indirizzi in input = NULL!');
          this.logger.log('ShippingAddressComponent: type = '+this.type);
        }
        this.isFetching = false;
      })
  }

  setNewPreferito(){
    if(this.currentSettings!=null){
      var radio_index = this.array_id_to_index[this.currentSettings.valueOf()];
      //this.logger.log('[POST] currentSettings = '+this.currentSettings); // DEBUG
      //this.logger.log('[POST] indice = '+radio_index); // DEBUG
      this.positions[radio_index].preferito = true;
      this.sendChanges();
      //this.debugPositions();
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  onChangeRadioBtn($event){
    this.logger.log('ShippingAddressComponent: onChangeRadioBtn() called');
    this.logger.log($event);

    if(this.currentSettings==null){
      return;
    }

    var radio_index = this.array_id_to_index[this.currentSettings.valueOf()];
    //this.logger.log('[PRE] currentSettings = '+this.currentSettings); // DEBUG
    //this.logger.log('[PRE] indice = '+radio_index); // DEBUG
    
    if(this.currentSettings!=null && this.buttons!=null && this.positions[radio_index]!=null){
      //this.logger.log('ShippingAddressComponent: togliendo il preferito a ID= '+this.buttons+' con indice= '+radio_index); // DEBUG
      //this.debugPositions();
      this.positions[radio_index].preferito = false;
    }
    this.currentSettings = $event.detail.value;
    if(this.array_id_to_index[this.currentSettings.valueOf()]==null){
      // Si verifica quando viene aggiunto un nuovo elemento e subito dopo viene reso 'preferito' 
      // ..poichè l'array ID_to_indici non era ancora inizializzato
      this.initAddressList();
    }else{
      this.setNewPreferito();
    }
  }

  debugPositions(){
    // Funzione di SOLO debug per capire quali elementi ci sono in positions
    var i = 0;
    for(let p of this.positions){
      if(p){
        this.logger.log('indice: '+i);
        this.logger.log('id '+p.id);
        this.logger.log('preferito '+p.preferito);
      }
      i = i + 1;
    }
  }

  onAdd(){
    this.logger.log('ShippingAddressComponent: onAdd()');
    var url = "/myprofile/"+this.type+"/shipping-address/edit/new";
    let navigationExtras: NavigationExtras = {
      state: {
        type: this.type
      }
    };
    this.router.navigate([url], navigationExtras); 
  }

  onRemove(index: number){
    this.logger.log('ShippingAddressComponent: onRemove() su index = '+index);
    this.CreatePopover(null, index);
  }

  async CreatePopover(ev: any, index: number) {
    this.pop = await this.popover.create({
      component: SADeleteAddressPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {}
    });

    this.pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('ShippingAddressComponent: popover closed with boolean = '+result.data);
         if(result.data){
           // Utente ha premuto YES, vuole eliminare l'item
           if(this.positions[index]){
            this.logger.log('ShippingAddressComponent: richiedendo una delete per il address con id = '+this.positions[index].id);
            this.userPositionsService.deleteAddress(this.positions[index].id.valueOf(), this.type).subscribe(
              result => {
                this.logger.log('Eliminato.. Result -> '+result);
                var temp_array = [];
                var k = 0;
                for(let x of this.positions){
                  if(k!=index){
                    temp_array[k]=this.positions[k];
                  }else{
                    this.logger.log('ShippingAddressComponent: eliminato il numero '+index+' anche dalla lista locale');
                  }
                  k = k + 1;
                }
                this.positions = temp_array;
                //this.debugPositions();
                this.initAddressList();
                this.id_removed.emit(index);
                //this.debugPositions();
              },
              error => {
                this.logger.log('error -> '+error);
              }
            )
           }else{
             this.logger.log('ShippingAddressComponent: indice non corrisponde a nessun address id');
           }
         }else{
           // Utente non ha premuto YES, tutto rimane come prima
         }
      }
    });

    return await this.pop.present();
  }

  async ngOnDestroy(){
    this.logger.log('ShippingAddressComponent: ----- ngOnDestroy()  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  async infoAlert(){
    const alert = await this.alertController.create({
      header: 'Indirizzo verificato',
      cssClass: 'alert-info-popup-style',
      subHeader: ' ',
      message: 'L\'esistenza di questo indirizzo è stata verificata automaticamente da Emplace. Se per un indirizzo non compare questa dicitura, potrebbe esserci stato un errore di digitazione dell\'utente, oppure il meccanismo di ricerca di Emplace non è stato abbastanza accurato. In tal caso non spaventarti, ti consigliamo solo di ricontrollare.',
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

}
