import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { ProdottoItem } from "src/app/shared/models/Prodotti.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ProdottiParentComponent } from "../prodotti-parent.interface";
import { UserService } from "src/app/shared/services/User.service";
import { ShoppingCartManagerService } from "src/app/shared/services/shopping-cart-manager.service";
import { SchedaProdottoComponent } from "./scheda-prodotto/scheda-prodotto.component";
import { NGXLogger } from "ngx-logger";


@Component({
    selector: 'app-swipe-prodotti',
    templateUrl: './swipe-prodotti.component.html',
    styleUrls: ['./swipe-prodotti.component.scss'],
})
export class SwipeProdottiComponent implements OnInit {
    LOADING_OFFSET = 3;
    customOptions = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true
        }
    }

    newItemIntoShoppingCart: boolean = false;
    moreItemsIntoShoppingCart: Number = 0;

    parentComponent: ProdottiParentComponent;
    initialIndex: number;
    private activeIndexTemp: number;
    loadingProducts: boolean = false;
    endReached: boolean = false;

    isFetching: boolean = false;
    errorMsg = null;

    productAvailability: boolean = true;
    toCartBtnDisabled: boolean = false;
    private swiperToBeUpdated: boolean = false;

    private prodotti: ProdottoItem[];
    private currentItem: ProdottoItem;

    //@ViewChild('prodottiSlides') swiper: IonSlides; // Removed due to migration to Ionic v7
    @ViewChild('swiper')
    swiperRef: ElementRef | undefined;
    swipeLoading: boolean = false;

    constructor(
        private modalController: ModalController, public platform: Platform,
        private navParams: NavParams, private router: Router, private logger: NGXLogger,
        private authService: AuthService, public userService: UserService,
        private shoppingCartManagerService: ShoppingCartManagerService){
        this.parentComponent = navParams.get("parentComponent");
        this.initialIndex = navParams.get('index');
        this.swipeLoading = true;
    }

    ngOnInit() { }

    ionViewWillEnter(){
        // First element queue loading
        this.prodotti = this.parentComponent.getProdotti();
        this.logger.log('Swipe -> ionViewWillEnter prodotti: '+this.prodotti);
    }
    
    ionViewDidEnter(){
        // This call has to be done to prevent a bug when opening the modal more than once
        // The bug is that the swipe object does not work properly (the dots at the bottom are not shown, and the scroll does not work well)
        //this.swiper.update(); // Removed migrating to Ionic v7
        this.swiperRef?.nativeElement.swiper.update(); // Added migrating to Ionic v7
        this.openTo(this.initialIndex);
        this.swipeLoading = false;
        this.newItemIntoShoppingCart = false;
    }

    openTo(index){
        // this.logger.log("SwipeProdottiComponent", "openTo(): Swiping to product with index", index, this.parentComponent.getProdotti()[index])
        // this.swiper.slideTo(index, 0); // Removed migrating to Ionic v7
        this.swiperRef?.nativeElement.swiper.slideTo(index,0); // Added migrating to Ionic v7
    }

    productSwiped(event){
        /* this.swiper.getActiveIndex().then(activeIndex => {
            if(this.parentComponent.moreDataToLoad() && !this.parentComponent.fetchingProducts() && activeIndex > this.parentComponent.getProdotti().length - this.LOADING_OFFSET){
                this.logger.log("Loading more products");
                this.loadingProducts = true;
                this.parentComponent.loadData(event);
            }
        }) // Removed migrating to Ionic v7
        */
        // The rest of this function has been added migrating to Ionic v7
        const activeIndexTemp = this.swiperRef?.nativeElement.swiper.activeIndex;
        if(this.parentComponent.moreDataToLoad() && !this.parentComponent.fetchingProducts() && activeIndexTemp > this.parentComponent.getProdotti().length - this.LOADING_OFFSET){
            this.logger.log("Loading more products");
            this.loadingProducts = true;
            this.parentComponent.loadData(event);
            this.swiperToBeUpdated = true;
        }
    }

    getCurrentItem($event){
        this.logger.log('Swiped!');
        /*this.swiper.getActiveIndex().then(
            activeIndex => {
                this.logger.log('Active index = '+activeIndex);
                this.prodotti = this.parentComponent.getProdotti();
                this.currentItem = this.prodotti[activeIndex];
                this.logger.log('Current item con titolo: '+this.currentItem.nome);
                if(this.prodotti[activeIndex].quantita_disponibile>0){
                    this.productAvailability = true;
                }else{
                    this.productAvailability = false;
                }
                this.logger.log('Product availability = '+this.productAvailability);
                if(this.userService.userIsVendor() || !this.productAvailability){
                    this.toCartBtnDisabled = true;
                }else{
                    this.toCartBtnDisabled = false;
                }
                this.logger.log('Btn toCart disabled? --> '+this.toCartBtnDisabled);
            }
        ) // Removed migrating to Ionic v7
        */
        // The rest of this function has been added migrating to Ionic v7
        this.logger.log(this.swiperRef?.nativeElement.swiper); // DEBUG
        const activeIndexTemp = this.swiperRef?.nativeElement.swiper.activeIndex;
        this.logger.log('Active index = '+activeIndexTemp);
        this.prodotti = this.parentComponent.getProdotti();
        this.currentItem = this.prodotti[activeIndexTemp];
        this.logger.log('Current item con titolo: '+this.currentItem.nome);
        if(this.prodotti[activeIndexTemp].quantita_disponibile>0){
            this.productAvailability = true;
        }else{
            this.productAvailability = false;
        }
        this.logger.log('Product availability = '+this.productAvailability);
        if(this.userService.userIsVendor() || !this.productAvailability){
            this.toCartBtnDisabled = true;
        }else{
            this.toCartBtnDisabled = false;
        }
        this.logger.log('Btn toCart disabled? --> '+this.toCartBtnDisabled);
        //this.logger.log(this.parentComponent.getProdotti()); // DEBUG
        this.initialIndex = activeIndexTemp;
    }

    moveToPreviousProduct(){
        // Removed migrating to Ionic v7
        /*if(this.swiper){
            this.swiper.slidePrev();
        }*/
        // The following has been added migrating to Ionic v7
        if(this.swiperRef?.nativeElement.swiper){
            this.swiperRef?.nativeElement.swiper.slidePrev(0,true);
            // Added to reload products while swiping
            this.productSwiped(null);
        }
    }

    hasNextProduct(index){
        if(this.parentComponent){
            const prodotti = this.parentComponent.getProdotti();
            if(prodotti){
                return index < prodotti.length -1;
            }
        }
        return false;
    }

    moveToNextProduct(){
        // Removed migrating to Ionic v7
        /*if(this.swiper){
            this.swiper.slideNext();
        }*/
        // The following has been added migrating to Ionic v7
        if(this.swiperToBeUpdated){
            this.logger.log('Swiper: Aggiornamento degli elementi... index='+this.initialIndex);
            this.openTo(this.initialIndex);
            this.swiperRef?.nativeElement.swiper.updateSlidesClasses();
            this.swiperRef?.nativeElement.swiper.updateSlides();
            this.swiperRef?.nativeElement.swiper.update();
            this.swiperToBeUpdated = false;
        }
        if(this.swiperRef?.nativeElement.swiper){
            this.swiperRef?.nativeElement.swiper.slideNext(0,true);
            // Added to reload products while swiping
            this.productSwiped(null);
        }
    }

    close(){
        let data = { 'updateCart': false };
        if(this.newItemIntoShoppingCart){
            data = { 'updateCart': true };
        }
        this.modalController.dismiss(data);
    }

    toCart(){
        this.logger.log('Swipe: -Vai al carrello- premuto');
        this.router.navigateByUrl("/shopping-cart");
        this.close();
    }

    insertToCart(prodotto: ProdottoItem){
        this.logger.log('Swipe -> Scheda Prodotto: -Inserisci nel carrello- premuto');
        
        this.newItemIntoShoppingCart = true;
        this.moreItemsIntoShoppingCart = Number(this.moreItemsIntoShoppingCart) + 1;

        this.parentComponent.onInsertToCart(prodotto);
    }

    putIntoCart(){
        this.logger.log('Swipe -> Scheda Prodotto: putIntoCart premuto');
        if(!this.toCartBtnDisabled){
            if(!this.currentItem){
                this.currentItem = this.prodotti[0];
            }
            this.insertToCart(this.currentItem);
        }
    }

}