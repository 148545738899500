import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserTokenData, SocialUserData, LoginRequestData, RefreshTokenRequestData, AuthResult, ManualLoginRequestData, ManualUserTokenData, RefreshBasicAuthTokenRequestData, ManualRegistrationRequestClientData, ManualRegistrationRequestVendorData, LoginRequestDataSecured, RefreshTokenRequestDataSecured, SocialRegistrationRequestVendorData } from '../models/Auth.models';
import * as AUTH_CONST from '../constants/auth.constants';

import { FacebookLogin } from '@capacitor-community/facebook-login';  /* Nell'import (anche se non usato): FacebookLoginResponse*/
import { Preferences } from '@capacitor/preferences';

import { catchError, map } from 'rxjs/operators';
import { of, Subject, throwError } from 'rxjs';
import { ConstantUtilsService } from './constant-utils.service';
import { ShoppingCartManagerService } from './shopping-cart-manager.service';
//import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';  /* Nell'import (anche se non usato): GoogleAuthPlugin */
import { MenuService } from './menu.service';
import { NGXLogger } from 'ngx-logger';

/* NEW Google Identity Services var declaration */
declare var google: any;


@Injectable({ providedIn: 'root' })
export class AuthService {
    GOOGLE_BACKEND = "google-oauth2";
    FACEBOOK_BACKEND = "facebook";
    
    DJANGO_CLIENT_IDS = {
        "google-oauth2": "6FF1DzlSFJojCADkTemaEp9xdAtUuPWb7pr1q5Nw",
        "facebook": "xyCboq7q9mVtbkFny5qASWjgilD2gfFSFMVl60pn"
    }
    /*DJANGO_CLIENT_SECRETS = {
        "google-oauth2": "nVkCZIglVJ0IloUcx7Vv5Rpul192t3xMatSGkVmFj9zKN1A4JzeZpdmxqVfGdDCXADS8rcIr4trvShYRv67XEU0pz1AlHAd0sotFUpzDrLggVkEYb99K5ee1whIH2cUn",
        "facebook": "hvyzTXBfiX0M0ozt8L7QzKNIJhDMMPpN7q7p9uvFvzaBdIYtt1OG05tSpX9qZ3hB9CaNHofZWd8CuMKo6E0fg1MPgdCwYSe28R0dR9uNzfeHzELx4vjz159xke9RGLND"
    }*/
    USER_STATUS_LOGGED_IN = true;
    USER_STATUS_LOGGED_OUT = false;

    // UserData
    refreshingToken: boolean = false;
    fetchingTokenFromStorage: boolean = false;
    userTokenData: UserTokenData;
    manualUserTokenData: ManualUserTokenData;
    private BASIC_AUTH_EXPIRES_IN: number = 900;   // 15 minuti
    socialUserData: SocialUserData;
    userStatusChanged = new Subject<Boolean>();

    private socialVendorEmail: string;
    private socialVendorName: string;
    private socialVendorSurname: string;

    // Admin flag
    admin: boolean = false;

    cartBadge: Number; 

    private isInitializedGIS: boolean = false;

    private CLIENT_ID_DEV: string = "139448897714-nukd9nts2gqrrnvul4him7i5popn1iec.apps.googleusercontent.com";
    private CLIENT_ID_PROD: string = "532758505470-9e5e9676qgios59rivf1bnh600jd768b.apps.googleusercontent.com";
    GIS_SCOPES: string = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";
    CLIENT_ID: string = "532758505470-9e5e9676qgios59rivf1bnh600jd768b.apps.googleusercontent.com";

    /*  clientId for GoogleAuth usage
    <!-- DEV ID: 139448897714-nukd9nts2gqrrnvul4him7i5popn1iec.apps.googleusercontent.com -->
    <!-- PROD ID: 532758505470-9e5e9676qgios59rivf1bnh600jd768b.apps.googleusercontent.com -->
    */

    constructor(
        private constantUtils: ConstantUtilsService,
        private http: HttpClient, 
        private router: Router, private logger: NGXLogger,
        private menuService: MenuService,
        private shoppingCartManagerService: ShoppingCartManagerService) {
            /* OLD Google Sign In initialization method */ 
            /*GoogleAuth.initialize({
                clientId: '139448897714-nukd9nts2gqrrnvul4him7i5popn1iec.apps.googleusercontent.com',
                scopes: ['profile', 'email'],
                grantOfflineAccess: true
            });*/
            this.initUserToken();
    }

    // manual registration url
    private manualClientRegURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/basic-buyer-registration/');
    private manualVendorRegURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/basic-vendor-registration/');
    
    initGISProcess(){
        this.logger.log('AuthService: GIS Process INIT');
        if(!this.isInitializedGIS){
            this.logger.log('AuthService: GIS Process initialized'); // DEBUG
            /* NEW Google Identity Services initialization */
            google.accounts.id.initialize({
                client_id: this.CLIENT_ID
            })
            this.isInitializedGIS = true;
        }else{
            this.logger.log('AuthService: GIS Process initialization NOT required...'); // DEBUG
        }
    }

    async initUserToken(){
        this.fetchingTokenFromStorage = true;
        await this.getUserTokenDataFromStorage();
        this.fetchingTokenFromStorage = false;
        if(this.userTokenData){
            if(this.userTokenData && this.userTokenData.expiration_date){
                const expiration_date = new Date(this.userTokenData.expiration_date)
                if(new Date() > expiration_date){
                    this.logger.log("AuthService.initUserToken - expired token found. Refreshing it.")
                    this.refreshToken()
                }
                else{
                    this.logger.log("AuthService.initUserToken - valid token found. Logging in.")
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                }
            }
        }else if(this.manualUserTokenData){
            if(this.manualUserTokenData && this.manualUserTokenData.expiration_date){
                const expiration_date = new Date(this.manualUserTokenData.expiration_date)
                if(new Date() > expiration_date){
                    this.logger.log("AuthService.initUserToken - BASIC - expired token found. Refreshing it.")
                    this.refreshBasicAuthToken();
                }
                else{
                    this.logger.log("AuthService.initUserToken - BASIC- valid token found. Logging in.")
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                }
            }
        }else{
            this.logger.log("AuthService.constructor - token not found.")
            this.userStatusChanged.next(this.USER_STATUS_LOGGED_OUT);
        }
    }

    isLoggedIn(){
        // this.logger.log("this.userTokeData = ", this.userTokenData, "retuning: ", !!this.userTokenData)
        return (!!this.userTokenData && !this.refreshingToken) || (!!this.manualUserTokenData && !this.refreshingToken);
    }

    hasToRefreshBasicAuth(){
        if(!this.manualUserTokenData){
            return false;
        }
        const data_attuale = new Date();
        const data_expiration = new Date(this.manualUserTokenData.expiration_date);
        this.logger.log('exp time: '+this.manualUserTokenData.expiration_date); // DEBUG
        this.logger.log('AuthService: expiration date = '+data_expiration+' e data attuale: '+data_attuale); // DEBUG
        return this.manualUserTokenData && data_expiration<data_attuale;
    }

    refreshBasicAuthToken(){
        const refreshToken = this.manualUserTokenData.refresh_token;
        const refreshData = new RefreshBasicAuthTokenRequestData(refreshToken);
        const refreshTokenUrl = this.constantUtils.getFullURL("/jwt/token/refresh/");
        this.logger.log("RefreshBasicAuthToken request data", refreshData)
        this.refreshingToken = true;
        return this.http.post(refreshTokenUrl, refreshData).toPromise().then(
            (responseData) => {
                this.logger.log("RefreshBasicAuthToken response", responseData);
                if(responseData['access']){
                    const manualUserToken = new ManualUserTokenData(responseData['access'], this.BASIC_AUTH_EXPIRES_IN, refreshToken);
                    this.storeManualUserTokenData(manualUserToken);
                    this.refreshingToken = false;
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                    this.logger.log('RefreshBasicAuthToken taken: shopping cart update launched...');
                    this.updateShoppingCart();
                    return new AuthResult(AUTH_CONST.E_RT_RESULT_SUCCESS, AUTH_CONST.E_CODE_RT_SUCCESS);
                }else{
                    this.logger.log("RefreshBasicAuthToken error: response data not as expected");
                    this.resetUserData();
                    this.refreshingToken = false;
                }
            },
            (error) => {
                // logout the user (maybe only if the error was 'refresh token invalid'?)
                this.logger.log("RefreshBasicAuthToken error", error)
                this.resetUserData();
                this.refreshingToken = false;
                // use all auth results according to errorRes content (RT invalid and no connection to backend)
                const errorResult = new AuthResult(AUTH_CONST.E_RT_RESULT_ERROR, AUTH_CONST.E_CODE_RT_TOKEN_INVALID);
                return errorResult;
            }
        );
    }

    refreshToken(){
        const refreshToken = this.userTokenData.refresh_token;
        const clientId = this.DJANGO_CLIENT_IDS[this.userTokenData.backend];
        //const clientSecret = this.DJANGO_CLIENT_SECRETS[this.userTokenData.backend];
        const refreshData = new RefreshTokenRequestDataSecured(clientId, refreshToken);
        // Old API = /auth/token, that is the direct call to Google APIs
        const refreshTokenUrl = this.constantUtils.getFullURL("/refresh-access-token/");
        this.logger.log("RefreshToken request data", refreshData)
        this.refreshingToken = true;
        return this.http.post(refreshTokenUrl, refreshData).toPromise().then(
            (responseData: UserTokenData) => {
                this.logger.log("RefreshToken response", responseData)
                this.storeUserTokenData(this.userTokenData.backend, responseData)
                this.refreshingToken = false;
                this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                this.logger.log('RefreshToken taken: shopping cart update launched...');
                this.updateShoppingCart();
                return new AuthResult(AUTH_CONST.E_RT_RESULT_SUCCESS, AUTH_CONST.E_CODE_RT_SUCCESS);
            },
            (error) => {
                // logout the user (maybe only if the error was 'refresh token invalid'?)
                this.logger.log("RefreshToken error", error)
                this.resetUserData();
                this.refreshingToken = false;
                // use all auth results according to errorRes content (RT invalid and no connection to backend)
                const errorResult = new AuthResult(AUTH_CONST.E_RT_RESULT_ERROR, AUTH_CONST.E_CODE_RT_TOKEN_INVALID);
                return errorResult;
            }
        );
    }

    updateShoppingCart(){
        this.logger.log('AuthService: updateShoppingCart called...');
        this.shoppingCartManagerService.getShoppingCartBadge('AuthService').subscribe(
            posts => {
              this.logger.log('AuthService: cartBadge returned = '+posts);
              this.cartBadge = posts;
            },
            error => {
              this.logger.log('AuthService: error returned... cartBadge = null');
              this.cartBadge = null;
              this.logger.log(error);
            });
    }

    register(extraData, tipologiaSelected){
        // parametri ulteriori a extraData possono essere null e non essere considerati
        return this.convertToken(AUTH_CONST.CT_ERROR_IF_EXISTS, extraData, tipologiaSelected);
    }

    manuallyRegisterClient(user_type: string, e: string, p: string, first: string, last: string){
        return this.manualRegistrationRequestClient(user_type, e, p, first, last);
    }

    manuallyRegisterVendor(user_type: string, e: string, p: string, first: string, last: string, reg_type: string, tipologia: string,
        denominazione: string, partitaiva: string, codicedest: string, iban: string, CF: string, titolo: string, descrizione: string, 
        biografia: string, indirizzo: string, comune: string, CAP: string, nazione: string, prefisso: string, telefono: string, associazione: string){
        nazione = 'Italia';
        return this.manualRegistrationRequestVendor(user_type, e, p, first, last, reg_type, tipologia, denominazione, partitaiva, codicedest, 
            iban, CF, titolo, descrizione, biografia, indirizzo, comune, CAP, nazione, prefisso, telefono, associazione);
    }

    manuallyLogin(user_type: string, e: string, p: string, admin: boolean){
        return this.manuallyConvertToken(true, user_type, e, p, admin);
    }

    async login(backend){
        this.logger.log('AuthService: login function started ...');
        let socialLoginObservable = null;
        if(backend === this.FACEBOOK_BACKEND){
            socialLoginObservable = this.facebookLogin(undefined)
        }
        else if(backend === this.GOOGLE_BACKEND){ 
            // The following is the DEPRECATED one (the OLD one)
            //socialLoginObservable = this.googleLogin(undefined)
            socialLoginObservable = this.GISLogin(undefined);
        }
        else{
            // Unexpected backend
            return of(new AuthResult(AUTH_CONST.SL_RESULT_ERROR, AUTH_CONST.SL_CODE_UNEXPECTED_BACKEND));
        }    
        return socialLoginObservable.then(
            (socialLoginRes: AuthResult) => {
                this.logger.log('AuthService.login - socialLoginRes:'); // DEBUG
                this.logger.log(socialLoginRes); // DEBUG
                if(socialLoginRes.resultSuccess){
                    // Login to Emplace
                    const loginRes = this.convertToken(AUTH_CONST.CT_LOGIN_IF_EXISTS, null, null);
                    this.logger.log("AuthService.login", "result", loginRes)
                    return loginRes;
                }
                else{
                    this.logger.log('AuthService.login', 'negative result', socialLoginRes)
                    return of(socialLoginRes);
                }
            },
            error => {
                this.logger.log("AuthService.login", "error", error)
                const socialLoginErrorResult = new AuthResult(AUTH_CONST.SL_RESULT_ERROR, AUTH_CONST.SL_CODE_GENERIC_ERROR)
                return of(socialLoginErrorResult);
            }
        )
    }

    // GISLogin() prende lo userType = undefined in async login(..) (a riga 265) 
    // Lo userType = undefined sarebbe stato ricevuto da googleLogin() allo stesso modo (a riga 264)
    // Ai fini del login non dovrebbe essere quindi determinante...
    /* NEW Google Identity Services login function */
    GISLogin(userType:string){
        this.initGISProcess();
        this.logger.log('AuthService: GIS Login launched!');

        const response = JSON.parse(sessionStorage.getItem("loggedInGISUser"));
        this.logger.log("Data got from Session Storage: "+JSON.stringify(response));
        
        return this.gisCompleteLogin(userType, response).then(res => {
            if(res==null){
                // Dovrebbe verificarsi solo quando response == null
                this.logger.log('AuthService.googleLogin: ERRORE -> utente recuperato da sessionStorage NULLO!');
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
            }

            // Return true if the accessToken was successfully retrieved, false otherwise
            // Note: the '!!' is a Typescript trick to convert a value in boolean
            this.logger.log("AuthService.googleLogin: Tentativo di recuperare socialUserData <- contenente accessToken di Google"); // DEBUG
            this.logger.log("AuthService.googleLogin: ..se la prossima stampa è ok allora hai accessToken e socialUserData!"); // DEBUG
            this.logger.log("AuthService.googleLogin - result", res);
            this.socialVendorEmail = res.email; // Salvato per essere usato durante la reg venditore
            this.socialVendorName = res.firstName; // Salvato per essere usato durante la reg venditore
            this.socialVendorSurname = res.lastName; // Salvato per essere usato durante la reg venditore
            if(!!this.socialUserData.serverAuthCode){
                this.logger.log(this.socialUserData); // DEBUG
                return new AuthResult(AUTH_CONST.G_L_RESULT_SUCCESS, AUTH_CONST.G_L_CODE_SUCCESS);
                }
            else{
                this.logger.log('AuthService.googleLogin: ERRORE -> socialUserData NON presente'); // DEBUG
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
                }
            });
    }

    // GISSignUp() prende lo userType = client/vendor venendo chiamato direttamente dal component registration 
    /* NEW Google Identity Services sign up function */
    GISSignUp(userType:string){
        this.initGISProcess();
        this.logger.log('AuthService: GIS Sign Up launched!');

        const response = JSON.parse(sessionStorage.getItem("loggedInGISUser"));
        this.logger.log("Data got from Session Storage: "+JSON.stringify(response));
        
        return this.gisCompleteLogin(userType, response).then(res => {
            if(res==null){
                // Dovrebbe verificarsi solo quando response == null
                this.logger.log('AuthService.googleSignUp: ERRORE -> utente recuperato da sessionStorage NULLO!');
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
            }

            // Return true if the accessToken was successfully retrieved, false otherwise
            // Note: the '!!' is a Typescript trick to convert a value in boolean
            this.logger.log("AuthService.googleSignUp: Tentativo di recuperare socialUserData <- contenente accessToken di Google"); // DEBUG
            this.logger.log("AuthService.googleSignUp: ..se la prossima stampa è ok allora hai accessToken e socialUserData!"); // DEBUG
            this.logger.log("AuthService.googleSignUp - result", res);
            this.socialVendorEmail = res.email; // Salvato per essere usato durante la reg venditore
            this.socialVendorName = res.firstName; // Salvato per essere usato durante la reg venditore
            this.socialVendorSurname = res.lastName; // Salvato per essere usato durante la reg venditore
            if(!!this.socialUserData.serverAuthCode){
                this.logger.log(this.socialUserData); // DEBUG
                return new AuthResult(AUTH_CONST.G_L_RESULT_SUCCESS, AUTH_CONST.G_L_CODE_SUCCESS);
                }
            else{
                this.logger.log('AuthService.googleSignUp: ERRORE -> socialUserData NON presente'); // DEBUG
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
                }
            });
    }

    private manualRegistrationRequestClient(user_type: string, e: string, p: string, first: string, last: string){
        this.logger.log('manualRegistrationRequest called with userType = '+user_type);

        let loginData = new ManualRegistrationRequestClientData(e,p,first,last,user_type);
                
        return this.http.post(this.manualClientRegURL, loginData).pipe(
            map(
                (responseData) => {
                    this.logger.log(responseData);
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                    return new AuthResult(AUTH_CONST.E_CT_RESULT_SUCCESS, AUTH_CONST.E_CT_CODE_SUCCESS);
            }),
            catchError(errorRes => {
                this.logger.log("ConvertToken error", errorRes)
                // TODO use all auth result codes
                let errorAuthResult = null;
                if(errorRes["status"] === 500){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                }
                else{
                    if(errorRes.error.errors=='Email already used.'){
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_ALREADY_EXISTS);
                    }else{
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                    }
                }
                return of(errorAuthResult);
            })
        );
    }

    private manualRegistrationRequestVendor(user_type: string, e: string, p: string, first: string, last: string, reg_type: string, tipologia: string,
        denominazione: string, partitaiva: string, codicedest: string, iban: string, CF: string, titolo: string, descrizione: string, 
        biografia: string, indirizzo: string, comune: string, CAP: string, nazione: string, prefisso: string, telefono: string, associazione: string){
        this.logger.log('manualRegistrationRequest called with userType = '+user_type);

        let loginData = new ManualRegistrationRequestVendorData(e,p,first,last, reg_type, user_type, tipologia,
            denominazione, partitaiva, codicedest, iban, CF, titolo, descrizione, biografia, indirizzo, comune, CAP, nazione, prefisso, telefono, associazione);
                
        return this.http.post(this.manualVendorRegURL, loginData).pipe(
            map(
                (responseData) => {
                    this.logger.log(responseData);
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                    return new AuthResult(AUTH_CONST.E_CT_RESULT_SUCCESS, AUTH_CONST.E_CT_CODE_SUCCESS);
            }),
            catchError(errorRes => {
                this.logger.log("ConvertToken error", errorRes)
                // TODO use all auth result codes
                let errorAuthResult = null;
                if(errorRes["status"] === 500){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                }
                else{
                    if(errorRes.error.errors=='Email already used.'){
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_ALREADY_EXISTS);
                    }else{
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                    }
                }
                return of(errorAuthResult);
            })
        );
    }

    manuallyConvertToken(login_if_exists: boolean, userType: string, e: string, p: string, admin: boolean){
        this.logger.log('manuallyConvertToken called with userType = '+userType+' e admin = '+admin);

        let loginData = new ManualLoginRequestData(e,p);
                
        const convertTokenUrl = this.constantUtils.getFullURL("/jwt/token/");
        return this.http.post(convertTokenUrl, loginData).pipe(
            map(
                (responseData) => {
                    this.logger.log(responseData);
                    if(responseData['access'] && responseData['refresh']){
                        // Updating admin flag
                        this.admin = admin;
                        // Contiene i campi utili alla login
                        var tokenData = new ManualUserTokenData(responseData['access'], this.BASIC_AUTH_EXPIRES_IN, responseData['refresh']);
                        this.storeManualUserTokenData(tokenData);
                        this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                        return new AuthResult(AUTH_CONST.E_CT_RESULT_SUCCESS, AUTH_CONST.E_CT_CODE_SUCCESS);
                    }else{
                        var errorRes = '..token autenticazione NON presente';
                        this.logger.log("ConvertToken error", errorRes);
                        this.resetUserData();
                        return new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                    }
            }),
            catchError(errorRes => {
                this.logger.log("ConvertToken error", errorRes)
                // TODO use all auth result codes
                let errorAuthResult = null;
                if(errorRes["status"] === 500){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                }
                else if(login_if_exists){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_USER_NOT_FOUND);
                }
                else{
                    if(errorRes.error.errors=='Email already used.'){
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_ALREADY_EXISTS);
                    }else{
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                    }
                }
                return of(errorAuthResult);
            })
        );
    }

    convertToken(login_if_exists: boolean, extraData, tipologiaSelected: string){
        if(!this.socialUserData || !(this.socialUserData.authType in this.DJANGO_CLIENT_IDS) ){
            this.logger.log("AuthService.convertToken - bad request!", "this.socialUserData:", this.socialUserData)
            return of(new AuthResult(AUTH_CONST.SL_RESULT_ERROR, AUTH_CONST.SL_CODE_GENERIC_ERROR));
        }
        
        // Send a registration request to Emplace backend
        var userType = this.socialUserData.userType;
        const backend = this.socialUserData.authType;
        const clientId = this.DJANGO_CLIENT_IDS[backend];
        //const clientSecret = this.DJANGO_CLIENT_SECRETS[backend] // **
        // ** il clientSecret non dovrà essere più inviato, il flusso rimane invariato ma dovrai chiamare un'API diversa QUI :::
        var extraDataFinal = extraData;
        if(userType==='vendor'){
            this.logger.log(this.socialVendorEmail); // DEBUG
            var tipologiaParam = extraData.tipologia;
            if(tipologiaSelected!=null){
                tipologiaParam = tipologiaSelected;
                this.logger.log('Tipologia inserita per il venditore = '+tipologiaParam); // DEBUG
            }
            extraDataFinal = new SocialRegistrationRequestVendorData(this.socialVendorName, this.socialVendorSurname, this.socialVendorEmail, 'Google', 
            tipologiaParam, extraData.denominazione, extraData.partita_iva, extraData.codice_destinatario, extraData.iban, extraData.codice_fiscale,
            extraData.titolo, extraData.descrizione, extraData.biografia, extraData.indirizzo, 
            extraData.città, extraData.cap, 'Italia', extraData.prefisso, extraData.telefono, extraData.associazione);
        }else{
            userType = 'client';
        }
        this.logger.log(extraDataFinal); // DEBUG
        let loginData = new LoginRequestDataSecured(clientId, backend, this.socialUserData.serverAuthCode, extraDataFinal);
        this.logger.log("AuthService.convertToken - parametro userType inviato = "+userType);   // DEBUG
        this.logger.log("AuthService.convertToken - parametro login_if_exists inviato = "+login_if_exists);   // DEBUG
        this.logger.log("AuthService.convertToken - campo backend della POST = "+backend);   // DEBUG
        this.logger.log("AuthService.convertToken - campo client_id della POST = "+clientId);   // DEBUG
        //this.logger.log("AuthService.convertToken - campo client_secret della POST = "+clientSecret);    // DEBUG
        this.logger.log("AuthService.convertToken - campo token della POST = "+this.socialUserData.serverAuthCode);   // DEBUG
                
        // vecchia API: /auth/convert-token
        // ::: la API sarà /v1/convert-access-token e sarà identica, senza parametro clientSecret.. La modifica viene fatta internamente al Django e non comporta altro
        const convertTokenUrl = this.constantUtils.getFullURL(this.constantUtils.API_PATH+"/convert-access-token/")+"?user_type="+userType+"&login_if_exists="+login_if_exists;
        return this.http.post(convertTokenUrl, loginData).pipe(
            map(
                (responseData: UserTokenData) => {
                    this.logger.log(responseData) 
                    this.storeUserTokenData(backend, responseData)
                    this.userStatusChanged.next(this.USER_STATUS_LOGGED_IN);
                    return new AuthResult(AUTH_CONST.E_CT_RESULT_SUCCESS, AUTH_CONST.E_CT_CODE_SUCCESS);
            }),
            catchError(errorRes => {
                this.logger.log("ConvertToken error", errorRes)
                // TODO use all auth result codes
                let errorAuthResult = null;
                if(errorRes["status"] === 500){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                }
                else if(login_if_exists){
                    errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_USER_NOT_FOUND);
                }
                else{
                    if(errorRes.error && errorRes.error.errors=='Email already used.'){
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_ALREADY_EXISTS);
                    }else{
                        errorAuthResult = new AuthResult(AUTH_CONST.E_CT_RESULT_ERROR, AUTH_CONST.E_CT_CODE_GENERIC_ERROR);
                    }
                }
                return of(errorAuthResult);
            })
        );
    }

    logout(){
        this.shoppingCartManagerService.resetShoppingCart();
        this.resetUserData();
        this.menuService.restoreMenu();
        this.router.navigateByUrl("/home");
    }

    async storeManualUserTokenData(userTokenData: ManualUserTokenData){
        this.manualUserTokenData = userTokenData;
        // Write the expiration date, in order to undestand when to refresh the access token
        // TODO use dates in UTC to prevent timezone errors.
        let expiration_date = new Date();
        expiration_date.setSeconds(expiration_date.getSeconds() + this.manualUserTokenData.expires_in)
        this.manualUserTokenData.expiration_date = expiration_date.toString();
        await Preferences.set({
            key: 'userBasicAuthTokenData',
            value: JSON.stringify(userTokenData)
        });
    }

    async storeUserTokenData(backend: string, userTokenData: UserTokenData){
        this.userTokenData = userTokenData;
        this.userTokenData.backend = backend;
        // Write the expiration date, in order to undestand when to refresh the access token
        // TODO use dates in UTC to prevent timezone errors.
        let expiration_date = new Date();
        expiration_date.setSeconds(expiration_date.getSeconds() + this.userTokenData.expires_in)
        this.userTokenData.expiration_date = expiration_date.toString();
        await Preferences.set({
            key: 'userTokenData',
            value: JSON.stringify(userTokenData)
        });
    }

    async getUserTokenDataFromStorage(){
        if(!this.userTokenData){
            const ret = await Preferences.get({ key: 'userTokenData' });
            this.userTokenData = JSON.parse(ret.value)
        }
        if(!this.manualUserTokenData){
            const ret = await Preferences.get({ key: 'userBasicAuthTokenData' });
            this.manualUserTokenData = JSON.parse(ret.value)
        }
        return this.userTokenData, this.manualUserTokenData
    }

    async removeUserTokenDataFromStorage() {
        await Preferences.remove({ key: 'userTokenData' });
        await Preferences.remove({ key: 'userBasicAuthTokenData' });
    }

    socialLogout(){
        if(this.socialUserData.authType === this.GOOGLE_BACKEND){
            return this.GISLogout();
            // The following is the DEPRECATED one (the OLD one)
            //return this.googleLogout();
        }
        else if(this.socialUserData.authType === this.FACEBOOK_BACKEND){
            return this.facebookLogout();
        }
        this.logger.log("SocialLogout", "Unexpected value of authType", this.socialUserData.authType)
    }

    /* NEW Google Identity Service logout method */
    GISLogout(){
        // remove session storage
        sessionStorage.removeItem("loggedInGISUser");
        this.isInitializedGIS = false;
        google.accounts.id.disableAutoSelect();
        this.resetUserData();
    }

    /* GoogleAuth signIn() DEPRECATED */
    /* GOOGLE LOGIN */
    /*googleLogin(userType:string){
        this.logger.log('WARNING: You are using the OLD Google Sign In procedure for logging!');
        return GoogleAuth.signIn().then(
            response => {
                // From the library DOC:
                // 1) const credential = auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
                // 2) return this.afAuth.auth.signInAndRetrieveDataWithCredential(credential);
                this.logger.log("Access Token got from Google: "+response.authentication.accessToken);
                this.logger.log("IDToken got from Google: "+response.authentication.idToken);
                return this.googleCompleteLogin(userType, response).then(res => {
                    // Return true if the accessToken was successfully retrieved, false otherwise
                    // Note: the '!!' is a Typescript trick to convert a value in boolean
                    this.logger.log("AuthService.googleLogin: Tentativo di recuperare socialUserData <- contenente accessToken di Google"); // DEBUG
                    this.logger.log("AuthService.googleLogin: ..se la prossima stampa è ok allora hai accessToken e socialUserData!"); // DEBUG
                    this.logger.log("AuthService.googleLogin - result", res);
                    this.socialVendorEmail = res.email; // Salvato per essere usato durante la reg venditore
                    this.socialVendorName = res.firstName; // Salvato per essere usato durante la reg venditore
                    this.socialVendorSurname = res.lastName; // Salvato per essere usato durante la reg venditore
                    if(!!this.socialUserData.serverAuthCode){
                        this.logger.log(this.socialUserData); // DEBUG
                        return new AuthResult(AUTH_CONST.G_L_RESULT_SUCCESS, AUTH_CONST.G_L_CODE_SUCCESS);
                    }
                    else{
                        this.logger.log('AuthService.googleLogin: ERRORE -> socialUserData NON presente'); // DEBUG
                        new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
                    }
                })
            }
          ).catch(err => {
            this.logger.log("Google Login - Error", err)
            this.resetUserData();
            if(err["error"] === "popup_closed_by_user"){
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_POPUP_CLOSED);
            }
            else{
                return new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
            }
        })
    }*/

    // Method to improve modularity and to be called from outside authService
    // It contains the then clause of the call to googleCompleteLogin()
    afterGoogleCompleteLogIn(res: any){
        this.logger.log("AuthService.afterGoogleCompleteLogIn: Tentativo di recuperare socialUserData <- contenente accessToken di Google"); // DEBUG
        this.logger.log("AuthService.afterGoogleCompleteLogIn: ..se la prossima stampa è ok allora hai accessToken e socialUserData!"); // DEBUG
        this.logger.log("AuthService.afterGoogleCompleteLogIn - result", res);
        this.socialVendorEmail = res.email; // Salvato per essere usato durante la reg venditore
        this.socialVendorName = res.firstName; // Salvato per essere usato durante la reg venditore
        this.socialVendorSurname = res.lastName; // Salvato per essere usato durante la reg venditore
        if(!!this.socialUserData.serverAuthCode){
            this.logger.log('AuthService.afterGoogleCompleteLogIn: SUCCESS'); // DEBUG
            this.logger.log(this.socialUserData); // DEBUG
            return new AuthResult(AUTH_CONST.G_L_RESULT_SUCCESS, AUTH_CONST.G_L_CODE_SUCCESS);
        }
        else{
            this.logger.log('AuthService.afterGoogleCompleteLogIn: ERRORE -> socialUserData NON presente'); // DEBUG
            new AuthResult(AUTH_CONST.G_L_RESULT_ERROR, AUTH_CONST.G_L_CODE_GENERIC_ERROR);
        }
    }

    /* NEW Method to get user profile data of google users */
    getGISUserProfileData(accessToken) {
        this.initGISProcess();
        this.logger.log('AuthService: getGISUserProfileData called');
        return this.http.get(
            'https://www.googleapis.com/oauth2/v3/userinfo',
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+accessToken }),
                responseType: 'json'
            }
        )
        .pipe(
            map(
            response => {
                this.logger.log(response);
                return response;
            }),
            catchError(err => {
                this.logger.log("GIS Get User Data - Error", err)
                return err;
            })
        );
    } 

    /* NEW Method to complete Google Log In */
    async gisCompleteLogin(userType, googleUser){
        if(googleUser!=null){
            const userID = googleUser["id"];
            const authType = this.GOOGLE_BACKEND;
            const firstName = googleUser["given_name"];
            const lastName = googleUser["family_name"];
            const email = googleUser["email"];

            const token = googleUser["accessToken"];
            this.logger.log(token); // DEBUG
            this.logger.log(googleUser); // DEBUG

            let pictureURL = googleUser["picture"]
            pictureURL = pictureURL.substring(0, pictureURL.lastIndexOf("=")) + "=s800"
            let accessToken = token;
            this.socialUserData = new SocialUserData(userID, userType, authType, firstName, lastName, email, pictureURL, token)
            // On Android, Google APIs do not return "accessToken", that is required to complete the login/registration on Emplace.
            // To solve this issue, we call the backend to convert the serverAuthCode into an AccessToken.
            if(!accessToken){
                accessToken = await this.googleConvertServerAuthCode(token)
                this.socialUserData.serverAuthCode = accessToken
                return this.socialUserData;
            }
            return this.socialUserData;
        }else{
            // Se googleUser == null significa che la response di GISLogin(), e quindi la sessione salvata, sono nulle
            // In tal caso andresti in errore, per cui ritorni qui un socialUserData nullo
            return null;
        }
    }

    /* OLD Method to complete Google Log In using DEPRECATED fields */
    /*async googleCompleteLogin(userType, googleUser){
        const userID = googleUser["id"];
        const authType = this.GOOGLE_BACKEND;
        const firstName = googleUser["givenName"];
        const lastName = googleUser["familyName"];
        const email = googleUser["email"];

        let pictureURL = googleUser["imageUrl"]
        pictureURL = pictureURL.substring(0, pictureURL.lastIndexOf("=")) + "=s800"
        let accessToken = googleUser["authentication"]["accessToken"]
        this.socialUserData = new SocialUserData(userID, userType, authType, firstName, lastName, email, pictureURL, accessToken)
        // On Android, Google APIs do not return "accessToken", that is required to complete the login/registration on Emplace.
        // To solve this issue, we call the backend to convert the serverAuthCode into an AccessToken.
        if(!accessToken){
            accessToken = await this.googleConvertServerAuthCode(googleUser["serverAuthCode"])
            this.socialUserData.serverAuthCode = accessToken
            return this.socialUserData;
        }
        return this.socialUserData;
    }*/

    async googleConvertServerAuthCode(serverAuthCode){
        const data = {
            "server_auth_code": serverAuthCode,
        }
        this.logger.log("AuthService.googleConvertServerAuthCode: sei qui perchè non avevi accessToken e stai per chiederlo al server Django");
        return this.http.post(this.constantUtils.getFullURL("/api/convert-server-code/"), data).toPromise().then(
            (responseData) => {
                const accessToken = responseData["access_token"]
                this.logger.log("AuthService.googleConvertServerAuthCode: accessToken da Django = "+accessToken);
                return accessToken;
            },
            (error) => {
                this.logger.log("AuthService.googleConvertServerAuthCode - error", error);
                return null;
            }
        );
    }

    /* GoogleAuth signOut() DEPRECATED */
    /*async googleLogout(){
        this.logger.log('WARNING: You are using the OLD Google Sign In procedure for logging OUT!');
        const result = await GoogleAuth.signOut()
        this.resetUserData();
    }*/

    /* FACEBOOK LOGIN  */
    facebookLogin(userType:string){
        const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];

        return FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then(result => {
            const token = result.accessToken.token;
            return this.facebookGetUserData(userType, token).toPromise().then(result => {
                return new AuthResult(AUTH_CONST.F_L_RESULT_SUCCESS, AUTH_CONST.F_L_CODE_SUCCESS);
            })
        }).catch(errorRes => {
            this.logger.log("Facebook Login - Error", errorRes)
            this.socialUserData = undefined;
            // If the user just closed the popup (thus cancelling the login), we get this dictionary as result:
            // {accessToken: {token:null}}
            if(errorRes["accessToken"] && errorRes["accessToken"]["token"] === null){
                return new AuthResult(AUTH_CONST.F_L_RESULT_ERROR, AUTH_CONST.F_L_CODE_POPUP_CLOSED);
            }
            else{
                return new AuthResult(AUTH_CONST.F_L_RESULT_ERROR, AUTH_CONST.F_L_CODE_GENERIC_ERROR);
            }
        })
    }

    facebookGetUserData(userType, token){
        const params = {
            "fields": "email,first_name,last_name,picture.width(800).height(800)",
            "access_token": token
        }
        return this.http.get(
            `https://graph.facebook.com/me`,
            {
                headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
                params: params,
                responseType: 'json'
            }
        )
        .pipe(
            map(
            response => {
                const userID = response["id"];
                const authType = this.FACEBOOK_BACKEND;
                const firstName = response["first_name"];
                const lastName = response["last_name"];
                const email = response["email"];
                const pictureURL = response["picture"]["data"]["url"]
                this.socialUserData = new SocialUserData(userID, userType, authType, firstName, lastName, email, pictureURL, token)
                return this.socialUserData;
            }),
            catchError(err => {
                this.logger.log("Facebook Get User Data - Error", err)
                return throwError(err);
            })
        );
    }

    async facebookLogout(){
        const result = await FacebookLogin.logout()
        this.logger.log("AuthService.facebookLogout - result", result)
        this.resetUserData();
        // this.router.navigate(["/login"])
    }

    resetUserData(){
        this.removeUserTokenDataFromStorage()
        this.socialUserData = undefined;
        this.userTokenData = undefined;
        this.manualUserTokenData = undefined;
        this.userStatusChanged.next(false);
        this.admin = false;
    }
}