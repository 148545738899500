import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IonInfiniteScroll, IonSearchbar, Platform, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AssociationPopover } from 'src/app/association/association-popover/association-popover.component';
import { VenditoreItem, Venditori } from 'src/app/shared/models/Venditori.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { GetUtentiVenditoriService } from 'src/app/shared/services/getUtentiVenditori.service';
import { ProdottiInteractionService } from 'src/app/shared/services/prodotti-interaction.service';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { UserService } from 'src/app/shared/services/User.service';

@Component({
  selector: 'app-vendorlist',
  templateUrl: './vendorlist.component.html',
  styleUrls: ['./vendorlist.component.scss'],
})
export class VendorlistComponent implements OnInit {
  ZERO_CONSTANT: Number = Number(0);
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  //@ViewChild(IonVirtualScroll) virtualScroll: IonVirtualScroll; // Removed due to migration to Ionic v7
  title: string = 'Venditori';
  next: string;
  next_index: number = 0;
  firstCall: boolean = true;
  resArray: Venditori;
  venditori: VenditoreItem[];
  hasVenditori: boolean = false;
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  cartBadge: Number;
  pop: any;

  searchInput: string;
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  noResults: boolean = false;

  private innerWidth: any;
  private SCREEN_SIZE_XS_SM: number = 576;
  private SCREEN_SIZE_SM_MD: number = 768;
  smallScreen: boolean = false;

  email: string = 'mailto: assistenza@emplacestore.it?Subject=Suggerimento nuovo venditore';

  threshold_one: number = 0.5;
  threshold_two: number = 1.5;
  threshold_three: number = 2.5;
  threshold_four: number = 3.5;
  threshold_five: number = 4.5;

  constructor(private http: HttpClient, private getVenditoriService: GetUtentiVenditoriService, 
    public platform: Platform, private router: Router, public userService: UserService,
    private prodottiInteractionService: ProdottiInteractionService, private popover: PopoverController,
    public constantUtils: ConstantUtilsService, private authService: AuthService,
    public shoppingCartManagerService: ShoppingCartManagerService, private logger: NGXLogger) { }

  ngOnInit() {
    this.logger.log('VendorListComponent: ngOnInit <--------------');
    
    this.getVendors(null, {'order_key': 'media_recensioni', 'ordering': 'desc'});
  }

  ionViewWillEnter(){
    this.logger.log('VendorListComponent: ionViewWillEnter <--------------');
    this.onResize(null);
    
    this.shoppingCartRetriever();
  }

  shoppingCartRetriever(){
    if(this.authService.isLoggedIn() && !this.userService.userIsVendor()){
      this.logger.log('VendorListComponent: utente loggato, inizializzazione badge del carrello tramite API');
      this.shoppingCartManagerService.getShoppingCartBadge('VendorListComponent').subscribe(
        posts => {
          this.logger.log('VendorListComponent: cartBadge returned = '+posts);
          this.cartBadge = posts;
        },
        error => {
          this.logger.log('VendorListComponent: error returned... cartBadge = null');
          this.cartBadge = null;
          this.logger.log(error);
        }
      )
    }else{ 
      this.logger.log('VendorListComponent: utente non loggato, inizializzazione badge del carrello tramite local storage');
      this.shoppingCartManagerService.localCart_storageKeys('VendorListComponent').then(
        res => {
          this.logger.log('VendorListComponent: local storage keys returned = '+res);
          this.cartBadge = res;
        }
      );
    }
  }

  ionViewDidEnter(){
    this.logger.log('VendorListComponent: ionViewDidEnter <--------------');

    this.cartBadge = this.prodottiInteractionService.itemsIntoCartAmount;
    this.logger.log('VendorListComponent: badge = '+this.cartBadge);
  }

  // Tiene conto dei cambi di screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //this.logger.log('HomePage: cambio di screen size -> '+this.innerWidth);  // DEBUG
    this.screenManager();
  }

  private screenManager(){
    // Gestisco il cambio di dimensione dello schermo 
    if(this.innerWidth<this.SCREEN_SIZE_SM_MD){
      this.smallScreen = true;
    }else{
      this.smallScreen = false;
    }
  }

  trackByFunc(index, item) {
    //return item ? item.id : index;
    return index;
  }

  loadData(event) {
    //this.getNextVendors(event);
    this.logger.log('VendorListComponent: loadData launched');
    this.getVendors(event, null);
    event.target.complete();
  }

  getVendors(event, requestParams){
    this.logger.log('VendorListComponent: getVendors');

    if(this.next!=null || this.firstCall){
      this.firstCall = false;
      this.errorSub = this.getVenditoriService.error.subscribe(errorMessage => {
        this.error = errorMessage;
      });

      this.isFetching = true;
      var result = null;
      this.logger.log('Request Params = '+requestParams);    // DEBUG
      if(requestParams){
        this.logger.log('Request Params NOT null');    // DEBUG
        result = this.getVenditoriService.fetchVendorsWithParams(this.next, requestParams);
      }else{
        this.logger.log('Request Params NULL');    // DEBUG
        result = this.getVenditoriService.fetchVendors(this.next);
      }
      result.subscribe(
        posts => {
          this.isFetching = false;
          this.resArray = <Venditori>posts;
          this.logger.log('VendorListComponent: GET retrieved something...');
          this.logger.log(this.resArray);
          this.next = this.resArray.next;
          this.logger.log('VendorListComponent: next parameter');
          this.logger.log(this.next);
          this.next_index = this.next_index + 10;
          this.logger.log('VendorListComponent: GET results:');
          if(this.venditori!=null){
            // after the first time...
            this.venditori = this.venditori.concat(this.resArray['results']);
            //this.virtualScroll.trackBy(this.next_index, this.venditori[this.next_index]);
          }else{
            // the first time...
            this.venditori = this.resArray['results'];
          }
          this.logger.log(this.venditori);
          if(this.venditori.length>0){
            this.hasVenditori = true;
            this.noResults = false;
          }else{
            this.noResults = true;
          }
        },
        error => {
          this.isFetching = false;
          this.hasVenditori = false;
          this.error = error.message;
          this.logger.log('VendorListComponent: GET..... an error occurred');
        }
      );
    }
  }

  onHandleError() {
    this.error = null;
  }

  ngOnDestroy() {
    this.errorSub.unsubscribe();
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  onInput($event){
    this.logger.log('VendorListComponent: SEARCH ------> onInput');
    this.searchbar.getInputElement().then(input => {
      var inputValue = input.value; 
      this.searchInput = inputValue;
      if(this.searchInput!=null && this.searchInput!=' ' && this.searchInput.length>1){
        //this.autocompleteRequest(this.searchInput);  // TODO: Autocomplete venditori
      }else{
        //this.hasSuggestion = false;    // TODO: Autocomplete venditori
      }
      this.logger.log('searchInput: '+this.searchInput);
    });
  }

  onCancel($event){
    this.logger.log('VendorListComponent: SEARCH - onCancel');
    this.searchInput = '';
    this.logger.log('searchInput: '+this.searchInput);
  }

  onSearch($event){   
    if($event && $event.key=='Enter'){ 
      this.logger.log('VendorListComponent: SEARCH - onSearch');
      this.logger.log('searchInput: '+this.searchInput);
      this.startSearch();
    }
  }

  startSearch(){
    this.logger.log('VendorListComponent: ..ricerca iniziata con query = '+this.searchInput);
    if(this.searchInput!=null){
      this.venditori = [];
      this.next = null;
      this.firstCall = true;
      if(this.searchInput!=''){
        this.logger.log('searching for: '+this.searchInput);
        this.getVendors(null,{'query': this.searchInput, 'order_key': 'media_recensioni', 'ordering': 'desc'});
      }else{
        this.logger.log('searching for all vendors...');
        this.getVendors(null,{'order_key': 'media_recensioni', 'ordering': 'desc'});
        this.noResults = false;
      }
      this.searchInput = '';
    }else{
      // la searchInput è null
      this.logger.log('searching for all vendors...');
      this.getVendors(null,{'order_key': 'media_recensioni', 'ordering': 'desc'});
      this.noResults = false;
    }
  }

  onSelectVendor(id: string, name: string, media_recensioni: number){
    // original page
    let navigationExtras: NavigationExtras = { state: { name: name, media_recensioni: media_recensioni } };
    let destinationURL = 'vendors/vendor/'+id;
    this.router.navigate([destinationURL], navigationExtras);
  }

  onReloadVendors(){
    // Retry clicked by the user..... attempt to reload vendors
    this.logger.log("VendorListComponent: onReloadVendors()");
    this.firstCall = true;
    this.prodottiInteractionService.announce('update_cart');
    this.ngOnInit();
    this.ionViewWillEnter();
  }

  toShoppingCart(){
    this.logger.log('VendorListComponent: ...toShoppingCart()');
    this.router.navigateByUrl("/shopping-cart");
  }

  async CreateAssociationPopover(ev: any, nome: string, logo: string, descrizione: string, link: string) {
    this.pop = await this.popover.create({
      component: AssociationPopover,
      cssClass: 'association-popover-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        "nome": nome,
        "logo": logo,
        "link": link,
        "descrizione": descrizione
      }
    });
    return await this.pop.present();
  }

}
