import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { VendorInfo, VendorProfile } from '../models/VendorInfo.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GetVendorUserService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}

  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendor-user/');
  private profile_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+"/vendor-user/myprofile/");
  error = new Subject<string>();

  // HTTP GET
  fetchVendorInfo(id: string) {
    if(id!=null){
        var one_time_url = this.url+id+'/';
        this.logger.log('fetchVendorInfo chiamato con url = '+one_time_url);
    
        return this.http
            .get< VendorInfo >(
            one_time_url,
            {
                headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
                /*params: requestParams,*/                                     
                responseType: 'json'
            }
            )
            .pipe(
            map(responseData => {
                this.logger.log(responseData);
                return <VendorInfo>responseData;
            }),
            catchError(errorRes => {
                // Send to analytics server
                return throwError(errorRes);
            })
            );
    }else{
        this.logger.log("getVendorInfo: ID venditore non corretto");
        return throwError("getVendorInfo: ID venditore non corretto");
    }
  }

  fetchVendorProfile() {
        return this.http
            .get< VendorProfile >(
            this.profile_url,
            {
                headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
                /*params: requestParams,*/                                     
                responseType: 'json'
            }
            )
            .pipe(
            map(responseData => {
                this.logger.log(responseData);
                return <VendorProfile>responseData;
            }),
            catchError(errorRes => {
                // Send to analytics server
                return throwError(errorRes);
            })
            );
  }
  
}