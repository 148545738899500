import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Categorie } from '../models/Categorie.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';


@Injectable()
export class GetCategorieService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}

  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/root-categories/');
  error = new Subject<string>();

  // HTTP POST
  /*createAndStorePost(count: number, next: string, previous: string, results: ProdottoItem[]) {
    const postData: ProdottiResponse = { count: count, next: next, previous: previous, results: results };
    this.http
      .post<{ name: string }>(
        this.url,
        postData,
        {
          observe: 'response'
        }
      )
      .subscribe(
        responseData => {
          this.logger.log(responseData);
        },
        error => {
          this.error.next(error.message);
        }
      );
  }*/

  // HTTP GET
  fetchCategories(next: string) {
    let requestURL = this.url
    if(next!=null){
      requestURL = next;
    }

    return this.http
      .get< Categorie >(
        requestURL,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: requestParams,*/                                     
          responseType: 'json'
        }
      )
      .pipe(
        map(responseData => {
          this.logger.log(responseData);
          return < Categorie >responseData;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      );
  }

  // HTTP DELETE
  deletePosts() {
    return this.http
      .delete(this.url, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }
}
