import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ShippingPolicyComponent } from './shipping-policy.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: ShippingPolicyComponent, pathMatch: 'full' }])
  ],
  providers: [],
  declarations: [ShippingPolicyComponent]
})
export class ShippingPolicyModule {}
