<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')" style="margin-top: 12px;">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 12px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Venditori
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Venditori
            </ion-title>
          </div>
        </ion-col>
        
        <!-- Bottoni laterali -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2" style="text-align: right; padding-right: 9px;">
          <!-- BUTTON del profilo non serve in questa pagina!!!
            <ion-button (click)="toUserProfile()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && !platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-icon slot="icon-only" name="person-circle"></ion-icon>
          </ion-button>-->
            
          <ion-button *ngIf="!userService.userIsVendor()" (click)="toShoppingCart()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && !platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-icon slot="icon-only" name="cart"></ion-icon>
          </ion-button>
        </ion-col>

        <!-- Nella SECONDA riga, versione con titolo e barra di ricerca -->
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
          <ion-searchbar showCancelButton="focus" placeholder="Cerca il miglior venditore di Chihuahua vegani" *ngIf="!isFetching && hasVenditori"
              (ionInput)="onInput($event)"
              (ionCancel)="onCancel($event)"
              (keypress)="onSearch($event)">
          </ion-searchbar>
          <!-- Suggestions TODO per ricerca venditori -->
          <!--
          <ion-list *ngIf="hasSuggestion" class="searchbar-list" style="padding-left: 32px;">
            <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))" 
              class="sugg-item"> {{ s.substring(1,s.length-1) }} </ion-item>
          </ion-list>-->
        </ion-col>
      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="padding-top: 0px;" >

  <ion-spinner name="bubbles"  *ngIf="isFetching"></ion-spinner>
  
  <div class="error-div" *ngIf="noResults && hasVenditori">
    <img src="assets/no_results_image/broccolo.png" class="error-resize">
    <p class="vendor-title-data" style="margin-top: 18px; text-align: center;"> La ricerca effettuata <br> non ha prodotto risultati! </p>
  </div>

  <ion-grid style="width:100%; background-image: linear-gradient(to bottom right, #22577A, #38A3A5);" *ngIf="hasVenditori">
    <ion-row>
      <ion-col size="12" style="text-align: center;">
        <p class="vendor-title-data" style="color: #ffffff;">Spedizione gratuita per ordini dallo stesso venditore superiori a 50€</p>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="hasVenditori" style="margin: auto; max-width: 1200px; margin-top: 8px;">
    <ion-row>
      <ion-col size="6" size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" style="padding: 10px;"
      *ngFor="let item of venditori">
        
        <ion-card class="data">
          <ion-grid class="vendor-info">
            <ion-row class="vendor-info">
              <ion-col size="5" class="img-col">
                <img src="{{ constantUtils.getFullURL(item.foto_profilo) }}" onerror="this.src='assets/images/vendor_user_icon.svg'" class="resize" (click)="onSelectVendor(item.id, item.titolo, item.media_recensioni)">
                <img src="{{ constantUtils.getFullURL(item.associazione.logo) }}" *ngIf="item.associazione && item.associazione.nome!='Nessuna' && item.associazione.logo" 
                class="logo-associazione" (click)="CreateAssociationPopover($event, item.associazione.nome, item.associazione.logo, item.associazione.descrizione, item.associazione.link)">
              </ion-col>
              <ion-col size="7">
          <ion-card-content class="data">
            <ion-row>
              <ion-col class="card-top">
                <p class="vendor-title-data" (click)="onSelectVendor(item.id, item.titolo, item.media_recensioni)"><ion-text color="primary">{{ item.titolo }}</ion-text></p>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="item.media_recensioni>0">
              <ion-col class="card-middle">
                <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="item.media_recensioni > threshold_one">
                <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="item.media_recensioni > threshold_two">
                <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="item.media_recensioni > threshold_three">
                <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="item.media_recensioni > threshold_four">
                <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="item.media_recensioni > threshold_five">
                <img src="assets/icon/logo_grey.png" class="rating" *ngIf="item.media_recensioni <= threshold_one">
                <img src="assets/icon/logo_grey.png" class="rating" *ngIf="item.media_recensioni <= threshold_two">
                <img src="assets/icon/logo_grey.png" class="rating" *ngIf="item.media_recensioni <= threshold_three">
                <img src="assets/icon/logo_grey.png" class="rating" *ngIf="item.media_recensioni <= threshold_four">
                <img src="assets/icon/logo_grey.png" class="rating" *ngIf="item.media_recensioni <= threshold_five">
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="card-bottom">
                <p class="vendor-city-data" *ngIf="item.residenza[0] && item.residenza[0]['regione'] && item.residenza.length>0"><ion-text style="color: #777777;"> {{ item.residenza[0]['regione'] }} </ion-text></p>
                <p class="vendor-city-data" *ngIf="item.residenza[0] && item.residenza[0]['regione'] && item.residenza.length==2"><ion-text style="color: #777777;"> e un'altra regione </ion-text></p>
                <p class="vendor-city-data" *ngIf="item.residenza[0] && item.residenza[0]['regione'] && item.residenza.length>2"><ion-text style="color: #777777;"> e altre {{ item.residenza.length-1 }} regioni </ion-text></p>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="item.verificato" style="margin-top: 8px;">
              <ion-col size="12">
                <ion-text color="darksea" style="float: right; padding-top: 4px;" class="vendor-city-data">Venditore verificato</ion-text>
                <ion-icon name="checkmark-circle" color="darksea" style="width: 25px; height: 25px;"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-card-content>
          </ion-col>
          </ion-row>
          </ion-grid>
        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>
  
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" *ngIf="hasVenditori">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <div *ngIf="!hasVenditori && !isFetching && !platform.is('ios')" class="error-div" style="top: 40%;">
    <img src="assets/error/cavolo.jpg" class="error-resize">
    <p class="vendor-title-data"> Cavolo, qualcosa è andato storto! </p>
    <p class="vendor-title-data"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry">
      <ion-fab-button color="primary" (click)="onReloadVendors()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>

  <!-- iOS ERROR DIV -->
  <div *ngIf="!hasVenditori && !isFetching && platform.is('ios')" class="error-div-ios" style="top: 40%;">
    <img src="assets/error/cavolo.jpg" class="error-resize-ios">
    <p class="vendor-title-data"> Cavolo, qualcosa è andato storto! </p>
    <p class="vendor-title-data"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry-ios">
      <ion-fab-button color="primary" (click)="onReloadVendors()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>

</ion-content>
