import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
import { BuyerUserRequiredGuard } from '../shared/buyer-user.guard';
import { CheckoutRiepilogoComponent } from './checkout-riepilogo/checkout-riepilogo.component';
import { BuyersOrdersService } from '../shared/services/buyers-orders.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckoutSuccessComponent } from './checkout-success/checkout-success.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CheckoutDonationComponent } from './checkout-donation/checkout-donation.component';
import { InfoPopoverComponent } from './checkout-donation/info-popover/info-popover.component';
import { UserAddressPageModule } from '../positions/user-address-page/user-address-page.module';
import { ShippingAddressModule } from '../positions/shipping-address/shipping-address.module';
import { CRDeleteItemPopover } from './checkout-riepilogo/checkout-riepilogo-popover/checkout-riepilogo-popover.component';
import { NumberPrefixService } from '../shared/services/numberPrefix.service';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    UserAddressPageModule,
    ShippingAddressModule,
    RouterModule.forChild([
      { path: '', canActivate: [BuyerUserRequiredGuard], component: CheckoutPageComponent},
      { path: 'success', canActivate: [BuyerUserRequiredGuard], component: CheckoutSuccessComponent},
      { path: 'earth-donation', /*canActivate: [BuyerUserRequiredGuard],*/ component: CheckoutDonationComponent}
    ])
  ],
  providers: [BuyersOrdersService, InAppBrowser, NumberPrefixService],
  declarations: [CheckoutPageComponent, CheckoutRiepilogoComponent, CheckoutDonationComponent, 
    CheckoutSuccessComponent, InfoPopoverComponent, CRDeleteItemPopover]
})
export class CheckoutModule {}