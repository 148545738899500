import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";



@Injectable()
export class MenuService {

    private appPages = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Prodotti',
          url: '/products',
          icon: 'apps'
        },
        {
          title: 'Categorie',
          url: '/categories',
          icon: 'trail-sign'
        },
        {
          title: 'Venditori',
          url: '/vendors',
          icon: 'pricetags'
        },
        {        
          title: 'Mission',
          url: '/mission',
          icon: 'compass'
        },
        {
          title: 'Salviamo il pianeta',
          url: '/save-the-planet',
          icon: 'earth'
        },
        {
          title: 'Come funziona',
          url: '/how-it-works',
          icon: 'leaf'
        },
        {
          title: 'Chi siamo',
          url: '/about-us',
          icon: 'megaphone'
        },
        {
          title: 'Profilo',
          url: '/myprofile',
          icon: 'person-circle'
        }
      ];

      private buyerMenu = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Prodotti',
          url: '/products',
          icon: 'apps'
        },
        {
          title: 'Categorie',
          url: '/categories',
          icon: 'trail-sign'
        },
        {
          title: 'Venditori',
          url: '/vendors',
          icon: 'pricetags'
        },
        {
          title: 'Recensioni',
          url: '/myprofile/user/reviews',
          icon: 'document-text'
        },
        {
          title: 'Ordini',
          url: '/myprofile/user/buyer/orders',
          icon: 'gift'
        },
        {
          title: 'Carrello',
          url: '/shopping-cart',
          icon: 'cart'
        },
        {        
          title: 'Mission',
          url: '/mission',
          icon: 'compass'
        },
        {
          title: 'Salviamo il pianeta',
          url: '/save-the-planet',
          icon: 'earth'
        },
        {
          title: 'Come funziona',
          url: '/how-it-works',
          icon: 'leaf'
        },
        {
          title: 'Chi siamo',
          url: '/about-us',
          icon: 'megaphone'
        },
        {
          title: 'Profilo',
          url: '/myprofile',
          icon: 'person-circle'
        }
      ];

    public accessPages = [
        {
          title: 'Accedi',
          url: '/login',
          icon: 'log-in'
        },
      ]
    public loggedInPages = [
        {
          title: 'Logout',
          url: '/logout',
          icon: 'log-out'
        },
      ]

    private vendorMenu = [
        {
          title: 'Profilo',
          url: '/myprofile',
          icon: 'person-circle'
        },
        {
          title: 'Ordini',
          url: '/myprofile/vendor/orders',
          icon: 'gift'
        },
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Prodotti',
          url: '/products',
          icon: 'apps'
        },
        {
          title: 'Categorie',
          url: '/categories',
          icon: 'trail-sign'
        },
        {
          title: 'Venditori',
          url: '/vendors',
          icon: 'pricetags'
        }
      ];
      

    public menu = this.appPages;
  
    constructor(private logger: NGXLogger) { }

    public restoreMenu(){
        this.logger.log('MenuService: menu restored !!!');
        this.menu = this.appPages;
    }

    public setVendorMenu(){
        this.logger.log('MenuService: menu for vendors set');
        this.menu = this.vendorMenu;
    }

    public setBuyerMenu(){
        this.logger.log('MenuService: menu for clients set');
        this.menu = this.buyerMenu;
    }

}