import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { BuyerUserProfile, UserProfile } from 'src/app/shared/models/UserProfile.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { ProdottiInteractionService } from 'src/app/shared/services/prodotti-interaction.service';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { UserService } from 'src/app/shared/services/User.service';
import { AnimalPopover } from './animal-popover/animal-popover.component';
import { DonationPopover } from './donation-popover/donation-popover.component';
import { MenuService } from 'src/app/shared/services/menu.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {

  title: string = "Profilo Utente";
  isFetching: boolean = true;
  hasUserProfileInfo: boolean = false;
  error: any;
  errorMsg = null;
  toRegistration: boolean = false;
  pop: any;

  cartBadge: Number;
  localStorageKeys: string[] = [];
  local_cart: any[] = [];

  userProfile: BuyerUserProfile;
  donazionePremium_uno: any;
  donazionePremium_due: any;
  euroInRifo: number;
  pFogliaRifo: number = 0;
  modRifo: number;
  euroInPlastica: number;
  pFogliaPlastica: number = 0;
  modPlastica: number = 0;
  euroInProgSociali: number;
  pFogliaProgSociali: number = 0;
  totaleDonazioni: number = 0;
  private RIFORESTAZIONE: number = 0;
  private PLASTICA: number = 1;
  private SOCIALI: number = 2;
  private VALORE_PFOGLIA: number = 0.05;
  TASSO: number = 20;

  constructor(
      private activatedRoute: ActivatedRoute, 
      private http: HttpClient, private router: Router,
      private authService: AuthService, private menuService: MenuService,
      private userService: UserService, private logger: NGXLogger,
      private popover: PopoverController,
      private prodottiInteractionService: ProdottiInteractionService,
      public constantUtilsService: ConstantUtilsService,
      public platform: Platform, private shoppingCartManagerService: ShoppingCartManagerService
  ) { }

  ngOnInit() {
    this.logger.log("ProfiloUtenteComponent: --------------->   START   <-----------------");
    this.logger.log("Chiamate demandate al ionViewWillEnter");

    this.logger.log("ProfiloUtenteComponent: announcing to update cart...");
    this.prodottiInteractionService.announce('update_cart');
  }

  async waitUserTokenInitialization(){
    if(this.authService.isLoggedIn()){
      this.logger.log("ProfiloUtenteComponent.constructor - token taken. Retrieving User Profile.");
      this.toRegistration = false;
      this.getUserProfile();
    }
    else{
      this.logger.log("ProfiloUtenteComponent.constructor - token not found.");
      this.isFetching = false;
      this.hasUserProfileInfo = false;
      this.toRegistration = true;
    }
  }

  getUserProfile(){
    this.logger.log("ProfiloUtenteComponent: --------->   START getUserProfile  <---------");
    
    this.isFetching = true;
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.isFetching = false;
        this.userProfile = < BuyerUserProfile >userProfile;
        this.logger.log('ProfiloUtenteComponent: GET results:');
        this.logger.log(this.userProfile);
        if(this.userProfile!=null){
          this.hasUserProfileInfo = true;
          this.setProfileData();
        }else{
          this.hasUserProfileInfo = false;
        }
      },
      error => {
        this.isFetching = false;
        this.hasUserProfileInfo = false;
        this.error = error.message;
        this.logger.log('ProfiloUtenteComponent: GET..... an error occurred');
      }
    );
    this.logger.log("ProfiloUtenteComponent: --------->   END getUserProfile  <---------");
  }

  setProfileData(){
    this.logger.log("ProfiloUtenteComponent.setProfileData(): START");
    this.euroInRifo = 0;
    this.pFogliaRifo = 0;
    this.euroInPlastica = 0;
    this.pFogliaPlastica = 0;
    this.euroInProgSociali = 0;
    this.pFogliaProgSociali = 0;
    this.modPlastica = 0;
    this.modRifo = 0;
    this.totaleDonazioni = 0;

    // NEW VERSION
    if(this.userProfile.assegnazioni){
      if(this.userProfile.assegnazioni[this.RIFORESTAZIONE]){
        this.pFogliaRifo = this.roundTo(this.userProfile.assegnazioni[this.RIFORESTAZIONE].somma_punti_foglia,0);
        this.modRifo = this.pFogliaRifo % this.TASSO;
        this.euroInRifo = Math.floor(this.userProfile.assegnazioni[this.RIFORESTAZIONE].somma_punti_foglia/this.TASSO);
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.RIFORESTAZIONE].somma_punti_foglia;
      }
      if(this.userProfile.assegnazioni[this.PLASTICA]){
        this.pFogliaPlastica = this.roundTo(this.userProfile.assegnazioni[this.PLASTICA].somma_punti_foglia,0);
        this.modPlastica = this.pFogliaPlastica % this.TASSO;
        this.euroInPlastica = Math.floor(this.userProfile.assegnazioni[this.PLASTICA].somma_punti_foglia/this.TASSO);
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.PLASTICA].somma_punti_foglia;
      }
      if(this.userProfile.assegnazioni[this.SOCIALI]){
        this.pFogliaProgSociali = this.roundTo(this.userProfile.assegnazioni[this.SOCIALI].somma_punti_foglia,0);
        this.euroInProgSociali = this.roundTo(this.userProfile.assegnazioni[this.SOCIALI].somma_punti_foglia*this.VALORE_PFOGLIA,2);
        // Sommo al totale della donazione effettuata
        this.totaleDonazioni = this.totaleDonazioni + this.userProfile.assegnazioni[this.SOCIALI].somma_punti_foglia;
      }
      // Solo alla fine, arrotondo (in difetto) il computo dei punti foglia
      this.totaleDonazioni = Math.floor(this.totaleDonazioni);
    }

    // Calcolo e preparo i parametri del popover nel caso dovesse essere azionato
    this.computeProgressValueAndName();
    this.logger.log("ProfiloUtenteComponent.setProfileData(): END");
  }



  onReloadUserProfile(){
    // Retry clicked by the user..... attempt to reload user profile
    this.logger.log("ProfiloUtenteComponent: onReloadUserProfile()");
    this.waitUserTokenInitialization();
  }

  onAccediButton(){
    // Accedi clicked by the user..... attempt to redirect to the Login/Registration page
    this.logger.log("ProfiloUtenteComponent: onAccediButton()");
    this.router.navigateByUrl("/login?next=user");
  }

  ngOnDestroy() {}

  ionViewWillEnter(){
    this.logger.log("ProfiloUtenteComponent: ionViewWillEnter");
    this.waitUserTokenInitialization();
    this.menuService.setBuyerMenu();
  }

  async CreateDonationPopover(ev: any, amount: number, cat: string) {
    this.pop = await this.popover.create({
      component: DonationPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        "modDonation": amount,
        "categoria": cat
      }
    });
    return await this.pop.present();
  }
  

  async CreatePopover(ev: any) {
    this.pop = await this.popover.create({
      component: AnimalPopover,
      cssClass: 'animal-popover-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        "user": this.userProfile,
        "index": this.animalIndex,
        "progress": this.progressValue,
        "progressValueUnbounded": this.progressValueUnbounded,
        "progressBound": this.progressBound,
        "donazioni": this.totaleDonazioni
      }
    });
    return await this.pop.present();
  }

  private basePath: string = 'assets/icon/animal/';
  public animalPicture: string = 'assets/icon/animal/kiwi.JPG';
  private animalName: string = 'Kiwi';
  private progressValue: number = 0.1;
  private progressValueUnbounded = 0;
  private progressBound: number = 20;
  private valueRangeVector = [20,50,100,200,350,500,750,1000,1500,3000,4500,6000,8000,10000,20000,99999];
  private animalIndex: number = 0;
  private picPathVector = ['kiwi.JPG','colocolo.JPG','pappagallo.JPG','caretta.JPG','pangolino.JPG',
                              'mosco.JPG','pinguino.JPG','aquila.JPG','delfino.JPG',
                              'tigre.JPG','panda.JPG','squalo.JPG','rinoceronte.JPG','elefante.JPG',
                              'balenottera.JPG','drago_blu.png'];

  computeProgressValueAndName(){
    var resultIndex = 0;
    for(let range of this.valueRangeVector){
      // OLD: this.roundTo(this.userProfile.totale_donazioni,0) invece di this.totaleDonazioni
      if(this.totaleDonazioni>range){
        resultIndex = resultIndex+1;
      }else{
        break;
      }
    }
    if(resultIndex!=0){
      this.logger.log('AnimalPopover: resultIndex = '+resultIndex);
      this.progressValue = (this.totaleDonazioni - this.valueRangeVector[resultIndex-1]) / 
                      (this.valueRangeVector[resultIndex] - this.valueRangeVector[resultIndex-1]);
      this.progressValueUnbounded = (this.totaleDonazioni - this.valueRangeVector[resultIndex-1]);
      this.progressBound = (this.valueRangeVector[resultIndex] - this.valueRangeVector[resultIndex-1]);
      this.animalIndex = resultIndex;
      this.animalPicture = this.basePath+this.picPathVector[resultIndex];
    }else if(resultIndex==0){
      this.logger.log('AnimalPopover: resultIndex = ZERO   --> si tratta del primo animale');
      this.progressValue = this.totaleDonazioni / this.valueRangeVector[resultIndex];
      this.progressValueUnbounded = this.totaleDonazioni;
      this.progressBound = this.valueRangeVector[resultIndex];
      this.animalIndex = resultIndex;
      this.animalPicture = this.basePath+this.picPathVector[resultIndex];
    }
    this.logger.log('AnimalPopover: progress calcolato = '+this.progressValue);
    this.logger.log('AnimalPopover: nome animale calcolato = '+this.animalName);
    this.logger.log('AnimalPopover: path foto animale calcolato = '+this.animalPicture);
    }

    toCart(){
      this.logger.log('ProfiloUtenteComponent: ...toCart()');
      this.router.navigateByUrl("/shopping-cart");
    }

    toOrders(){
      this.logger.log('ProfiloUtenteComponent: ...toOrders()');
      this.router.navigate(["myprofile/user/buyer/orders"]);
    }

    toShippingAddressList(){
      this.logger.log('ProfiloUtenteComponent: ...toShippingAddressList()');
      let navigationExtras: NavigationExtras = {
        state: {
          type: 'user'
        }
      };
      this.router.navigate(['myprofile/user/shipping-address/edit'], navigationExtras);
    }

    async ionViewWillLeave(){
      this.logger.log('ProfiloUtenteComponent: ----- ionViewWillLeave  ----- ');
      // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
      if(this.pop){
        this.logger.log('TROVATO IL POP');
        const popover = await this.popover.getTop();
        if(popover){
          await popover.dismiss();
          this.logger.log('Popover dismissed');
        }
      }
    }

    toTreeDonation(){
      this.CreateDonationPopover(null, this.modRifo, 'Tree');
    }

    toPlasticDonation(){
      this.CreateDonationPopover(null, this.modPlastica, 'Plastic');
    }

    toSocialDonation(){
      this.CreateDonationPopover(null, this.euroInProgSociali, 'Social');
    }

    roundTo(num: number, places: number) {
      const factor = 10 ** places;
      return Math.round(num * factor) / factor;
    }

    ionViewDidEnter(){
      this.logger.log('UserProfile: ionViewDidEnter() launched...');
      this.initializeShoppingCartBadge();
    }

    // Metodo della home con piccole accortezze ***
    initializeShoppingCartBadge(){
      // Controllo quali items ho nel carrello locale
      // Se l'utente è loggato e nel carrello locale ci sono items, allora li fondo 
      // Se l'utente è loggato e nel carrello locale non ci sono items, inizializzo il badge (*** non serve)
      // Se l'utente non è loggato inizializzo il badge con i dati del carrello locale (*** non presente perchè l'utente è per forza loggato)
        
      this.shoppingCartManagerService.localCart_storageValues('UserProfile').then(
        res => {
          this.logger.log('UserProfile: local storage keys returned = '+res);
          this.localStorageKeys = res;
          this.cartBadge = this.localStorageKeys.length;
  
          var index = 0;
          for(let key of this.localStorageKeys){
            const temp_index = index;
            this.shoppingCartManagerService.localCart_getObject(key).then(
              value => { 
                this.local_cart[temp_index] = value;
                this.local_cart[temp_index]['key'] = key;
  
                if(temp_index==this.localStorageKeys.length-1 && this.authService.isLoggedIn()){
                  // Carrello locale contiene items, e l'utente è loggato -> fusione dei carrelli
                  this.logger.log('UserProfile: Fusione del carrello locale con il carrello remoto.. Invio tramite POST del carrello locale');
                    
                  this.shoppingCartManagerService.mergeRemoteAndLocalShoppingCart('UserProfile', this.local_cart).subscribe(
                    posts => {
                      this.logger.log(posts);

                      // Indico al servizio globale che il carrello è cambiato 
                      this.shoppingCartManagerService.isChanged = true;
                      // Recupero il carrello 
                      this.shoppingCartRetriever();
                    });
                }else{
                  // DEBUG prints
                  this.logger.log('Carrello locale vuoto (riga 331), temp index errato (next print), oppure isloggedin ritorna false (riga 334)');
                  this.logger.log(temp_index);
                }
  
              });
            index = index + 1;
          }

          this.logger.log('UserProfile: isLoggedIn = '+this.authService.isLoggedIn());
  
          if(this.localStorageKeys==null || this.localStorageKeys.length==0){
            this.logger.log('UserProfile: carrello locale vuoto');
            if(this.authService.isLoggedIn()){
              // Aggiornamento del carrello per ottenere il corretto badge
              this.shoppingCartManagerService.isChanged = true;
              this.shoppingCartRetriever();
            }
          }
        });

    }

    shoppingCartRetriever(){
      if(this.authService.isLoggedIn()){
        this.userService.fetchMyProfile().then(
          ended => {
            if(this.userService.userIsClient()){
              this.logger.log('UserProfile: utente loggato, inizializzazione badge del carrello tramite API');
              this.shoppingCartManagerService.getShoppingCartBadge('UserProfile').subscribe(
                posts => {
                  this.logger.log('UserProfile: cartBadge returned = '+posts);
                  this.cartBadge = posts;
                },
                error => {
                  this.logger.log('UserProfile: error returned... cartBadge = null');
                  this.cartBadge = null;
                  //this.hasRemoteCart = false;
                  this.logger.log(error);
                }
              )
            }else{
              // utente loggato non è cliente 
              this.logger.log('UserProfile: utente venditore -> aggiornamento carrello evitato');
            }
          }
        );
      }else{
        // Badge del carrello inizializzato in storageValues 
        this.logger.log('UserProfile: utente non loggato, inizializzazione badge del carrello tramite local storage');
      }
    }

}
