import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, IonContent, Platform, PopoverController } from '@ionic/angular';
import { BillingListItem, BillingElementDetail, OrderList, OrderListDetail, OrderListElem } from 'src/app/shared/models/Billing.models';
import { OrderDetail } from 'src/app/shared/models/Ordini.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BillingService } from 'src/app/shared/services/billing.service';
import { BillingPopoverComponent } from './billing-popover/billing-popover.component';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { NGXLogger } from 'ngx-logger';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {

  @ViewChild(IonContent, {static: false}) private content: IonContent;

  // variables for closed orders
  orderList: BillingListItem[] = [];
  orderListIsCompleted = {};
  orderDetail: BillingElementDetail[] = [];
  // variables for pending orders
  pendingOrdersHead: OrderList;
  pendingOrdersList: OrderListElem[] = [];
  pendingOrderDetail: OrderListDetail[] = [];
  isFetching: boolean = false;
  firstCall: boolean = true;
  private next: string = null;

  generale: boolean = true;
  pendenti: boolean = false;
  private currentID: string;
  private payedOrders: any = [];
  pop: any;

  constructor(public platform: Platform, private authService: AuthService, private popover: PopoverController,
    private billingService: BillingService, private router: Router, private alertController: AlertController,
    private ref: ChangeDetectorRef, private logger: NGXLogger) { }

  ngOnInit() {}

  ionViewWillEnter(){
    this.logger.log('OrderListComponent: ----- Billing List ----- ionViewWillEnter');
    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.pendingOrdersRequest();
          this.ordersToBePayedRequest();
          this.ordersPayedRequest();
        })
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.pendingOrdersRequest();
      this.ordersToBePayedRequest();
      this.ordersPayedRequest();
    }
  }

  switchToPendingOrders(){
    this.pendenti = true;
  }

  switchToClosedOrders(){
    this.pendenti = false;
  }

  pendingOrdersRequest(){

    if(this.moreDataToLoad()){
      this.isFetching = true;
      this.firstCall = false;
      this.billingService.fetchOrders(this.next).subscribe(
        res => {
          this.logger.log(res);
          this.isFetching = false;
          this.pendingOrdersHead = res;
          this.pendingOrdersList = this.pendingOrdersList.concat(res.results);
          this.next = res.next;
        },
        err => {
          this.logger.log(err);
          this.isFetching = false;
          if(err.status && err.status==403){
            this.logger.log('FORBIDDEN caught');
            this.backHome();
          }else{
            this.logger.log('Altro errore catturato...');
            this.backToBillingLoginPage();
          }
        }
      )
    }
  }

  checkIfBottomReached(){
    // this.logger.log("checkIfBottomReached start")
    if(!this.moreDataToLoad() || this.isFetching || !this.pendenti){
      // this.logger.log("checkIfBottomReached. exiting", this.moreDataToLoad(), this.isFetching)
      return;
    }
    this.checkForScrollbar().then(bottomReached => {
      // this.logger.log("checkIfBottomReached - scrollBarVisible=", bottomReached)
      if (!bottomReached && !this.isFetching) {
        this.logger.log("Screen is not filled with orders. Loading some more.")
        this.pendingOrdersRequest();
      }
    });
  }

  moreDataToLoad(){
    return this.next != null || this.firstCall;
  }

  async checkForScrollbar() {
    // This method is used to fix a bug related to the InfiniteScroll object,
    // that does not work if the content (meaning, the grid of products) does not fill the screen
    // (this bug only occurs on big screens with a high resolution for this page)
    const scrollElement = await this.content.getScrollElement()
    // this.logger.log("checkForScrollbar", scrollElement.scrollHeight, scrollElement.clientHeight)
    
    // If the scrollHeight is 0, the view is not yet loaded. Don't do anything.
    if(scrollElement.scrollHeight === 0){
      return true;
    }
    // this.logger.log("Returning", scrollElement.scrollHeight > scrollElement.clientHeight)
    return scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  loadData(event) {
    if(!this.isFetching){
      this.pendingOrdersRequest();
    }
    if(event){
      event.target.complete();
    }
  }

  ordersToBePayedRequest(){
    this.billingService.fetchOrdersToBePayed().subscribe(
      res => {
        this.logger.log(res);
        this.orderList = res;
        this.logger.log('OrderListComponent: order list has '+this.orderList.length+' elements');
        // Preparo il dizionario per capire se un ordine è in stato COMPLETED
        for(let order of this.orderList){
          this.orderListIsCompleted[order.id] = true;
          this.toIsItCompleted(order.id);
        }
        // Dizionario di default -> Tutti completed
        this.logger.log(this.orderListIsCompleted);
      },
      err => {
        this.logger.log(err);
        if(err.status && err.status==403){
          this.logger.log('FORBIDDEN caught');
          this.backHome();
        }else{
          this.logger.log('Altro errore catturato...');
          this.backToBillingLoginPage();
        }
      }
    )
  }

  ordersPayedRequest(){
    // Richiedo anche gli ordini pagati per ottenere gli ID del... pagamento? fattura? BO!
    this.billingService.fetchOrdersAlreadyPayed().subscribe(
      res => {
        this.logger.log(res);
        this.payedOrders = res;
      },
      error => {
        this.logger.log(error);
        this.logger.log('In questo caso non sarà possibile effettuare la PUT di aggiornamento di paga e fattura!!!');
      }
    );
  }

  backHome(){
    this.router.navigate(['home']);
  }

  backToBillingLoginPage(){
    let navigationExtras: NavigationExtras = {
      state: {
        where: 'Lista degli ordini',
        error: 'Errore nella richiesta dei dati'
      }
    };
    this.router.navigate(['svneaonak-bimming-akjfawwomerutenavbzpaoganaibqn'], navigationExtras);
  }

  toPendingOrder(ID: string){
    this.logger.log('OrderListComponent: clicked on PENDING Order with ID = '+ID);
    this.generale = false;
    this.pendingOrderDetailRequest(ID);
  }

  pendingOrderDetailRequest(ID: string){

      this.billingService.fetchOrderDetail(ID).subscribe(
        res => {
          this.logger.log(res);
          this.isFetching = false;
          this.pendingOrderDetail = res;
        },
        err => {
          this.logger.log(err);
          this.isFetching = false;
          if(err.status && err.status==403){
            this.logger.log('FORBIDDEN caught');
            this.backHome();
          }else{
            this.logger.log('Altro errore catturato...');
            this.backToBillingLoginPage();
          }
        }
      )
  }

  toIsItCompleted(ID: string){
    this.logger.log('toIsItCompleted: launched for ID = '+ID);
    this.billingService.fetchOrderToBePayed(ID).subscribe(
      res => {
        for(let x_res of res){
          if(!x_res.fattura || !x_res.pagato){
            this.logger.log(ID+' -> FALSE');
            this.orderListIsCompleted[ID] = false;
            // Update DOM
            this.ref.detectChanges();
            break;
          }
        }
      },
      error =>{
        this.logger.log('ERRORE nella valutazione degli ordini COMPLETED!');
        this.orderListIsCompleted[ID] = true;
      }
    )
  }

  toClosedOrder(ID: string){
    this.logger.log('OrderListComponent: clicked on item with ID = '+ID);
    this.generale = false;
    this.billingService.fetchOrderToBePayed(ID).subscribe(
      res => {
        this.logger.log(res);
        this.orderDetail = res;
        this.logger.log(this.orderDetail);
        this.currentID = ID;
        this.logger.log('ID ordine corrente = '+this.currentID);
      },
      error => {
        this.logger.log(error);
        this.generale = true;
        if(error.status == 403){
          this.router.navigate(['home']);
        }
      }
    );
  }

  async popupDonazione(o: BillingListItem){
    this.logger.log('OrderListComponent: clicked on item with ID = '+o.id);

    var testo = 'NESSUNA';

    this.logger.log(o.punti_foglia_assegnati);

    if(o.punti_foglia_assegnati){
      testo = 'Donazione: ';
      for(let item of o.punti_foglia_assegnati){
        this.logger.log(item['servizio']); // DEBUG
        if(item['servizio'] && item['servizio']=="Riforestazione"){
          testo = testo + " - Riforestazione: "+item['punti_foglia_assegnati']+" p.f. - ";
        }else if(item['servizio'] && item['servizio']=="Smaltimento Plastica"){
          testo = testo + " - Smaltimento Plastica: "+item['punti_foglia_assegnati']+" p.f. - ";
        }else if(item['servizio'] && item['servizio']=="Progetto Sociale"){
          testo = testo + " - Progetti Sociali: "+item['punti_foglia_assegnati']+" p.f. - ";
        }
      }
    }

    const alert = await this.alertController.create({
      header: 'Donazione',
      cssClass: 'alert-info-popup-style',
      subHeader: 'Prodotto con ID: '+o.id,
      message: testo,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-style'
        }
      ]
    });
  
    await alert.present();

  }

  donationToNumber(o: BillingListItem, w: number){
    this.logger.log('OrderListComponent: donationToText() with ID = '+o.id);

    var testo = 'NESSUNA';

    //this.logger.log(o.punti_foglia_assegnati); // DEBUG

    if(o.punti_foglia_assegnati){
      testo = '/';
      for(let item of o.punti_foglia_assegnati){
        //this.logger.log(item['servizio']); // DEBUG
        if(item['servizio'] && item['servizio']=="Riforestazione" && w==1){
          testo = testo + this.roundTo(item['punti_foglia_assegnati'],2);
          return this.roundTo(item['punti_foglia_assegnati'],2);
        }else if(item['servizio'] && item['servizio']=="Smaltimento Plastica" && w==2){
          testo = testo + this.roundTo(item['punti_foglia_assegnati'],2);
          return this.roundTo(item['punti_foglia_assegnati'],2);
        }else if(item['servizio'] && item['servizio']=="Progetto Sociale" && w==3){
          testo = testo + this.roundTo(item['punti_foglia_assegnati'],2);
          return this.roundTo(item['punti_foglia_assegnati'],2);
        }
        testo = testo + '/';
      }
    }

    return 0;
  }

  toBack(){
    this.logger.log('OrderListComponent: back pressed.. toward general view');
    this.generale = true;
  }

  async toDeliveredProductsDetail(rowElem: BillingElementDetail){
    this.logger.log(rowElem.info_prodotto);

    var testo = '';
    for(let x of rowElem.info_prodotto){
      testo = testo + ''+x.quantità+' '+x.prodotto+', \n';
    }
    
    const alert = await this.alertController.create({
      header: 'Dettaglio',
      cssClass: 'alert-info-popup-style',
      subHeader: 'Dettaglio delle quantità dei prodotti per questo item: ',
      message: testo,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-style'
        }
      ]
    });
  
    await alert.present();
  }

  async ionViewWillLeave(){
    this.logger.log('OrderListComponent: ----- ionViewWillLeave  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  ionViewDidLeave(){
    this.logger.log('OrderListComponent: ionViewDidLeave() launched');
    this.authService.logout();
  }

  toBillingPopover(item: BillingElementDetail){
    this.logger.log('Cliccato sull\'ordine con ID = '+this.currentID+' e vendor = '+item['venditore']);
    if(!this.currentID || (item && !item['venditore'])){
      this.logger.log('Aperto un popup che non potrà permettere pagamenti o fatture...');
    }
    this.CreateBillingPopover(null, this.currentID, item['venditore']);
  }

  async CreateBillingPopover(ev: any, ID: string, venditore: string) {
    this.pop = await this.popover.create({
      component: BillingPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        "text": 'testo',
        "ID": ID,
        "venditore": venditore
      }
    });

    this.pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('OrderListComponent: popover closed with boolean = '+result.data);
         if(result.data){
           // Aggiornamento andato a buon fine
           this.toClosedOrder(this.currentID);
         }else{
           // Aggiornamento rimandato, tutto rimane come prima
         }
      }
    });

    return await this.pop.present();
  }

  // EXCEL and DOWNLOAD methods
  public exportAsExcelFile(json: any[], excelFileName: string){
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsExcelFileDonationDetails(json1: any[], excelFileName: string){
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var ws1 = XLSX.utils.json_to_sheet(json1);
    XLSX.utils.book_append_sheet(wb, ws1, 'elementiOrdine');

    var json2 = [];
    var index = 0;
    for(let item of json1){
      json2[index] = { id : item.id};
      json2[index+1] = item.punti_foglia_assegnati[0];
      json2[index+2] = item.punti_foglia_assegnati[1];
      json2[index+3] = item.punti_foglia_assegnati[2];
      index = index + 4;
    }
    var ws2 = XLSX.utils.json_to_sheet(json2);
    XLSX.utils.book_append_sheet(wb, ws2, 'infoDonazione');
    
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsExcelFileBillingDetails(json1: any[], excelFileName: string){
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var ws1 = XLSX.utils.json_to_sheet(json1);
    XLSX.utils.book_append_sheet(wb, ws1, 'elementiOrdine');

    var json2 = [];
    var index = 0;
    for(let item of json1){
      json2[index] = item.info_fatturazione;
      index = index + 1;
    }
    var ws2 = XLSX.utils.json_to_sheet(json2);
    XLSX.utils.book_append_sheet(wb, ws2, 'infoFatturazione');
    
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string){
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     fileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

}
