<ion-card style="margin: 0px;">
    <ion-card-header>
      <div style="margin-top: 24px;">
        <p style="text-align: center; color: var(--ion-color-primary); font-size: 18px;"><strong>Checkout</strong>
          <ion-icon name="alert-circle" color="danger" style="height: 45px; width: 45px; margin-bottom: -16px; margin-left: 6px;"></ion-icon>
        </p>
      </div>
    </ion-card-header>
    <ion-card-content style="padding-left: 8px; padding-right: 8px;">
    
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <p style="font-size: 15px; text-align: center;">
                <strong>Vuoi davvero eliminare questo elemento dal tuo ordine?</strong>
            </p>
          </ion-col>
          <ion-col size="12"></ion-col>
        </ion-row>
        <ion-row>
          
          <ion-col size="12" style="text-align: center;">
            <ion-button color="sea" (click)="onNot()">Annulla</ion-button>
            <ion-button color="danger" (click)="onYes()" style="margin-left: 6px; width: 90px;">Elimina</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
  
    </ion-card-content>
</ion-card>