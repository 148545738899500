import { LOCALE_ID, NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CategoriesModule } from './categories/categories.module';
import { HomePageModule } from './home/home.module';
//import { MapModule } from './map/map.module';
import { UserModule } from './user/user.module';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { ShoppingCartModule } from './shopping-cart/shopping-cart.module';
import { ProdottiInteractionService } from './shared/services/prodotti-interaction.service';
import { CheckoutModule } from './checkout/checkout.module';
import { OrderDetailModule } from './order-detail/order-detail.module'

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { ProductsModule } from './products/products.module';
import { ShoppingCartManagerService } from './shared/services/shopping-cart-manager.service';
import { ShoppingCartItemsService } from './shared/services/shopping-cart-items.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { VendorListModule } from './vendors/vendorlist/vendorlist.module';
import { VendorModule } from './vendors/vendor.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BimmingModule } from './bimming/login/bimming.module';
import { OrderListBimmingModule } from './bimming/order-list/order-list.module';
import { ShippingPolicyModule } from './shipping-policy/shipping-policy.module';
import { AboutUsModule } from './about-us/about-us.module';
import { MissionModule } from './mission/mission.module';
import { MenuService } from './shared/services/menu.service';
import { SignalModule } from './signal/signal.module';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';

registerLocaleData(localeIt, localeItExtra);

// Production/Quality LOGGING
// Vai su environment.prod.ts e setta isDebugMode = true se li vuoi, false altrimenti
// Polyfills e main.ts NON sono più decisivi ai fini del logging


@NgModule({
  declarations: [AppComponent],
  /*entryComponents: [], Removed during the update from Angular v15 to v16*/
  schemas: [CUSTOM_ELEMENTS_SCHEMA], /* Added during migration to Ionic v7*/
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.isDebugMode ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      // serverLogLevel
      //serverLogLevel: NgxLoggerLevel.OFF
      }),
    UserModule,
    ShoppingCartModule,
    CategoriesModule,
    HomePageModule,
    CheckoutModule,
    OrderDetailModule,
    ProductsModule,
    VendorListModule,
    VendorModule,
    PageNotFoundModule,
    ShippingPolicyModule,
    AboutUsModule,
    SignalModule,
    MissionModule,
    BimmingModule,
    OrderListBimmingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "it-IT"},
    StatusBar,
    ProdottiInteractionService,
    ShoppingCartManagerService,
    Deeplinks,
    MenuService,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
