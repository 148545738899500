<ion-header class="ion-no-border">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">  <!-- #scrollArea scrollEvents="true" -->

  <div *ngIf="hasUserProfileInfo && !isFetching" class="exp-div" style="background-image: linear-gradient(to bottom, #26a96c, #57cc99);"> 
    <ion-item lines="none" color="transparent">
      <div class="userpic-container">
        <img src="{{ constantUtilsService.getFullURL(userProfile.foto_profilo) }}" *ngIf="userProfile && userProfile.foto_profilo!='Missing'" onerror="this.src='assets/placeholders/male_user.svg'" class="userpic">
        <img src="assets/placeholders/male_user.svg" *ngIf="userProfile && userProfile.foto_profilo=='Missing'" class="userpic">
        <img src="{{ animalPicture }}" onerror="this.src='assets/icon/animal/kiwi.JPG'" class="user-animal" (click)="CreatePopover($event)">

        <ion-fab-button title="Carica una nuova foto" *ngIf="!errorMsg" 
            title="Modifica i tuoi dati" routerLink="/myprofile/user/edit" class="fab-load-btn">
          <ion-icon name="pencil-outline" color="primary"></ion-icon>
        </ion-fab-button>
      </div>
    </ion-item>

    <ion-item lines="none" color="transparent" style="margin-top: -2px;">
      <ion-label class="username"> 
        <h1 style="color: #ffffff;">
          {{ userProfile.first_name }} {{ userProfile.last_name }} 
        </h1>
      </ion-label>
    </ion-item>

    <ion-item lines="none" color="transparent" style="margin-top: -2px;">
      <ion-label class="username" style="color: #ffffff;"> {{ userProfile.email }} </ion-label>
    </ion-item>
  </div>

  <ion-spinner name="bubbles"  *ngIf="isFetching"></ion-spinner>

  <ion-button *ngIf="toRegistration" class="accedi" (click)="onAccediButton()">Accedi</ion-button>

  <div *ngIf="!hasUserProfileInfo && !isFetching && !toRegistration" class="error-div">
    <img src="assets/error/cavolo.jpg" class="error-resize">
    <p class="vendor-title"> Cavolo, qualcosa è andato storto! </p>
    <p class="vendor-title"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry">
      <ion-fab-button color="primary" (click)="onReloadUserProfile()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>

  <ion-grid *ngIf="hasUserProfileInfo && !isFetching" class="gettoni-container">
    <ion-row>

      <!--
      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="gettoni-col">

        <ion-card class="gettone-card">
          <ion-card-content>
            <img src="assets/images/gettone.jpg" class="gettone"> 
            I tuoi eco-gettoni: {{ userProfile.saldo_gettoni }} 
          </ion-card-content>
        </ion-card>

      </ion-col>-->

      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="gettoni-col">

        <ion-card class="gettone-card">
          <ion-card-content> 
            <img src="assets/icon/logo_secondary_green.png" class="gettone">
            I tuoi punti foglia: <strong>{{ roundTo(totaleDonazioni,0) }}</strong>
          </ion-card-content>
        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="hasUserProfileInfo && !isFetching" style="padding: 0px; margin-bottom: 24px;">
    <ion-row>
      <ion-col class="grid-of-grids" size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">

        <!-- GRIGLIA DELLE DONAZIONI -->
        <ion-grid *ngIf="hasUserProfileInfo && !isFetching" class="donazioni-grid">
          <ion-text class="ion-text-center" color="primary">
            <h3>Le tue donazioni</h3>
          </ion-text>
          
          <ion-row>
            <ion-col 
              size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toTreeDonation()">
                <ion-card-content style="padding-top: 4px;">
                  <ion-row>
                    <ion-col style="padding: 0px; height: 20px;">
                      <p *ngIf="euroInRifo && euroInRifo!=1" class="gettone-text"> <strong>{{ euroInRifo }}</strong> alberi piantati </p>
                      <p *ngIf="euroInRifo && euroInRifo==1" class="gettone-text"> <strong>{{ euroInRifo }}</strong> albero piantato </p>
                      <img src="assets/images/albero_ritagliato.png" class="gettone" style="margin-top: -6px;" *ngIf="!platform.is('ios')"> <!-- *ngIf="!donazionePremium_uno.donazione" -->
                      <img src="assets/images/albero_ritagliato.png" class="gettone" style="margin-top: 0px;" *ngIf="platform.is('ios')">
                      <p class="gettone-text" *ngIf="!euroInRifo" style="margin-right: -16px; padding-right: 8px;"> Dona per piantare alberi! </p>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="9" style="padding: 0px; height: 20px;">
                      <ion-progress-bar value="{{roundTo(modRifo/TASSO,1)}}" style="float: right; width: 80px; margin-top: 9px;"></ion-progress-bar>
                    </ion-col>
                    <ion-col size="3" style="text-align: right; padding: 0px; height: 20px;">
                      <ion-text color="primary" style="font: 14px; font-size: 14px;"><strong>{{modRifo}}</strong>/{{TASSO}}</ion-text>
                      <img src="assets/icon/logo_secondary_green.png" style="height: 12px; width: 12px; margin-left: 3px;">
                    </ion-col>
                  </ion-row>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toPlasticDonation()">
                <ion-card-content style="padding-top: 4px;">
                  <ion-row>
                    <ion-col style="padding: 0px; height: 20px;">
                      <p class="gettone-text" *ngIf="euroInPlastica && euroInPlastica!=1"> <strong>{{ euroInPlastica }}</strong> bottiglie rimosse </p>
                      <p class="gettone-text" *ngIf="euroInPlastica && euroInPlastica==1"> <strong>{{ euroInPlastica }}</strong> bottiglia rimossa </p>
                      <img src="assets/images/bottiglia_ritagliata.png" class="gettone" style="margin-right: 0px; margin-top: -8px;" *ngIf="!platform.is('ios')">  <!-- *ngIf="!donazionePremium_due.donazione" -->
                      <img src="assets/images/bottiglia_ritagliata.png" class="gettone" style="margin-right: 0px; margin-top: -2px;" *ngIf="platform.is('ios')">
                      <p class="gettone-text" style="margin-right: -16px; padding-right: 8px;" *ngIf="!euroInPlastica"> Dona per rimuovere plastica! </p>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="9" style="padding: 0px; height: 20px;">
                      <ion-progress-bar value="{{roundTo(modPlastica/TASSO,1)}}" style="float: right; width: 80px; margin-top: 9px;"></ion-progress-bar>
                    </ion-col>
                    <ion-col size="3" style="text-align: right; padding: 0px; height: 20px;">
                      <ion-text color="primary" style="font: 14px; font-size: 14px;"><strong>{{modPlastica}}</strong>/{{TASSO}}</ion-text>
                      <img src="assets/icon/logo_secondary_green.png" style="height: 12px; width: 12px; margin-left: 3px;">
                    </ion-col>
                  </ion-row>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toSocialDonation()">
                <ion-card-content>
                  <img src="assets/images/casa_ritagliata.png" class="gettone" style="margin-top: -16px;">
                  <p class="gettone-text"> <strong>{{ euroInProgSociali }}€</strong> in progetti sociali </p>
                </ion-card-content>
              </ion-card>
            </ion-col>

          </ion-row>
        </ion-grid>
      <!-- FINE GRIGLIA DELLE DONAZIONI -->
    </ion-col>
    
    <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
        <!-- GRIGLIA DELLE ATTIVITA -->
        <ion-grid *ngIf="hasUserProfileInfo && !isFetching" class="donazioni-grid">
          <ion-text class="ion-text-center" color="primary">
            <h3>Le tue attività</h3>
          </ion-text>
          
          <ion-row>
            <ion-col 
              size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toOrders()">
                <ion-card-content>
                  <ion-icon name="gift" color="primary" class="btn-icon" style="margin-top: -11px;"></ion-icon>
                  I tuoi ordini
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
    
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toCart()">
                <ion-card-content>
                  <ion-icon name="cart" color="primary" class="btn-icon" style="margin-left: -3px; margin-top: -11px;"></ion-icon>
                  Carrello
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
    
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" 
              class="donazioni">
              <ion-card class="donazione-card" (click)="toShippingAddressList()">
                <ion-card-content>
                  <ion-icon name="location" color="primary" class="btn-icon" style="margin-top: -12px;"></ion-icon>
                  I tuoi indirizzi
                </ion-card-content>
              </ion-card>
            </ion-col>
    
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>
  
  <!--<ion-fab vertical="top" horizontal="end" slot="fixed"  *ngIf="!errorMsg" edge style="right: 18%; margin-top: 32px;">
    <ion-fab-button class="edit" title="Modifica i tuoi dati" routerLink="/myprofile/user/edit">
      <ion-icon name="pencil-outline" color="primary"></ion-icon>
    </ion-fab-button>
  </ion-fab>-->
</ion-content>
