import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { of, Subject, throwError } from 'rxjs';
import { UserService } from './User.service';
import { catchError, map } from 'rxjs/operators';
import { OrderDetail, UserOrdersListResponse } from '../models/Ordini.models';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class UserOrderService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger,
    private userService: UserService) {}
    
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyers/orders');

  error = new Subject<string>();

  fetchOrders(url, searchParams){
    if(!this.userService.userIsClient()){
      this.logger.log("UserOrderService - fetchOrders", "user is not a Client. Exiting.");
      return of(null)
    }

    if(!url){
      url = this.defaultUrl;
    }

    return this.http
    .get<UserOrdersListResponse>(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                    
        responseType: 'json'
      }
    )
    .pipe(
      map((responseData:UserOrdersListResponse) => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  createBuyerOrderRequest(orderId: string){
    this.logger.log('UserOrdersService: createBuyerOrderRequest launched');
    if(orderId!=null && orderId!=' '){
      return this.fetchOrderDetails(orderId);
    }
  }

  fetchOrderDetails(orderId: string){
    return this.http
    .get<OrderDetail>(
      this.defaultUrl+'/'+orderId,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),                                    
        responseType: 'json'
      }
    )
  }
}