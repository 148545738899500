import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { OrderListComponent } from './order-list.component';
import { BillingService } from 'src/app/shared/services/billing.service';
import { BillingPopoverComponent } from './billing-popover/billing-popover.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: OrderListComponent},
    ])
  ],
  providers: [BillingService],
  declarations: [OrderListComponent, BillingPopoverComponent]
})

// Questo passerà alla storia come il gesto più inutile della storia della programmazione:
// Chiamare un modulo BIMMING invece di BILLING solo perchè la URL nascosta sarà BIMMING-qualcosa-qualcosa
// ..invece di BILLING-qualcosa-qualcosa, per il solo terrore infondato che la parola BILLING venga in qualche modo individuata

export class OrderListBimmingModule {}