<div class="swipe-container">
  <div class="swipe-header">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-button (click)="this.close()" title="Chiudi" style="height: 100%;">
            <ion-icon class="close" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="this.toCart()" *ngIf="!userService.userIsVendor()" style="height: 100%;">
            <ion-badge class="notifications-badge" *ngIf="newItemIntoShoppingCart && !platform.is('ios')">+{{ moreItemsIntoShoppingCart }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="newItemIntoShoppingCart && platform.is('ios')">+{{ moreItemsIntoShoppingCart }}</ion-badge>
            <ion-icon slot="icon-only" name="cart"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title *ngIf="!platform.is('ios')" style="margin: auto; max-width: 130px; text-align: right; padding-right: 26px;">Prodotto</ion-title>
        <ion-title *ngIf="platform.is('ios')" style="margin: auto;">Prodotto</ion-title>
        <ion-buttons slot="end" *ngIf="userService.userIsVendor()">
          <ion-text color="primary" style="width: 40px;">A</ion-text>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  </div>
  
  <div class="swipe-body">
    <swiper-container pagination="false" slidesPerView="1" speed="500" disableOnInteraction="false" 
      class="container" (slidechangetransitionstart)="productSwiped(null)"
      *ngIf="!isFetching && !errorMsg" #swiper (slidechangetransitionend)="getCurrentItem($event)">
      <!-- (init)="getCurrentItem($event)" Removed migrating to Ionic v7-->
        <swiper-slide *ngFor="let p of this.parentComponent.getProdotti(); let i = index">
          <app-scheda-prodotto [swiper]="this" [prodotto]="p" [index]="i" [isLoading]="this.swipeLoading"></app-scheda-prodotto>
        </swiper-slide>
        <!-- This slides is shown if the user reach the end of the slides while we're loading more products -->
        <swiper-slide *ngIf="this.parentComponent.fetchingProducts()">
          <ion-spinner class="products-slides-spinner" name="bubbles"></ion-spinner>
        </swiper-slide>
    </swiper-container>
  </div>

  <div class="swipe-footer">
    <ion-text color="primary" style="font-size: 12px;">Muoviti verso destra o sinistra per vedere altri prodotti, tocca l'immagine per avere ulteriori dettagli.</ion-text>
    <!-- FOOTER -->
    <ion-footer *ngIf="!isFetching && !errorMsg" style="text-align: center;">
      <ion-button color="primary" (click)="putIntoCart()" disabled={{toCartBtnDisabled}}>Aggiungi al carrello</ion-button>
    </ion-footer>
  </div>

</div>