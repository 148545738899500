import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, NavParams, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { BillingService } from 'src/app/shared/services/billing.service';

@Component({
  selector: 'app-billing-popover',
  templateUrl: './billing-popover.component.html',
  styleUrls: ['./billing-popover.component.scss'],
})
export class BillingPopoverComponent implements OnInit {

  fattura: boolean = false;
  paga: boolean = false;
  error: string = null;
  orderID: string;
  vendorID: string;
  timePagamento: string | string[] = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss', 'en');
  timeFattura: string | string[] = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss', 'en');
  @ViewChild('dateTimePagamento') dateTimePagamento: IonDatetime;
  @ViewChild('dateTimeFattura') dateTimeFattura: IonDatetime;


  constructor(private popoverController: PopoverController, private navParams: NavParams,
    private billingService: BillingService, private logger: NGXLogger) {
    this.orderID = navParams.get('ID');
    this.vendorID = navParams.get('venditore');
    if(!this.orderID){
      this.logger.log('Nessun ID ordine trovato.. tornando indietro dal popover per il billing...');
      this.popoverController.dismiss(false);
    }
    if(!this.vendorID){
      this.logger.log('Nessun ID venditore trovato.. tornando indietro dal popover per il billing...');
      this.popoverController.dismiss(false);
    }
    this.logger.log('ID ordine: '+this.orderID);  // DEBUG
    this.logger.log('vendor ID: '+this.vendorID);  // DEBUG
   }

  ngOnInit() {}

  toAnnulla(){
    this.logger.log('toAnnulla()...');
    this.popoverController.dismiss(false);
  }

  toRiprova(){
    this.logger.log('toRiprova()...');
    this.error = null;
    this.fattura = false;
    this.paga = false;
  }

  toConferma(){
    this.logger.log('toConferma()...');
    var date_paga_param = null;
    var date_fattura_param = null;
    if(this.paga){
      date_paga_param = this.timePagamento;
    }
    if(this.fattura){
      date_fattura_param = this.timeFattura;
    }
    this.billingService.onUpdateOrderToBePayed(this.orderID, this.vendorID, this.paga,this.fattura,date_paga_param,date_fattura_param).subscribe(
      res => {
        this.error = null;
        this.logger.log(res);
        this.popoverController.dismiss(true);
      },
      error => {
        this.logger.log(error);
        this.error = error;
        this.fattura = false;
        this.paga = false;
      }
    );
  }

  onChangeTimePagamento(){
    // TODO: TEST after migration to Ionic v7: timePagamento ora è string | string[]
    this.timePagamento = this.dateTimePagamento.value;
    this.logger.log('PAGAMENTO - New Date proposed = '+this.timePagamento);
  }

  onChangeTimeFattura(){
    // TODO: TEST after migration to Ionic v7: timePagamento ora è string | string[]
    this.timeFattura = this.dateTimeFattura.value;
    this.logger.log('FATTURA - New Date proposed = '+this.timeFattura);
  }

  onChangeCBFattura(){
    if(this.fattura){
      this.fattura = false;
    }else{
      this.fattura = true;
    }
  }

  onChangeCBPaga(){
    if(this.paga){
      this.paga = false;
    }else{
      this.paga = true;
    }
  }

}
