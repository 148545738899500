import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GrigliaProdottiComponent } from './griglia-prodotti.component';
import { SwipeProdottiModule } from '../swipe-prodotti/swipe-prodotti.module';
import { ProductCardMenuComponent } from './product-card-menu/product-card-menu.component';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { DeleteProductPopover } from './delete-product-popover/delete-product-popover.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwipeProdottiModule
  ],
  providers: [ConstantUtilsService],
  declarations: [GrigliaProdottiComponent, ProductCardMenuComponent, DeleteProductPopover],
  exports: [GrigliaProdottiComponent]
})
export class GrigliaProdottiModule {}
