
  <ion-radio-group (ionChange)="onChangeRadioBtn($event)" value="{{initialButton}}">
    <ion-list lines="none" *ngFor="let x of positions; let i = index">
      <ion-grid class="ion-no-padding">
        <ion-row *ngIf="x">
          <ion-col size-xs="8" size-sm="9" size-md="9" size-lg="9" size-xl="9">
            <ion-item lines="none" style="padding-top: 12px;">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
                    <ion-text>{{ x.indirizzo }}, {{ x.civico }}</ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                    <ion-text><strong>Città:</strong> {{ x['città'] }}</ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                    <ion-text><strong>CAP:</strong> {{ x.cap }}</ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                    <ion-text><strong>Provincia:</strong> {{ x.provincia }}</ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                    <ion-text><strong>Nazione:</strong> {{ x.paese }}</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="x.latitudine!=0 && x.longitudine!=0">
                  <ion-col style="text-align: right;">
                    <ion-icon name="checkmark-circle" color="primary" style="margin-top: 2px; margin-right: 4px; display: inline-block; vertical-align: middle; padding-bottom: 4px;"></ion-icon>
                    <ion-text color="primary" style="font-size: 14px; margin-right: 32px; display: inline-block; vertical-align: middle;" (click)="infoAlert()">Verificato</ion-text>
                  </ion-col>
                </ion-row>
              </ion-grid>  
            </ion-item>
          </ion-col>
          <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="3" size-xl="3">
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col size="12" class="last">
                  <ion-radio value="{{x.id}}" class="radio-style" *ngIf="!x.preferito"></ion-radio>
                  <ion-button fill="clear" color="danger" (click)="onRemove(i)" class="remove" *ngIf="!x.preferito">
                    <ion-icon slot="icon-only" name="close-circle"></ion-icon>
                  </ion-button>

                  <ion-radio value="{{x.id}}" class="radio-style-pref" *ngIf="x.preferito"></ion-radio>
                  <p *ngIf="x.preferito"><a>Principale</a></p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
  </ion-radio-group>