import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-info-for-vendor-popover',
  templateUrl: './info-for-vendor-popover.component.html',
  styleUrls: ['./info-for-vendor-popover.component.scss'],
})
export class InfoForVendorPopoverComponent implements OnInit {

  private innerWidth: any;
  smallScreen: boolean = true;
  private SCREEN_SIZE_XS_SM: number = 576;
  private SCREEN_SIZE_SM_MD: number = 768;
  user_logged_in: boolean = false;

  titolo1: string = 'Crea la tua vetrina online';
  titolo1_11: string = ' per i tuoi prodotti ';
  titolo1_1: string = 'ecosostenibili';
  testo1: string = 'Nessun costo, nessun abbonamento';
  testo2: string = 'Emplace prenderà una commissione per ogni vendita';
  testo3: string = 'Pensiamo noi al marketing';
  testo4: string = 'Ci occupiamo di spargere la voce e farti conoscere sui social network ed online';
  testo5: string = 'Salviamo insieme il mondo';
  testo6: string = 'Per ogni prodotto che venderai ai nostri emplacer, Emplace destinerà metà della commissione per piantare alberi, raccogliere plastica dagli oceani e supportare progetti sociali.';
  modeInfo: boolean = false;
  testoInfo1: string = 'Che venda prodotti ';
  testoInfo2: string = 'sostenibili per l\'ambiente o per le persone,';
  testoInfo3: string = ' che produca cibo, bevande o abbigliamento ';
  testoInfo4: string = 'biologico,';
  testoInfo5: string = ' che faccia a mano prodotti con ';
  testoInfo6: string = 'materiali riciclati';
  testoInfo7: string = ' o prodotti della ';
  testoInfo8: string = 'tradizione locale,';
  testoInfo9: string = ' che tu sia ';
  testoInfo10: string = 'artigiana';
  testoInfo10_1: string = ' o abbia una ';
  testoInfo10_2: string = 'bottega';
  testoInfo11: string = ' o un piccolo negozio che ';
  testoInfo12: string = 'tiene vivo il centro storico';
  testoInfo12_1: string = ':';
  testoInfo13: string = 'Emplace ti aspetta! Registrati subito, è semplice e gratuito!';

  constructor(private popoverProp: PopoverController, private router: Router, private authService: AuthService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.user_logged_in = this.authService.isLoggedIn();
    this.onResize(null);
  }

  onClose(){
    this.logger.log('InfoPopover: onClose() called');
    this.ClosePopover();
  }

  ClosePopover() {
    this.popoverProp.dismiss();
  }

  toRegistrati(){
    this.router.navigate(['registration']);
    this.popoverProp.dismiss();
  }

  toInfo(){
    if(!this.modeInfo){
      this.modeInfo = true;
    }else{
      this.modeInfo = false;
    }
  }

  // Tiene conto dei cambi di screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //this.logger.log('HomePage: cambio di screen size -> '+this.innerWidth);  // DEBUG
    this.screenManager();
  }

  private screenManager(){
    // Gestisco il cambio di dimensione dello schermo 
    if(this.innerWidth<this.SCREEN_SIZE_XS_SM){
      this.smallScreen = true;
    }else{
      this.smallScreen = false;
    }
  }

}
