import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements OnInit {

  constructor(private popoverProp: PopoverController, private logger: NGXLogger) { }

  titolo1: string = 'I Punti Foglia:';
  testo1: string = 'Una delle caratteristiche di Emplace sono i ';
  testo2: string = 'punti foglia:'
  testo3: string = 'per ogni Euro speso sulla piattaforma si guadagna 1 punto foglia (che ha il valore di 0,05€). Questi punti vengono poi utilizzati per effettuare ';
  testo4: string = 'donazioni';
  testo5: string = ' e accedere a degli ';
  testo6: string = 'incredebili sconti.';
  titolo2: string = 'Donazioni per ogni acquisto:';
  testo7: string = 'Ogni volta che realizzi un acquisto i punti foglia ottenuti vengono utilizzati per ';
  testo8: string = 'effettuare delle donazioni'; 
  testo9: string = ' a progetti di ';
  testo10: string = 'riforestazione,';
  testo11: string = ' di ';
  testo12: string = 'pulizia degli oceani';
  testo13: string = ' o di ';
  testo14: string = 'inclusione sociale.';
  testo14_1: string = 'Sarai tu a scegliere'
  testo15: string = ' dove preferisci destinare le tue donazioni!';
  testo16: string = ' Il costo dei prodotti non subisce nessun aumento, quindi le donazioni saranno totalmente a carico di Emplace.';

  ngOnInit() {}

  ClosePopover() {
    this.popoverProp.dismiss();
  }

  onClose(){
    this.logger.log('InfoPopover: onClose() called');
    this.ClosePopover();
  }

}
