import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { ShoppingCartComponent } from './shopping-cart.component';
import { ShoppingCartItemsService } from '../shared/services/shopping-cart-items.service';
import { SCDeleteItemPopover } from './shopping-cart-popover/shopping-cart-popover.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: ShoppingCartComponent},
      { path: 'shopping-cart/', component: ShoppingCartComponent}
    ])
  ],
  providers: [ShoppingCartItemsService],
  declarations: [ShoppingCartComponent, SCDeleteItemPopover]
})
export class ShoppingCartModule {}