import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ConstantUtilsService {

  //private SERVER_BASE_URL = "http://localhost:8080"   // DEV
  //private SERVER_BASE_URL = "https://api.preproduzione.emplacestore.it"   // QUALITY
  private SERVER_BASE_URL = "https://api.emplacestore.it"   // PROD
  API_PATH = "/v1"

  getFullURL(relativeURL: string){
    return this.SERVER_BASE_URL + relativeURL;
  }

  isEmplaceBackend(url){
    // Check whether the outgoing request is made to Emplace backend
    //const emplaceBackendNames = ["127.0.0.1:8080", "localhost:8080"]   // DEV
    //const emplaceBackendNames = ["https://api.preproduzione.emplacestore.it"]     // QUALITY
    const emplaceBackendNames = ["https://api.emplacestore.it"]     // PROD
    let isEmplaceBackend = false;
    emplaceBackendNames.forEach(serverName => {
        if(url.includes(serverName)){
            isEmplaceBackend = true;
        }
    })
    return isEmplaceBackend;
  }

  /*isStripeMessage(url){
    // Check whether the outgoing request is made by Stripe webservice
    const emplaceBackendNames = ["https://stripe.com", "https://www.stripe.com", 
    "https://dashboard.stripe.com", "https://www.dashboard.stripe.com"]
    let isEmplaceBackend = false;
    emplaceBackendNames.forEach(serverName => {
        if(url.includes(serverName)){
            isEmplaceBackend = true;
        }
    })
    return isEmplaceBackend;
  }*/

  
  round(num: number){
    if(num>=10){
      // Se il numero è 10 o maggiore di 10 allora approssimo agli interi
      return this.roundTo(num,0);
    }else if(num>0 && num<10){
      // Se il numero è minore di 10 allora approssimo alla prima cifra decimale 
      return this.roundTo(num,1);
    }else{
      // Se è minore o uguale a 0 allora ritorno 0
      return 0;
    }
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

}