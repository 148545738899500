import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-shipping-policy',
  templateUrl: './shipping-policy.component.html',
  styleUrls: ['./shipping-policy.component.scss'],
})
export class ShippingPolicyComponent implements OnInit {

  constructor(public platform: Platform) { }

  title1: string = 'Termini e Condizioni: Policy Spedizioni e Resi di ';
  title2: string = 'Emplace';

  testo1: string = 'Il Venditore si impegna a seguire le seguenti istruzioni di spedizione ed a spedire il nuovo ordine ricevuto entro ';
  testo2: string = 'tre';
  testo3: string = ' (3) giorni lavorativi dall’avvenuto acquisto da parte dell\'Acquirente su Emplace. Il Venditore verrà avvisato del nuovo ordine tramite e-mail e tramite apposita notifica dentro la piattaforma.';

  testo4: string = 'A questo link il Venditore può trovare la soluzione di spedizione più adatta ed economica e scegliere quella che meglio soddisfa le proprie esigenze: ';
  testo5: string = 'https://www.iospedisco.it/';

  testo6: string = 'N.B. Il Venditore è tenuto a scegliere un tipo di spedizione che preveda una modalità di tracciamento con apposito codice da inserire nella pagina dell’ordine relativo in Emplace per contrassegnare l’ordine come "Spedito".';

  testo7: string = 'In caso di mancata spedizione da parte del Venditore entro il termine indicato, qualora fosse richiesto dall’utente, e in ogni caso non oltre i ';
  testo8: string = 'dieci';
  testo9: string = ' (10) giorni lavorativi, Emplace rimborserà all’Aquirente l’intero importo pagato.';

  testo10: string = 'Emplace rimborserà interamente al Venditore la quota di spedizione indicata sulla scheda prodotto al momento dell’acquisto. Tuttavia, per ordini di prodotti venduti dallo stesso Venditore superiori a 50€, la quota di spedizione viene azzerata. Emplace non è responsabile nel caso in cui il costo sostenuto dal Venditore per la spedizione sia diverso da quello indicato al momento dell’acquisto.';

  testo11: string = 'REQUISITI DI IMBALLAGGIO: una volta ricevuto l’ordine, il Venditore si impegna ad imballare adeguatamente il Pacco, rispettando le linee guida per il confezionamento riportate nel link sottostante, evitando in particolare qualunque tipo di merce considerata pericolosa.'

  testo12: string = 'https://www.poste.it/files/1473800270602/poste-delivery-web-guida-spedizione.pdf';

  testo13: string = 'Inoltre il Venditore si impegna a rispettare le linee guida per un '
  testo14: string = 'imballaggio ecosostenibile:';
  punto1: string = 'Utilizzando imballaggi, materiali di riempimento e stabilizzazione, nastri adesivi ed etichette in carta, cartone o altre fibre naturali evitando l’uso di materiali contenenti la plastica;';
  punto2: string = 'Scegliendo imballaggi, scatole e contenitori che abbiano dimensioni affini ai prodotti da spedire, ottimizzando l’uso dello spazio interno;';
  punto3: string = 'Là dove possibile, riciclando imballaggi e materiali di riempimento e stabilizzazione già utilizzati, che siano tuttavia ancora in ottime condizioni e senza etichette o scritte relativi ad utilizzi precedenti.';
  
  testo15: string = 'Il Venditore si impegna, una volta effettuata la spedizione, a contrassegnare l’ordine come "Spedito" e ad inserire negli appositi campi nella sezione di gestione ordine "Spedisci" ';
  testo16: string = 'il corriere scelto';
  testo17: string = ' per la spedizione ed il ';
  testo18: string = 'codice di tracciamento';
  testo19: string = ' (o tracking) del prodotto assicurandosi che sia corretto e funzionante (o in caso di spedizione gestita ed effettuata dal Venditore stesso, un numero di cellulare attivo e funzionante).';

  testo20: string = 'Anche l\'Acquirente potrà così monitorare costantemente lo stato di spedizione del proprio ordine grazie al codice di tracking, ricercando la spedizione inserendolo nel sito del relativo corriere.';

  testo21: string = 'Le spedizioni impiegano solitamente '
  testo22: string = 'due';
  testo23: string = ' (2) o ';
  testo24: string = 'tre';
  testo25: string = ' (3) giorni lavorativi se rapide e fino a ';
  testo26: string = 'cinque';
  testo27: string = ' (5) in caso di spedizioni standard.';

  testo28: string = 'Il Venditore si impegna a monitorare l\'andamento della consegna e a contrassegnare immediatamente su Emplace lo stato dell’ordine come "Consegnato" non appena riceverà il relativo avviso di avvenuta consegna da parte del corriere scelto.';

  testo29: string = 'In fase di consegna dell\'ordine, l\'Acquirente si impegna a non accettare il pacco in caso i cui ci siano evidenti segni di danni o rotture dell’imballaggio provocati durante la spedizione.';

  testo30: string = 'Non appena lo stato dell\'ordine verrà contrassegnato come "Consegnato" Emplace procederà al pagamento a beneficio del Venditore dell\'importo dovuto (già indicato al Venditore in fase di caricamento del prodotto), in ogni caso entro e non oltre ';
  testo31: string = 'tre';
  testo32: string = ' (3) giorni dopo l\'avvenuta consegna dell\'ordine, tramite bonifico bancario all\'indirizzo iban indicato dal Venditore in fase di registrazione.';
  
  testo33: string = 'Per effettuare il reso l\'Acquirente dovrà inviare un\'e-mail ad ';
  testo34: string = 'assistenza@emplacestore.it';
  testo35: string = ' comunicando i dettagli dell\'ordine da rendere e, se lo desidera, le ragioni del reso. Emplace contatterà il Venditore fornendo le istruzioni dettagliate per effettuare il reso.'; 
  // ' [oppure contattando il servizio clienti Emplace tramite il numero Whatsapp o dall’apposita sezione nella scheda dell\'ordine che intende rendere (coming soon)]';

  testo36: string = 'I prodotti resi non devono essere stati utilizzati, devono essere integri, con cartellini e confezioni originali.  Le etichette non devono essere rimosse o danneggiate. Inoltre, devono essere rispettate anche tutte le indicazioni fornite nei termini e condizioni generali.';

  testo37: string = 'L\'Acquirente ha a disposizione ';
  testo38: string = 'sette';
  testo39: string = ' (7) giorni (inclusi i weekend) a partire dalla data di consegna dell\'ordine, per comunicare ad Emplace di voler effettuare il reso dell\'ordine (o anche di uno solo dei prodotti che lo compongono). Per i prodotti venduti da ';
  testo39_1: string = 'Venditore - Persona Giuridica';
  testo39_2: string = ' il termine si estende fino a ';
  testo39_3: string = 'quattordici';
  testo39_4: string = ' (14) giorni dalla data di consegna.';

  testo40: string = 'Se sono rispettate tutte le condizioni Emplace contatterà il Venditore fornendo le istruzioni dettagliate per effettuare il reso e fornirà all\'Acquirente i dati per poter effettuare la spedizione. Una volta ricevuti i dati, l\'Acquirente è tenuto a ';
  testo41: string = 'spedire';
  testo42: string = ' l\'oggetto del reso entro e non oltre i ';
  testo43: string = 'quattordici';
  testo44: string = ' (14) giorni dalla data di consegna dell\'ordine. Per i prodotti venduti da ';
  testo44_1: string = 'Venditore - Persona Giuridica';
  testo44_2: string = ' il termine si estende fino a ';
  testo44_3: string = 'quattordici';
  testo44_4: string = ' (14) giorni dalla data in cui è stata comunicata la volontà di effettuare il reso. Emplace si impegna a comunicare i dati all\'Acquirente garantendo un tempo adeguato per poter spedire il reso.';
  
  testo45: string = 'Emplace si riserva il diritto di rifiutare resi comunicati o rispediti in ritardo e di non rimborsare gli ordini che dovessero arrivare in condizioni diverse da quelle sopra indicate.';

  testo46: string = 'Il costo di spedizione del reso è interamente a carico dell’Acquirente che può scegliere il metodo di spedizione più adatto, che può trovare anche al seguente link: ';
  testo47: string = 'https://www.iospedisco.it/';

  testo48: string = 'Una volta ricevuto il reso, il Venditore lo comunicherà ad Emplace. Se il reso rispetterà tutte le relative condizioni, Emplace provvederà quindi a rimborsare all\'Acquirente l\'intero importo pagato per l\'ordine, entro ';
  testo49: string = 'tre';
  testo50: string = ' (3) giorni a partire dalla ricezione del reso da parte del Venditore.';

  testo51: string = 'In caso in cui l\'ordine consegnato all\'Acquirente sia difettoso, danneggiato o differisca in modo sostanziale dalla descrizione e dalle immagini pubblicate su Emplace dal Venditore, questo sarà tenuto a rimborsare all\'Acquirente i costi di spedizioni sostenuti per il reso.';
  
  testo52: string = 'Si intendono danni e difetti non causati dal trasporto, caso in cui sarà responsabilità del Venditore, una volta completato il reso, di richiedere il rimborso al corriere scelto.';

  testo53: string = 'In caso in cui a seguito di una richiesta di reso non si trovi un accordo tra l\'Acquirente ed il Venditore, Emplace farà da mediatore proponendo una soluzione che sia più corretta possibile in base alle evidenze in suo possesso.';

  testo54: string = 'Resta inteso che per i resi, laddove sussistano le condizioni indicate dal regolatore, siano valide le indicazioni già riportate nei termini e condizioni principali di Emplace.';

  
  ngOnInit() {}

}
