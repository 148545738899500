<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px; padding-top: 4px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')" style="margin-top: 12px;">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 12px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              {{page_title}}
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              {{page_title}}
            </ion-title>
          </div>
        </ion-col>
        
        <!-- Bottoni laterali -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2" style="text-align: right; padding-right: 9px;">
          <!-- BUTTON del profilo non serve in questa pagina!!!
            <ion-button (click)="toUserProfile()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && !platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-icon slot="icon-only" name="person-circle"></ion-icon>
          </ion-button>-->
            
          <ion-button *ngIf="!userService.userIsVendor()" (click)="toShoppingCart()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && !platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-icon slot="icon-only" name="cart"></ion-icon>
          </ion-button>
        </ion-col>

        <!-- SOLO in questa pagina, la barra di ricerca è solo nella SECONDA riga -->
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
          <ion-searchbar showCancelButton="focus" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="hasProdotti && !filterMode"
              (ionInput)="onInput($event)"
              (ionCancel)="onCancel($event)"
              (keypress)="onSearch($event)">
          </ion-searchbar>
          <ion-list *ngIf="hasSuggestion && hasProdotti" class="searchbar-list" style="padding-left: 32px;">
            <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))" 
              class="sugg-item"> {{ s.substring(1,s.length-1) }} </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="padding-top: 0px;" (window:resize)="checkIfBottomReached()">

  <ion-spinner class="first-products-spinner" name="bubbles"  *ngIf="isFetching && !prodotti"></ion-spinner>

  <!--<ion-searchbar showCancelButton="focus" class="ion-margin" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="hasProdotti && platform.is('ios') && !filterMode"
      (ionInput)="onInput($event)" style="padding-bottom: 0px; max-width: 750px;"
      (ionCancel)="onCancel($event)"
      (keypress)="onSearch($event)"
      inputmode="search">
  </ion-searchbar>

  <ion-list *ngIf="hasSuggestion && hasProdotti && platform.is('ios')" class="searchbar-list-ios">
    <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))"
    class="sugg-item-ios"> {{ s.substring(1,s.length-1) }} </ion-item>
  </ion-list>-->

  <!-- RICERCA VUOTA -->
  <div *ngIf="noResults && hasProdotti" class="error-div">
    <img src="assets/no_results_image/broccolo.png" class="error-resize">
    <p class="product-title-data" style="margin-top: 18px;"> La ricerca effettuata non ha prodotto risultati! </p>
  </div>

<div *ngIf="!filterMode" class="smart-filters">
  <!-- Visualizzazione rapida filtri impostati -->
  <div *ngIf="!hasSuggestion && hasProdotti && hasActiveFilters && !noResults" style="padding-left: 12px;">
    <ion-button color="secondary" *ngIf="this.choiceSorting=='h_rec'" (click)="removeSortingFilter()">Recensioni più alte<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.choiceSorting=='l_rec'" (click)="removeSortingFilter()">Recensioni più basse<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.choiceSorting=='h_pre'" (click)="removeSortingFilter()">Prezzo più alto<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.choiceSorting=='l_pre'" (click)="removeSortingFilter()">Prezzo più basso<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.choiceCategory!='Nessuna'" (click)="removeCategoryFilter()">{{choiceCategory}}<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.min_price>0 && this.max_price>0" (click)="removePriceRangeFilter()">{{min_price}}-{{max_price}} €<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.min_price>0 && this.max_price<=0" (click)="removeMinPriceFilter()">min {{min_price}} €<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.min_price<=0 && this.max_price>0" (click)="removeMaxPriceFilter()">max {{max_price}} €<ion-icon name="close-outline"></ion-icon></ion-button>
    <ion-button color="secondary" *ngIf="this.media_recensioni_filter>0" (click)="removeMediaRecFilter()">+{{media_recensioni_filter}} rating<ion-icon name="close-outline"></ion-icon></ion-button>
  </div>

  <!-- GRIGLIA PRODOTTI -->
  <app-griglia-prodotti *ngIf="hasProdotti" [parentComponent]="this" [prodotti]="prodotti"></app-griglia-prodotti>
  <!-- this spinner is only used while loading a batch of products after the first one (show under the grid) -->
  <ion-spinner class="next-products-spinner" name="bubbles" color="primary" *ngIf="isFetching && prodotti"></ion-spinner>
  
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" *ngIf="hasProdotti">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <ion-grid class="message" style="padding-top: 0px; padding-bottom: 0px;" *ngIf="hasProdotti && !noResults">
    <ion-card style="margin-inline: 0px; background-image: linear-gradient(to bottom right, #22577A, #38A3A5);">
      <ion-row>
        <ion-col size-xs="12" size-sm="7" size-md="7" size-lg="7" size-xl="7" style="margin-top: 14px; text-align: center;">
          <ion-text style="color: #ffffff;" class="sea-banner">Non hai trovato il prodotto che cercavi? </ion-text>
        </ion-col>
        <ion-col size-xs="12" size-sm="5" size-md="5" size-lg="4" size-xl="4" style="padding-left: 0px; text-align: center;">
          <ion-button color="primary" [href]="email">Suggeriscilo!</ion-button>
        </ion-col>
      </ion-row>
    </ion-card>
  </ion-grid>   

  <div *ngIf="!hasProdotti && !isFetching && !platform.is('ios')" class="error-div" style="top: 40%">
    <img src="assets/error/cavolo.jpg" class="error-resize">
    <p class="product-title-data"> Cavolo, qualcosa è andato storto! </p>
    <p class="product-title-data"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry">
      <ion-fab-button color="primary" (click)="onReloadProducts()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>

  <!-- iOS ERROR DIV -->
  <div *ngIf="!hasProdotti && !isFetching && platform.is('ios')" class="error-div-ios" style="top: 40%;">
    <img src="assets/error/cavolo.jpg" class="error-resize-ios">
    <p class="product-title-data"> Cavolo, qualcosa è andato storto! </p>
    <p class="product-title-data"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry-ios">
      <ion-fab-button color="primary" (click)="onReloadProducts()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>


<!-- FILTRI -->
<div class="ion-padding" style="color: #737373;" *ngIf="filterMode">
  <div class="filter-container">

  <ion-grid>
    <ion-row class="filter-title">
      <ion-col size="12" class="centered-title">
        <ion-label color="primary">
          <strong>Filtri</strong>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="centered">
        <ion-label><strong>Categoria</strong></ion-label>

        <ion-grid style="padding: 0px;">
          <ion-row style="padding: 0px;">
            <ion-col size-xs="2" size="1" style="padding: 0px;"></ion-col>
            <ion-col size-xs="8" size="10" style="padding: 0px;">
              <ion-select aria-label="Categoria" value="Nessuna" okText="Okay" cancelText="Chiudi" [(ngModel)]="choiceCategory" (ionChange)="onChangeSelectCategory()" class="categorie">
                <ion-select-option value="Nessuna">Nessuna</ion-select-option>
                <ion-select-option *ngFor="let c of categories" value="{{c.nome}}">{{c.nome}}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size-xs="2" size="1" style="padding: 0px;"></ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    
      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="centered">
        <ion-label style="margin-top:0px;">
          <strong>Media recensioni</strong>
        </ion-label>
        <div class="product-grid-card-rating">
          <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="media_recensioni_filter > 0.5" (click)="setMediaRec(1)">
          <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="media_recensioni_filter > 1.5" (click)="setMediaRec(2)">
          <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="media_recensioni_filter > 2.5" (click)="setMediaRec(3)">
          <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="media_recensioni_filter > 3.5" (click)="setMediaRec(4)">
          <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="media_recensioni_filter > 4.5" (click)="setMediaRec(5)">
          <img src="assets/icon/logo_grey.png" class="rating" *ngIf="media_recensioni_filter <= 0.5" (click)="setMediaRec(1)">
          <img src="assets/icon/logo_grey.png" class="rating" *ngIf="media_recensioni_filter <= 1.5" (click)="setMediaRec(2)">
          <img src="assets/icon/logo_grey.png" class="rating" *ngIf="media_recensioni_filter <= 2.5" (click)="setMediaRec(3)">
          <img src="assets/icon/logo_grey.png" class="rating" *ngIf="media_recensioni_filter <= 3.5" (click)="setMediaRec(4)">
          <img src="assets/icon/logo_grey.png" class="rating" *ngIf="media_recensioni_filter <= 4.5" (click)="setMediaRec(5)">
          {{media_recensioni_filter}}/5
        </div>
      </ion-col>

      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="centered">
        <ion-button style="margin: auto; margin-top: 3%" (click)="resetFilter()">Reset</ion-button>
      </ion-col>

      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="centered">
        <ion-label><strong>Ordinamento</strong></ion-label>

        <ion-grid style="padding: 0px;">
          <ion-row style="padding: 0px;">
            <ion-col size-xs="2" size="1" style="padding: 0px;"></ion-col>
            <ion-col size-xs="8" size="10" style="padding: 0px;">
              <ion-select aria-label="Ordinamento" value="Nessuno" okText="Okay" cancelText="Chiudi" [(ngModel)]="choiceSorting" (ionChange)="onChangeSelectSorting()" class="categorie">
                <ion-select-option value="Nessuno">Nessuno</ion-select-option>
                <ion-select-option value="h_rec">Recensioni migliori</ion-select-option>
                <ion-select-option value="l_rec">Recensioni più basse</ion-select-option>
                <ion-select-option value="h_pre">Prezzo più alto</ion-select-option>
                <ion-select-option value="l_pre">Prezzo più basso</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size-xs="2" size="1" style="padding: 0px;"></ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>

      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="centered">
        <ion-label><strong>Prezzo</strong></ion-label>
        <div class="product-grid-card-price">
          <ion-grid class="price">
            <ion-row style="margin-top: 12px;">
              <ion-col size-xs="1" size-sm="1" size-md="1" size-lg="2" size-xl="2"></ion-col>
              <ion-col size-xs="3" size="3" class="price-t">
                <ion-text style="padding-top: 2px; padding-bottom: 2px;">Min</ion-text>
              </ion-col>
              <ion-col size-xs="6" size-sm="6" size-md="5" size-lg="5" size-xl="5" class="price">
                <ion-input aria-label="Min" placeholder="0" [(ngModel)]="min_price_input" class="price"></ion-input>
              </ion-col>
              <ion-col size="1" class="price-e">
                <ion-text style="padding-top: 2px; padding-bottom: 2px;">€</ion-text>
              </ion-col>
              <ion-col size-xs="1" size-sm="1" size-md="2" size-lg="2" size-xl="2"></ion-col>
            </ion-row>
            <ion-row style="margin-top: 8px;">
              <ion-col size-xs="1" size-sm="1" size-md="1" size-lg="2" size-xl="2"></ion-col>
              <ion-col size-xs="3" size="3" class="price-t">
                <ion-text style="padding-top: 2px; padding-bottom: 2px; text-align: right;">Max</ion-text>
              </ion-col>
              <ion-col size-xs="6" size-sm="6" size-md="5" size-lg="5" size-xl="5" class="price">
                <ion-input aria-label="Max" placeholder="0" [(ngModel)]="max_price_input" class="price"></ion-input>
              </ion-col>
              <ion-col size="1" class="price-e">
                <ion-text style="padding-top: 10px; padding-bottom: 10px;">€</ion-text>
              </ion-col>
              <ion-col size-xs="1" size-sm="1" size-md="2" size-lg="2" size-xl="2"></ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>

    </ion-row>
  </ion-grid>

  </div>
</div>

</ion-content>

<!-- FOOTER FILTRI -->
<ion-footer *ngIf="filterMode">
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="onCloseFilter()" color="primary" class="sx-footer">Annulla</ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button class="dx-footer" color="primary" (click)="doFilter()">Filtra</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<!-- BOTTONE FILTRO -->
<!-- se viene messo dentro al content finirà per scrollare con esso, erroneamente -->
<ion-fab horizontal="end" vertical="bottom" slot="fixed" class="filter global" *ngIf="hasProdotti && !filterMode">
  <ion-fab-button (click)="onFilter()" color="primary">
    <ion-icon name="filter-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>