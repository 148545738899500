
<ion-grid style="margin-bottom: 18px;">
  <ion-row>
    <ion-col size="12">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <p class="msg"> <b>{{titolo1}}</b> </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" style="text-align: center; margin: auto; align-items: center;">
            <!-- IMG INIZIALE -->
            <img src="assets/icon/logo_primary_green.png" class="howto"/>
          </ion-col>
          <ion-col size="8">
            <p class="msg"> {{testo1}} <b>{{testo2}}</b> {{testo3}} <b>{{testo4}}</b> {{testo5}} <b>{{testo6}}</b></p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <p class="msg"> <b>{{titolo2}}</b> </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" style="padding-left: 0%;">
            <ion-grid style="padding: 0%; margin: 0%; height: 100%;">
              <ion-row style="padding: 0%; margin: 0%; height: 33%">
                <ion-col size="12" style="padding: 0%; margin: auto;">
                  <img src="assets/images/albero_ritagliato.png" class="donazioni">
                </ion-col>
              </ion-row>
              <ion-row style="padding: 0%; margin: 0%; height: 33%">
                <ion-col size="12" style="padding: 0%; margin: auto;">
                  <img src="assets/images/bottiglia_ritagliata.png" class="donazioni">
                </ion-col>
              </ion-row>
              <ion-row style="padding: 0%; margin: 0%; height: 33%">
                <ion-col size="12" style="padding: 0%; margin: auto;">
                  <img src="assets/images/casa_ritagliata.png" class="donazioni">
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
          <ion-col size="8">
            <p class="msg" style="margin-bottom: 6px;"> {{testo7}} <b>{{testo8}}</b> {{testo9}} <b>{{testo10}}</b> {{testo11}} <b>{{testo12}}</b> {{testo13}} <b>{{testo14}}</b></p>
            <p class="msg" style="margin-top: 6px;"><b>{{testo14_1}}</b> {{testo15}}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="8"></ion-col>
    <ion-col size="4" style="text-align: center;">
      <ion-label color="primary" (click)="onClose()"><strong>OK</strong></ion-label>
    </ion-col>
  </ion-row>
</ion-grid>
