import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, NavController, NavParams, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { BuyerUserProfile, UserProfile } from 'src/app/shared/models/UserProfile.models';

@Component({
  selector: 'popover-shipping',
  templateUrl: 'shipping-popover.component.html',
  styleUrls: ['./shipping-popover.component.scss']
})
export class ShippingPopover {
  tracking: string;
  corriere: string;
  prefisso: string;
  telefono: string;
  
  constructor (private popoverProp: PopoverController, private router: Router, private navParams: NavParams,
    private logger: NGXLogger) {
    this.tracking = navParams.get('tracking');
    this.corriere = navParams.get('corriere');
    this.telefono = navParams.get('telefono');
    this.prefisso = navParams.get('prefisso');
  }

  ClosePopover() {
    this.popoverProp.dismiss();
  }

  onClose(){
    this.logger.log('close event caught');
    this.ClosePopover();
  }

}