import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {

  testo1: string = '"The Eco-Sustainable Marketplace" è la piattaforma dove puoi trovare prodotti sostenibili per l\'ambiente e per le persone, con un\'attenzione particolare al commercio di prodotti locali. Per ogni acquisto effettuato puoi decidere come destinare parte della spesa per salvare il pianeta.';
  testo2: string = 'Emplace nasce dallo stupore provato nei viaggi in giro per il mondo di fronte alle meraviglie della natura, dalla rigogliosa foresta Amazzonica al caldo Deserto di Atacama, dalle possenti onde dell\'Oceano Pacifico che si abbattono sulle spiagge dell\'Isola di Pasqua fino agli immensi ghiacciai che ricoprono le montagne della Terra del Fuoco. Ma nasce soprattutto davanti alla consapevolezza del fatto che tutto ciò è sempre più in pericolo e che se non faremo nulla per proteggerlo, presto scomparirà per sempre.';
  testo3: string = 'Siamo un gruppo di ragazzi con una forte passione verso tutto ciò che è sostenibilità. Crediamo fermamente che il futuro sia nelle nostre mani e che con acquisti progressivamente più consapevoli e sostenibili il mondo possa migliorare.';

  constructor(public platform: Platform) { }

  ngOnInit() {}

}
