<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
      <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
          Billing
      </ion-title>
      
      <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title *ngIf="!platform.is('ios')" style="max-width: 100px; margin: auto; padding-left: 0px;">Billing</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" (window:resize)="checkIfBottomReached()">

  <p style="margin-left: 24px;" *ngIf="!generale"><strong>Usa il tasto <i>Indietro</i> che trovi in fondo alla pagina per muoverti all'interno del gestionale</strong></p>
  <p style="margin-left: 24px; margin-top: -4px;" *ngIf="!generale">Se usi altri tasti per tornare indietro, uscirai dal gestionale e dovrai reinserire le credenziali</p>

  <!-- DIREZIONALE -->
  <p style="padding-left: 10px; margin-top: 32px; color: var(--ion-color-primary);" *ngIf="generale && !pendenti" (click)="switchToPendingOrders()">
    CLICCA QUI per andare al <strong>Gestionale degli ordini PENDENTI</strong>
  </p>
  <p style="padding-left: 10px; margin-top: 32px; color: var(--ion-color-primary);" *ngIf="generale && pendenti" (click)="switchToClosedOrders()">
    CLICCA QUI per andare al <strong>Gestionale degli ordini CHIUSI</strong>
  </p>


  <!-- SEZIONE DEGLI ORDINI PENDENTI -->

  <!-- VISTA RELATIVA ALLA LISTA DEGLI ORDINI PENDENTI -->
  <ion-grid style="margin: auto;" *ngIf="generale && pendenti">
    <ion-row>
      <ion-col size="12">
        <p style="color: var(--ion-color-primary);"><strong>Gestionale degli ordini PENDENTI</strong></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="6" size-sm="6" size-md="4" size-lg="2" size-xl="2" class="line">
        <p><strong>ID</strong></p>
      </ion-col>
      <ion-col size-xs="6" size-sm="6" size-md="4" size-lg="2" size-xl="2" class="line">
        <p><strong>Buyer</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="4" size-lg="2" size-xl="2" class="line">
        <p><strong>Purchase Date</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Many Vendors</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="2" size-xl="2" class="line">
        <p><strong>Shipping Date</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="2" size-xl="2" class="line">
        <p><strong>Delivery Date</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Status</strong></p>
      </ion-col>
    </ion-row>
    
  </ion-grid>

  <ion-grid style="margin: auto;" *ngIf="generale && pendenti">

    <ion-row *ngFor="let order of pendingOrdersList" style="margin-bottom: 12px;">
      <ion-col size-xs="6" size-sm="6" size-md="4" size-lg="2" size-xl="2" (click)="toPendingOrder(order.id)" class="line">
        <p><a color="primary">{{order.id.substring(0,8)}}</a></p>
      </ion-col>
      <ion-col size-xs="6" size-sm="6" size-md="4" size-lg="2" size-xl="2" class="line">
        <p>{{order.acquirente}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="4" size-lg="2" size-xl="2" class="line">
        <p>{{order.data_acquisto | date:"shortDate":"it-IT"}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="1" size-xl="1" class="line">
        <p>{{order.venditori_multipli}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="2" size-xl="2" class="line">
        <p>{{order.data_spedizione | date:"shortDate":"it-IT"}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="2" size-xl="2" class="line">
        <p>{{order.data_consegna | date:"shortDate":"it-IT"}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="3" size-lg="1" size-xl="1" class="line">
        <p>{{order.stato}}</p>
      </ion-col>
    </ion-row>
    <hr style="background: #777777; height: 3px; margin-left: 5px; margin-right: 5px;">
  </ion-grid>
  <p style="margin-left: 24px; color: var(--ion-color-primary);" *ngIf="generale && pendenti">Clicca l'ID di un ordine per avere ulteriori dettagli</p>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" *ngIf="generale && pendenti">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <ion-button *ngIf="generale && pendenti" (click)="exportAsExcelFile(pendingOrdersList,'ListaPendenze')" style="float: right; margin-bottom: 12px; margin-right: 12px;" color="danger">
    Export
  </ion-button>

  <!-- VISTA RELATIVA AL DETTAGLIO DI UN ORDINE PENDENTE -->
  <ion-grid style="margin: auto;" *ngIf="!generale && pendenti">
    <ion-row>
      <ion-col size="12">
        <p style="color: var(--ion-color-primary);"><strong>Dettaglio dell'Ordine PENDENTE</strong></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="2" size-xl="2" class="line">
        <p><strong>Prodotto</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Vendor</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Telefono</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Email</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>Quantità</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Data Spediz</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Data Consegna</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>Stato</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Corriere</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Tracking</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Data Acquisto</strong></p>
      </ion-col>
    </ion-row>
    
  </ion-grid>

  <ion-grid style="margin: auto;" *ngIf="!generale && pendenti">
    <ion-row *ngFor="let order of pendingOrderDetail" style="margin-bottom: 12px;">
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="2" size-xl="2" class="line">
        <p>{{order.prodotto}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p style="color: var(--ion-color-darksea);"><strong>{{order.venditore}}</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p style="color: var(--ion-color-darksea);"><strong>{{order.telefono}}</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="1" size-xl="1" class="line">
        <p style="color: var(--ion-color-darksea);"><strong>{{order.email_venditore}}</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="1" size-lg="1" size-xl="1" class="line">
        <p>{{order['quantità']}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{order.data_spedizione | date:"shortDate":"it-IT"}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{order.data_consegna | date:"shortDate":"it-IT"}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="1" size-lg="1" size-xl="1" class="line">
        <p>{{order.stato}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{order.shipping_dealer}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{order.codice_tracking}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{order.data_acquisto | date:"shortDate":"it-IT"}}</p>
      </ion-col>
    </ion-row>
    
  </ion-grid>
  <!-- FINE SEZIONE DEGLI ORDINI PENDENTI -->
  <ion-button *ngIf="!generale && pendenti" (click)="exportAsExcelFile(pendingOrderDetail,'DettaglioPendenze')" style="float: right; margin-bottom: 12px; margin-right: 12px;" color="danger">
    Export
  </ion-button>


  <!-- SEZIONE DEGLI ORDINI CHIUSI -->

  <!-- VISTA RELATIVA ALLA LISTA DEGLI ORDINI CHIUSI -->
  <ion-grid style="margin: auto;" *ngIf="generale && !pendenti">
    <ion-row>
      <ion-col size="12">
        <p style="color: var(--ion-color-primary);"><strong>Gestionale degli ordini CHIUSI</strong></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="6" size-sm="6" size-md="2" size-lg="2" size-xl="2" class="line">
        <p><strong>ID</strong></p>
      </ion-col>
      <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3" class="line">
        <p><strong>Purchase Date</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="2" size-xl="2" class="line">
        <p><strong>Total Cost</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>#Delivered</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>#Not Delivered</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>Refo</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>Plastic</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p><strong>Social</strong></p>
      </ion-col>
    </ion-row>
    
  </ion-grid>

  <ion-grid style="margin: auto;" *ngIf="generale && !pendenti">

    <ion-row *ngFor="let order of orderList" style="margin-bottom: 12px;">
      <ion-col size-xs="6" size-sm="6" size-md="2" size-lg="2" size-xl="2" (click)="toClosedOrder(order.id)" class="line">
        <p><a color="primary">{{order.id.substring(0,8)}}</a></p>
      </ion-col>
      <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3" class="line">
        <p>{{order.data_acquisto | date:"shortDate":"it-IT"}}
          <ion-badge color=primary *ngIf="orderListIsCompleted[order.id]">Completed</ion-badge>
        </p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="2" size-xl="2" class="line">
        <p>{{order.costo_totale}}€</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p>{{order.elementi_ordine_consegnati}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <p *ngIf="order.elementi_ordine_non_consegnati==0">
          <ion-text color="primary"><strong>{{order.elementi_ordine_non_consegnati}}</strong></ion-text>
        </p>
        <p *ngIf="order.elementi_ordine_non_consegnati!=0">
          <ion-text color="danger">{{order.elementi_ordine_non_consegnati}}</ion-text>
        </p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <ion-text color="primary">{{donationToNumber(order,1)}}</ion-text>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <ion-text color="primary">{{donationToNumber(order,2)}}</ion-text>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="1" size-lg="1" size-xl="1" class="line">
        <ion-text color="primary">{{donationToNumber(order,3)}}</ion-text>
      </ion-col>
    </ion-row>
    <hr style="background: #777777; height: 3px; margin-left: 5px; margin-right: 5px;">
  </ion-grid>

  <p style="margin-left: 24px; color: var(--ion-color-primary);" *ngIf="generale && !pendenti">Clicca l'ID di un ordine per avere ulteriori dettagli</p>

  <ion-button *ngIf="generale && !pendenti" (click)="exportAsExcelFileDonationDetails(orderList,'ListaOrdiniChiusi')" style="float: right; margin-bottom: 12px; margin-right: 12px;" color="danger">
    Export
  </ion-button>

  <!-- VISTA RELATIVA AL DETTAGLIO DI UN ORDINE CHIUSO -->
  <ion-grid style="margin: auto;" *ngIf="!generale && !pendenti">

    <ion-row>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="1" size-xl="1" class="line">
        <p><strong>Venditore</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1" class="line">
        <p><strong>Tot Da pagare</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1" class="line">
        <p><strong>Da Fatturare</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Costo Emplace</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Elem delivered</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Elem not delivered</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Pagato</strong></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Fattura</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Data Spediz</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1" class="line">
        <p><strong>Data Consegna</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Tracking</strong></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1" class="line">
        <p><strong>Dealer</strong></p>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid style="margin: auto;" *ngIf="!generale && !pendenti">
    <ion-row *ngFor="let item of orderDetail" style="margin-bottom: 12px;">
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="1" size-xl="1">
        <p><ion-text style="color: var(--ion-color-sea);">{{item.venditore}}</ion-text></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1">
        <p><ion-text color="danger"><strong>{{item.totale_da_pagare}}€</strong></ion-text></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1">
        <p><strong>{{item.totale_da_fatturare}}€</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1">
        <p><ion-text color="primary"><strong>{{item.costo_emplace}}€</strong></ion-text></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" (click)="toDeliveredProductsDetail(item)">
        <p><u>{{item.elementi_ordine_consegnati}}</u></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1">
        <p *ngIf="item.elementi_ordine_non_consegnati==0">
          <ion-text color="primary"><strong>{{item.elementi_ordine_non_consegnati}}</strong></ion-text>
        </p>
        <p *ngIf="item.elementi_ordine_non_consegnati!=0">
          <ion-text color="danger">{{item.elementi_ordine_non_consegnati}}</ion-text>
        </p>
      </ion-col>
      <ion-col size-xs="2" size-sm="3" size-md="3" size-lg="1" size-xl="1">
        <p *ngIf="item.pagato==true" style="cursor: pointer;"><ion-text color="primary">{{item.pagato}}</ion-text></p>
        <p *ngIf="item.pagato==false" style="cursor: pointer;" (click)="toBillingPopover(item)"><ion-text color="danger">{{item.pagato}}</ion-text></p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="1" size-xl="1">
        <p *ngIf="item.fattura==true" style="cursor: pointer;"><ion-text color="primary">{{item.fattura}}</ion-text></p>
        <p *ngIf="item.fattura==false" style="cursor: pointer;" (click)="toBillingPopover(item)"><ion-text color="danger">{{item.fattura}}</ion-text></p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1">
        <p>{{item.data_spedizione}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1">
        <p>{{item.data_consegna}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1">
        <p>{{item.codice_tracking}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1">
        <p>{{item.shipping_dealer}}</p>
      </ion-col>

      <!-- INFO Fatturazione -->
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p><strong>{{item.info_fatturazione.iban}}</strong></p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.codice_fiscale}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.partita_iva}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.denominazione}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.codice_destinatario}}</p>
      </ion-col>
      <ion-col size-xs="4" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.tipologia}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.first_name}}</p>
      </ion-col>
      <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.last_name}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1" class="line">
        <p>{{item.telefono}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.indirizzo}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione['città']}}</p>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="1" size-xl="1" *ngIf="item.info_fatturazione" class="line">
        <p>{{item.info_fatturazione.cap}}</p>
      </ion-col>
      
    </ion-row>
    <hr style="background: #777777; height: 3px; margin-left: 5px; margin-right: 5px;">
  </ion-grid>

  <p style="margin-left: 24px; color: var(--ion-color-primary);" *ngIf="!generale && !pendenti">Clicca i valori nelle colonne Pagato o Fattura per contrassegnare gli items come pagati o con fattura emessa.</p>

  <ion-button *ngIf="!generale && !pendenti" (click)="exportAsExcelFileBillingDetails(orderDetail,'DettaglioOrdiniChiusi')" style="float: right; margin-bottom: 12px; margin-right: 12px;" color="danger">
    Export
  </ion-button>

</ion-content>

<!-- FOOTER: INDIETRO (quando viene presentato il dettaglio di un ordine) -->
<ion-footer *ngIf="!generale" style="text-align: center;">
  <ion-button color="primary" (click)="toBack()">Indietro</ion-button>
</ion-footer>