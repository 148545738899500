import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
// import { Plugins } from '@capacitor/core';
// const { Storage } = Plugins;
import { Preferences } from '@capacitor/preferences';

import { BuyerUserProfile, UserProfile, VendorUserProfile, VendorUserProfilePUT } from '../models/UserProfile.models';
import { AuthService } from './auth.service';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class UserService {
  USER_TYPE_CLIENT = "client";
  USER_TYPE_VENDOR = "vendor";

  UPDATE_USER_SUCCESS = true;
  UPDATE_USER_ERROR = false;

  userChanged = new Subject<UserProfile>();
  error = new Subject<string>();

  isFetching: boolean = false;
  isLoggedIn: boolean = false;
  userData: UserProfile;

  constructor(private http: HttpClient, private authService: AuthService, private constantUtils: ConstantUtilsService, 
    private logger: NGXLogger) {
    this.authService.userStatusChanged.subscribe(loggedIn => {
      if(loggedIn){
        this.fetchMyProfile();
      }
      else{
        this.userChanged.next(null);
        this.removeUserDataFromStorage();
      }
    })
  }

  private base_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyer-user/');
  private myprofile_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/myprofile/')
  private myprofile_vendor_update_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendor-user/myprofile/');
  private myprofile_client_update_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyer-user/myprofile/');

  
  onRemovingProfileAndData(){
    if(this.authService.isLoggedIn() && this.userIsClient()){
      // Rimozione di un cliente
      this.logger.log('Rimozione di un cliente...');
      return this.userProfileRemoval(this.myprofile_client_update_url);
    }else if(this.authService.isLoggedIn() && this.userIsVendor()){
      // Rimozione di un venditore
      this.logger.log('Rimozione di un venditore...');
      return this.userProfileRemoval(this.myprofile_vendor_update_url);
    }else{
      this.logger.log('Non è ne venditore ne cliente.. NADAAA!');
      return Promise.resolve(this.UPDATE_USER_ERROR);
    }
  }

  userProfileRemoval(url: string){
    this.logger.log("UserService - userProfileRemoval", "Start");
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - userProfileRemoval", "user is not logged in. Exiting.");
      return Promise.resolve(this.UPDATE_USER_ERROR);
    }else{
      const httpHeaders = new HttpHeaders({ 
        'Content-Type' : 'application/json',
        // 'Authorization':"Bearer "+ this.userTokenData.access_token
      });
  
      return this.http.delete(url, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        res => {
          this.logger.log(res);
          this.authService.logout();
          return this.UPDATE_USER_SUCCESS;
        },
        error => {
          this.logger.log(error);
          return this.UPDATE_USER_ERROR;
        });
    }
  }

  // HTTP GET
  fetchMyProfile() {
    this.logger.log("UserService - fetchMyProfile", "start")
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
    }else if(this.authService.admin){
      this.logger.log("UserService - fetchMyProfile", "user is ADMIN. Exiting.");
      return Promise.resolve(null);
    }else if(!this.userData){
      var one_time_url = this.myprofile_url;
      const httpHeaders = new HttpHeaders({ 
        'Content-Type' : 'application/json',
        // 'Authorization':"Bearer "+ this.userTokenData.access_token
      });

      this.isFetching = true;
      
      return this.http
        .get< UserProfile >(one_time_url, {headers: httpHeaders, responseType: 'json'})
        .toPromise().then(
          responseData => {
            this.userChanged.next(responseData);
            this.storeUserData(responseData);
            this.isFetching = false;
            return < UserProfile >responseData;
          },
          errorRes => {
            // Send to analytics server
            this.isFetching = false;
            this.logger.log("UserService - fetchMyProfile error", errorRes);
            this.authService.resetUserData(); // deleting local user token data
            
            this.userChanged.next(null);
            return null;
          }
        )
    }
    else{
      // Use 'of' to always return an Observable
      this.userChanged.next(this.userData);
      return Promise.resolve(this.userData);
    }
  }

  // HTTP PUT
  updateMyProfile(userData) {
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
    }

    var userUpdateURL = ""
    if(this.userIsVendor()){
      userUpdateURL = this.myprofile_vendor_update_url;
      return this.updateVendorProfile(userUpdateURL, userData);
    }
    else{
      userUpdateURL = this.myprofile_client_update_url;
      return this.updateUserProfile(userUpdateURL, userData);
    }
  }

  private updateVendorProfile(userUpdateURL, userData){
    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      // 'Authorization':"Bearer "+ this.userTokenData.access_token
    });

    return this.http
      .put< VendorUserProfilePUT >(userUpdateURL, userData, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData: UserProfile) => {
          this.logger.log("Update result", responseData)
          this.userData = {
            ...this.userData,
            ...responseData
          }
          this.userChanged.next(this.userData)
          return this.UPDATE_USER_SUCCESS;
        },
        errorRes => {
          this.logger.log("UserService - Update error", errorRes)
          return this.UPDATE_USER_ERROR;
        }
      )
  }

  private updateUserProfile(userUpdateURL, userData){
    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      // 'Authorization':"Bearer "+ this.userTokenData.access_token
    });

    return this.http
      .put< BuyerUserProfile >(userUpdateURL, userData, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData: UserProfile) => {
          this.logger.log("Update result", responseData)
          this.userData = {
            ...this.userData,
            ...responseData
          }
          this.userChanged.next(this.userData)
          return this.UPDATE_USER_SUCCESS;
        },
        errorRes => {
          this.logger.log("UserService - Update error", errorRes)
          return this.UPDATE_USER_ERROR;
        }
      )
  }

  async storeUserData(userData){
    this.userData = userData;
    await Preferences.set({
        key: 'userData',
        value: JSON.stringify(userData)
    });
  }

  async getUserDataFromStorage(){
      if(!this.userData){
          const ret = await Preferences.get({ key: 'userData' });
          this.userData = JSON.parse(ret.value)
      }
      return this.userData
  }

  async removeUserDataFromStorage() {
      await Preferences.remove({ key: 'userData' });
      this.userData = null;
  }

  userType(){
    if(!this.userData){
      return undefined
    }
    if(this.userData["saldo_gettoni"] !== undefined){
      return this.USER_TYPE_CLIENT;
    }
    else if(this.userData["titolo"] !== undefined){
      return this.USER_TYPE_VENDOR;
    }
    else{
      console.error("User.service", "Unexpected userData instance", this.userData, typeof this.userData);
    }
  }

  userIsClient(){
    return this.userType() === this.USER_TYPE_CLIENT;
  }
  
  userIsVendor(){
    return this.userType() === this.USER_TYPE_VENDOR;
  }

}