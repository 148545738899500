import { Component, isDevMode, NgZone, OnInit } from '@angular/core';

import { AlertController, MenuController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FacebookLogin } from '@capacitor-community/facebook-login';

import { registerPlugin, registerWebPlugin } from '@capacitor/core';
import { AuthService } from './shared/services/auth.service';
import { BuyerUserProfile, UserProfile, VendorUserProfile } from './shared/models/UserProfile.models';
import { UserService } from './shared/services/User.service';
import { ConstantUtilsService } from './shared/services/constant-utils.service';
import { ShoppingCartManagerService } from './shared/services/shopping-cart-manager.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Router } from '@angular/router';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AnimalPopover } from './user/user-profile/animal-popover/animal-popover.component';
import { SwUpdate } from '@angular/service-worker';
import { MenuService } from './shared/services/menu.service';
import { register } from 'swiper/element/bundle';
import { NGXLogger } from 'ngx-logger';

register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  userData: any;
  userProfile: BuyerUserProfile;
  isLoggedIn = false;
  isDevMode: boolean = true;
  private FREQ_UPDATE: number = 3;

  public selectedIndex = 0;
  

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    public userService: UserService,
    public alertController: AlertController,
    public toastController: ToastController,
    public constantUtilsService: ConstantUtilsService,
    private shoppingCartManagerService: ShoppingCartManagerService,
    private deepLinks: Deeplinks,
    private router: Router, private logger: NGXLogger,
    private ngZone: NgZone, 
    private popover: PopoverController,
    private menuController: MenuController,
    private readonly updates: SwUpdate,
    public menuService: MenuService
  ) {
    // Looppa.. da valutare se può essere usato ogni tanto per forzare il refresh
    var random_number = Math.random();
    random_number = Math.ceil(random_number*1000);
    this.logger.log('APP.COMPONENT: numero random = '+random_number%this.FREQ_UPDATE);
    if(random_number==0 && this.updates.isEnabled){
      this.logger.log('Service Workers enabled');
      this.updates.activateUpdate().then(event => {
        document.location.reload();
        this.appUpdateToast('App aggiornata con successo');
      });
    }else{
      this.logger.log('Service Workers NOT enabled or wrong random number');
    }
    
    //registerPlugin("FacebookLogin")
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      //this.initializeDeepLinks(); // Deeplinks implementation
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          // this.logger.log("appLinkOpen", event, event.url)
          this.ngZone.run(() => {
              // Example url: https://beerswift.app/tabs/tab2
              // slug = /tabs/tab2
              const slug = event.url.split(".app").pop();
              if (slug) {
                  this.router.navigateByUrl(slug);
              }
              // If no match, do nothing - let regular routing
              // logic take over
          });
      });
    });
  }

  ngOnInit() {
    // Get user data if logged in
    this.isDevMode = isDevMode();
    this.userService.userChanged.subscribe(
      userData => {
        const prevLoggedIn = this.isLoggedIn;
        this.isLoggedIn = userData !== null;
        this.userData = userData
        if(this.isLoggedIn){
          let loginMessage = "";
          // If the userChanged confirmed that a user, who was already logged in, is still logged in, do not send any toast.
          if(prevLoggedIn && this.isLoggedIn){
            return;
          }
          if(this.userData.first_name && this.userData.last_name){
            loginMessage = "Accesso effettuato come "+userData.first_name + " " + userData.last_name +".";
            if(this.userData.telefono){
              this.logger.log("Menu venditore");
              this.menuService.setVendorMenu();
            }else{
              this.logger.log("Menu cliente");
              this.menuService.setBuyerMenu();
            }
          }
          else{
            loginMessage = "Accesso effettuato con successo."
          }
          this.confirmToast(loginMessage);

        }
      }
    )

    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.menuService.menu.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  async logoutModal() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Conferma Logout',
      message: 'Confermi di voler uscire?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Conferma',
          handler: () => {
            this.logger.log('Confirm logout');
            this.authService.logout();
            this.confirmToast("Logout effettuato con successo.");
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  toUserProfile(){
    this.menuController.close();
    this.router.navigate(['/myprofile']);
  }

  async appUpdateToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000
    });
    toast.present();
  }

}
