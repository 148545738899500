import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Categorie } from '../models/Categorie.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';


@Injectable()
export class AllCategorieService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/all-categories/');
  error = new Subject<string>();

  // HTTP GET
  fetchAllCategories(next: string) {
    let requestURL = this.url
    if(next!=null){
      requestURL = next;
    }

    return this.http
      .get< Categorie >(
        requestURL,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: requestParams,*/                                     
          responseType: 'json'
        }
      )
      .pipe(
        map(responseData => {
          this.logger.log(responseData);
          return < Categorie >responseData;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      );
  }

}
