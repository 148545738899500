import { HttpClient } from '@angular/common/http';
import { Component, isDevMode, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IonContent, Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CategoriaItem, Categorie } from 'src/app/shared/models/Categorie.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { GetCategorieService } from 'src/app/shared/services/getRootCategories.service';
import { ProdottiInteractionService } from 'src/app/shared/services/prodotti-interaction.service';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { UserService } from 'src/app/shared/services/User.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories-list.component.html',
  providers:[
    GetCategorieService
  ],
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesComponent implements OnInit {
  @ViewChild(IonContent, {static: false}) private content: IonContent;

  ZERO_CONSTANT: Number = Number(0);
  title: string = 'Categorie';
  resArray: Categorie;
  categorie: CategoriaItem[];
  next: string;
  next_index: number = 0;
  firstCall: boolean = true;
  hasCategorie: boolean = false;
  isFetching = false;
  error = null;
  private errorSub: Subscription;

  cartBadge: Number;

  constructor(private http: HttpClient, private getCategorieService: GetCategorieService, private router: Router,
    public userService: UserService, private prodottiInteractionService: ProdottiInteractionService,
    public constantUtilsService: ConstantUtilsService, public shoppingCartManagerService: ShoppingCartManagerService,
    private authService: AuthService, public platform: Platform, private logger: NGXLogger) { }

  ngOnInit() {
    this.getCategories(null);
  }

  ionViewWillEnter(){
    this.logger.log("CategoriesComponent: ionViewWillEnter");
    this.checkIfBottomReached();
    
    this.shoppingCartRetriever();
  }

  shoppingCartRetriever(){
    if(this.authService.isLoggedIn() && !this.userService.userIsVendor()){
      this.logger.log('CategoriesComponent: utente loggato, inizializzazione badge del carrello tramite API');
      this.shoppingCartManagerService.getShoppingCartBadge('CategoriesComponent').subscribe(
        posts => {
          this.logger.log('CategoriesComponent: cartBadge returned = '+posts);
          this.cartBadge = posts;
        },
        error => {
          this.logger.log('CategoriesComponent: error returned... cartBadge = null');
          this.cartBadge = null;
          this.logger.log(error);
        }
      )
    }else{ 
      this.logger.log('CategoriesComponent: utente non loggato, inizializzazione badge del carrello tramite local storage');
      this.shoppingCartManagerService.localCart_storageKeys('CategoriesComponent').then(
        res => {
          this.logger.log('CategoriesComponent: local storage keys returned = '+res);
          this.cartBadge = res;
        }
      );
    }
  }

  trackByFunc(index, item) {
    //return item ? item.id : index;
    return index;
  }

  loadData(event) {
    //this.getNextVendors(event);
    this.getCategories(event);
    event.target.complete();
  }

  moreDataToLoad(){
    return this.next != null || this.firstCall;
  }

  getCategories(event){
    this.logger.log('CategoriesComponent: ngOnInit <--------------');

    if(this.moreDataToLoad()){
      this.firstCall = false;
      this.errorSub = this.getCategorieService.error.subscribe(errorMessage => {
        this.error = errorMessage;
      });

      this.isFetching = true;
      this.getCategorieService.fetchCategories(this.next).subscribe(
        posts => {
          this.isFetching = false;
          this.resArray = <Categorie>posts;
          this.logger.log('CategoriesComponent: GET retrieved something...');
          this.logger.log(this.resArray);
          this.next = this.resArray.next;
          this.logger.log('CategoriesComponent: next parameter');
          this.logger.log(this.next);
          this.next_index = this.next_index + 10;
          this.logger.log('CategoriesComponent: GET results:');
          if(this.categorie!=null){
            // after the first time...
            this.categorie = this.categorie.concat(this.resArray['results']);
          }else{
            // the first time...
            this.categorie = this.resArray['results'];
          }
          this.logger.log(this.categorie);
          if(this.categorie.length>0){
            this.hasCategorie = true;
          }
        },
        error => {
          this.isFetching = false;
          this.error = error.message;
          this.logger.log('CategoriesComponent: GET..... an error occurred');
        }
      );
    }
  }

  onHandleError() {
    this.error = null;
  }

  ngOnDestroy() {
    this.errorSub.unsubscribe();
  }

  onSelectCategory(id: string, name: string){
    // original page
    let navigationExtras: NavigationExtras = { state: { name: name } };
    let destinationURL = '/products/category/'+id;
    this.router.navigate([destinationURL], navigationExtras);
  }

  onReloadCategories(){
    // Retry clicked by the user..... attempt to reload categories
    this.logger.log("CategoriesComponent: onReloadCategories()");
    this.firstCall = true;
    this.prodottiInteractionService.announce('update_cart');
    this.ngOnInit();
    this.ionViewWillEnter();
  }

  checkIfBottomReached(){
    // this.logger.log("checkIfBottomReached start")
    if(!this.moreDataToLoad() || this.isFetching){
      // this.logger.log("checkIfBottomReached. exiting", this.moreDataToLoad(), this.isFetching)
      return;
    }
    this.checkForScrollbar().then(bottomReached => {
      // this.logger.log("checkIfBottomReached - scrollBarVisible=", bottomReached)
      if (!bottomReached && !this.isFetching) {
        this.logger.log("Screen is not filled with products. Loading some more.")
        this.getCategories(null);
      }
    });
  }

  async checkForScrollbar() {
    // This method is used to fix a bug related to the InfiniteScroll object,
    // that does not work if the content (meaning, the grid of products) does not fill the screen
    // (this bug only occurs on big screens with a high resolution for this page)
    const scrollElement = await this.content.getScrollElement()
    // this.logger.log("checkForScrollbar", scrollElement.scrollHeight, scrollElement.clientHeight)
    
    // If the scrollHeight is 0, the view is not yet loaded. Don't do anything.
    if(scrollElement.scrollHeight === 0){
      return true;
    }
    // this.logger.log("Returning", scrollElement.scrollHeight > scrollElement.clientHeight)
    return scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  toShoppingCart(){
    this.logger.log("Navigation from CategoriesComponent to ShoppingCart...");
    this.router.navigateByUrl("/shopping-cart");
  }

}
