import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
  HttpEvent
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Prodotti, ProdottoItem } from '../models/Prodotti.model';
import { AuthService } from './auth.service';
import { UserService } from './User.service';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GetProdottiFilteredService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger) {}

    private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/products/filter/');
    error = new Subject<string>();


    fetchProductsByUrlAndParams(url, params){
        if(url!=null){
          // after the first time
          return this.fetchProducts(url,params);
        }else{
          // the request for the first 10 objects
          return this.fetchProducts(this.defaultUrl,params);
        }
      }

    private fetchProducts(url, searchParams){

        this.logger.log(url);

        return this.http
        .get<Prodotti>(
        url,
        {
            headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
            params: searchParams,                                     // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
            responseType: 'json'
        }
        )
        .pipe(
        map(responseData => {
            return responseData;
        }),
        catchError(errorRes => {
            // Send to analytics server
            return throwError(errorRes);
        })
        );
    }

    // HTTP DELETE
    deletePosts() {
        return this.http
        .delete(this.defaultUrl, {
            observe: 'events',
            responseType: 'text'
        })
        .pipe(
            tap(event => {
            this.logger.log(event);
            if (event.type === HttpEventType.Sent) {
                // ...
            }
            if (event.type === HttpEventType.Response) {
                this.logger.log(event.body);
            }
            })
        );
    }

}
