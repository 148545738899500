<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
      {{title}}
    </ion-title>

    <ion-buttons slot="start">
      <ion-back-button defaultHref="{{back}}"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="!platform.is('ios')" class="ion-margin" style="max-width: 150px; margin: auto; padding-left: 0px;"> {{ title }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" slot="fixed" scrollY="false">
  <!--
  <ion-header collapse="condense">
    <ion-toolbar style="padding-top: 0px; margin-top: -2px;">
      <ion-title size="large" style="background: var(--ion-color-primary); color: white;">{{title}}</ion-title>
    </ion-toolbar>
  </ion-header>-->

  <ion-spinner class="cart-items-loading" name="bubbles" *ngIf="isFetchingItems"></ion-spinner>

  <ion-spinner name="bubbles"  *ngIf="paymentLoading" class="cart-items-loading"></ion-spinner>

  <div class="container" *ngIf="!paymentLoading">
  <ion-grid class="container" *ngIf="!isFetchingItems">
    <ion-row class="style-first" *ngIf="focusRiepilogo">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="riepilogo">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onReduceRiepilogo()"><strong>Riepilogo</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onReduceRiepilogo()" *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onReduceRiepilogo()" *ngIf="platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
            <ion-card-subtitle>
              Pronti per l'acquisto 
              <!--<p class="total">Totale: <strong>{{ total }}€</strong></p>-->
            </ion-card-subtitle>
          </ion-card-header>
          <ion-content scrollY="true" class="riepilogo">
            <app-checkout-riepilogo [items]="cart" (total_emitter)="totalReceiver($event)" (trasporto_emitter)="trasportoReceiver($event)" *ngIf="!isFetchingItems"></app-checkout-riepilogo>
            <ion-spinner name="bubbles"  *ngIf="isFetchingItems"></ion-spinner>
          </ion-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row class="style-closed" *ngIf="!focusRiepilogo">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="riepilogo-closed">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onFocusRiepilogo()"><strong>Riepilogo</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onFocusRiepilogo()" *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onFocusRiepilogo()" *ngIf="platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row class="style-first" *ngIf="focusIndirizzi">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="indirizzo">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onReduceIndirizzo()"><strong>Indirizzo</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onReduceIndirizzo()"  *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onReduceIndirizzo()"  *ngIf="platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
            <ion-card-subtitle>Scegli un'indirizzo di spedizione</ion-card-subtitle>
          </ion-card-header>
          <ion-fab vertical="bottom" horizontal="end" class="btn-add" *ngIf="!isFetchingAddress">
            <ion-fab-button color="primary" title="Aggiungi un nuovo indirizzo" class="btn-add" (click)="onAdd()">
              <ion-icon name="add-outline"></ion-icon>
            </ion-fab-button>
          </ion-fab>
          <ion-content scrollY="true" class="addr">
            <app-shipping-address [positions]=positions [type]=type *ngIf="!isFetchingAddress"
                                  (id_changes)="onIDChanges($event)" (index_changes)="onIndexChanges($event)"
                                  (id_removed)="onIDRemoved($event)"></app-shipping-address>
            <div style="width: 100%; height: 50px;" *ngIf="!isFetchingAddress">
              <ion-text></ion-text>
            </div>
          
            <ion-spinner name="bubbles"  *ngIf="isFetchingAddress"></ion-spinner>
          </ion-content>
          <ion-content *ngIf="!hasPositions && !isFetchingAddress">
            <ion-item lines="none"> Nessun indirizzo presente. </ion-item>
            <ion-item lines="none"> Inserisci il tuo primo indirizzo tramite l'apposito pulsante! </ion-item>
          </ion-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row class="style-closed" *ngIf="!focusIndirizzi">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="indirizzo-closed">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onFocusIndirizzo()"><strong>Indirizzo</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onFocusIndirizzo()" *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onFocusIndirizzo()" *ngIf="platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
      
    <ion-row class="style-first" *ngIf="focusPagamento">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="pagamento">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onReducePagamento()"><strong>Spedizione</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onReducePagamento()" *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onReducePagamento()" *ngIf="platform.is('ios')">
                <ion-icon name="chevron-up-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
            <ion-card-subtitle>Completa le info di spedizione</ion-card-subtitle>
          </ion-card-header>
          
          <ion-content scrollY="true" class="info">

            <form [formGroup]="editClientDataForm">
                <div class="form-fields-area">
                  <ion-item lines="none">
                    <ion-row class="form-row">
                      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="form-title"><strong>Nome</strong><ion-text color="danger">*</ion-text></ion-label>
                      
                        <ion-item lines="none">
                          <ion-input aria-label="Nome" type="text" errorText="Nome non valido"
                          placeholder="Nome dell'acquirente" formControlName="Nome"></ion-input>
                          <!-- Gestione Obsoleta 
                          <ion-text style="font-size: 12px; padding-bottom: 4px;" color="danger" 
                          *ngIf="goingToDonation && editClientDataForm.get('Nome').errors">Nome con formato errato</ion-text>-->
                        </ion-item>
                      </ion-col>

                      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="form-title"><strong>Cognome</strong><ion-text color="danger">*</ion-text></ion-label>
                      
                        <ion-item lines="none">
                          <ion-input aria-label="Cognome" type="text" errorText="Cognome non valido"
                          placeholder="Cognome dell'acquirente" formControlName="Cognome"></ion-input>
                          <!-- Gestione Obsoleta
                          <ion-text style="font-size: 12px; padding-bottom: 4px;" color="danger" 
                          *ngIf="goingToDonation && editClientDataForm.get('Cognome').errors">Cognome con formato errato</ion-text>-->
                        </ion-item>
                      </ion-col>

                    </ion-row>
                  </ion-item>

                  <ion-item lines="none" class="ion-margin-top">
                    <ion-row class="form-row">
                      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="form-title"><strong>Numero</strong><ion-text color="danger">*</ion-text></ion-label>
                      
                        <ion-row style="padding: 0px;">
                          <ion-col size="5" style="padding: 0px;">
                            <ion-item lines="none">
                              <ion-select aria-label="Numero" placeholder="+39" okText="Okay" cancelText="Chiudi" #prefixSelect (ionChange)="onChangeSelectPrefix()" style="max-width: 80%;">
                                <ion-select-option *ngFor="let p of prefixService.prefix_array; index as index_i" value="{{p}}">{{prefixService.country_prefix_array[index_i]}}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-col>
                          <ion-col size="7" style="padding: 0px;">
                            <ion-item lines="none">
                              <ion-input aria-label="Numero" errorText="Numero Mobile non valido" (ionBlur)="saveNumber()"
                              placeholder="Numero di telefono" formControlName="Numero" style="padding-left: 2px;"></ion-input>
                              <!-- Gestione Obsoleta
                              <ion-text style="font-size: 12px; padding-bottom: 4px;" color="danger" 
                              *ngIf="goingToDonation && editClientDataForm.get('Numero').errors">Numero con formato errato</ion-text>-->
                            </ion-item>
                          </ion-col>
                        </ion-row>
                        
                      </ion-col>
                      <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="form-title"><strong>Email</strong><ion-text color="danger">*</ion-text></ion-label>
                      
                        <ion-item lines="none">
                          <ion-input aria-label="Email" errorText="Email non valida"
                          placeholder="Email" formControlName="Email"></ion-input>
                          <!-- Gestione Obsoleta
                          <ion-text style="font-size: 12px; padding-bottom: 4px;" color="danger" 
                          *ngIf="goingToDonation && editClientDataForm.get('Email').errors">Email con formato errato</ion-text>-->
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-row class="form-row">
                      <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1">
                        <ion-checkbox (ionChange)="fatturaIsChanged()" #fattura style="float: right;"></ion-checkbox>
                      </ion-col>
                      <ion-col size-xs="10" size-sm="11" size-md="11" size-lg="11" size-xl="11">
                        <p style="margin-top: 18px; margin-bottom: 12px;" *ngIf="!platform.is('ios')"><ion-text><strong>Richiedi fattura</strong></ion-text></p>
                        <p *ngIf="platform.is('ios')" style="margin-top: 12px; font-size: 14px; margin-bottom: 12px;"><ion-text><strong>Richiedi fattura</strong></ion-text></p>
                        <p style="font-size: 10px; margin-top: 12px;"><ion-text>La fattura verrà emessa solo nel caso di venditori con partita IVA.</ion-text></p>
                      </ion-col>
                    </ion-row>
                  </ion-item>

                  <ion-item lines="none" class="ion-margin-top">
                    <ion-row class="form-row">
                      <ion-col size="12">
                          <ion-label class="form-title"><strong>Codice Fiscale</strong><ion-text *ngIf="CFMandatory" color="danger">*</ion-text></ion-label>
                          <ion-item lines="none">
                            <ion-input aria-label="Codice Fiscale" errorText="Codice Fiscale non valido" (ionBlur)="saveCF()"
                            type="text" placeholder="Codice Fiscale" formControlName="CodiceFiscale"></ion-input>
                          </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-row class="form-row">
                      <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1">
                        <ion-checkbox (ionChange)="terminiCondizioniIsChanged()" #termini style="float: right;"></ion-checkbox>
                      </ion-col>
                      <ion-col size-xs="10" size-sm="11" size-md="11" size-lg="11" size-xl="11">
                        <p style="margin-top: 18px;" *ngIf="!platform.is('ios')"><ion-text>Ho letto e accetto </ion-text>
                          <ion-text color="primary"><a  href="https://www.iubenda.com/termini-e-condizioni/10266349"><strong>Termini e condizioni</strong></a></ion-text>
                          <ion-text> e la </ion-text>
                          <ion-text color="primary" (click)="toPolicy()"><u><strong>Policy spedizioni e resi</strong></u></ion-text></p>
                        <p *ngIf="platform.is('ios')" style="margin-top: 12px; font-size: 14px;"><ion-text>Ho letto e accetto </ion-text>
                          <ion-text color="primary" style="font-size: 14px;"><a  href="https://www.iubenda.com/termini-e-condizioni/10266349"><strong>Termini e condizioni</strong></a></ion-text>
                          <ion-text  style="font-size: 14px;"> e la </ion-text>
                          <ion-text color="primary" (click)="toPolicy()"  style="font-size: 14px;"><u><strong>Policy spedizioni e resi</strong></u></ion-text></p>
                      </ion-col>
                    </ion-row>
                  </ion-item>

                  <ion-item lines="none" class="ion-margin-top" style="margin-bottom: 32px;">
                    <ion-row class="form-row">
                      <ion-col size=12>
                        <ion-label class="form-title"><strong>Note</strong></ion-label>
                        
                        <ion-item lines="none">
                          <ion-input aria-label="Note" placeholder="Inserisci eventuali note per i corrieri o per il venditore" formControlName="Note"></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-item>
                </div>
              </form>

          </ion-content>

        </ion-card>
        <div class="pagamento">
          <ion-badge color="danger" *ngIf="!this.editClientDataForm || !this.editClientDataForm.valid" style="float: right;">
            Campi mancanti *
          </ion-badge>
          <ion-badge color="danger" *ngIf="this.editClientDataForm && this.editClientDataForm.valid && !terminiCondizioniFlagged" style="float: right;">
            Prendi visione dei termini e condizioni *
          </ion-badge>
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="style-closed" *ngIf="!focusPagamento">
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
        <ion-card class="pagamento-closed">
          <ion-card-header>
            <ion-card-title style="color: #777777;" (click)="onFocusPagamento()"><strong>Spedizione</strong>
              <ion-button color="secondary" clear icon-only shape="round" class="right" 
                (click)="onFocusPagamento()" *ngIf="!platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
              <ion-button color="secondary" clear icon-only shape="round" class="right-ios" 
                (click)="onFocusPagamento()" *ngIf="platform.is('ios')">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-button>
            </ion-card-title>
          </ion-card-header>
          
        </ion-card>
      </ion-col>
    </ion-row>

  </ion-grid>
  </div>

  <div class="totale-div" *ngIf="!isFetchingItems && !paymentLoading">
    <ion-grid style="padding: 0px;">
      <ion-row class="tot">
        <ion-col size-xl="4" size-lg="3" size-md="3" size-sm="2" size-xs="1" style="padding-left: 0px; padding-right: 0px;"></ion-col>
        <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="6" class="tot">
          <p style="margin-bottom: 2px; margin-top: 20px;">
            <ion-label class="totale-label">Totale: <strong>{{ roundTo(total,1) }}€ </strong></ion-label>
          </p>
          <p style="margin-top: 2px;">
            <ion-label class="trasporto-label">di cui <strong>{{ trasporto_total }}€</strong> di trasporto</ion-label>
          </p>
        </ion-col>
        <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="5" class="tot">
          <ion-button class="paga-btn" 
            (click)="toPayment()" [disabled]="!this.editClientDataForm || !this.editClientDataForm.valid"
            [title]="(!this.editClientDataForm || !this.editClientDataForm.valid) ? 'Inserisci i dati per la spedizione e l\'indirizzo'  : null" >Paga</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

</ion-content>