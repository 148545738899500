
export class SocialUserData{
    id: string;
    userType: string;
    authType: string;
    firstName: string;
    lastName: string;
    profilePictureURL: string;
    email: string;
    serverAuthCode: string;

    constructor(id: string, userType: string, authType: string, firstName: string, lastName: string, email: string, profilePictureURL: string, serverAuthCode: string) {
        this.id = id;
        this.userType = userType;
        this.authType = authType;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.profilePictureURL = profilePictureURL;
        this.serverAuthCode = serverAuthCode
    }
}

export class ManualLoginRequestData{
    grant_type = "convert_token";
    username: string; 
    password: string; 

    constructor(e: string, p: string) {
        this.username = e;
        this.password = p;
    }
}

export class ManualRegistrationRequestClientData{
    grant_type = "convert_token";
    email: string; 
    password: string; 
    first_name: string;
    last_name: string;
    registration_type: string;
    user_type: string;

    constructor(e: string, p: string, name: string, surname: string, type: string) {
        this.email = e;
        this.password = p;
        this.first_name = name;
        this.last_name = surname;
        this.registration_type = "Basic";
        this.user_type = type;
    }
}

export class ManualRegistrationRequestVendorData{
    grant_type = "convert_token";
    email: string; 
    password: string; 
    first_name: string;
    last_name: string;
    registration_type: string;
    user_type: string;
    tipologia: string;
    denominazione: string; 
    partita_iva: string;
    codice_destinatario: string;
    codice_fiscale: string;
    titolo: string;
    descrizione: string;
    biografia: string;
    indirizzo: string;
    città: string;
    cap: string;
    paese: string;
    iban: string;
    prefisso: string;
    telefono: string;
    associazione: string;

    constructor(e: string, p: string, name: string, surname: string, registration_type: string, type: string, tipologia: string,
        denominazione: string, partitaiva: string, codicedest: string, iban: string, CF: string, titolo: string, descrizione: string, 
        biografia: string, indirizzo: string, comune: string, CAP: string, nazione: string, prefisso: string, telefono: string,
        associazione: string) {
        this.email = e;
        this.password = p;
        this.first_name = name;
        this.last_name = surname;
        this.registration_type = registration_type;
        if(this.registration_type!='Basic' && this.registration_type!='Google'){
            this.registration_type = 'Basic'
        }
        this.user_type = type;
        this.denominazione = denominazione;
        this.paese = nazione;
        this.indirizzo = indirizzo;
        this.cap = CAP;
        this.città = comune;
        this.partita_iva = partitaiva;
        this.codice_destinatario = codicedest;
        this.iban = iban;
        this.codice_fiscale = CF;
        this.titolo = titolo;
        this.descrizione = descrizione;
        this.biografia = biografia;
        this.prefisso = prefisso;
        this.telefono = telefono;
        if(tipologia=='fisica'){
            this.tipologia = 'FS';
        }else{
            this.tipologia = 'GR';
        }
        this.associazione = associazione;
    }
}

export class SocialRegistrationRequestVendorData{
    first_name: string;
    last_name: string;
    email: string;
    registration_type: string;
    tipologia: string;
    denominazione: string; 
    partita_iva: string;
    codice_destinatario: string;
    codice_fiscale: string;
    titolo: string;
    descrizione: string;
    biografia: string;
    indirizzo: string;
    città: string;
    cap: string;
    paese: string;
    iban: string;
    prefisso: string;
    telefono: string;
    associazione: string;

    constructor(nome: string, surname: string, email: string, registration_type: string, tipologia: string,
        denominazione: string, partitaiva: string, codicedest: string, iban: string, CF: string, titolo: string, descrizione: string, 
        biografia: string, indirizzo: string, comune: string, CAP: string, nazione: string, prefisso: string, telefono: string,
        associazione: string) {
        this.first_name = nome;
        this.last_name = surname;
        this.email = email;
        this.registration_type = registration_type;
        if(this.registration_type!='Basic' && this.registration_type!='Google'){
            this.registration_type = 'Basic'
        }
        this.denominazione = denominazione;
        this.paese = nazione;
        this.indirizzo = indirizzo;
        this.cap = CAP;
        this.città = comune;
        this.partita_iva = partitaiva;
        this.codice_destinatario = codicedest;
        this.iban = iban;
        this.codice_fiscale = CF;
        this.titolo = titolo;
        this.descrizione = descrizione;
        this.biografia = biografia;
        this.prefisso = prefisso;
        this.telefono = telefono;
        if(tipologia=='fisica'){
            this.tipologia = 'FS';
        }else{
            this.tipologia = 'GR';
        }
        this.associazione = associazione;
    }
}


export class LoginRequestData{
    grant_type = "convert_token";
    client_id: string;
    client_secret: string;
    backend: string;
    token: string;
    extraData: SocialRegistrationRequestVendorData;  

    constructor(client_id: string, client_secret: string, backend: string, token: string, extraData: SocialRegistrationRequestVendorData) {
        this.client_id = client_id;
        this.client_secret = client_secret;
        this.backend = backend;
        this.token = token;
        this.extraData = extraData;
    }
}

export class LoginRequestDataSecured{
    grant_type = "convert_token";
    client_id: string;
    //client_secret: string; // Removed for security reasons
    backend: string;
    token: string;
    extraData: string;  

    constructor(client_id: string, backend: string, token: string, extraData: ManualRegistrationRequestVendorData) {
        this.client_id = client_id;
        this.backend = backend;
        this.token = token;
        this.extraData = JSON.stringify(extraData);
    }
}

export class RefreshTokenRequestData{
    grant_type = "refresh_token";
    client_id: string;
    client_secret: string;
    refresh_token: string;

    constructor(client_id: string, client_secret: string, refresh_token: string) {
        this.client_id = client_id;
        this.client_secret = client_secret;
        this.refresh_token = refresh_token;
    }
}

export class RefreshTokenRequestDataSecured{
    grant_type = "refresh_token";
    client_id: string;
    client_secret: string;
    refresh_token: string;

    constructor(client_id: string, refresh_token: string) {
        this.client_id = client_id;
        this.refresh_token = refresh_token;
    }
}

export class RefreshBasicAuthTokenRequestData{
    grant_type = "refresh_token";
    refresh: string; // backend side it is called refresh instead of refresh_token

    constructor(refresh_token: string) {
        this.refresh = refresh_token;
    }
}

export class ManualUserTokenData{
    // backend is not necessary because it's only used for manual auth (basic auth, not social auth)
    access_token: string;
    expiration_date: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;

    constructor(access_token: string, expires_in: number, /*token_type: string, scope: string,*/ refresh_token: string) {
        this.access_token = access_token;
        this.expires_in = expires_in;
        /*this.token_type = token_type;
        this.scope = scope;*/
        this.refresh_token = refresh_token;
    }
}

export class UserTokenData{
    backend: string;
    access_token: string;
    expiration_date: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;

    constructor(backend: string, access_token: string, expires_in: number, token_type: string, scope: string, refresh_token: string) {
        this.backend = backend;
        this.access_token = access_token;
        this.expires_in = expires_in;
        this.token_type = token_type;
        this.scope = scope;
        this.refresh_token = refresh_token;
    }
}

export class AuthResult{
    /*  This class is used by the AuthService to notify to the login/register pages 
        the result of the Authentication operation 
    */
    resultSuccess: boolean;
    resultCode: string;

    constructor(resultSuccess: boolean, resultCode: string){
        this.resultSuccess = resultSuccess;
        this.resultCode = resultCode;
    }
}