import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SwipeProdottiComponent } from './swipe-prodotti.component';
import { SchedaProdottoComponent } from './scheda-prodotto/scheda-prodotto.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [SwipeProdottiComponent, SchedaProdottoComponent],
  exports: [SwipeProdottiComponent]
})
export class SwipeProdottiModule {}
