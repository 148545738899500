import { Component } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { NavParams, PopoverController } from "@ionic/angular";
import { GrigliaProdottiComponent } from "../griglia-prodotti.component";
import { NGXLogger } from "ngx-logger";

@Component({
    templateUrl: './product-card-menu.component.html',
})
export class ProductCardMenuComponent {
    private parentComponent: GrigliaProdottiComponent;
    private index;
    public product;

    constructor(private popover: PopoverController, private navParams: NavParams, private router: Router, private logger: NGXLogger) {
        this.parentComponent = navParams.get('parentComponent');
        this.index = navParams.get('index');
        this.product = navParams.get('product');
    }

    openProductSwipe(){
        this.parentComponent.trigger = true;
        this.parentComponent.openSwipeTo(this.index);
        this.dismissPopover();
    }

    openProductDetail(){
        this.parentComponent.openDetailTo(this.index);
        this.dismissPopover();
    }

    openEditProductPage(){
        let navigationExtras: NavigationExtras = {
            state: {
              why: 'EDIT'
            }
          };
        let destinationURL = '/myprofile/vendor/products/'+this.product["id"]+"/edit";
        this.router.navigate([destinationURL], navigationExtras);
        this.dismissPopover();
    }

    openEditTAGsPage(){
        let navigationExtras: NavigationExtras = {
            state: {
              why: 'TAG'
            }
          };
        let destinationURL = '/myprofile/vendor/products/'+this.product["id"]+"/edit";
        this.router.navigate([destinationURL], navigationExtras);
        this.dismissPopover();
    }

    dismissPopover(){
        this.popover.dismiss();
    }

    setAsUnavailable(){
        this.logger.log('ProductMenu: setAsUnavailable');
        this.parentComponent.setAsUnavailable(this.index);
        this.dismissPopover();
    }
}