<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')" style="margin-top: 12px;">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 12px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Categorie
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="6" size-sm="4" size-md="8" size-lg="8" size-xl="8" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Categorie
            </ion-title>
          </div>
        </ion-col>
        
        <!-- Bottoni laterali -->
        <ion-col size-xs="3" size-sm="4" size-md="2" size-lg="2" size-xl="2" style="text-align: right; padding-right: 9px;">
          <!--<ion-button (click)="toUserProfile()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && !platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-icon slot="icon-only" name="person-circle"></ion-icon>
          </ion-button>-->
            
          <ion-button *ngIf="!userService.userIsVendor()"  (click)="toShoppingCart()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && !platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-icon slot="icon-only" name="cart"></ion-icon>
          </ion-button>
        </ion-col>

      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="padding-top: 0px;" (window:resize)="checkIfBottomReached()">
      
  <ion-spinner name="bubbles"  *ngIf="isFetching"></ion-spinner>
  
    <ion-grid *ngIf="hasCategorie" style="margin: auto; max-width: 1200px; margin-top: 8px;">
      <ion-row>
        <ion-col style="padding: 10px;"
        size-xs="6" size-sm="6" size-md="4" size-lg="3" size-xl="3"
        *ngFor="let c of categorie">
          <ion-card class="data" (click)="onSelectCategory(c['id'],c['nome'])">
            <img src="{{ constantUtilsService.getFullURL(c['immagine']) }}" onerror="this.src='assets/error/verde.jpg'" class="img-resize"/>
            <div class="card-title"> {{ (c['nome'].length > 22)? (c['nome'] | slice:0:22)+'..':(c['nome']) }}</div>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" *ngIf="hasCategorie">
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
      

    <div *ngIf="!hasCategorie && !isFetching" class="error-div">
      <img src="assets/error/cavolo.jpg" class="error-resize">
      <p class="category-title-data"> Cavolo, qualcosa è andato storto! </p>
      <p class="category-title-data"> Verifica la tua connessione e riprova </p>
      <ion-fab horizontal="center" slot="fixed" class="retry">
        <ion-fab-button color="primary" (click)="onReloadCategories()">
          <ion-icon name="repeat-sharp"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </div>
  
</ion-content>
