import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';

@Component({
  selector: 'popover-animal',
  templateUrl: 'association-popover.component.html',
  styleUrls: ['./association-popover.component.scss']
})
export class AssociationPopover {
    constructor (private popoverProp: PopoverController, private navParams: NavParams, 
        public constantUtils: ConstantUtilsService, private logger: NGXLogger) {
        this.nome = navParams.get('nome');
        this.logo = navParams.get('logo');
        this.link = navParams.get('link');
        this.descrizione = navParams.get('descrizione');
        this.logger.log('AssociationPopover: '+this.nome);   // DEBUG
        this.logger.log('AssociationPopover: '+this.logo);
        this.logger.log('AssociationPopover: '+this.link);
        this.logger.log('AssociationPopover: '+this.descrizione);   // DEBUG
        if(!this.nome || !this.logo || !this.descrizione
            || this.nome=='' || this.logo=='' || this.descrizione==''){
                this.logger.log('AssociationPopover: Non sono stati ricevuti i parametri necessari...');
                this.popoverProp.dismiss();
        }
    }

    nome: string;
    logo: string;
    link: string;
    descrizione: string;

    ClosePopover() {
        this.popoverProp.dismiss();
    }

    close(){
        this.ClosePopover();
    }

}