<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    <ion-title *ngIf="platform.is('ios') && !isMyProfile()" style="background: var(--ion-color-primary); color: white; font-size: 20px; text-align: center;"> 
      Venditore
    </ion-title>
    <ion-title *ngIf="platform.is('ios') && isMyProfile()" style="background: var(--ion-color-primary); color: white; font-size: 20px; text-align: center;"> 
      Il tuo negozio
    </ion-title>

    <ion-buttons slot="start">
      <ion-back-button defaultHref="home"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="!isMyProfile() && !platform.is('ios')" class="ion-margin" style="margin: auto; text-align: center;">Venditore</ion-title>
    <ion-title *ngIf="isMyProfile() && !platform.is('ios')" class="ion-margin" style="margin: auto; text-align: center;">Il tuo negozio</ion-title>
    <ion-buttons slot="end" class="cart-btn">
      <ion-button (click)="toHome()" style="--padding-end: 4px; --padding-start: 4px;">
        <ion-icon slot="icon-only" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">

  <ion-grid style="width:100%; background-image: linear-gradient(to bottom right, #22577A, #38A3A5);" *ngIf="!isFetchingVendorData && !errorMsg">
    <ion-row>
      <ion-col size="12" style="text-align: center;">
        <p style="color: #ffffff;">Spedizione gratuita per ordini dallo stesso venditore superiori a 50€</p>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div style="margin-left: 8px; margin-right: 8px; ">

  <div class="vendor-data-loading-area" *ngIf="isFetchingVendorData">
    <ion-spinner name="bubbles" ></ion-spinner>
  </div>

  <!-- VENDOR USER DATA -->
  <ion-card style="max-width: 750px; margin: auto; margin-top: 18px; background: #fdfdfd;" *ngIf="!isFetchingVendorData && !errorMsg">
    <ion-grid style="padding: 0px;" class="vendor-card">
      <ion-row style="padding: 0px;">
        <ion-col size-xs="12" size-sm="4" size-md="6" size-lg="6" size-xl="6" style="padding: 0px; margin: auto;">
          <ion-card class="img">
            <img class="userpic-new" src="{{ constantUtilsService.getFullURL(vendorUserData.foto_profilo) }}" onerror="this.src='assets/images/vendor_user_icon.svg'">
          </ion-card>
          <img src="{{ constantUtilsService.getFullURL(vendorUserData.associazione.logo) }}" 
            *ngIf="vendorUserData.associazione && vendorUserData.associazione.nome!='Nessuna' && vendorUserData.associazione.logo" 
            class="logo-associazione" (click)="CreateAssociationPopover($event, vendorUserData.associazione.nome, vendorUserData.associazione.logo, vendorUserData.associazione.descrizione, vendorUserData.associazione.link)">
        </ion-col>
        <ion-col size-xs="12" size-sm="8" size-md="6" size-lg="6" size-xl="6" style="padding: 0px; margin: 0px;">
          <ion-grid style="padding: 0px; margin: 0px;">
            <!-- Riga dedicata al titolo -->
            <ion-row style="padding: 0px; margin: 0px;">
              <ion-col size="12" style="padding-left: 12px; margin: 0px; margin-top: 16px; text-align: center;">
                <ion-text color="primary" style="font-size: 16px;"><strong>{{vendorUserData.titolo}}</strong></ion-text>
              </ion-col>
            </ion-row>
            <!-- Riga dedicata alle recensioni -->
            <ion-row style="padding: 0px; margin: 0px;" *ngIf="vendorUserData.media_recensioni>0">
              <ion-col size="12" style="padding-left: 12px; margin: 0px; margin-top: 24px; height: 19px;">
                <div class="rating">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="vendorUserData.media_recensioni > 0.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="vendorUserData.media_recensioni > 1.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="vendorUserData.media_recensioni > 2.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="vendorUserData.media_recensioni > 3.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="vendorUserData.media_recensioni > 4.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="vendorUserData.media_recensioni <= 0.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="vendorUserData.media_recensioni <= 1.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="vendorUserData.media_recensioni <= 2.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="vendorUserData.media_recensioni <= 3.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="vendorUserData.media_recensioni <= 4.5">
                  <ion-text style="margin-left: 4px;">{{vendorMediaRecensioni}}/5</ion-text>
                </div>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="vendorUserData.media_recensioni==0" style="padding: 0px; margin: 0px;">
              <ion-col size="12" style="padding-left: 12px; margin: 0px; margin-top: 24px; height: 19px;">
                <div class="rating">
                  <!--<ion-text style="margin-left: 4px;">Nessuna recensione</ion-text>-->
                </div>
              </ion-col>
            </ion-row>
            <!-- Riga dedicata alla posizione -->
            <ion-row style="padding: 0px; margin: 0px;">
              <ion-col size="12" style="padding-left: 0px; margin: 0px; text-align: center; margin-top: 18px;">
                <ion-icon name="location" color="primary" *ngIf="!!vendorUserData.residenza && vendorUserData.residenza.length>0" style="width: 22px; height: 22px; margin-right: 12px; margin-bottom: -2px;"></ion-icon>
                <ion-icon name="location" color="sea" *ngIf="(!vendorUserData.residenza || vendorUserData.residenza.length==0) && isMyProfile()" style="width: 22px; height: 22px; margin-right: 12px; margin-bottom: -2px;"></ion-icon>
                <ion-icon name="location" color="primary" *ngIf="(!vendorUserData.residenza || vendorUserData.residenza.length==0) && !isMyProfile()" style="width: 22px; height: 22px; margin-right: 12px; margin-bottom: -2px;"></ion-icon>
                <ion-text *ngIf="!!vendorUserData.residenza && isMyProfile() && vendorMainAddress" (click)="toAddNewAddress()" style="padding-bottom: 4px;">{{ vendorMainAddress }}</ion-text>
                <ion-text *ngIf="!!vendorUserData.residenza && !isMyProfile() && vendorMainAddress" style="padding-bottom: 4px;">{{ vendorMainAddress }}</ion-text>
                <ion-text *ngIf="(!vendorUserData.residenza || vendorUserData.residenza.length==0 || !vendorMainAddress) && isMyProfile()" (click)="toAddNewAddress()" style="padding-bottom: 4px;"><u style="color: var(--ion-color-danger);">Nessun indirizzo presente</u></ion-text>
                <ion-text *ngIf="(!vendorUserData.residenza || vendorUserData.residenza.length==0 || !vendorMainAddress) && !isMyProfile()" style="padding-bottom: 4px;">Nessun indirizzo presente</ion-text>
              </ion-col>
              <ion-col size-xs="12" size-sm="1" size-md="1" size-lg="1" size-xl="1" style="margin: 0px;"></ion-col>
              <ion-col size-xs="12" size-sm="5" size-md="5" size-lg="5" size-xl="5" style="margin: 0px;">
                <ion-grid>
                  <ion-row>
                    <ion-col size="1"></ion-col>
                    <ion-col size="3" style="text-align: right;"><ion-icon name="pricetags" color="primary" style="width: 22px; height: 22px; margin-top: -4px;"></ion-icon></ion-col>
                    <ion-col size="8" style="padding-left: 12px;" *ngIf="products.length!=1"><strong>{{products.length}}</strong> prodotti</ion-col>
                    <ion-col size="8" style="padding-left: 12px;" *ngIf="products.length==1"><strong>{{products.length}}</strong> prodotto</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" style="margin: 0px;">
                <ion-grid>
                  <ion-row>
                    <ion-col size="1"></ion-col>
                    <ion-col size="3" style="text-align: right;"><ion-icon name="people" color="primary" style="width: 22px; height: 22px; margin-top: -4px;"></ion-icon></ion-col>
                    <ion-col size="8" style="padding-left: 12px;"><strong>{{vendorUserData.numero_clienti}}</strong> clienti</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" *ngIf="vendorUserData.verificato" style="padding-right: 18px; padding-bottom: 18px;">
                <!--<ion-text style="font-size: 12px; float: right;" color="primary">Venditore verificato</ion-text> OLD -->
                <ion-text color="darksea" style="float: right; padding-top: 4px;" class="vendor-city-data">Venditore verificato</ion-text>
                <ion-icon name="checkmark-circle" color="darksea" style="width: 25px; height: 25px; float: right;"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-fab vertical="top" horizontal="end" *ngIf="myProfile && !errorMsg">
      <ion-fab-button color="primary" title="Modifica i tuoi dati" routerLink="/myprofile/vendor/edit" style="margin-top: 12px; margin-right: 8px;">
        <ion-icon name="pencil-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-card>
    <!-- Griglia dedicata alla descrizione -->
    <ion-grid *ngIf="!isFetchingVendorData && !errorMsg" style="margin: auto;" class="vendor-card">
      <ion-row *ngIf="myProfile" style="padding: 0px;">
        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: center; padding: 0px; margin-top: 8px;">
          <ion-badge color="primary" (click)="toReviews()" style="width: 110px; cursor: pointer;">
            Recensioni
          </ion-badge>
        </ion-col>

        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: center; padding: 0px; margin-top: 8px;">
          <ion-badge color="primary" (click)="toAddNewProduct()" style="width: 110px; cursor: pointer;">
            Nuovo prodotto
          </ion-badge>
        </ion-col>

        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: center; padding: 0px; margin-top: 8px;">
          <ion-badge color="primary" (click)="toOrders()" style="width: 110px; cursor: pointer;" *ngIf="!platform.is('ios')">
            Ordini
          </ion-badge>
          <ion-badge class="notifications-badge" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && !platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
          <ion-badge color="primary" (click)="toOrders()" style="width: 110px; cursor: pointer;" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && platform.is('ios')">
            {{vendorOrdersService.ordersCounter}} Ordini
          </ion-badge>
          <ion-badge color="primary" (click)="toOrders()" style="width: 110px; cursor: pointer;" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter==0 && platform.is('ios')">
            Ordini
          </ion-badge>
        </ion-col>

      </ion-row>
    </ion-grid>
    <ion-grid *ngIf="!isFetchingVendorData && !errorMsg" style="margin: auto;" class="vendor-card">

      <ion-row>
        <ion-col size="12" size="12" style="height: 48px;">
          <div class="descrizione">
            <ion-text><strong>Descrizione</strong></ion-text>
            <ion-button color="primary" clear icon-only shape="round" class="right" (click)="onFocusDescrizione()" 
            *ngIf="!this.platform.is('ios') && !focusDescrizione">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right-ios" (click)="onFocusDescrizione()" 
            *ngIf="this.platform.is('ios') && !focusDescrizione">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right" (click)="onReduceDescrizione()" 
            *ngIf="!this.platform.is('ios') && focusDescrizione">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right-ios" (click)="onReduceDescrizione()" 
            *ngIf="this.platform.is('ios') && focusDescrizione">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
        <ion-col size="12" *ngIf="vendorUserData.descrizione && focusDescrizione" style="padding: 9px;">
          <ion-text>{{vendorUserData.descrizione}}</ion-text>
        </ion-col>
        <ion-col size="12" *ngIf="!vendorUserData.descrizione && focusDescrizione" style="padding: 9px;">
          <ion-text>Nessuna descrizione per questo Venditore</ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size="12" style="height: 48px;">
          <div class="descrizione">
            <ion-text><strong>Biografia</strong></ion-text>
            <ion-button color="primary" clear icon-only shape="round" class="right" (click)="onFocusBiografia()" 
            *ngIf="!this.platform.is('ios') && !focusBiografia">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right-ios" (click)="onFocusBiografia()" 
            *ngIf="this.platform.is('ios') && !focusBiografia">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right" (click)="onReduceBiografia()" 
            *ngIf="!this.platform.is('ios') && focusBiografia">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </ion-button>
            <ion-button color="primary" clear icon-only shape="round" class="right-ios" (click)="onReduceBiografia()" 
            *ngIf="this.platform.is('ios') && focusBiografia">
              <ion-icon name="chevron-up-outline"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
        <ion-col size="12" *ngIf="vendorUserData.biografia && focusBiografia" style="padding: 9px;">
          <ion-text>{{vendorUserData.biografia}}</ion-text>
        </ion-col>
        <ion-col size="12" *ngIf="!vendorUserData.biografia && focusBiografia" style="padding: 9px;">
          <ion-text>Nessuna biografia per questo Venditore</ion-text>
        </ion-col>
      </ion-row>
      
    
    </ion-grid>

  <!-- VENDOR PRODUCTS -->
  <!-- Griglia dedicata ai prodotti -->
  <ion-grid *ngIf="hasProdotti" style="margin: auto;" class="vendor-card">
    <ion-row>
      <ion-col size="12" style="margin-top: 12px; height: 32px;">
        <ion-text><strong>Prodotti</strong></ion-text>
      </ion-col>
    </ion-row>
    <ion-row style="padding: 0px;">
      <ion-col size="12" style="padding: 0px;">
        <app-griglia-prodotti *ngIf="hasProdotti"
          [parentComponent]="this" [prodotti]="products" [myVendorProfile]="isMyProfile()"></app-griglia-prodotti>
      </ion-col>
    </ion-row>
  </ion-grid>
    
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" *ngIf="hasProdotti && !errorMsg" style="margin: auto;">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  
  <!-- *ngIf="!isFetchingVendorProducts && !hasProducts()" -->
  <div class="vendor-products-area" *ngIf="errorMsg || (!isFetchingVendorProducts && !hasProducts())" style="margin-top: 24px;">
    <ion-grid>
      <ion-row>
        <ion-col 
          size-xs="8" size-sm="6" size-md="4" size-lg="4" size-xl="4"
          offset-xs="2" offset-sm="3" offset-md="4" offset-lg="4" offset-xl="4">

          <img *ngIf="!errorMsg" src="assets/images/no-vendor-products.svg" class="error-resize-products">

          <img *ngIf="errorMsg" src="assets/error/cavolo.jpg" class="error-resize-products">
        </ion-col>

        <ion-col
          size-xs="10" size-sm="6" size-md="4" size-lg="4" size-xl="4"
          offset-xs="1" offset-sm="3" offset-md="4" offset-lg="4" offset-xl="4">
          <div *ngIf="!errorMsg">
            <p>Nessun prodotto trovato.</p>
            <p *ngIf="!isMyProfile()">
              Siamo spiacenti, ma il catalogo di questo Venditore è vuoto per ora.
            </p>
            <p *ngIf="isMyProfile()">
              Il tuo catalogo è vuoto, inizia a pubblicare i tuoi prodotti!
            </p>
          </div>

          <div *ngIf="errorMsg">
            <p class="product-title"> Cavolo, qualcosa è andato storto! </p>
            <p class="product-title"> Verifica la tua connessione e riprova </p>
            <ion-fab horizontal="center" slot="fixed" class="retry-products">
              <ion-fab-button color="primary" (click)="onReloadProfile()">
                <ion-icon name="repeat-sharp"></ion-icon>
              </ion-fab-button>
            </ion-fab>
          </div>

        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  </div>

  <p style="max-width: 750px; margin: auto;  margin-bottom: 16px;" *ngIf="!myProfile">
    <ion-text style="text-align: right; color: var(--ion-color-danger); cursor: pointer; margin-left: 32px; margin-top: 6px; font-size: 14px;" (click)="onSignal()">Segnala
      <ion-icon name="alert-circle" color="danger" style="cursor: pointer; height: 30px; width: 30px; margin-bottom: -10px;" (click)="onSignal()"></ion-icon>
    </ion-text>
  </p>
  
</ion-content>

<ion-footer style="max-width: 750px; margin: auto;">
  <ion-fab horizontal="end" *ngIf="myProfile && !errorMsg">
    <ion-fab-button color="primary" title="Aggiungi un nuovo Prodotto" (click)="toAddNewProduct()" style="margin-top: -72px; margin-right: 8px;">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-footer>