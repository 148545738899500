import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { BuyerOrderComponent } from './buyer-order/buyer-order.component';
import { VendorOrderComponent } from './vendor-order/vendor-order.component';
import { UserOrderService } from '../shared/services/user-orders.service';
import { BuyerUserRequiredGuard } from '../shared/buyer-user.guard';
import { VendorUserRequiredGuard } from '../shared/vendor-user.guard';
import { VendorOrderItemShippingComponent} from './vendor-order/vendor-order-item-shipping/vendor-order-item-shipping.component';
import { ShippingPopover } from './buyer-order/shipping-popover/shipping-popover.component';
import { VendorShippingInstructionsPopover } from './vendor-order/vendor-shipping-instructions/vendor-shipping-instructions.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: 'order/buyer-order/:orderId', /*canActivate: [BuyerUserRequiredGuard],*/ component: BuyerOrderComponent},
      { path: 'order/vendor-order', /*canActivate: [VendorUserRequiredGuard],*/ component: VendorOrderComponent}
    ])
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
      UserOrderService
    ],
  declarations: [
    BuyerOrderComponent,
    VendorOrderComponent,
    VendorOrderItemShippingComponent,
    ShippingPopover,
    VendorShippingInstructionsPopover
  ]
})
export class OrderDetailModule {}