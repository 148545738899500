<ion-card *ngIf="!isFetching && prodottiToBeShown && prodottiToBeShown.length>0" class="main"
  [ngClass]="{'products-section-title': this.sezione.tipo === 'products-query', 'category-section-title': this.sezione.tipo === 'category-products'}">
  <ion-card-header style="background-image: linear-gradient(to bottom right, #26a96c, #57cc99);">
    <ion-grid style="padding: 0px; margin: 0px">
      <ion-row style="padding: 0px; margin: 0px">
        <ion-col size="10" style="padding: 0px; margin: 0px">
          <ion-card-title style="color: #ffffff; margin-top: 4px; font-size: 20px;">
            <ion-text style="color: #ffffff;"><strong>{{sezione.nome}}</strong></ion-text>
          </ion-card-title>
        </ion-col>
        <ion-col size="2" style="padding: 0px; margin: 0px">
          <ion-button style="--color: #ffffff; --background: #ffffff;" class="section-btn" *ngIf="!this.platform.is('ios')"
          icon-only shape="round" (click)="toPaginaProdotti()">
            <ion-icon name="chevron-forward-outline" style="color: var(--ion-color-primary);"></ion-icon>
          </ion-button>
          <ion-button style="--color: #ffffff; --background: #ffffff;" class="section-btn-ios" *ngIf="this.platform.is('ios')"
          icon-only shape="round" (click)="toPaginaProdotti()">
            <ion-icon name="chevron-forward-outline" style="color: var(--ion-color-primary);"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- <ion-card-subtitle (click)="toPaginaProdotti()">Scopri di più</ion-card-subtitle> -->
  </ion-card-header>

  <ion-spinner color="darksea" name="bubbles" *ngIf="isFetching"></ion-spinner>
  <app-griglia-prodotti *ngIf="!isFetching && prodottiToBeShown" [prodotti]="prodottiToBeShown" [swipeDisabled]=true 
  [name]=sezione.nome [url]=sezione.url></app-griglia-prodotti>
</ion-card>

<ion-card *ngIf="!isFetching && categorie && categorie.length>0" class="main">
  <ion-card-header style="background-image: linear-gradient(to bottom right, #26a96c, #57cc99);">
    <ion-grid style="padding: 0px; margin: 0px">
      <ion-row style="padding: 0px; margin: 0px">
        <ion-col size="10" style="padding: 0px; margin: 0px">
          <ion-card-title style="color: #ffffff; margin-top: 4px; font-size: 20px;">
            <ion-text style="color: #ffffff;"><strong>{{sezione.nome}}</strong></ion-text>
          </ion-card-title>
        </ion-col>
        <ion-col size="2" style="padding: 0px; margin: 0px">
          <ion-button style="--color: #ffffff; --background: #ffffff;" class="section-btn" *ngIf="!this.platform.is('ios')"
          icon-only shape="round" (click)="toCategoryPage()">
            <ion-icon name="chevron-forward-outline" style="color: var(--ion-color-primary);"></ion-icon>
          </ion-button>
          <ion-button style="--color: #ffffff; --background: #ffffff;" class="section-btn-ios" *ngIf="this.platform.is('ios')"
          icon-only shape="round" (click)="toCategoryPage()">
            <ion-icon name="chevron-forward-outline" style="color: var(--ion-color-primary);"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-header>

  <ion-spinner color="darksea" name="bubbles" *ngIf="isFetching"></ion-spinner>

  <ion-grid>
    <ion-row>
      <ion-col size-xs="6" size-sm="6" size-xl="3" size-lg="3" size-md="4" *ngFor="let c of categorie; let i = index" style="padding: 0px; padding-bottom: 10px;">
        <ion-card class="data-cat" (click)="onSelectCategory(c['id'],c['nome'])" *ngIf="i<four_col_categories && innerWidth>=SCREEN_SIZE_LG_XL">
          <img src="{{ constantUtilsService.getFullURL(c['immagine']) }}" onerror="this.src='assets/error/verde.jpg'" class="img-resize"/>
          <div class="card-title"> {{ (c['nome'].length > 22)? (c['nome'] | slice:0:22)+'..':(c['nome']) }}</div>
        </ion-card>
        <ion-card class="data-cat" (click)="onSelectCategory(c['id'],c['nome'])" *ngIf="i<three_col_categories && innerWidth>=SCREEN_SIZE_MD && innerWidth<SCREEN_SIZE_LG_XL">
          <img src="{{ constantUtilsService.getFullURL(c['immagine']) }}" onerror="this.src='assets/error/verde.jpg'" class="img-resize"/>
          <div class="card-title"> {{ (c['nome'].length > 22)? (c['nome'] | slice:0:22)+'..':(c['nome']) }}</div>
        </ion-card>
        <ion-card class="data-cat" (click)="onSelectCategory(c['id'],c['nome'])" *ngIf="i<two_col_categories && innerWidth>=SCREEN_SIZE_XS_SM && innerWidth<SCREEN_SIZE_MD">
          <img src="{{ constantUtilsService.getFullURL(c['immagine']) }}" onerror="this.src='assets/error/verde.jpg'" class="img-resize"/>
          <div class="card-title"> {{ (c['nome'].length > 22)? (c['nome'] | slice:0:22)+'..':(c['nome']) }}</div>
        </ion-card>
        <ion-card class="data-cat" (click)="onSelectCategory(c['id'],c['nome'])" *ngIf="i<two_col_categories && innerWidth<SCREEN_SIZE_XS_SM">
          <img src="{{ constantUtilsService.getFullURL(c['immagine']) }}" onerror="this.src='assets/error/verde.jpg'" class="img-resize"/>
          <div class="card-title"> {{ (c['nome'].length > 22)? (c['nome'] | slice:0:22)+'..':(c['nome']) }}</div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-card>