import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
/*declare var Stripe;
import { BuyersOrdersSessionResponse } from 'src/app/shared/models/BuyersOrdersSession.models';*/
import { Position } from 'src/app/shared/models/Position.models';
import { BuyersOrdersService } from 'src/app/shared/services/buyers-orders.service';
import { UserPositionsService } from 'src/app/shared/services/user-positions.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, IonCheckbox, IonSelect, Platform } from '@ionic/angular';
import { UserService } from 'src/app/shared/services/User.service';
import { UserProfile } from 'src/app/shared/models/UserProfile.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NumberPrefixService } from 'src/app/shared/services/numberPrefix.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnInit {

  /*stripe;
  pub_key: string = null;*/
  @ViewChild('fattura') checkboxFattura: IonCheckbox;
  @ViewChild('termini') checkboxTerminiCondizioni: IonCheckbox;
  @ViewChild('prefixSelect') prefixSelect: IonSelect;

  navParams = undefined;
  title: string = 'Checkout';
  private from: string;
  cart: any[];
  isFetchingAddress: boolean = false;
  type: string = 'user';  // Constant for checkout page -> only a -user- can buy something
  hasPositions: boolean = false;
  positions: Position[];
  focusRiepilogo: boolean = true;
  focusIndirizzi: boolean = false;
  focusPagamento: boolean = false;
  paymentLoading: boolean = false;
  CFMandatory: boolean = false;
  terminiCondizioniFlagged: boolean = false;
  goingToDonation: boolean = false;

  settingsChanged: boolean;
  private initialSettings: number;
  currentSettings: number;
  currentIndex: number;

  userData: UserProfile;
  editClientDataForm: FormGroup;
  private prefisso: string;
  private phone: string;
  private telefono: string = "";   // Var usata solo per salvare il telefono durante i vari step di checkout
  private codiceFiscale: string = "";   // Var usata solo per salvare il CF durante i vari step di checkout
  private email: string;
  private first_name: string;
  private last_name: string;
  private note: string;
  private codFis: string;

  total: number = 0;
  trasporto_total: number = 0;
  private donazione_totale: number = 0;
  private QUOTA_DONAZIONE: number = 0.05;
  private IVA_ARRAY = {
    "1.00": 0.00,
    "1.04": 0.04,
    "1.05": 0.05,
    "1.10": 0.10,
    "1.22": 0.22
  }

  back: string = 'shopping-cart';
  isFetchingItems: boolean = true;

  prefixChoice: string = '+39';
  index: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private buyersOrders: BuyersOrdersService,
    private userPositionsService: UserPositionsService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private userService: UserService,
    private authService: AuthService,
    private inAppBrowser: InAppBrowser,
    private cDetRef: ChangeDetectorRef,
    public platform: Platform,
    public prefixService: NumberPrefixService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.logger.log('CheckoutPageComponent: ngOnInit started')

    this.activatedRoute.queryParams.subscribe(params => {
      // tutta la parte di elaborazione dei parametri è stata sposta al di fuori di qui 
      // per mantenere questa parte asincrona il più breve possibile (e per evitare problemi di lifecycle - maggiori dettagli di seguito).
      // Simone: OK, perfetto
      this.navParams = this.router.getCurrentNavigation().extras.state;
    })

    // Initialyzing the last form of the checkout page
    this.initForm(null, null, null, null);
  }

  totalReceiver($event){
    this.logger.log('CheckoutPageComponent: ricevuto il nuovo totale: '+$event);
    /*this.total = $event;
    this.cDetRef.detectChanges();*/
  }

  trasportoReceiver($event){
    this.logger.log('CheckoutPageComponent: ricevuto il nuovo totale trasporto: '+$event);
    /*this.trasporto_total = $event;
    this.cDetRef.detectChanges();*/
  }

  ionViewWillEnter() {
    this.logger.log('CheckoutPageComponent: ionViewWillEnter started');
    
    // Questa parte è stata spostata qui perché, essendo asincrona, mandava in tilt il lifecycle di Angular.
    // In particolare, siccome asicrona, non interrompeva il progredire del lifecycle, e quindi, in caso di errore nel prendere i navParams,
    // questo component continuava ad esistere anche nel caso in cui facevamo redirect verso la homepage, 
    // con conseguente "sfarfallio" delle pagine negli istanti successivi ad un refresh.   // Simone: OK, perfetto
    this.logger.log('CheckoutPageComponent: navigation params: '+this.navParams);
    if (this.navParams) {
      if(this.navParams.from=='shopping-cart'){
        this.from = this.navParams.from;
        this.logger.log('CheckoutPageComponent: from = '+this.from);
        //this.logger.log('CheckoutPageComponent: shopping-cart got from previous page = '+this.navParams.cart); // DEBUG
        this.cart = JSON.parse(this.navParams.cart);
        this.total = this.cart[0].costo;
        this.donazione_totale = 0;
        var subtotal = 0;
        for(let c of this.cart){
          subtotal = subtotal + (c['prezzo_vetrina']*c['quantità']);
          const prezzo_cad = c['prezzo_vetrina'] / (1 + this.IVA_ARRAY[c['prodotto']['categoria_iva']]);
          this.donazione_totale = this.donazione_totale + prezzo_cad*c['quantità']; // *this.QUOTA_DONAZIONE // Non serve! -> i punti foglia sono interi! Ognuno vale 0.05 ma questo rimane solo lato BE o mostrato nel caso dei progetti sociali
        }
        this.trasporto_total = this.roundTo(this.total - subtotal,1);

        this.logger.log('Trasporto = '+this.trasporto_total);
        this.logger.log('Subtotal = '+subtotal);
        this.logger.log('Total = '+this.total);
        this.logger.log('Donazione totale = '+this.donazione_totale);

        this.isFetchingItems = false;
        
        // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
        // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
        if(this.authService.hasToRefreshBasicAuth()){
          this.authService.refreshBasicAuthToken().then(
            go => {
              this.getAddressList();
            })
        }else{
          // ..Altrimenti chiedo i dati senza eseguire il refresh
          this.getAddressList();
        }
        
      }else if(this.navParams.from=='vendor'){
        this.from = this.navParams.from;
        this.logger.log('CheckoutPageComponent: from = '+this.from);
        this.router.navigate(['home']);
      }else{
        this.logger.log('CheckoutPageComponent: from NOT SUPPORTED .... redirecting home');
        this.router.navigateByUrl("/")
      }
    }else{
      this.logger.log('CheckoutPageComponent: NO FROM param .... resuming the page?');
      if(!this.from || !this.cart){
        this.logger.log('CheckoutPageComponent: CARRELLO VUOTO o NON VALIDO -> torno alla Home');
        this.router.navigate(['home']);
      }
    }

    this.onChangeSelectPrefix();
  }

  getAddressList(){
    if(!this.cart || this.isFetchingAddress){
      return;
    }
    this.isFetchingAddress = true;
    this.userPositionsService.fetchPositionsByURL(this.type).subscribe(
      responseData => {
        this.logger.log('CheckoutPageComponent: retrieved something');
        this.positions = <Position[]>responseData;
        
        if(this.positions.length > 0){
          this.hasPositions = true;
        }
        var i = 0;
        for(let p of this.positions){
          if(p && p.preferito){
            this.initialSettings = p.id.valueOf();
            this.logger.log('CheckoutPageComponent: initialSettings = '+this.initialSettings);
          }
          i = i + 1;
        }
        this.isFetchingAddress = false;
      },
      error => {
        this.logger.log('An error occurred -> '+error);
        this.hasPositions = false;
        this.isFetchingAddress = false;
      })
  }

  ionViewWillLeave(){
    this.logger.log('CheckoutPageComponent: ionViewWillLeave()');
    if(this.currentSettings==null){
      this.currentSettings = this.initialSettings;
    }
    this.logger.log('CheckoutPageComponent: ionViewWillLeave() settingsChanged = '+this.settingsChanged);
    this.logger.log('CheckoutPageComponent: ionViewWillLeave() currentSettings = '+this.currentSettings);
    this.logger.log('CheckoutPageComponent: ionViewWillLeave() initialSettings = '+this.initialSettings);
    if(this.settingsChanged && this.initialSettings!=null && this.initialSettings!=this.currentSettings && this.currentIndex!=null){
      this.logger.log('CheckoutPageComponent: ionViewWillLeave() aggiornamento indirizzi');
      // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
      // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
      if(this.authService.hasToRefreshBasicAuth()){
        this.authService.refreshBasicAuthToken().then(
          go => {
            this.creatingPositionPUT();
          })
      }else{
        // ..Altrimenti chiedo i dati senza eseguire il refresh
        this.creatingPositionPUT();
      }
    }
  }

  private creatingPositionPUT(){
    var newItem = { indirizzo: this.positions[this.currentIndex].indirizzo,
      civico: this.positions[this.currentIndex].civico,
      cap: this.positions[this.currentIndex].cap,
      paese: this.positions[this.currentIndex].paese,
      città: this.positions[this.currentIndex].città,
      preferito: this.positions[this.currentIndex].preferito,
      provincia: this.positions[this.currentIndex].provincia,
      regione: this.positions[this.currentIndex].regione,
      id: this.positions[this.currentIndex].id }
    this.userPositionsService.createPositionPut(newItem, this.type).subscribe(
      res => {
        this.logger.log('CheckoutPageComponent: Aggiornamento (PUT) ritorna: '+res);
        var newPos = { indirizzo: newItem.indirizzo, civico: newItem.civico, cap: newItem.cap, paese: newItem.paese, città: newItem.città,
          preferito: newItem.preferito, provincia: newItem.provincia, regione: newItem.regione, id: newItem.id,
          latitudine: null, longitudine: null
        } // lat e long inserite nulle solo per non creare un nuovo oggetto
        this.positions.push(newPos);
      },
      error => {
        this.logger.log('CheckoutPageComponent: Aggiornamento (PUT) in errore: '+error);
      }
    );
  }

  onIDChanges($event){
    this.logger.log('CheckoutPageComponent: onIDChanges() called');
    this.settingsChanged = true;
    this.logger.log('CheckoutPageComponent: currentSettings = '+$event);
    this.currentSettings = $event;
    this.onIndexChanges(null);
  }

  onIDRemoved($event){
    this.logger.log('CheckoutPageComponent: onIDRemoved() called with event = '+$event);
    this.addressRemover($event);
    //this.debugAddresses();
  }

  onIndexChanges($event){
    this.logger.log('CheckoutPageComponent: onIndexChanges() called');
    if($event!=null){
      this.currentIndex = $event;
    }
    this.logger.log('CheckoutPageComponent: currentIndex = '+this.currentIndex);
  }

  addressRemover($event){
    this.logger.log('CheckoutPageComponent: addressRemover() called');
    var temp_array = [];
    var k = 0;
    for(let x of this.positions){
      if(k!=$event){
        temp_array[k]=this.positions[k];
      }else{
        this.logger.log('CheckoutPageComponent: eliminato il numero '+$event+' anche dalla lista locale');
      }
      k = k + 1;
      }
      this.positions = temp_array;
  }

  onAdd(){
    this.logger.log('CheckoutPageComponent: onAdd()');
    var url = "/myprofile/"+this.type+"/shipping-address/edit/new";
    let navigationExtras: NavigationExtras = {
      state: {
        type: 'checkout',
        cart: JSON.stringify(this.cart)
      }
    };
    this.router.navigate([url], navigationExtras); 
  }

  onReduceIndirizzo(){
    this.logger.log('CheckoutPageComponent: onReduceIndirizzo()');
    this.focusIndirizzi = false;
  }

  onReduceRiepilogo(){
    this.logger.log('CheckoutPageComponent: onReduceRiepilogo()');
    this.focusRiepilogo = false;
  }

  onReducePagamento(){
    this.logger.log('CheckoutPageComponent: onReducePagamento()');
    this.focusPagamento = false;
  }

  onFocusPagamento(){
    this.logger.log('CheckoutPageComponent: onFocusPagamento()');
    this.focusPagamento = true;
    this.focusIndirizzi = false;
    this.focusRiepilogo = false;
  }

  onFocusIndirizzo(){
    this.logger.log('CheckoutPageComponent: onFocusIndirizzo()');
    this.focusPagamento = false;
    this.focusIndirizzi = true;
    this.focusRiepilogo = false;
  }

  onFocusRiepilogo(){
    this.logger.log('CheckoutPageComponent: onFocusRiepilogo()');
    this.focusPagamento = false;
    this.focusIndirizzi = false;
    this.focusRiepilogo = true;
  }

  toPayment(){
    this.logger.log('CheckoutPageComponent: toPayment()');
    if(this.checkPaymentConditions()){
      this.launchCheckoutDonation();  // V3
    }
  }

  launchCheckoutDonation(){
    this.logger.log('CheckoutPageComponent: launchCheckoutDonation called');
    if(this.currentSettings==null){
      this.currentSettings = this.initialSettings;
    }
    let navigationExtras: NavigationExtras = { state: { 
      first_name: this.first_name,
      last_name: this.last_name,
      address: this.currentSettings,  // L'ordine viene creato con l'ID della posizione
      codFis: this.codFis,
      bo: null,
      email: this.email,
      prefisso: this.prefisso,
      phone: this.phone, 
      note: this.note,
      prezzo: this.total,
      donazione_totale: this.donazione_totale
     } };
    this.router.navigate(['checkout/earth-donation'], navigationExtras);
  }


  debugAddresses(){
    for(let x of this.positions){
      if(x!=null){
        this.logger.log('CheckoutPageComponent: addr con id'+x.id);
      }
    }
  }

  ionViewDidEnter(){
    this.logger.log('CheckoutPageComponent: ionViewDidEnter()');

    if(!this.authService.isLoggedIn()){
      this.redirectHome();
    }else{
      this.userData = this.userService.userData;
      this.initForm(this.userData.first_name, this.userData.last_name, this.userData.email, null);
      if(!this.userData){
        this.redirectToShoppingCart();
      }else{
        // Updating the address list for the first time
        // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
        // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
        if(this.authService.hasToRefreshBasicAuth()){
          this.authService.refreshBasicAuthToken().then(
            go => {
              this.getAddressList();
            })
        }else{
          // ..Altrimenti chiedo i dati senza eseguire il refresh
          this.getAddressList();
        }
      }
    }
  }

  redirectHome(){
    this.logger.log('CheckoutPageComponent: redirectHome called');
    this.logger.log('CheckoutPageComponent: checkout page called with user not logged in');
    this.router.navigate(['home']);
  }

  redirectToShoppingCart(){
    this.logger.log('CheckoutPageComponent: redirectToShoppingCart called');
    this.logger.log('CheckoutPageComponent: checkout page did not get user data...');
    this.router.navigate(['shopping-cart']);
  }

  saveNumber(){
    this.telefono = this.editClientDataForm.value.Numero;
    this.logger.log('CheckoutPageComponent: saveNumber() ha salvato il numero '+this.telefono);
  }

  saveCF(){
    this.codiceFiscale = this.editClientDataForm.value.CodiceFiscale;
    this.logger.log('CheckoutPageComponent: saveCF() ha salvato il CF '+this.codiceFiscale);
  }

  initForm(nome: string, cognome: string, email: string, numero: string){
    if(nome==null){
      nome = ""
    }
    if(cognome==null){
      cognome = ""
    }
    if(email==null){
      email = ""
    }
    if(numero==null && this.telefono==null){
      this.telefono = "";
    }else if(numero!=null && numero!=""){
      this.telefono = numero;
    }
    this.editClientDataForm = this.formBuilder.group({
      Nome: [nome, Validators.compose([Validators.maxLength(50)])],
      Cognome: [cognome, Validators.compose([Validators.maxLength(50)])],
      CodiceFiscale: [this.codiceFiscale, Validators.compose([Validators.maxLength(50), 
        Validators.pattern("^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$")])],
      Email: [email, Validators.compose([Validators.required, Validators.maxLength(50), 
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      Numero: [this.telefono, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(12),
        Validators.pattern('^3[0-9]{2}[0-9]{6,7}$')])],
      Note: ["", Validators.compose([Validators.maxLength(100)])]
    })
  }

  checkPaymentConditions(){
    this.logger.log('CheckoutPage: checkPaymentConditions called...');
    var result = true;
    this.goingToDonation = true;

    if(!this.hasPositions){
      result = false;
      this.checkoutErrorAlert('Errore nella sezione: Indirizzi', 'Nessun indirizzo selezionato nella sezione Indirizzi');
    }

    this.logger.log(this.checkboxTerminiCondizioni)

    if(!this.terminiCondizioniFlagged){
      this.checkoutErrorAlert('Errore nella sezione: Pagamento', 'Conferma di accettare i nostri Termini e Condizioni e riprova!');
      return false;
    }

    let addressFormValue = this.editClientDataForm.value;  
    this.logger.log("Confirm address user data edit - Start", addressFormValue);

    // Necessario convertire in formData per far funzionare la form
    const clientFormData = new FormData();
    if(!this.editClientDataForm.valid){
      result = false;
      if(this.CFMandatory && !this.editClientDataForm.get("CodiceFiscale").valid){
        this.checkoutErrorAlert('Errore nella sezione: Pagamento', 'Codice Fiscale inserito non valido');
      }else{
        this.checkoutErrorAlert('Errore nella sezione: Pagamento', 'Dati di fatturazione mancanti nella sezione \'Informazioni di Fatturazione\'');
      }
    }else{
      if(this.CFMandatory && this.editClientDataForm.get("CodiceFiscale").value==""){
        this.checkoutErrorAlert('Errore nella sezione: Pagamento', 'Codice Fiscale inserito non valido');
        return false;
      }
      for (let fieldName in addressFormValue) {
        clientFormData.append(fieldName, this.editClientDataForm.get(fieldName).value);
      }
      this.first_name = clientFormData.get('Nome').toString();
      this.last_name = clientFormData.get('Cognome').toString();
      this.prefisso = this.prefixChoice;
      this.logger.log('CheckoutPage: prefisso = '+this.prefisso);  // DEBUG
      this.phone = clientFormData.get('Numero').toString();
      this.logger.log('CheckoutPage: phone = '+this.phone);    // DEBUG
      this.email = clientFormData.get('Email').toString();
      this.note = clientFormData.get('Note').toString();
      this.codFis = clientFormData.get('CodiceFiscale').toString();
    }

    this.logger.log('CheckoutPage: checkPaymentConditions ended with result = '+result);
    return result;
  }

  fatturaIsChanged(){
    this.logger.log('CheckoutPage: fattura flaggato o sflaggato -> '+this.checkboxFattura.checked);
    if(this.checkboxFattura.checked){
      this.CFMandatory = true;
    }else{
      this.CFMandatory = false;
    }
  }

  terminiCondizioniIsChanged(){
    this.logger.log('CheckoutPage: termini e condizioni flaggato o sflaggato -> '+this.checkboxTerminiCondizioni.checked);
    if(this.checkboxTerminiCondizioni.checked){
      this.terminiCondizioniFlagged = true;
    }else{
      this.terminiCondizioniFlagged = false;
    }
  }

  toTerminiCondizioni(){
    window.location.href = "https://www.iubenda.com/termini-e-condizioni/10266349";
  }

  round(num: number){
    if(num>=10){
      // Se il numero è 10 o maggiore di 10 allora approssimo agli interi
      return this.roundTo(num,0);
    }else if(num>0 && num<10){
      // Se il numero è minore di 10 allora approssimo alla prima cifra decimale 
      return this.roundTo(num,1);
    }else{
      // Se è minore o uguale a 0 allora ritorno 0
      return 0;
    }
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

  async checkoutErrorAlert(header: string, text: string){
    if(!text || !header){
      header = 'Errore'
      text = 'Ricontrolla i dati inseriti e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Checkout',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  toPolicy(){
    this.logger.log('CheckoutPage: toPolicy()');
    this.router.navigate(['shipping-policy']);
  }

  onChangeSelectPrefix(){
    if(this.prefixSelect){
      if(this.prefixSelect.value){
        this.prefixChoice = this.prefixSelect.value;
      }else{
        this.prefixChoice = '+39';
      }
      this.logger.log('CheckoutPage: Nuova scelta prefisso: '+this.prefixChoice);
      this.prefixSelect.selectedText = this.prefixChoice;
      if(this.prefixChoice=='+39'){
        this.editClientDataForm.get('Numero').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(12),
          Validators.pattern('^3[0-9]{2}[0-9]{6,7}$')]);
      }else{
        this.editClientDataForm.get('Numero').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(12)]);
      }
      // Utile per il recheck dopo il cambio di validators
      this.prefixSelect.selectedText = this.prefixChoice;
    }
  }

}
