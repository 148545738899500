import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { NewPosition, Position, PositionUpdate } from '../models/Position.models';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class UserPositionsService { 

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private vendorUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendor-positions/');
  private buyerUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyer-positions/');
  error = new Subject<string>();


  fetchPositionsByURL(type: string){
    if(type=='user'){
      this.logger.log('UserPositionService: USER type');
      return this.fetchPositions(null, this.buyerUrl);
    }else if(type=='vendor'){
      this.logger.log('UserPositionService: VENDOR type');
      return this.fetchPositions(null, this.vendorUrl);
    }else{
      this.logger.log('UserPositionService: NOT MANAGED type');
    }
  }

  private fetchPositions(searchParams, url){
    this.logger.log(url);
    this.logger.log('..fetching positions');
    return this.http.get<Position[]>(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        this.logger.log(responseData)
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        this.logger.log(errorRes);
        return throwError(errorRes);
      })
    );
  }

  deleteAddress(id: number, type: string){
    if(type=='user'){
      return this.deleteRequest(this.buyerUrl+id);
    }else if(type=='vendor'){
      return this.deleteRequest(this.vendorUrl+id);
    }else{
      this.logger.log('UserPositionService: NOT MANAGED type');
    }
  }

  // HTTP DELETE
  private deleteRequest(url: string) {
    return this.http
      .delete(url, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }

  /*fetchPositionsByUrlAndParams(params){
    return this.fetchPositions(params);
  }*/

  createPositionsPost(newItems: NewPosition[], type: string){
    if(type=='user'){
      return this.sendPositionsPost(newItems, this.buyerUrl);
    }else if(type=='vendor'){
      return this.sendPositionsPost(newItems, this.vendorUrl);
    }else{
      this.logger.log('UserPositionService: NOT MANAGED type');
    } 
  }

  createPositionPost(newItem: NewPosition, type: string){
    if(type=='user'){
      return this.sendPositionPost(newItem, this.buyerUrl);   
    }else if(type=='vendor'){
      return this.sendPositionPost(newItem, this.vendorUrl);   
    }else{
      this.logger.log('UserPositionService: NOT MANAGED type');
    }
  }

  createPositionPut(newItem: PositionUpdate, type: string){
    if(type=='user'){
      return this.sendPositionPut(newItem, this.buyerUrl+newItem.id.valueOf()+'/');  
    }else if(type=='vendor'){
      return this.sendPositionPut(newItem, this.vendorUrl+newItem.id.valueOf()+'/');  
    }else{
      this.logger.log('UserPositionService: NOT MANAGED type');
    }
  }

  private sendPositionPut(newItem: PositionUpdate, url: string) {
    return this.http
      .put<{res: string}>(
        url,
        newItem,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  private sendPositionsPost(newItems: NewPosition[], url: string) {   
    return this.http
      .post<{res: string}>(
        url,
        newItems,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  private sendPositionPost(newItem: NewPosition, url: string) {   
    return this.http
      .post<{res: string}>(
        url,
        newItem,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

}
