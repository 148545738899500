<ion-card class="card-aspect" style="margin: 0px;">
    <ion-button style="float: right;" fill="clear" (click)="close()">
        <ion-icon name="close-circle" color="danger"></ion-icon>
    </ion-button>
    <img src="{{ basePath + animalPicture }}" onerror="this.src='assets/icon/animal/kiwi.JPG'" class="resize" style="margin-right: -60px;">
    <ion-card-header>
        <!--<ion-card-subtitle>Animale in via di estinzione</ion-card-subtitle>-->
        <ion-card-title color="primary"><strong>{{ animalName }}</strong></ion-card-title>
      </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-grid style="padding: 0px;">
                    <ion-row style="padding: 0px;">
                        <ion-col size-xs="7" size-sm="7" size-md="7" size-lg="7" size-xl="7" style="padding: 0px;">
                            <ion-progress-bar value="{{ progressValue }}" class="progress-bar-aspect"></ion-progress-bar>
                        </ion-col>
                        <ion-col size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" style="padding: 0px;">
                            <p>{{ progressValueUnbounded }}/{{ progressBound }}</p>
                            <img src="assets/icon/logo_secondary_green.png" class="logo-pfoglia">
                        </ion-col>
                    </ion-row>
                    <ion-row style="padding: 0px;">
                        <ion-col size="12" style="padding: 0px; text-align: start;">
                            <p style="font-size: 12px;"><ion-text color="secondary">Livello {{animalIndex+1}}</ion-text></p>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>
            <ion-row class="title-expl-row"><strong>{{ animalTitle }}</strong></ion-row>
            <ion-row class="text-expl-row">
                {{ animalText }}
            </ion-row>
            <ion-row class="text-expl-row" *ngIf="!userProfile"><ion-text color="sea" (click)="toLogIn()">..effettua il login per scoprire gli altri animali fantastici in cui si evolverà!</ion-text></ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>