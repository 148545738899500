
<ion-grid style="min-height: 500px;">
  <ion-row>
    <ion-col size="12">
      <p color="primary">
        <strong>
          Paga o emetti Fattura
        </strong>
      </p>
    </ion-col>
    <ion-col size="12" *ngIf="!error">
      <p>
        Inserisci una data di pagamento e/o fattura e poi conferma.
      </p>
    </ion-col>
    <ion-col size="12" *ngIf="!error">
      <ion-item>
        <ion-checkbox slot="start" (ionChange)="onChangeCBPaga()"></ion-checkbox>
        <ion-label>Paga</ion-label>
      </ion-item>
      <ion-item>
        <ion-checkbox slot="start" (ionChange)="onChangeCBFattura()"></ion-checkbox>
        <ion-label>Emetti Fattura</ion-label>
      </ion-item>
    </ion-col>
    <ion-col size="12" *ngIf="!error && paga">
      <p>
        <strong>Data Pagamento</strong>
      </p>
    </ion-col>
    <ion-col size="12" *ngIf="!error && paga">
      <ion-datetime #dateTimePagamento value="{{timePagamento}}" min="2022-12-01T00:00:00" max="2025-12-30T00:00:00" (ionChange)="onChangeTimePagamento()"></ion-datetime>
    </ion-col>
    <ion-col size="12" *ngIf="!error && fattura">
      <p>
        <strong>Data fattura</strong>
      </p>
    </ion-col>
    <ion-col size="12" *ngIf="!error && fattura">
      <ion-datetime #dateTimeFattura value="{{timeFattura}}" min="2022-12-01T00:00:00" max="2025-12-30T00:00:00" (ionChange)="onChangeTimeFattura()"></ion-datetime>
    </ion-col>
    <ion-col size="12" *ngIf="error">
      <p>
        E' stato rilevato un errore tecnico.. Ricontrolla la data inserita e riprova! Ti ricordiamo che la data è obbligatoria.
      </p>
    </ion-col>
  </ion-row>
  
</ion-grid>

<ion-footer>
  <ion-row style="margin-bottom: 4px;" *ngIf="!error">
    <ion-col size="6" style="text-align: center;">
      <ion-button (click)="toAnnulla()">Annulla</ion-button>
    </ion-col>
    <ion-col size="6" style="text-align: center;">
      <ion-button (click)="toConferma()">Conferma</ion-button>
    </ion-col>
  </ion-row>
  <ion-row style="margin-bottom: 4px;" *ngIf="error">
    <ion-col size="12" style="text-align: center;">
      <ion-button (click)="toRiprova()">Riprova</ion-button>
    </ion-col>
  </ion-row>
  </ion-footer>
