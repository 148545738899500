import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { CategoriesComponent } from './categories-list/categories-list.component';
import { GetCategorieService } from 'src/app/shared/services/getRootCategories.service';
import { ConstantUtilsService } from '../shared/services/constant-utils.service';
import { ShoppingCartItemsService } from '../shared/services/shopping-cart-items.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: CategoriesComponent, pathMatch: "full" }])
  ],
  providers: [GetCategorieService, ConstantUtilsService, ShoppingCartItemsService],
  declarations: [CategoriesComponent]
})
export class CategoriesModule {}
