import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { AddOrUpdateVendorProductReturn, ProdottoItem } from 'src/app/shared/models/Prodotti.model';
import { SwipeProdottiComponent } from '../swipe-prodotti/swipe-prodotti.component';
import { ProdottiParentComponent } from '../prodotti-parent.interface';
import { ProdottiInteractionService } from 'src/app/shared/services/prodotti-interaction.service';
import { NavigationExtras, Router } from '@angular/router';
import { ProductCardMenuComponent } from './product-card-menu/product-card-menu.component';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { GetProdottiService } from 'src/app/shared/services/getProdotti.service';
import { UserService } from 'src/app/shared/services/User.service';
import { GetCategorieService } from 'src/app/shared/services/getRootCategories.service';
import { DeleteProductPopover } from './delete-product-popover/delete-product-popover.component';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-griglia-prodotti',
  templateUrl: './griglia-prodotti.component.html',
  styleUrls: ['./griglia-prodotti.component.scss']
})
export class GrigliaProdottiComponent implements OnInit{
  // This parameter enabled the options menu to manage the product.
  @Input() myVendorProfile: boolean = false;

  @Input() parentComponent: ProdottiParentComponent;
  @Input() prodotti: ProdottoItem[];
  @Input() swipeDisabled: boolean = false;
  @Input() name: string = null;
  @Input() url: string = null;
  private type: string = null;
  private ordering: string = null;
  @Input() trigger: boolean = true;

  pop: any;

  constructor(
    private modalController: ModalController,
    private popover: PopoverController,
    private prodottiInteractionService: ProdottiInteractionService,
    private router: Router, private logger: NGXLogger,
    public constantUtilsService: ConstantUtilsService,
    private productsService: GetProdottiService,
    private userService: UserService,
    private alertController: AlertController) { }

  ngOnInit() {
    this.logger.log("GrigliaProdotti: onInit");
  }

  async openDetailTo(index){
    this.logger.log('GrigliaProdotti: openDetailTo... index = '+index);
    this.router.navigateByUrl("/products/" + this.prodotti[index].id + "/detail");
  }

  async openSwipeTo(index){
    this.logger.log('GrigliaProdotti: openSwipeTo con swipeDisabled = '+this.swipeDisabled);
    
    if(this.swipeDisabled && this.name && this.url){
      this.logger.log('GrigliaProdotti: openSwipeTo tenta di aprire PaginaProdotti');
      if(this.url.includes('punti_foglia')){
        this.type = 'punti_foglia';
      }else if(this.url.includes('tagsost')){
        this.type = 'tagsost';
        this.ordering = 'desc';
      }else if(this.url.includes('top_venduti')){
        this.type = 'top_venduti';
        this.ordering = 'desc';
      }else if(this.url.includes('media_recensioni')){
        this.type = 'media_recensioni';
      }else if(this.url.includes('sconto')){
        this.type = 'sconto';
      }else{
        this.logger.log('GrigliaProdotti: openSwipeTo chiuso per url errato');
        return;
      }
      
      this.logger.log('GrigliaProdotti: external ..navigating to PaginaProdotti');
      let navigationExtras: NavigationExtras;
      if(this.ordering){
        navigationExtras = { state: { name: this.name, type: this.type, ordering: this.ordering, index: index } };
      }else{
        navigationExtras = { state: { name: this.name, type: this.type, index: index } };
      }
      let destinationURL = '/products';
      this.router.navigate([destinationURL], navigationExtras);
    }else if(!this.swipeDisabled && this.trigger){
      this.logger.log('GrigliaProdotti: openSwipeTo tenta di aprire SwipeProdotti');
      const pop = await this.modalController.create({
        component: SwipeProdottiComponent,
        cssClass: 'modal-fullscreen',
        componentProps: {
          "parentComponent": this.parentComponent,
          "index": index,
        }
      });
      pop.onDidDismiss().then(data => {
        this.logger.log('[Data returned by the openSwipeTo] data.data[updateCart]: '+data.data['updateCart']);
        if(data.data['updateCart']){
          this.logger.log('GrigliaProdotti richiama la inizializzazione del carrello in PaginaProdotti...');
          this.prodottiInteractionService.announce('update_cart');
        }
        this.name = null;
        this.type = null;
      });
      return await pop.present();
    }
  }

  setAsUnavailable(index: number){
    if(this.prodotti[index]){
      this.logger.log('GrigliaProdotti: setAsUnavailable to product: '+this.prodotti[index].nome);
      
      const productFormData = new FormData();
      for (let pkey in this.prodotti[index]) {
        let fieldValue = null;
        if(pkey=='immagini'){
          // do nothing...   (altrimenti da errore)
          // il caricamento delle immagini segue un filone diverso e comunque non è usato per azzerare la disponibilità...
        }else if(pkey=='categorie'){
          fieldValue = this.prodotti[index][pkey][0].id;
          this.logger.log(pkey+' = '+fieldValue);
          productFormData.set(pkey, fieldValue);
        }else if(pkey=='quantita_disponibile'){
          fieldValue = 0;
          this.logger.log(pkey+' = '+fieldValue);
          productFormData.set(pkey, fieldValue);
        }else if(pkey=='spedito_da'){
          // do nothing... (excluded from the update)
        }else if(pkey=='TAGSost'){
          var temp_array = []
          for(let tag of this.prodotti[index][pkey]){
            temp_array.push(tag.id);
          }
          if(temp_array.length>0){
            this.logger.log(pkey+' = '+temp_array);
            productFormData.set(pkey, temp_array.toString());
          }
        }else{
          fieldValue = this.prodotti[index][pkey];
          this.logger.log(pkey+' = '+fieldValue);
          productFormData.set(pkey, fieldValue);
        }
      }
      var prezzo_cad = this.round(this.prodotti[index].prezzo_vetrina/1.22);
      productFormData.set('prezzo_cad', ''+prezzo_cad);
      
      this.productsService.updateVendorProduct(this.prodotti[index].id, productFormData).then(
          (response: AddOrUpdateVendorProductReturn) => {
            if(response.result === this.userService.UPDATE_USER_SUCCESS){
              this.logger.log("VendorEditProfileComponent.onSubmit", "Data updated successfully")
              this.openInfoAlert('Disponibilità azzerata', 'In questo momento il tuo prodotto è ancora visibile dagli utenti e modificabile dal venditore. Puoi eliminarlo completamente tramite il pulsante Cestino.');
              // Aggiorno localmente il valore di quantità del prodotto in questione
              // Al prossimo refresh o ricaricamento della pagina i prodotti verranno presi da remoto e tutto tornerà allineato
              this.prodotti[index].quantita_disponibile = 0;
            }
            else{
              this.logger.log("Confirm vendor user data edit - Error. End", response.result)              
              this.openErrorAlert('Disponibilità invariata', 'Qualcosa è andato storto, se vuoi cambiare la disponibilità del prodotto torna indietro e riprova.');
            }
      });
    }else{
      // do nothing...
      this.logger.log('GrigliaProdotti: indice fornito per indisponibilità prodotto non valido!');
    }  
  }

  async openProductMenu(ev: any, index){
    this.trigger = false;
    // this.logger.log("openProductMenu")
    this.pop = await this.popover.create({
      component: ProductCardMenuComponent,
      event: ev,
      translucent: true,
      componentProps: {
        "parentComponent": this,
        "index": index,
        "product": this.prodotti[index]
      }
    });
    return await this.pop.present();
  }

  async ngOnDestroy(){
    this.logger.log('GrigliaProdotti: ----- ngOnDestroy()  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  async openErrorAlert(header: string, text: string){
    if(!text || !header){
      header = 'Errore'
      text = 'Torna indietro e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Prodotto',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  async openInfoAlert(header: string, text: string){
    if(!text || !header){
      header = 'Info'
      text = ' ';
    }
    const alert = await this.alertController.create({
      header: 'Prodotto',
      cssClass: 'alert-info-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  deleteProduct(ev: any, index){
    this.logger.log('GrigliaProdotti: eliminazione del prodotto con index = '+index);
    this.trigger = false;

    if(this.prodotti[index]){
      this.logger.log('Invocando la eliminazione del prodotto con ID = '+Number(this.prodotti[index].id));
      this.CreateProductRemovePopover(null,Number(this.prodotti[index].id),index);
    }
  }

  async CreateProductRemovePopover(ev: any, id: number, index: number) {
    var pop = await this.popover.create({
      component: DeleteProductPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {}
    });

    pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('UserEditProfileComponent: popover closed with boolean = '+result.data);
         if(result.data){
           // Utente ha premuto YES, vuole eliminare il profilo
           this.productsService.deleteVendorProduct(id).then(
            res => {
              this.logger.log('GrigliaProdotti: deleteProduct exited with res = '+res);
              if(res){
                // Il boolean è true -> SUCCESS
                this.openInfoAlert('Eliminazione del prodotto', 'Il tuo prodotto è stato eliminato con successo!');
                // Rimuovo l'elemento dall'array locale.. 
                // Al prossimo refresh i prodotti verranno presi da remoto e tutto tornerà allineato
                this.prodotti.splice(index,1);
              }else{
                // Il boolean è false -> FAILURE
                this.openErrorAlert('Eliminazione del prodotto', 'Qualcosa è andato storto... Riprova in seguito.');
              }
            }
          )
         }
        }
      },
      error => {
        this.logger.log('error -> '+error);
        }
      );
          
    return await pop.present();
  }

  round(num: number){
    if(num>=10){
      // Se il numero è 10 o maggiore di 10 allora approssimo agli interi
      return this.roundTo(num,0);
    }else if(num>0 && num<10){
      // Se il numero è minore di 10 allora approssimo alla prima cifra decimale 
      return this.roundTo(num,1);
    }else{
      // Se è minore o uguale a 0 allora ritorno 0
      return 0;
    }
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }
}
