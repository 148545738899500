import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserAccountActivationComponent } from './registration/user-account-activation/user-account-activation.component';
import { AnonUserRequiredGuard } from './shared/anon-user.guard';
import { BuyerUserRequiredGuard } from './shared/buyer-user.guard';
import { LoginRequiredGuard } from './shared/logged-user.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'vendors',
    loadChildren: () => import('./vendors/vendorlist/vendorlist.module').then( m => m.VendorListModule),
  }/*,
  {
    path: 'vendor',
    loadChildren: () => import('./vendors/vendor.module').then( m => m.VendorModule),
  }*/,
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesModule),
  },
  {
    path: 'login',
    canActivate: [AnonUserRequiredGuard],
    loadChildren: () => import('./login/login.module').then( m => m.LoginModule),
  },
  {
    path: 'registration',
    canActivate: [AnonUserRequiredGuard],
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then( m => m.ProductsModule),
  },
  /*{               // Rimosso il modulo Around
    path: 'around',
    loadChildren: () => import('./map/map.module').then( m => m.MapModule),
  },*/
  {
    path: 'myprofile',
    canActivate: [LoginRequiredGuard],
    loadChildren: () => import('./myprofile/myprofile.module').then( m => m.MyProfileModule),
  },
  // {
  //   path: 'user',
  //   loadChildren: () => import('./user/user.module').then( m => m.UserModule),
  // },
  {
    path: 'shopping-cart',
    /*canActivate: [BuyerUserRequiredGuard], // ELIMINATO ALTRIMENTI L'UTENTE NON LOGGATO NON VEDE IL CARRELLO */
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then( m => m.ShoppingCartModule),
  },
  {
    path: 'checkout',
    canActivate: [BuyerUserRequiredGuard],
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutModule),
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./howitworks/howitworks.module').then( m => m.HowItWorksModule),
  },
  {
    path: 'mission',
    loadChildren: () => import('./mission/mission.module').then( m => m.MissionModule),
  },
  {
    path: 'save-the-planet',
    loadChildren: () => import('./save-the-planet/save-the-planet.module').then( m => m.SaveThePlanetModule),
  },
  {
    path: 'user-account-activation/:id/:token',
    loadChildren: () => import('./registration/user-account-activation/user-account-activation.module').then( m => m.UserAccountActivationModule),
  },
  {
    path: 'user-password-recovery',
    loadChildren: () => import('./registration/user-password-recovery/user-password-recovery.module').then( m => m.UserPasswordRecoveryModule),
  },
  {
    path: 'user-password-reset',
    loadChildren: () => import('./registration/user-password-reset/user-password-reset.module').then( m => m.UserPasswordResetModule),
  },
  {
    path: 'svneaonak-bimming-akjfawwomerutenavbzpaoganaibqn',
    loadChildren: () => import('./bimming/login/bimming.module').then( m => m.BimmingModule),
  },
  {
    path: 'billing/order-list',
    loadChildren: () => import('./bimming/order-list/order-list.module').then( m => m.OrderListBimmingModule),
  },
  {
    path: 'shipping-policy',
    loadChildren: () => import('./shipping-policy/shipping-policy.module').then( m => m.ShippingPolicyModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsModule),
  }
];

// !!! relativeLinkResolution added to the commented part after update to Angular v15 !!!

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, /*relativeLinkResolution: 'legacy', enableTracing: true*/ })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
