import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { NewShoppingCartItem, ShoppingCart, ShoppingCartUpdate } from '../models/ShoppingCart.models';
import { ProdottoItem } from '../models/Prodotti.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ShoppingCartItemsService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/shopping-cart-items/');
  error = new Subject<string>();
  private new_item: NewShoppingCartItem;


  fetchItemsByURL(){
    return this.fetchCartItems(null)
  }

  private fetchCartItems(searchParams){

    return this.http
    .get<ShoppingCart>(
      this.defaultUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     
        responseType: 'json'
      }
    )
  }

  // HTTP DELETE
  deletePosts() {
    return this.http
      .delete(this.defaultUrl, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }

  fetchItemsByUrlAndParams(params){
    return this.fetchCartItems(params);
  }

  createItemsPost(newItems: NewShoppingCartItem[]){
    return this.sendItemsPost(newItems, {many:'True', override:'True'});
  }

  createItemPost(newItem: ProdottoItem){
    this.new_item = { prodotto: Number(newItem.id), quantità: 1};
    return this.sendItemPost(this.new_item, {override:'False'});
  }

  updateItemsPost(newItems: any[]){
    var new_items = [];
    for(let x of newItems){
      if(x['quantità'] && x['key'] && x['prezzo_vetrina'] && x['costo']){
        new_items.push( { prodotto: Number(x['key'].substring(13,x['key'].length)), quantità: x['quantità']} );
      }else{
        this.logger.log('updateItemsPost: conversione di UN item del carrello fallita');
      }
    }
    if(new_items.length>0){
      this.logger.log('updateItemsPost: POST inviata');
      return this.sendItemsPost(new_items, {many:'True', override:'False'});
    }else{
      this.logger.log('updateItemsPost: POST annullata, conversione del carrello locale errata');
      return null;
    }
  }

  private sendItemsPost(newItems: NewShoppingCartItem[], params: {}) {
    const postData: NewShoppingCartItem[] = newItems;
    // {res: string}
    return this.http
      .post<ShoppingCartUpdate[]>(
        this.defaultUrl,
        postData,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          params: params,                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  private sendItemPost(newItem: NewShoppingCartItem, params: {}) {
    const postData: NewShoppingCartItem = newItem;
    return this.http
      .post<{res: string}>(
        this.defaultUrl,
        postData,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          params: params,                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

}
