import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Venditori } from '../models/Venditori.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GetUtentiVenditoriService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/utenti-venditori/');
  error = new Subject<string>();


  // HTTP POST
  /*createAndStorePost(count: number, next: string, previous: string, results: ProdottoItem[]) {
    const postData: ProdottiResponse = { count: count, next: next, previous: previous, results: results };
    this.http
      .post<{ name: string }>(
        this.url,
        postData,
        {
          observe: 'response'
        }
      )
      .subscribe(
        responseData => {
          this.logger.log(responseData);
        },
        error => {
          this.error.next(error.message);
        }
      );
  }*/

  // HTTP GET without params
  fetchVendors(next: string) {
    var one_time_url: string;

    if(next!=null){
      one_time_url = next;
    }else{
      one_time_url = this.url;
    }

    //this.logger.log('utenti-venditori: one_time_url = '+one_time_url);
    
      return this.http
        .get< Venditori >(
          one_time_url,
          {
            headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),                                    
            responseType: 'json'
          }
        )
        .pipe(
          map(responseData => {
            this.logger.log(responseData);
            return <Venditori>responseData;
          }),
          catchError(errorRes => {
            // Send to analytics server
            return throwError(errorRes);
          })
        );
    }

  // HTTP GET with params
  fetchVendorsWithParams(next: string, requestParams) {
    var one_time_url: string;

    if(next!=null){
      one_time_url = next;
    }else{
      one_time_url = this.url;
    }

    //this.logger.log('utenti-venditori: one_time_url = '+one_time_url);
    
      return this.http
        .get< Venditori >(
          one_time_url,
          {
            headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
            params: requestParams,                                     
            responseType: 'json'
          }
        )
        .pipe(
          map(responseData => {
            this.logger.log(responseData);
            return <Venditori>responseData;
          }),
          catchError(errorRes => {
            // Send to analytics server
            return throwError(errorRes);
          })
        );
  }

  // HTTP DELETE
  /*
  deletePosts() {
    return this.http
      .delete(this.url, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }*/
}