<ion-card style="margin: 0px;">
  <ion-card-header *ngIf="!success && !error">
    <div style="margin-top: 24px;">
      <p style="text-align: center; color: var(--ion-color-danger); font-size: 18px;"><strong>Segnala</strong>
        <ion-icon name="alert-circle" color="danger" style="height: 45px; width: 45px; margin-bottom: -16px; margin-left: 6px;"></ion-icon>
      </p>
    </div>
  </ion-card-header>
  <ion-card-content style="padding-left: 8px; padding-right: 8px;">
  
    <ion-grid>
      <ion-row *ngIf="!success && !error">
        <ion-col size="12">
          <p style="font-size: 15px; text-align: center;" *ngIf="isProduct">
            La tua segnalazione verrà inoltrata agli amministratori di Emplace. Una segnalazione utile potrebbe riguardare <strong>prodotti non ecosostenibili</strong> o <strong>immagini non adatte</strong> alla piattaforma.
          </p>
          <p style="font-size: 15px; text-align: center;" *ngIf="isVendor">
            La tua segnalazione verrà inoltrata agli amministratori di Emplace. Una segnalazione utile potrebbe riguardare <strong>venditori scorretti</strong>, inattivi, che vendano <strong>prodotti non ecosostenibili</strong> o che pubblichino <strong>materiale non adatto</strong> alla piattaforma.
          </p>
        </ion-col>
        <ion-col size="12"></ion-col>
      </ion-row>
      <ion-row *ngIf="!isFetching && (success || error)">
        <ion-col size="12" style="text-align: center; margin-top: 24px;">
          <!--  -->
          <ion-icon name="checkmark-circle" color="sea" style="width: 45px; height: 45px;" *ngIf="success"></ion-icon>
          <p style="font-size: 15px; text-align: center; color: var(--ion-color-sea);" *ngIf="success">
            <strong>Grazie!</strong> La tua segnalazione è stata inviata.
          </p>
          <!-- Messaggio di errore -->
          <ion-icon name="close-circle" color="danger" style="width: 45px; height: 45px;" *ngIf="error"></ion-icon>
          <p style="font-size: 15px; text-align: center; color: var(--ion-color-danger);" *ngIf="error">
            Errore tecnico, ti chiediamo di controllare la tua connessione e riprovare.
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" *ngIf="!isFetching && !success && !error">
          <ion-item lines="none">
              <ion-label><strong>Testo</strong></ion-label>
          </ion-item>
          <ion-item lines="none">
              <ion-input aria-label="Testo" type="text" #testo (ionInput)="onChange()" 
              placeholder="Descrivi la tua segnalazione.." maxlength="{{TESTO_MAX}}" counter="true"></ion-input>
          </ion-item>
          <!--<ion-text color="danger" style="font-size: 14px;" *ngIf="redMessage">La lunghezza del testo eccede i 500 caratteri o non è valida.
          </ion-text>    Commentato per inserimento del limite di testo -->
        </ion-col>
        
        <ion-col size="12" style="text-align: center;" *ngIf="!isFetching && !success && !error">
          <ion-button color="sea" (click)="onClose()">Annulla</ion-button>
          <ion-button color="danger" (click)="onSignal()" style="margin-left: 8px; width: 85px;">Invia</ion-button>
        </ion-col>

        <ion-col size="12" style="text-align: right;" *ngIf="!isFetching && (success || error)">
          <ion-button color="sea" (click)="onClose()">Chiudi</ion-button>
        </ion-col>
        
        <ion-col size="12" *ngIf="this.isFetching" style="text-align: center;">
          <ion-spinner name="bubbles"  *ngIf="this.isFetching"></ion-spinner>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-card-content>
</ion-card>