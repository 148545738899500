import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { BuyersOrdersSessionResponse } from 'src/app/shared/models/BuyersOrdersSession.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BuyersOrdersService } from 'src/app/shared/services/buyers-orders.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { InfoPopoverComponent } from './info-popover/info-popover.component';
import { NGXLogger } from 'ngx-logger';
declare var Stripe;

@Component({
  selector: 'app-checkout-donation',
  templateUrl: './checkout-donation.component.html',
  styleUrls: ['./checkout-donation.component.scss'],
})
export class CheckoutDonationComponent implements OnInit {

  title: string = 'Dona';
  quotaR: number = 0;  // R = Riforestazione
  quotaP: number = 0;  // P = Plastica
  quotaS: number = 0;  // S = Sociali
  private finalQuotaR: number = 0;  // R = Riforestazione
  private finalQuotaP: number = 0;  // P = Plastica
  private finalQuotaS: number = 0;  // S = Sociali
  private conv_res: BuyersOrdersSessionResponse = null;
  stripe;
  private pub_key: string = null;
  paymentLoading: boolean = false;
  private prefisso: string;
  private phone: string;
  private first_name: string;
  private last_name: string;
  private note: string;
  private codFis: string = null;
  private address: string;
  private email: string;
  private prezzo: number;
  private donazione_totale: number;
  private ERROR_MESSAGE_QUANTITY_NOT_AVAILABLE: string = "Quantità richiesta non disponibile!";
  pop: any;

  progressValue: number = 0.1;
  nPuntiFoglia: number = 0;
  puntiFogliaAssegnati: number = 0;

  constructor(private router: Router, private popover: PopoverController, private activatedRoute: ActivatedRoute,
    private buyersOrders: BuyersOrdersService, public platform: Platform, private authService: AuthService, 
    private constantUtilsService: ConstantUtilsService, private alertController: AlertController,
    private logger: NGXLogger) { 
    // Recupero l'extra (parametro del nome) dalla pagina sorgente
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        var state = this.router.getCurrentNavigation().extras.state;
        this.getParameters(state);
      }
    });
  }

  getParameters(state: any){
    this.logger.log('CheckoutDonation: getParameters launched');
    if(state.first_name){
      this.first_name = state.first_name;
      this.last_name = state.last_name;
    }else{
      this.logger.log('no name parameters');
    }
    if(state.prefisso){
      this.prefisso = state.prefisso;
    }else{
      this.logger.log('no prefisso parameter');
    }
    if(state.phone){
      this.phone = state.phone;
    }else{
      this.logger.log('no phone parameter');
    }
    if(state.codFis){
      this.codFis = state.codFis;
    }else{
      this.logger.log('no codFis parameter');
    }
    if(state.address){
      this.address = state.address;
    }else{
      this.logger.log('no address parameter');
    }
    if(state.email){
      this.email = state.email;
    }else{
      this.logger.log('no email parameter');
    }
    if(state.prezzo){
      this.prezzo = state.prezzo;
      this.progressValue = 1.0;
      /*this.nPuntiFoglia = Number(Number(this.prezzo).toFixed(0));
      this.puntiFogliaAssegnati = Number(Number(this.prezzo).toFixed(0));*/
    }else{
      this.logger.log('no PREZZO parameter');
    }
    if(state.donazione_totale){
      this.donazione_totale = state.donazione_totale;
      this.progressValue = 1.0;
      if(this.donazione_totale>=1){
        this.nPuntiFoglia = this.donazione_totale;
        this.puntiFogliaAssegnati = this.donazione_totale;
      }else{
        this.nPuntiFoglia = 1.00;
        this.puntiFogliaAssegnati = 1.00;
      }
    }else{
      this.logger.log('no DONAZIONE_TOTALE parameter');
    }
  }

  ngOnInit() {}

  toPayment(){
    this.logger.log('CheckoutDonation: toPayment() called');
    this.launchStripeDropInUI();
  }

  showInfo(){
    this.logger.log('CheckoutDonation: showInfo() called');
    this.CreatePopover(null);
  }

  // R = Riforestazione
  decrementItemR(){
    this.logger.log('CheckoutDonation: decrement method - Riforestazione - called');
    if(this.quotaR-1>=0){
      this.quotaR = this.constantUtilsService.roundTo(this.quotaR-1,0);
      this.logger.log(this.quotaR);

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }
  incrementItemR(){
    this.logger.log('CheckoutDonation: increment method - Riforestazione - called');
    if(this.quotaR+1<=this.nPuntiFoglia){
      if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaP-1>=0){
        // Decremento
        this.quotaP = this.constantUtilsService.roundTo(this.quotaP-1,0);
        this.logger.log(this.quotaP);

        // Incremento
        this.quotaR = this.constantUtilsService.roundTo(this.quotaR+1,0);
        this.logger.log(Number(this.quotaR).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaS-1>=0){
        // Decremento
        this.quotaS = this.constantUtilsService.roundTo(this.quotaS-1,0);
        this.logger.log(this.quotaS);

        // Incremento
        this.quotaR = this.constantUtilsService.roundTo(this.quotaR+1,0);
        this.logger.log(Number(this.quotaR).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP<this.nPuntiFoglia){
        // Incremento
        this.quotaR = this.constantUtilsService.roundTo(this.quotaR+1,0);
        this.logger.log(Number(this.quotaR).toFixed(1));
      }

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }

  // P = Plastica
  decrementItemP(){
    this.logger.log('CheckoutDonation: decrement method - Plastica - called');
    if(this.quotaP-1>=0){
      this.quotaP = this.constantUtilsService.roundTo(this.quotaP-1,0);
      this.logger.log(this.quotaP);

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }
  incrementItemP(){
    this.logger.log('CheckoutDonation: increment method - Plastica - called');
    if(this.quotaP+1<=this.nPuntiFoglia){

      if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaR-1>=0){
        // Decremento
        this.quotaR = this.constantUtilsService.roundTo(this.quotaR-1,0);
        this.logger.log(this.quotaR);
    
        // Incremento
        this.quotaP = this.constantUtilsService.roundTo(this.quotaP+1,0);
        this.logger.log(Number(this.quotaP).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaS-1>=0){
        // Decremento
        this.quotaS = this.constantUtilsService.roundTo(this.quotaS-1,0);
        this.logger.log(this.quotaS);

        // Incremento
        this.quotaP = this.constantUtilsService.roundTo(this.quotaP+1,0);
        this.logger.log(Number(this.quotaP).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP<this.nPuntiFoglia){
        // Incremento
        this.quotaP = this.constantUtilsService.roundTo(this.quotaP+1,0);
        this.logger.log(Number(this.quotaP).toFixed(1));
      }

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }

  // S = Sociali
  decrementItemS(){
    this.logger.log('CheckoutDonation: decrement method - Sociali - called');
    if(this.quotaS-1>=0){
      this.quotaS = this.constantUtilsService.roundTo(this.quotaS-1,0);
      this.logger.log(this.quotaS);

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }
  incrementItemS(){
    this.logger.log('CheckoutDonation: increment method - Sociali - called');
    if(this.quotaS+1<=this.nPuntiFoglia){
    
      if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaR-1>=0){
        // Decremento
        this.quotaR = this.constantUtilsService.roundTo(this.quotaR-1,0);
        this.logger.log(this.quotaR);

        // Incremento
        this.quotaS = this.constantUtilsService.roundTo(this.quotaS+1,0);
        this.logger.log(Number(this.quotaS).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP>=this.nPuntiFoglia && this.quotaP-1>=0){
        // Decremento
        this.quotaP = this.constantUtilsService.roundTo(this.quotaP-1,0);
        this.logger.log(this.quotaP);

        // Incremento
        this.quotaS = this.constantUtilsService.roundTo(this.quotaS+1,0);
        this.logger.log(Number(this.quotaS).toFixed(1));
      }else if(this.quotaS+this.quotaR+this.quotaP<this.nPuntiFoglia){
        // Incremento
        this.quotaS = this.constantUtilsService.roundTo(this.quotaS+1,0);
        this.logger.log(Number(this.quotaS).toFixed(1));
      }

      // Aggiorno il numero di pFoglia assegnati
      this.puntiFogliaAssegnati = this.quotaP + this.quotaR + this.quotaS;
      // Aggiorno la progressBar
      this.progressValue = this.puntiFogliaAssegnati / this.nPuntiFoglia;
    }
  }

  async CreatePopover(ev: any) {
    this.pop = await this.popover.create({
      component: InfoPopoverComponent,
      cssClass: 'info-popover-big-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        "text": 'testo'
      }
    });
    return await this.pop.present();
  }

  async ionViewWillLeave(){
    this.logger.log('CheckoutDonationComponent: ----- ionViewWillLeave()  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  computeInitialQuotes(){
    this.logger.log('CheckoutDonationComponent: computing Initial Quotes...');
    // Il 35% del totale viene assegnato a riforestazione
    this.quotaR = this.constantUtilsService.roundTo(this.nPuntiFoglia*35/100,0);
    // Il 35% del totale viene assegnato a smaltimento plastica
    this.quotaP = this.constantUtilsService.roundTo(this.nPuntiFoglia*35/100,0)
    // Il totale meno i primi due viene assegnato ai progetti sociali (per evitare calcoli elaborati e errori di gestione dei decimali)
    this.quotaS = this.constantUtilsService.roundTo(this.nPuntiFoglia - this.quotaR - this.quotaP,0); 
    if(this.quotaS<1 && this.quotaP==0 && this.quotaR==0){
      // Altrimenti se il totale_donazione < 1 le quote sarebbero tutte nulle 
      this.quotaS = 1;
    }
    if(this.quotaS < 0){
      // Può capitare se sia quotaR che quotP vengono arrotondati per eccesso
      this.quotaS = 0;
    }
    // Per evitare di mostrare un totale con valori decimali
    this.nPuntiFoglia = this.quotaS + this.quotaP + this.quotaR;
    this.puntiFogliaAssegnati = this.nPuntiFoglia;
  }

  ionViewDidEnter(){
    this.logger.log('CheckoutDonationComponent: ---> ionViewDidEnter <--- ');

    if(this.nPuntiFoglia==null || Number(this.nPuntiFoglia)==0 || this.first_name==null || this.prefisso==null || this.phone==null){
      this.logger.log('CheckoutDonationComponent: Not enough parameters');
      this.router.navigate(['home']);
    }else{
      this.computeInitialQuotes();
    }

    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.doRequestStripeConfig();
        })
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.doRequestStripeConfig();
    }
  }

  doRequestStripeConfig(){
    this.buyersOrders.requestStripeConfig().subscribe(
      responseData =>{
        this.logger.log('CheckoutDonationComponent: requestStripeConfig returns = '+responseData.body.publicKey);
        this.pub_key = responseData.body.publicKey;
        this.stripe = Stripe(this.pub_key);
      },
      errorRes => {
        this.logger.log('CheckoutDonationComponent: requestStripeConfig throws errors..... ');
        this.logger.log(errorRes);
      }
    )
  }

  launchStripeDropInUI(){   // V3
    this.logger.log('CheckoutDonationComponent: launchStripeDropInUI called...');
    this.paymentLoading = true;

    var totalQuota = this.quotaR + this.quotaP + this.quotaS;
    this.logger.log("CheckoutDonationComponent: quota totale dell ordine = "+totalQuota);
    this.finalQuotaR = this.constantUtilsService.roundTo((this.quotaR / totalQuota) * 100,0);
    this.logger.log("CheckoutDonationComponent: quota riforestazione percentuale = "+this.finalQuotaR+" (iniziale "+this.quotaR+")");
    this.finalQuotaP = this.constantUtilsService.roundTo((this.quotaP / totalQuota) * 100,0);
    this.logger.log("CheckoutDonationComponent: quota plastica percentuale = "+this.finalQuotaP+" (iniziale "+this.quotaP+")");
    this.finalQuotaS = 100 - this.finalQuotaR - this.finalQuotaP;
    this.logger.log("CheckoutDonationComponent: quota sociale percentuale = "+this.finalQuotaS+" (iniziale "+this.quotaS+")");

    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.doCreateSessionRequest();
        })
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.doCreateSessionRequest();
    }
    
  }

  doCreateSessionRequest(){
    this.buyersOrders.createSessionRequest(this.first_name, this.last_name, this.address, this.codFis, null, this.prefisso, this.phone, this.note, this.finalQuotaR, this.finalQuotaP, this.finalQuotaS).subscribe(
      res => {
        this.paymentLoading = false;
        this.logger.log('CheckoutDonationComponent: createSessionRequest --- res = '+res);
        this.logger.log(res);
        var conv_res: BuyersOrdersSessionResponse = res.body as unknown as BuyersOrdersSessionResponse;
        this.logger.log('CheckoutDonationComponent: session_id = '+conv_res.stripe_checkout_id);

        // Versione con DropIn UI (funzionante, con problema del checkout/success su app android)
        // - - - - - >   Launching stripe DropIn UI   < - - - - - 
        this.stripe.redirectToCheckout({
          sessionId: conv_res.stripe_checkout_id 
        }).then( result => {
          this.logger.log('redirect to checkout --- result: '+result);
        });

      },
      error => {
        this.logger.log(error);
        this.paymentLoading = false;
        var error_quantita: boolean = false;
        if(error['errors']){
          for(let elem in error['errors']){
            if(elem == this.ERROR_MESSAGE_QUANTITY_NOT_AVAILABLE){
              error_quantita = true;
              this.sessionErrorAlert('Quantità errata', 'La quantità di prodotto che stai provando ad acquistare non è più disponibile.. Torna indietro e riprova!');
              break;
            }
          }
          if(!error_quantita){
            this.sessionErrorAlert('Errore generico', 'Per favore contatta il nostro servizio clienti (assistenza@emplacestore.it) e riprova!');
          }
        }
      }
    );
  }

  async sessionErrorAlert(header: string, text: string){
    if(!text || !header){
      header = 'Errore'
      text = 'Torna indietro e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Creazione dell\'ordine',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

}
