import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, NavParams, PopoverController } from '@ionic/angular';
import { SignalService } from '../shared/services/signal.service';
import { UserService } from '../shared/services/User.service';
import { clientToProductPOSTparam, clientToVendorPOSTparam, vendorToProductPOSTparam, vendorToVendorPOSTparam } from '../shared/models/Signal.model';
import { BuyerUserProfile, VendorUserProfile } from '../shared/models/UserProfile.models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.scss'],
})
export class SignalComponent implements OnInit {

  isFetching: boolean = false;
  isProduct: boolean = false;
  isVendor: boolean = false;
  redMessage: boolean = false;
  success: boolean = false;
  error: boolean = false;
  TESTO_MAX: number = 500;
  @ViewChild('testo') inputTesto: IonInput;
  private lTesto: number = 0;
  private type: string;
  private vendorID: string;
  private productID: string;
  private clientID: string;
  private senderVendorID: string;
  private userProfile: BuyerUserProfile;
  private vendorUserProfile: VendorUserProfile;

  constructor(private popoverProp: PopoverController, private navParams: NavParams, private signalService: SignalService,
    private userService: UserService, private logger: NGXLogger) {
    this.type = navParams.get('type');
    this.vendorID = navParams.get('vendorID');
    this.productID = navParams.get('productID');
   }

  ngOnInit() {
    if(!this.type){
      this.logger.log('SignalPopover: Nessun TYPE !!!');
      this.popoverProp.dismiss();
    }else if(this.type=='vendor'){
      this.isVendor = true;
      this.isProduct = false;
    }else if(this.type=='product'){
      this.isProduct = true;
      this.isVendor = false;
    }else{
      this.logger.log('SignalPopover: TYPE non conforme !!!');
      this.popoverProp.dismiss();
    }
  }

  onClose(){
    this.popoverProp.dismiss();
  }

  onSignal(){
    this.logger.log('SignalComponent: segnalazione inviata...');
    if(this.validator()){
      this.isFetching = true;
      if(this.isVendor && this.userService.userIsClient()){
        this.onClientSignalsVendor();
      }else if(this.isVendor && this.userService.userIsVendor()){
        this.onVendorSignalsVendor();
      }else if(this.isProduct && this.userService.userIsClient()){
        this.onClientSignalsProduct();
      }else if(this.isProduct && this.userService.userIsVendor()){
        this.onVendorSignalsProduct();
      }else{
        this.logger.log('SignalComponent: segnalazione abortita...');
        this.isFetching = false;
        this.error = true;
      }
    }else{
      this.redMessage = true;
    }
  }

  onVendorSignalsVendor(){
    this.logger.log('SignalComponent: onVendorSignalsVendor called!');
    if(!this.vendorID || this.vendorID==''){
      this.logger.log('SignalComponent: onVendorSignalsVendor called with (receiver)vendorID invalid');
      this.error = true;
      return;
    }
    // Getting user data
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.vendorUserProfile = < VendorUserProfile >userProfile;
        this.logger.log('SignalComponent: GET User Profile results:');
        this.logger.log(this.vendorUserProfile);
        this.senderVendorID = this.vendorUserProfile.id;
        
        // Sending (POSTing) the signal 
        const newSignal: vendorToVendorPOSTparam = {
          utente_venditore_segnalatore: this.senderVendorID,
          utente_venditore_segnalato: this.vendorID,
          descrizione: this.inputTesto.value.toString()
        }
        this.signalService.vendorSignalsVendor(newSignal).subscribe(
          res => {
            this.logger.log(res);
            this.isFetching = false;
            this.success = true;
          },
          error => {
            this.logger.log(error);
            this.error = true;
            this.isFetching = false;
          });

      },
      error => {
        this.logger.log(error);
        this.isFetching = false;
        this.error = true;
      });
  }

  onVendorSignalsProduct(){
    this.logger.log('SignalComponent: onVendorSignalsProduct called!');
    if(!this.productID || this.productID==''){
      this.logger.log('SignalComponent: onVendorSignalsProduct called with productID invalid');
      this.error = true;
      return;
    }
    // Getting user data
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.vendorUserProfile = < VendorUserProfile >userProfile;
        this.logger.log('SignalComponent: GET User Profile results:');
        this.logger.log(this.vendorUserProfile);
        this.senderVendorID = this.vendorUserProfile.id;
        
        // Sending (POSTing) the signal 
        const newSignal: vendorToProductPOSTparam = {
          utente_venditore: this.senderVendorID,
          prodotto: this.productID,
          descrizione: this.inputTesto.value.toString()
        }
        this.signalService.vendorSignalsProduct(newSignal).subscribe(
          res => {
            this.logger.log(res);
            this.isFetching = false;
            this.success = true;
          },
          error => {
            this.logger.log(error);
            this.error = true;
            this.isFetching = false;
          });

      },
      error => {
        this.logger.log(error);
        this.isFetching = false;
        this.error = true;
      });
  }

  onClientSignalsVendor(){
    this.logger.log('SignalComponent: onClientSignalsVendor called!');
    if(!this.vendorID || this.vendorID==''){
      this.logger.log('SignalComponent: onClientSignalVendor called with vendorID invalid');
      this.error = true;
      return;
    }
    // Getting user data
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.userProfile = < BuyerUserProfile >userProfile;
        this.logger.log('SignalComponent: GET User Profile results:');
        this.logger.log(this.userProfile);
        this.clientID = this.userProfile.id;
        
        // Sending (POSTing) the signal 
        const newSignal: clientToVendorPOSTparam = {
          utente_cliente: this.clientID,
          utente_venditore: this.vendorID,
          descrizione: this.inputTesto.value.toString()
        }
        this.signalService.clientSignalsVendor(newSignal).subscribe(
          res => {
            this.logger.log(res);
            this.isFetching = false;
            this.success = true;
          },
          error => {
            this.logger.log(error);
            this.error = true;
            this.isFetching = false;
          });

      },
      error => {
        this.logger.log(error);
        this.isFetching = false;
        this.error = true;
      });
  }

  onClientSignalsProduct(){
    this.logger.log('SignalComponent: onClientSignalsProduct called!');
    if(!this.productID || this.productID==''){
      this.logger.log('SignalComponent: onClientSignalsProduct called with productID invalid');
      this.error = true;
      return;
    }
    // Getting user data
    this.userService.fetchMyProfile().then(
      userProfile => {
        this.userProfile = < BuyerUserProfile >userProfile;
        this.logger.log('SignalComponent: GET User Profile results:');
        this.logger.log(this.userProfile);
        this.clientID = this.userProfile.id;
        
        // Sending (POSTing) the signal 
        const newSignal: clientToProductPOSTparam = {
          utente_cliente: this.clientID,
          prodotto: this.productID,
          descrizione: this.inputTesto.value.toString()
        }
        this.signalService.clientSignalsProduct(newSignal).subscribe(
          res => {
            this.logger.log(res);
            this.isFetching = false;
            this.success = true;
          },
          error => {
            this.logger.log(error);
            this.error = true;
            this.isFetching = false;
          });

      },
      error => {
        this.logger.log(error);
        this.isFetching = false;
        this.error = true;
      });
  }

  validator(){
    if(this.lTesto<=this.TESTO_MAX && this.lTesto>0){
      return true;
    }
    return false;
  }

  onChange(){
    if(!this.inputTesto.value){
      this.lTesto = 0;
    }else{
      this.lTesto = this.inputTesto.value.toString().length;
    }
  }

}
