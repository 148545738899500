import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

@Injectable()
export class ProdottiInteractionService {

  constructor(private logger: NGXLogger){}

  // Observable string sources
  private newItemIntoCart = new Subject<string>();
  private howManyItemsIntoCart = new Subject<Number>();
  itemsIntoCartAmount: Number;

  // Observable string streams
  newItemIntoCartAnnounced = this.newItemIntoCart.asObservable();
  shoppingCartItemsAmount = this.howManyItemsIntoCart.asObservable();

  // Service message commands
  announce(mission: string) {
    this.newItemIntoCart.next(mission);
  }

  announceAmount(amount: Number) {
    this.logger.log('Service CART COMMUNICATION: itemsIntoCartAmount = '+this.itemsIntoCartAmount);
    this.itemsIntoCartAmount = amount;
    this.logger.log('Service CART COMMUNICATION: itemsIntoCartAmount = '+this.itemsIntoCartAmount);
    this.logger.log('Service CART COMMUNICATION: amount = '+amount);
    this.howManyItemsIntoCart.next(amount);
  }
}