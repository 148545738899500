import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { VendorlistComponent } from './vendorlist/vendorlist.component';
import { GetUtentiVenditoriService } from 'src/app/shared/services/getUtentiVenditori.service';
import { VendorProfileComponent } from './vendor/vendor.component';
import { GetVendorUserService } from '../shared/services/getVendorUser.service';
import { GrigliaProdottiModule } from '../products/griglia-prodotti/griglia-prodotti.module';
import { VendorEditProfileComponent } from './vendor-edit-data/vendor-edit-data.component';
import { VendorProductFormComponent } from './vendor-product-form/vendor-product-form.component';
import { VendorOrdersComponent } from './vendor-orders/vendor-orders.component';
import { VendorReviewsComponent } from './vendor-reviews/vendor-reviews.component';
import { VendorOrderItemComponent } from './vendor-orders/vendor-order-item/vendor-order-item.component';
import { VendorReviewItemComponent } from './vendor-reviews/vendor-review-item/vendor-review-item.component';
import { ShoppingCartItemsService } from '../shared/services/shopping-cart-items.service';
import { ShippingAddressModule } from '../positions/shipping-address/shipping-address.module';
import { UserAddressPageModule } from '../positions/user-address-page/user-address-page.module';
import { AllCategorieService } from '../shared/services/allCategories.service';
import { ShippingPricePopover } from './vendor-product-form/shipping-price-popover/shipping-price-popover.component';
import { NumberPrefixService } from '../shared/services/numberPrefix.service';
import { MenuService } from '../shared/services/menu.service';
import { TAGService } from '../shared/services/tag.service';
import { VendorRemoveDataPopover } from './vendor-edit-data/vendor-remove-data-popover/vendor-remove-data-popover.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    GrigliaProdottiModule,
    ShippingAddressModule,
    UserAddressPageModule,
    RouterModule.forChild([
      { path: '', component: VendorlistComponent},
      { path: 'vendor/:id', component: VendorProfileComponent}
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [GetUtentiVenditoriService, GetVendorUserService, ShoppingCartItemsService, 
    AllCategorieService, NumberPrefixService, MenuService, TAGService], 
  declarations: [
    VendorProfileComponent, 
    VendorEditProfileComponent, 
    VendorProductFormComponent, 
    VendorOrdersComponent,
    VendorOrderItemComponent,
    VendorReviewsComponent,
    VendorReviewItemComponent,
    ShippingPricePopover,
    VendorRemoveDataPopover
  ]
})
export class VendorModule {}
