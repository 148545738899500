import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'popover-delete-item',
  templateUrl: 'checkout-riepilogo-popover.component.html',
  styleUrls: ['./checkout-riepilogo-popover.component.scss']
})
export class CRDeleteItemPopover {
  constructor (private popover: PopoverController, private logger: NGXLogger) { }

  private delete: boolean = false;

  ClosePopover() {
    this.popover.dismiss(this.delete);
  }

  onYes(){
      this.logger.log('DeleteItemPopover: ..user clicked YES');
      this.delete = true;
      this.popover.dismiss(this.delete);
  }

  onNot(){
    this.logger.log('DeleteItemPopover: ..user clicked NO');
    this.delete = false;
    this.popover.dismiss(this.delete);
    }
    
}