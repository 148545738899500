import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { UserService } from './services/User.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class BuyerUserRequiredGuard implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService, private router: Router, 
    private logger: NGXLogger) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {

    // If the app is loading the user data, wait for the request to complete, then check.

    // This step was added to handle a race condition in which authService didn't get the token from the Storage yet.
    if(this.authService.fetchingTokenFromStorage){
      return this.authService.userStatusChanged.pipe(take(1)).toPromise().then(isLoggedIn => {
        if(!isLoggedIn){
          return this.router.createUrlTree(['/']);
        }
        return this.checkUserTypeReady(route, state)
      })
    }
    else{
      // If the authService is not fetching the user token from the storage, check if it has already been checked by the userService
      return this.checkUserTypeReady(route, state)
    }
  }

  checkUserTypeReady(route, state){
    // This method checks if the userService is getting the userProfile from the backend.
    // If so, wait for it to complete, then checks if the user is a buyer.
    // Otherwise, the user profile could have already been loaded: check if the user is a buyer.
    if(this.userService.isFetching){
      return this.userService.userChanged.pipe(take(1)).toPromise().then(userData => {
        return this.checkUserType(route, state);
      })
    }
    else{
      return this.checkUserType(route, state)
    }
  }
  
  checkUserType(route, state){
    // this.logger.log("BUYER REQUIRED", "logged in = ", this.authService.isLoggedIn(), "isClient = ", this.userService.userIsClient())
    if(this.authService.isLoggedIn() && this.userService.userIsClient()){
      this.logger.log("AO 2")
      return true;
    }
    else{
      this.logger.log("AO", route, state, this.authService.isLoggedIn(), this.userService.userIsClient())
      // If the user is already logged in, redirect him/her to the home page
      return this.router.createUrlTree(['/']);
    }
  }
}
