import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({ providedIn: 'root' })
export class LoginRequiredGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {

      // this.logger.log("LOGGED IN REQUIRED", state.url, this.authService.isLoggedIn())
      if(this.authService.isLoggedIn()){
        return true;
      }
      else{
        // If the user is not logged in, redirect him/her to the login page
        return this.router.createUrlTree(
          ['/login'], 
          {queryParams: { next: state.url }});
      }
  }
}
