import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { VendorlistComponent } from './vendorlist.component';
import { GetUtentiVenditoriService } from 'src/app/shared/services/getUtentiVenditori.service';
import { VendorModule } from '../vendor.module';
import { AssociationPopover } from 'src/app/association/association-popover/association-popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    VendorModule,
    RouterModule.forChild([
      { path: '', component: VendorlistComponent}
    ])
  ],
  providers: [GetUtentiVenditoriService],
  declarations: [
    VendorlistComponent, AssociationPopover
  ]
})
export class VendorListModule {}
