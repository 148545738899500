<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="8" size-sm="10" size-md="10" size-lg="10" size-xl="10" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 12px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Emissioni inutili
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="8" size-sm="10" size-md="10" size-lg="10" size-xl="10" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              Emissioni inutili
            </ion-title>
          </div>
        </ion-col>

        <ion-col size-xs="2" size-sm="1" size-md="1" size-lg="1" size-xl="1"></ion-col>

      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

  
<ion-content [fullscreen]="true" style="padding-top: 0px; background-color: #def7df;">

  <div style="width: 100%; max-width: 500px; margin: auto; text-align: center; margin-top: 90px;">
    <!--<img src="assets/no_results_image/page_not_found.png" class="error-resize">-->
    <img src="assets/images/404-error-alien.png" class="error-resize">
    <p color="primary" style="margin-top: 24px; font-size: 24px; color: var(--ion-color-primary);"><strong>Pagina non trovata</strong></p>
  </div>

</ion-content>