<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
      <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
          Accedi
      </ion-title>
      
      <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title *ngIf="!platform.is('ios')" style="max-width: 100px; margin: auto; padding-left: 0px;">Accedi</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">

  <ion-row style="max-width: 350px; margin: auto; margin-top: 48px;">
      <ion-col offset-xs=1 size-xs=10 size-sm=8 offset-sm=2 style="padding: 0px; align-items: center; margin: auto; text-align: center;">
                      <form [formGroup]="basicLoginForm" (ngSubmit)="onLogin()" *ngIf="!this.isFetching">
                          <ion-item lines="none">
                              <ion-label>Username</ion-label>
                          </ion-item>
                          <ion-item>
                              <ion-input aria-label="Username" type="email" formControlName="email" placeholder="Inserisci la tua email"></ion-input>
                          </ion-item>
                          <ion-item lines="none">
                              <ion-label>Password</ion-label>
                          </ion-item>
                          <ion-item>
                              <ion-input aria-label="Password" type="password" formControlName="password" placeholder="Inserisci la password"></ion-input>
                          </ion-item>
                      <ion-button class="social-btn" color="primary" [disabled]="!basicLoginForm.valid" type="submit" style="margin-top: 12px;">
                          Accedi
                      </ion-button>
                      </form>

                  <div class="loading-area" *ngIf="this.isFetching">
                      <ion-spinner name="bubbles"  *ngIf="this.isFetching"></ion-spinner>
                      <p>Completa l'accesso per continuare</p>
                  </div>
      </ion-col>
  </ion-row>

</ion-content>