<ion-card style="margin: 0px;">
    
    <ion-row>
        <ion-col style="text-align: center;">
            <img src="{{ constantUtils.getFullURL(logo) }}" *ngIf="logo && nome!='Nessuna' && logo!=''" 
            style="border-radius:5px; margin-top: 16px;" class="resize">
            <ion-button style="float: right; position: absolute;" fill="clear" (click)="close()">
                <ion-icon name="close-circle" color="danger"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
    
    <ion-card-header>
        <ion-card-title color="primary"><strong>{{ nome }}</strong></ion-card-title>
      </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col size="12">
                    <p>{{descrizione}}</p>
                </ion-col>
                <ion-col size="12" *ngIf="link">
                    <p><a href="{{link}}">
                        Visita il sito web
                    </a></p>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>