import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ConstantUtilsService } from './constant-utils.service';
import { TAG, TAG_GETResponse } from '../models/TAG.models';
import { NGXLogger } from 'ngx-logger';


@Injectable()
export class TAGService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private LIMIT: number = 30; // La chiamata ritorna pochi elementi per essere iterata + volte..
  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/tags/?limit='+this.LIMIT);
  error = new Subject<string>(); 


  // HTTP GET
  fetchTAGs() {

    return this.http
      .get(
        this.url,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: requestParams,*/                                     
          responseType: 'json'
        }
      )
      .pipe(
        map(responseData => {
          this.logger.log(responseData);
          return < TAG_GETResponse >responseData;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      );
  }

}
