<ion-card class="card-aspect">
    <img src="assets/images/forest.jpg" class="resize" *ngIf="categoria=='Tree'">
    <img src="assets/images/sea.png" class="resize" *ngIf="categoria=='Plastic'">
    <img src="assets/images/social.png" class="resize" *ngIf="categoria=='Social'">
    <ion-card-header>
        <ion-card-title color="primary"><strong>Donazioni</strong></ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row *ngIf="categoria=='Plastic' || categoria=='Tree'">
                <ion-col size="8" style="padding: 0px; height: 20px;">
                    <ion-progress-bar value="{{roundTo(modDonation/TASSO,1)}}" style="margin-top: 9px;"></ion-progress-bar>
                </ion-col>
                <ion-col size="4" style="text-align: center; padding: 0px; height: 20px;">
                    <ion-text color="primary" style="font: 14px; font-size: 14px;"><strong>{{modDonation}}</strong>/{{TASSO}}</ion-text>
                    <img src="assets/icon/logo_secondary_green.png" style="height: 12px; width: 12px; margin-left: 3px;">
                </ion-col>
            </ion-row>
            <ion-row style="margin-top: 18px;">
                <ion-col size="12" *ngIf="categoria!='Social'">
                    <p>Hai donato ulteriori <ion-text color="primary" style="font-size: 14px;">{{modDonation}} punti foglia</ion-text>, ne mancano esattamente <ion-text color="primary" style="font-size: 14px;">{{TASSO - modDonation}}</ion-text> per {{textShort}}.</p>
                    <p>Effettua altri acquisti su <ion-text color="primary" style="font-size: 14px;"><strong>Emplace</strong></ion-text> e dona i tuoi <ion-text color="primary" style="font-size: 14px;">punti foglia</ion-text> in <ion-text *ngIf="categoria=='Tree'"><strong>riforestazione</strong></ion-text><ion-text *ngIf="categoria=='Plastic'"><strong>smaltimento plastica</strong></ion-text> per {{text}} <ion-text color="primary" (click)="toService()" style="font-size: 14px;"><strong>{{service}}</strong></ion-text>.</p>
                </ion-col>
                <ion-col size="12" *ngIf="categoria=='Social'">
                    <p>Finora hai donato <strong>{{modDonation}}€</strong> in progetti sociali.</p>
                    <p>Effettua altri acquisti su <ion-text color="primary" style="font-size: 14px;"><strong>Emplace</strong></ion-text> e dona i tuoi <ion-text color="primary" style="font-size: 14px;">punti foglia</ion-text> in <strong>progetti sociali</strong> per {{text}} <ion-text color="primary" (click)="toService()" style="font-size: 14px;"><strong>{{service}}</strong></ion-text>.</p>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
    <ion-button style="float: right;" fill="clear" color="primary" (click)="close()">
        OK
    </ion-button>
</ion-card>