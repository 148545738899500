import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { of, Subject, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from './User.service';
import { catchError, map } from 'rxjs/operators';
import { ConstantUtilsService } from './constant-utils.service';
import { VendorRef, SignalAboutVendor, clientToVendorPOSTparam, vendorToVendorPOSTparam, clientToProductPOSTparam, vendorToProductPOSTparam } from '../models/Signal.model';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class SignalService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger,
    private userService: UserService) {}
    
  private clientToVendorURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyers/complaints/');
  private vendorToVendorURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/complaints/');
  private clientToProductURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyers/products/complaints/');
  private vendorToProductURL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/complaints/');
  
  error = new Subject<string>();


  fetchSignalsAboutVendors(){
    if(!this.userService.userIsClient()){
      this.logger.log("SignalService - fetchSignalsAboutVendors", "user is not a Client. Exiting.");
      return of(null)
    }

    return this.http
    .get<VendorRef[]>(
      this.clientToVendorURL,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map((responseData:VendorRef[]) => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  clientSignalsVendor(newSignal: clientToVendorPOSTparam) {   
    return this.http
      .post<{res: string}>(
        this.clientToVendorURL,
        newSignal,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  clientSignalsProduct(newSignal: clientToProductPOSTparam) {   
    return this.http
      .post<{res: string}>(
        this.clientToProductURL,
        newSignal,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  vendorSignalsVendor(newSignal: vendorToVendorPOSTparam) {   
    return this.http
      .post<{res: string}>(
        this.vendorToVendorURL,
        newSignal,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }


  vendorSignalsProduct(newSignal: vendorToProductPOSTparam) {   
    return this.http
      .post<{res: string}>(
        this.vendorToProductURL,
        newSignal,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }
  
}