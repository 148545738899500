<ion-grid>
  <ion-row>
    <ion-col 
    size="12"
    size-xs="6"
    size-sm="4"
    size-md="3"
    size-lg="3"
    size-xl="3" style="padding: 10px;"
    *ngFor="let p of prodotti; let i = index">
      <ion-card>
        <ion-card-header style="padding: 0px;" (click)="openSwipeTo(i)">
          <ion-badge class="unavailable-badge" *ngIf="p.quantita_disponibile==0">TERMINATO</ion-badge>
          <img *ngIf="p['immagini'].length>0"
            src="{{ constantUtilsService.getFullURL(p['immagini'][0]['immagine']) }}" onerror="this.src='assets/placeholders/product.jpg'" class="resizePlus"/>
          <img *ngIf="p['immagini'].length === 0"
            src="assets/placeholders/product.jpg" class="resizePlus"/>
          <ion-fab vertical="top" horizontal="end" *ngIf="myVendorProfile">
            <ion-fab-button color="primary" (click)="openProductMenu($event, i)" style="width: 35px; height: 35px;">
              <ion-icon name="ellipsis-vertical"></ion-icon>
            </ion-fab-button>
          </ion-fab>
          <ion-fab vertical="top" horizontal="start" *ngIf="myVendorProfile && p.quantita_disponibile==0">
            <ion-fab-button color="danger" (click)="deleteProduct($event, i)" style="width: 35px; height: 35px;">
              <ion-icon name="trash-outline"></ion-icon>
            </ion-fab-button>
          </ion-fab>    
          <!-- Removed for iOs bad UI -->
          <!--<ion-card-title color="primary" style="padding: 5px;" class="product-grid-item-name">{{p.nome}}</ion-card-title>-->
        </ion-card-header>
        <ion-card-content>
          <div (click)="openSwipeTo(i)">
            <ion-row>
              <ion-col size="12" style="padding: 5px; text-align: center;">
                <p color="primary" class="product-grid-item-name" style="color: var(--ion-color-primary);"><strong>{{p.nome}}</strong></p>
              </ion-col>
              <ion-col size="12" style="padding-bottom: 2px;">
                <div class="product-grid-card-price">
                  <p style="margin-bottom: 0px;"><strong>{{p.prezzo_vetrina}} €</strong></p>
                </div>
              </ion-col>
              <ion-col size="12" style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px;" *ngIf="p.media_recensioni > 0.0">
                <div class="product-grid-card-rating">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="p.media_recensioni > 0.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="p.media_recensioni > 1.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="p.media_recensioni > 2.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="p.media_recensioni > 3.5">
                  <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="p.media_recensioni > 4.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="p.media_recensioni <= 0.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="p.media_recensioni <= 1.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="p.media_recensioni <= 2.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="p.media_recensioni <= 3.5">
                  <img src="assets/icon/logo_grey.png" class="rating" *ngIf="p.media_recensioni <= 4.5">
                  {{p.media_recensioni}}/5
                </div>
              </ion-col>
              <ion-col size="12" style="margin-bottom: 16px;" *ngIf="p.media_recensioni == 0.0">
              </ion-col>
              <!-- OLD VERSION: Nessuna recensione
              <ion-col size="12" style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; padding-top: 4px;" *ngIf="p.media_recensioni == 0.0">
                <div class="product-grid-card-rating">
                  <ion-text style="font-size: 14px;"><i>Nessuna recensione</i></ion-text>
                </div>
              </ion-col>-->
            </ion-row>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>