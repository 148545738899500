import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'vendor-shipping-instructions-popover',
  templateUrl: 'vendor-shipping-instructions.component.html',
  styleUrls: ['./vendor-shipping-instructions.component.scss']
})
export class VendorShippingInstructionsPopover {

    email: string = 'mailto: assistenza@emplacestore.it?Subject=Richiesta di chiarimenti riguardo il processo di spedizione';
  
  constructor (private popoverProp: PopoverController) {}

  closePopup(){
    this.popoverProp.dismiss();
  }

}