<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    
        <ion-buttons slot="start">
          <ion-back-button defaultHref="home"></ion-back-button>
        </ion-buttons>
      
        <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px;"> 
          {{title}}
        </ion-title>
        <ion-title class="ion-margin" *ngIf="!platform.is('ios')" style="max-width: 100px; margin: auto; padding-left: 0px;"> {{ title }} </ion-title>
      
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" slot="fixed" scrollY="true" class="custom-padding">
  <!--
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large"> {{ title }} </ion-title>
    </ion-toolbar>
  </ion-header>-->

  <ion-spinner name="bubbles"  *ngIf="paymentLoading" class="cart-items-loading"></ion-spinner>

  <div class="msg-div" *ngIf="!paymentLoading">
    <p class="msg"> Complimenti! Grazie a questo acquisto puoi donare <b>{{nPuntiFoglia}} punti foglia!</b></p>
  </div>

  <ion-grid class="info" *ngIf="!paymentLoading">
    <ion-row>
      <ion-col size="12">
        <ion-grid class="internal">
          <!-- INFO CARD -->
          <ion-card>
            <ion-row class="internal">
              <ion-col size="3">
                <img src="assets/icon/logo_primary_green.png" class="logo">
              </ion-col>
              <ion-col size="7" class="text"><ion-text>Acquista aiutando la Terra</ion-text></ion-col>
              <ion-col size="2">
                <ion-button clear icon-only shape="round" (click)="showInfo()" class="info">
                  <ion-icon name="information"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!paymentLoading" class="pfoglia">
    <ion-row class="progress">
      <ion-col size="7" class="progress">
        <ion-progress-bar value="{{ progressValue }}" class="progress-bar-aspect"></ion-progress-bar>
      </ion-col>
      <ion-col size="4" class="progress">
        <p class="progress">{{puntiFogliaAssegnati}}<b>/{{nPuntiFoglia}}</b></p>
      </ion-col>
      <ion-col size="1" class="progress">
        <img src="assets/icon/logo_secondary_green.png" class="logo-pfoglia">
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!paymentLoading" class="don">
    <ion-row>
      <ion-col size="12">

        <ion-grid class="internal">
          <!-- RIFORESTAZIONE CARD -->
          <ion-card>
            <ion-row class="internal">
              <ion-col size="2" class="img">
                <img src="assets/images/albero_ritagliato.png" class="img"></ion-col>
              <ion-col size="5" class="text"><ion-text>Riforestazione</ion-text></ion-col>
              <ion-col size="5" class="q">
                <ion-grid class="buttons">
                  <ion-row class="footer">
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="decrementItemR()" class="btn-left">
                        <ion-icon name="remove-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-text class="quanti">{{ quotaR }}</ion-text>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="incrementItemR()" class="btn-right">
                        <ion-icon name="add-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">

        <ion-grid class="internal">
          <!-- PLASTICA CARD -->
          <ion-card>
            <ion-row class="internal">
              <ion-col size="2" class="img">
                <img src="assets/images/bottiglia_ritagliata.png" class="img">
              </ion-col>
              <ion-col size="5" class="text"><ion-text>Smaltimento plastica</ion-text></ion-col>
              <ion-col size="5" class="q">
                <ion-grid class="buttons">
                  <ion-row class="footer">
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="decrementItemP()" class="btn-left">
                        <ion-icon name="remove-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-text class="quanti">{{ quotaP }}</ion-text>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="incrementItemP()" class="btn-right">
                        <ion-icon name="add-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>
    
    <ion-row>
      <ion-col size="12">

        <ion-grid class="internal">
          <!-- SOCIALI CARD -->
          <ion-card>
            <ion-row class="internal">
              <ion-col size="2" class="img">
                <img src="assets/images/casa_ritagliata.png" class="img">
              </ion-col>
              <ion-col size="5" class="text"><ion-text>Sociale</ion-text></ion-col>
              <ion-col size="5" class="q">
                <ion-grid class="buttons">
                  <ion-row class="footer">
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="decrementItemS()" class="btn-left">
                        <ion-icon name="remove-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-text class="quanti">{{ quotaS }}</ion-text>
                    </ion-col>
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="footer">
                      <ion-button fill="clear" color="primary" (click)="incrementItemS()" class="btn-right">
                        <ion-icon name="add-circle-outline" class="btn"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>

  </ion-grid>

  <div class="msg-div" *ngIf="!paymentLoading">
    <!--<p class="msg"> Il venditore ha messo a disposizione una parte del prezzo di questi articoli per finanziare la <a>riforestazione</a>, lo <a>smaltimento plastica</a> e numerosi <a>progetti sociali</a> </p>-->
    <p class="msg"> <b><i>Decidi tu come distribuire le quote della donazione,</i></b> altrimenti la donazione verrà spartita automaticamente.  </p>
    <ion-button (click)="toPayment()" color="primary" class="pay" disabled="{{paymentLoading}}">
      Termina e paga
    </ion-button>
  </div>
  <!-- Potremmo inserire dei link su 'riforestazione', 'smaltimento plastica' etc per portare alla pagina 'Chi siamo'. 
       Lì, potremmo mostrare la lista di servizi che operano con noi.
  -->

</ion-content>