<ion-content>
    <ion-spinner name="bubbles"  *ngIf="isLoading"></ion-spinner>

    <div class="sp-main" *ngIf="!isLoading">
        <ion-grid>
            <ion-row style="margin: auto;">
                <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" style="margin: auto;">
                    <ion-button class="swipe-btn" icon-only clear shape="round" 
                    *ngIf="this.hasPreviousProduct() && !this.platform.is('ios')" (click)="this.moveToPreviousProduct()" color="primary">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </ion-button><!-- Prev product btn -->
                    <ion-button class="swipe-btn-ios" icon-only clear shape="round" 
                    *ngIf="this.hasPreviousProduct() && this.platform.is('ios')" (click)="this.moveToPreviousProduct()" color="primary">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </ion-button><!-- Prev product btn iOs-->
                </ion-col>
                <ion-col size-xs="8" size-sm="8" size-md="8" size-lg="8" size-xl="8" style="margin: auto;">
                    <ion-card class="resize" (click)="this.toProductDetail()">
                        <img *ngIf="prodotto['immagini'].length>0" src="{{ constantUtilsService.getFullURL(prodotto['immagini'][0]['immagine']) }}" onerror="this.src='assets/placeholders/product.jpg'"/>
                        <img *ngIf="prodotto['immagini'].length === 0" src="assets/placeholders/product.jpg"/>
                    </ion-card>
                </ion-col>
                <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" style="margin: auto;">
                    <ion-button class="swipe-btn" icon-only clear shape="round" 
                    *ngIf="this.hasNextProduct() && !this.platform.is('ios')" (click)="this.moveToNextProduct()" color="primary">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </ion-button><!-- Next product btn -->
                    <ion-button class="swipe-btn-ios" icon-only clear shape="round" 
                    *ngIf="this.hasNextProduct() && this.platform.is('ios')" (click)="this.moveToNextProduct()" color="primary">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </ion-button><!-- Next product btn -->
                </ion-col>
            </ion-row>
        </ion-grid>
        
        <div class="sp-title margin-light">
            <p><ion-text class="sp-main-name" color="primary" (click)="this.toProductDetail()"><strong>{{prodotto.nome}}</strong></ion-text></p>
            <p><ion-text class="sp-price" (click)="this.toProductDetail()">{{prodotto.prezzo_vetrina}} €</ion-text></p>
            <p style="margin-bottom: 8px;">
                <ion-text class="sp-vendor-name">Venduto da: </ion-text><ion-text (click)="toVendor()" color="sea">{{prodotto.venditore.titolo}}</ion-text>
            </p>
            <p *ngIf="prodotto.venditore.associazione.nome!='Nessuna'" style="margin-top: 8px;">
                <ion-text class="sp-vendor-name">Associato a: </ion-text><ion-text color="sea">{{prodotto.venditore.associazione.nome}}</ion-text>
            </p>
            <p *ngIf="prodotto.venditore.verificato" style="font-size: 14px; margin-top: -8px;"><ion-text class="sp-vendor-name" color="primary" style="font-size: 18px;">Venditore verificato</ion-text></p>

            <div class="sp-availability-section ion-margin-top">
                <div class="sp-availability-unavailable" *ngIf="prodotto.quantita_disponibile==0">
                    <ion-badge color="danger">Prodotto non disponibile</ion-badge>
                </div>
                <div class="sp-availability-few" *ngIf="prodotto.quantita_disponibile > 0 && prodotto.quantita_disponibile <= 5">
                    <ion-badge color="warning" *ngIf="prodotto.quantita_disponibile!=1">Solo {{prodotto.quantita_disponibile}} disponibili</ion-badge>
                    <ion-badge color="warning" *ngIf="prodotto.quantita_disponibile==1">Solo {{prodotto.quantita_disponibile}} disponibile</ion-badge>
                </div>
                <div class="sp-availability-ok" *ngIf="prodotto.quantita_disponibile > 5">
                    <ion-badge color="sea">Disponibilità immediata</ion-badge>
                </div>
            </div>

            <!-- PRE-FOOTER -->
            <!--
            <div class="swipe-dettagli-div">
                <a (click)="toProductDetail()">..dettagli</a>
            </div>-->

        </div>

        <div class="sp-description margin-light">
            <ion-grid style="padding: 0px; margin: 0px;">
                <ion-row>
                    <!-- TAGs -->
                    <ion-col size="12" style="padding-left: 0px; padding-right: 0px;" *ngIf="prodotto.TAGSost && prodotto.TAGSost.length>0">
                        <ion-text class="sp-description-title" (click)="this.toProductDetail()">Tags</ion-text>
                    </ion-col>
                    <ion-col size="12" *ngIf="prodotto.TAGSost && prodotto.TAGSost.length>0" style="margin-bottom: 18px;" (click)="this.toProductDetail()">
                        <ion-badge *ngFor="let tag of prodotto.TAGSost" color="secondary" style="margin-right: 8px; padding-left: 6px; padding-right: 6px;">{{TAGNameToFullName[tag.nome]}}</ion-badge>
                    </ion-col>
                    <!-- Descrizione -->
                    <ion-col size="12" style="padding-left: 0px; padding-right: 0px;">
                        <ion-text class="sp-description-title" (click)="this.toProductDetail()">Descrizione</ion-text>
                        <ion-button class="details-btn" shape="round" (click)="this.toProductDetail()" 
                        *ngIf="!this.platform.is('ios')" color="primary">
                            <ion-icon name="information"></ion-icon>
                        </ion-button><!--Product details btn -->
                        <ion-button class="details-btn-ios" shape="round" (click)="this.toProductDetail()" 
                        *ngIf="this.platform.is('ios')" color="primary">
                            <ion-icon name="information"></ion-icon>
                        </ion-button><!--Product details btn -->
                    </ion-col>
                    <ion-col size="12">
                        <ion-text class="sp-description-text" *ngIf="prodotto.descrizione">{{prodotto.descrizione}}</ion-text>
                    </ion-col>
                    <ion-col size="12">
                        <ion-text class="sp-description-text" *ngIf="!prodotto.descrizione">Nessuna descrizine per questo prodotto.</ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>

        <div class="sp-description margin-light"  *ngIf="prodotto.why_eco">
            <ion-grid style="padding: 0px; margin: 0px;">
                <ion-row>
                    <ion-col size="12" style="padding-left: 0px; padding-right: 0px;">
                        <ion-text class="sp-description-title" color="primary" style="font-size: 18px;">Perchè è Ecosostenibile</ion-text>
                        <ion-button class="details-btn" shape="round" (click)="this.toProductDetail()" 
                        *ngIf="!this.platform.is('ios')" color="primary">
                            <ion-icon name="information"></ion-icon>
                        </ion-button><!--Product details btn -->
                        <ion-button class="details-btn-ios" shape="round" (click)="this.toProductDetail()" 
                        *ngIf="this.platform.is('ios')" color="primary">
                            <ion-icon name="information"></ion-icon>
                        </ion-button><!--Product details btn -->
                    </ion-col>
                    <ion-col size="12">
                        <ion-text class="sp-description-text">{{prodotto.why_eco}}</ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>

        <!-- PRE-FOOTER -->
        <!--
        <div class="div-buttons" 
            *ngIf="!this.userService.userIsVendor()">
            <ion-button class="right" (click)="onInsertToCart()">
              Inserisci nel carrello
              <ion-icon name="cart" class="to-cart-icon"></ion-icon>
            </ion-button>
        </div>-->
        
        <!-- Recensioni -->
        <div class="sp-reviews ion-margin-top margin-light" style="padding-left: 0px; padding-right: 0px;">
            <div style="display: flex" >
                <ion-text class="sp-reviews-title" >Recensioni</ion-text>
                <div class="sp-reviews-avg-rating" *ngIf="prodotto.media_recensioni > 0">
                    <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="prodotto.media_recensioni > 0.5"/>
                    <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="prodotto.media_recensioni > 1.5"/>
                    <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="prodotto.media_recensioni > 2.5"/>
                    <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="prodotto.media_recensioni > 3.5"/>
                    <img src="assets/icon/logo_secondary_green.png" class="rating" *ngIf="prodotto.media_recensioni > 4.5"/>
                    <img src="assets/icon/logo_grey.png" class="rating" *ngIf="prodotto.media_recensioni <= 0.5"/>
                    <img src="assets/icon/logo_grey.png" class="rating" *ngIf="prodotto.media_recensioni <= 1.5"/>
                    <img src="assets/icon/logo_grey.png" class="rating" *ngIf="prodotto.media_recensioni <= 2.5"/>
                    <img src="assets/icon/logo_grey.png" class="rating" *ngIf="prodotto.media_recensioni <= 3.5"/>
                    <img src="assets/icon/logo_grey.png" class="rating" *ngIf="prodotto.media_recensioni <= 4.5"/>
                </div>
            </div>

            <div class="reviews-btn-div right">
                <ion-button class="reviews-btn" shape="round" *ngIf="prodotto.media_recensioni >= 0 && !this.platform.is('ios')" 
                    (click)="this.moveToReviewsPage()" color="primary">
                    <ion-icon name="information"></ion-icon>
                </ion-button><!--Reviews details btn -->
                <ion-button class="reviews-btn-ios" shape="round" *ngIf="prodotto.media_recensioni >= 0 && this.platform.is('ios')" 
                    (click)="this.moveToReviewsPage()" color="primary">
                    <ion-icon name="information"></ion-icon>
                </ion-button><!--Reviews details btn -->
            </div>
        </div>
        <p style="margin-top: 0px; text-align: left; padding-left: 12px;" *ngIf="prodotto.media_recensioni == 0">
            <ion-text style="font-size: 16px;">
                <i>Nessuna recensione</i>
            </ion-text>
        </p>
        <!-- Recensioni -->
        
        <!-- PRE-FOOTER -->
        <!--
        <div class="swipe-dettagli-div">
            <a (click)="toProductDetail()">..per saperne di più</a>
        </div>-->
    </div>
</ion-content>

<!-- 
<ion-fab *ngIf="prodotto.quantita_disponibile > 0"
    vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="success" >
        <ion-icon name="cart"></ion-icon>
    </ion-fab-button>
</ion-fab> -->