import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss'],
})
export class CheckoutSuccessComponent implements OnInit {

  title: string = 'Checkout';
  //back: string = 'checkout'; // Non credo ci vada il pulsante back qui

  constructor(private router: Router, public platform: Platform, private logger: NGXLogger) { }

  ngOnInit() {}

  toOrders(){
    this.logger.log('CheckoutSuccessComponent: toOrders...');
    this.router.navigateByUrl('myprofile/user/buyer/orders');
  }

  toUserProfile(){
    this.logger.log('CheckoutSuccessComponent: toUserProfile...');
    this.router.navigateByUrl('myprofile/user');
  }

  toHome(){
    this.logger.log('CheckoutSuccessComponent: toHome...');
    this.router.navigateByUrl('home');
  }

}
