<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title *ngIf="!platform.is('ios')" style="max-width: 200px; margin: auto; padding-left: 8px;">Spedizioni e resi</ion-title>
    <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
      Spedizioni e resi
    </ion-title>
  </ion-toolbar>
</ion-header>
  
<ion-content [fullscreen]="true" style="padding-top: 0px; height: 100%;">

  <ion-grid style="margin: auto; max-width: 600px;">
    <ion-row>
      <ion-col size="12">
        <p style="font-size: 18px; margin-top: 36px;">{{title1}}<strong>{{title2}}</strong></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p><strong>Spedizioni</strong></p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo1}}<strong>{{testo2}}</strong>{{testo3}}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo4}}<a href="{{testo5}}" style="color: var(--ion-color-sea);">{{testo5}}</a></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;"><strong>{{testo6}}</strong></p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo7}}<strong>{{testo8}}</strong>{{testo9}}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo10}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo11}}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;"><a href="{{testo12}}" style="color: var(--ion-color-sea);">{{testo12}}</a></p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px; margin-bottom: 0px;">{{testo13}}<strong>{{testo14}}</strong></p>
      </ion-col>
      <ion-col size="12">
        <ul style="margin-top: 0px;">
          <li>
            <p style="font-size: 14px; margin-top: 4px; margin-bottom: 4px;">{{punto1}}</p>
          </li>
          <li>
            <p style="font-size: 14px; margin-top: 4px; margin-bottom: 4px;">{{punto2}}</p>
          </li>
          <li>
            <p style="font-size: 14px; margin-top: 4px; margin-bottom: 4px;">{{punto3}}</p>
          </li>
        </ul>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo15}}<strong>{{testo16}}</strong>{{testo17}}<strong>{{testo18}}</strong>{{testo19}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo20}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo21}}<strong>{{testo22}}</strong>{{testo23}}<strong>{{testo24}}</strong>{{testo25}}<strong>{{testo26}}</strong>{{testo27}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo28}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo29}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo30}}<strong>{{testo31}}</strong>{{testo32}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="margin-top: 24px;"><strong>Resi</strong></p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo33}}<strong>{{testo34}}</strong>{{testo35}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo36}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo37}}<strong>{{testo38}}</strong>{{testo39}}
          <i>{{testo39_1}}</i>{{testo39_2}}<strong>{{testo39_3}}</strong>{{testo39_4}}
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo40}}<strong>{{testo41}}</strong>{{testo42}}<strong>{{testo43}}</strong>{{testo44}}
        <i>{{testo44_1}}</i>{{testo44_2}}<strong>{{testo44_3}}</strong>{{testo44_4}}
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo45}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo46}}<a href="{{testo47}}" style="color: var(--ion-color-sea);">{{testo47}}</a></p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo48}}<strong>{{testo49}}</strong>{{testo50}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo51}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo52}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo53}}</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <p style="font-size: 14px; margin-top: 0px;">{{testo54}}</p>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>