import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AutoCompleteService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/autocomplete/products/')
  error = new Subject<string>();


  // HTTP GET
  fetchProductsByParams(){
    const params = null; 
    return this.fetchProducts(this.defaultUrl, params); 
  }

  fetchProductsByURL(url: string){
    return this.fetchProducts(url, null)
  }

  fetchProducts(url, searchParams){

    return this.http
    .get(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,     
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  // HTTP DELETE
  deletePosts() {
    return this.http
      .delete(this.defaultUrl, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }

  fetchProductsByUrlAndParams(url, params){
    if(url!=null){
      // after the first time
      return this.fetchProducts(url,params);
    }else{
      // the request for the first 10 objects
      return this.fetchProducts(this.defaultUrl,params);
    }
  }

}
