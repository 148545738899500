import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams, HttpEventType, HttpEvent} from "@angular/common/http";
import {map, catchError, tap} from "rxjs/operators";
import {Subject, Subscription, throwError} from "rxjs";
import {SezioneHome} from "../models/SezioneHome.model";
import { ConstantUtilsService } from "./constant-utils.service";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class HomeService {

  constructor(private http : HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}

  private defaultURL = this.constantUtils.getFullURL(this.constantUtils.API_PATH+"/home-sections/");

  fetchHomeSections() {
    return this.http.get<SezioneHome[]>(this.defaultURL, {
      headers: new HttpHeaders({"Content-Type": "application/json"}),
      /*params: searchParams,*/
      responseType: "json"
    }).pipe(
      map((responseData) => {
        this.logger.log("HomeService", "fetchHomeSections", "responseData", responseData)
        return <SezioneHome[]>responseData; 
      }), 
      catchError((errorRes) => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }
}