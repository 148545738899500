import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { AutoCompleteService } from 'src/app/shared/services/autocomplete.service';
import { PaginaProdottiComponent } from './pagina-prodotti/pagina-prodotti.component';
import { GrigliaProdottiModule } from './griglia-prodotti/griglia-prodotti.module';
import { GetProdottiFilteredService } from '../shared/services/getProdottiFiltered.service';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { DeleteProductPopover } from './griglia-prodotti/delete-product-popover/delete-product-popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GrigliaProdottiModule,
    RouterModule.forChild([
      { path: '', component: PaginaProdottiComponent, pathMatch: "full"},
      {
        path: ':id/reviews',
        loadChildren: () => import('./product-reviews/product-reviews.module').then( m => m.ProductReviewsModule),
      },
      {
        path: ':id/detail',
        loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailModule),
      },
      { path: ':type/:id', component: PaginaProdottiComponent},
      { path: 'category/:id', component: PaginaProdottiComponent},
      { path: '**', redirectTo: '404' },
      { path: '404', component: PageNotFoundComponent}
    ])
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [AutoCompleteService, GetProdottiFilteredService],
  declarations: [PaginaProdottiComponent],
  exports: [PaginaProdottiComponent]
})
export class ProductsModule {}