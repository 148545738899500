import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { of, Subject, throwError } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { AuthService } from './auth.service';
import { UserService } from './User.service';
import { catchError, map } from 'rxjs/operators';
import { VendorOrderItem, VendorOrdersListResponse } from '../models/Ordini.models';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class VendorOrderService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger,
    private userService: UserService) {}
    
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/orders');
  private UPDATE_ORDER_SUCCESS: boolean = true;
  private UPDATE_ORDER_ERROR: boolean = false;

  toBeUpdated: boolean = false;
  firstCall: boolean = true;
  ordersCounter: number = 0;
  error = new Subject<string>();

  resetOrdersCount(){
    this.ordersCounter = 0;
    this.toBeUpdated = false;
    this.firstCall = true;
  }

  fetchOrders(searchParams){
    if(!this.userService.userIsVendor()){
      this.logger.log("VendorOrderService - fetchOrders", "user is not a Vendor. Exiting.");
      return of(null)
    }

    // Vecchia versione con la url parametro e la possibilità di fare 'next'
    /*if(!url){
      url = this.defaultUrl;
    }*/

    return this.http
    .get<VendorOrderItem[]>(
      this.defaultUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
        responseType: 'json'
      }
    )
    .pipe(
      map((responseData:VendorOrderItem[]) => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  fetchOrderDetail(orderID){
    if(!this.userService.userIsVendor()){
      this.logger.log("VendorOrderService - fetchOrders", "user is not a Vendor. Exiting.");
      return of(null)
    }

    // L'API restituisce una lista di cui prenderai il primo oggetto
    return this.http
    .get<VendorOrderItem>(
      this.defaultUrl+'/'+orderID+'/',
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map((responseData:VendorOrderItem) => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  updateVendorOrder(orderID, tracking: string, trasportatore: string, prefisso: string, telefono: string){
    if(!this.userService.userIsVendor()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("VendorOrderService", "updateVendorOrder: Attempt of editind an order, but user is not a vendor");
      return Promise.resolve(false);
    }

    if(!orderID || (!tracking && !telefono)){
      if(!orderID){
        console.error("VendorOrderService", "updateVendorOrder: Attempt of editing an order, but order ID is empty");
      }else{
        console.error("VendorOrderService", "updateVendorOrder: Attempt of editing an order, but tracking code and telephone n are empty");
      }
      return Promise.resolve(false);
    }

    const request_url = this.defaultUrl + '/' + orderID + '/';
    this.logger.log(request_url); // DEBUG

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    const newInfo = {
      codice_tracking: tracking,
      shipping_dealer: trasportatore,
      prefisso: prefisso,
      telefono: telefono
    }

    this.logger.log(newInfo);    // DEBUG

    return this.http
      .put(request_url, newInfo, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData) => {
          this.logger.log("updateVendorOrder - Product update result", responseData)
          return this.UPDATE_ORDER_SUCCESS;
        },
        errorRes => {
          this.logger.log("updateVendorOrder - Product update error", errorRes)
          return this.UPDATE_ORDER_ERROR;
        }
      )
  }


  deliverVendorOrder(orderID){
    if(!this.userService.userIsVendor()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("VendorOrderService", "updateVendorOrder: Attempt of editind an order, but user is not a vendor");
      return Promise.resolve(false);
    }

    if(!orderID){
      console.error("VendorOrderService", "updateVendorOrder: Attempt of editing an order, but order ID is empty");
      return Promise.resolve(false);
    }

    const request_url = this.defaultUrl + '/' + orderID + '/';
    this.logger.log(request_url); // DEBUG

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    const newInfo = {
      ordine_consegnato: 'consegnato'
    }

    return this.http
      .put(request_url, newInfo, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData) => {
          this.logger.log("updateVendorOrder - Product update result", responseData)
          return this.UPDATE_ORDER_SUCCESS;
        },
        errorRes => {
          this.logger.log("updateVendorOrder - Product update error", errorRes)
          return this.UPDATE_ORDER_ERROR;
        }
      )
  }
}