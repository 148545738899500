import {Injectable} from "@angular/core";


@Injectable()
export class AddressUtilsService {
  
    provincia_regione_matrix = [
        'ROMA','Lazio', 
        'VITERBO','Lazio', 
        'RIETI','Lazio', 
        'FROSINONE','Lazio',  
        'LATINA','Lazio', 

        'TERNI','Umbria', 
        'PERUGIA','Umbria', 

        'SASSARI','Sardegna', 
        'NUORO','Sardegna', 
        'CAGLIARI','Sardegna', 
        'ORISTANO','Sardegna', 

        'AOSTA','Valle D\'Aosta', 

        'TORINO','Piemonte', 
        'CUNEO','Piemonte', 
        'VERCELLI','Piemonte', 
        'BIELLA','Piemonte',  
        'ASTI','Piemonte', 
        'ALESSANDRIA','Piemonte',
        'NOVARA','Piemonte',  
        'VERBANIA','Piemonte', 


        'GENOVA','Liguria',  
        'SAVONA','Liguria',  
        'IMPERIA','Liguria',  
        'LA SPEZIA','Liguria', 

        'MILANO','Lombardia',  
        'MONZA','Lombardia', 
        'VARESE','Lombardia',  
        'COMO','Lombardia',  
        'SONDRIO','Lombardia', 
        'LECCO','Lombardia', 
        'BERGAMO','Lombardia',  
        'BRESCIA','Lombardia', 
        'CREMONA','Lombardia',  
        'LODI','Lombardia',  
        'PAVIA','Lombardia', 
        'MONZA E BRIANZA','Lombardia', 
        'MANTOVA','Lombardia', 

        'VENEZIA','Veneto', 
        'TREVISO','Veneto', 
        'BELLUNO','Veneto', 
        'PADOVA','Veneto', 
        'VICENZA','Veneto', 
        'VERONA','Veneto', 
        'ROVIGO','Veneto', 

        'UDINE', 'Friuli Venezia Giulia',
        'PORDENONE', 'Friuli Venezia Giulia',  
        'TRIESTE', 'Friuli Venezia Giulia', 
        'GORIZIA', 'Friuli Venezia Giulia',  

        'TRENTO', 'Trentino Alto Adige', 
        'BOLZANO', 'Trentino Alto Adige', 

        'BOLOGNA','Emilia Romagna', 
        'MODENA','Emilia Romagna', 
        'REGGIO EMILIA','Emilia Romagna', 
        'PARMA','Emilia Romagna', 
        'FERRARA','Emilia Romagna', 
        'FORLI','Emilia Romagna',  
        'RIMINI','Emilia Romagna', 
        'RAVENNA','Emilia Romagna',  
        'CESENA','Emilia Romagna',
        'PIACENZA','Emilia Romagna', 

        'FIRENZE','Toscana',  
        'PISTOIA','Toscana',  
        'AREZZO',' Toscana', 
        'SIENA','Toscana',  
        'MASSA','Toscana',  
        'LUCCA','Toscana',  
        'PISA',' Toscana', 
        'LIVORNO',' Toscana', 
        'GROSSETO','Toscana',  
        'PRATO','Toscana',  

        'ANCONA',' Marche', 
        'PESARO','Marche',  
        'MACERATA','Marche',  
        'ASCOLI PICENO','Marche',  
        'FERMO','Marche',  

        'TERAMO','Abbruzzo', 
        'PESCARA','Abbruzzo', 
        'CHIETI','Abbruzzo', 
        'L\'AQUILA','Abbruzzo',  

        'BARI',' Puglia', 
        'FOGGIA','Puglia', 
        'BRINDISI', 'Puglia', 
        'LECCE','Puglia', 
        'TARANTO','Puglia', 
        'BARLETTA','Puglia',
        'ANDRIA','Puglia',
        'TRANI','Puglia',

        'NAPOLI','Campania',  
        'CASERTA','Campania', 
        'BENEVENTO','Campania',  
        'AVELLINO','Campania', 
        'SALERNO','Campania',  

        'POTENZA','Basilicata', 
        'MATERA','Basilicata', 

        'CAMPOBASSO','Molise', 
        'ISERNIA','Molise', 

        'COSENZA','Calabria',  
        'CATANZARO','Calabria',
        'CROTONE','Calabria', 
        'REGGIO CALABRIA','Calabria',
        'VIBO VALENTIA','Calabria',  

        'PALERMO','Sicilia', 
        'TRAPANI','Sicilia', 
        'AGRIGENTO','Sicilia',  
        'CALTANISSETTA','Sicilia',  
        'ENNA', 'Sicilia',
        'CATANIA','Sicilia',  
        'SIRACUSA','Sicilia', 
        'RAGUSA','Sicilia',  
        'MESSINA','Sicilia' 
    ]

    province_array = [
        'ROMA',  
        'VITERBO',  
        'RIETI',  
        'FROSINONE',   
        'LATINA',  

        'TERNI',  
        'PERUGIA',  

        'SASSARI',  
        'NUORO',  
        'CAGLIARI',  
        'ORISTANO',  

        'AOSTA',

        'TORINO',
        'CUNEO',  
        'VERCELLI',  
        'BIELLA',   
        'ASTI',  
        'ALESSANDRIA', 
        'NOVARA',   
        'VERBANIA',  


        'GENOVA',   
        'SAVONA',   
        'IMPERIA',   
        'LA SPEZIA',  

        'MILANO',   
        'MONZA', 
        'VARESE',   
        'COMO',
        'SONDRIO',  
        'LECCO', 
        'BERGAMO',   
        'BRESCIA',  
        'CREMONA',   
        'LODI',   
        'PAVIA',  
        'MONZA E BRIANZA',  
        'MANTOVA',  

        'VENEZIA',  
        'TREVISO',  
        'BELLUNO',  
        'PADOVA',  
        'VICENZA',  
        'VERONA',  
        'ROVIGO',  

        'UDINE',  
        'PORDENONE',    
        'TRIESTE',   
        'GORIZIA',    

        'TRENTO',   
        'BOLZANO',   

        'BOLOGNA', 
        'MODENA', 
        'REGGIO EMILIA',  
        'PARMA', 
        'FERRARA', 
        'FORLI',   
        'RIMINI', 
        'RAVENNA',  
        'CESENA', 
        'PIACENZA',  

        'FIRENZE',   
        'PISTOIA',   
        'AREZZO',
        'SIENA',  
        'MASSA',  
        'LUCCA',  
        'PISA', 
        'LIVORNO',
        'GROSSETO',   
        'PRATO',   

        'ANCONA', 
        'PESARO',   
        'MACERATA',   
        'ASCOLI PICENO',   
        'FERMO', 

        'TERAMO', 
        'PESCARA',  
        'CHIETI',  
        'L\'AQUILA',   

        'BARI',  
        'FOGGIA',  
        'BRINDISI',  
        'LECCE',  
        'TARANTO',  
        'BARLETTA', 
        'ANDRIA', 
        'TRANI', 

        'NAPOLI',  
        'CASERTA', 
        'BENEVENTO',   
        'AVELLINO',  
        'SALERNO',   

        'POTENZA',  
        'MATERA',  

        'CAMPOBASSO',  
        'ISERNIA',  

        'COSENZA',   
        'CATANZARO', 
        'CROTONE',  
        'REGGIO CALABRIA', 
        'VIBO VALENTIA',   

        'PALERMO',  
        'TRAPANI',  
        'AGRIGENTO',   
        'CALTANISSETTA',   
        'ENNA',
        'CATANIA',   
        'SIRACUSA',  
        'RAGUSA',   
        'MESSINA'
    ]

    constructor() {}

    getRegioneTramiteProvincia(provincia: string){
        const provincia_UC = provincia.toUpperCase();
        var index = 0;
        while(index < this.provincia_regione_matrix.length){
            if(this.provincia_regione_matrix[index]==provincia_UC){
                return this.provincia_regione_matrix[index+1];
            }
            index = index + 2;
        }
        return null;
    }

}