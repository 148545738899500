import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable } from 'rxjs';
  import { exhaustMap, take } from 'rxjs/operators';
  import { AuthService } from './auth.service';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';
  
  @Injectable()
  export class AuthInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (!this.constantUtils.isEmplaceBackend(req.url)) {
            this.logger.log('Richiesta non intercettata: non è per una URL di Emplace backend...'); // DEBUG
            return next.handle(req);
        }

        // If the user is not logged in, there is nothing to inject
        if(!this.authService.isLoggedIn()){
          this.logger.log('Richiesta non intercettata: utente NON loggato...'); // DEBUG
            return next.handle(req);
        }

        // If the request is for Emplace backend, get the user access token and inject it into the outgoing request
        var accessToken = null;
        if(this.authService.userTokenData){
          accessToken = this.authService.userTokenData.access_token;
        }else if(this.authService.manualUserTokenData){
          accessToken = this.authService.manualUserTokenData.access_token;
          this.logger.log('exp time: '+this.authService.manualUserTokenData.expiration_date); // DEBUG
        }else{
          this.logger.log('AuthInterceptor: non è stato trovato ne token social ne token basic auth');
          return;
        }

        const modifiedReq = req.clone({
            headers: new HttpHeaders({ 
                'Authorization': "Bearer "+ accessToken
            })
        });
        this.logger.log('REQUEST HEADERS:')   // DEBUG
        this.logger.log(modifiedReq.headers);
        return next.handle(modifiedReq);
    }
  }
  