import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AnimalPopover } from './user-profile/animal-popover/animal-popover.component';
import { UserEditProfileComponent } from './user-edit-data/user-edit-data.component';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { UserOrderItemComponent } from './user-orders/user-order-item/user-order-item.component';
import { DonationPopover } from './user-profile/donation-popover/donation-popover.component';
import { UserRemoveDataPopover } from './user-edit-data/user-remove-data-popover/user-remove-data-popover.component';
import { BuyerReviewsComponent } from './user-reviews/user-reviews.component';
import { ProductReviewItemComponent } from '../products/product-reviews/product-review-item/product-review-item.component';
import { BuyerReviewItemComponent } from './user-reviews/buyer-review-item/buyer-review-item.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: UserProfileComponent},
      { path: 'user/', component: UserProfileComponent}
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  declarations: [
    UserProfileComponent, 
    AnimalPopover,
    DonationPopover, 
    UserEditProfileComponent, 
    UserOrdersComponent,
    UserOrderItemComponent,
    UserRemoveDataPopover,
    BuyerReviewsComponent,
    BuyerReviewItemComponent
  ]
})
export class UserModule {}