import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpParams
} from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ConstantUtilsService } from './constant-utils.service';
import { StripeConfig } from '../models/BuyersOrdersSession.models';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class BuyersOrdersService {
  
  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/buyers/orders/');
  private stripeConfig_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/payments/stripe/config');
  error = new Subject<string>();


  createSessionRequest(name: string, surname: string, address: string, CF: string, 
    partitaIVA: string, prefisso: string, telefono: string, note: string, quotaR: Number, quotaP: Number, quotaS: Number){
      this.logger.log('BuyersOrders.createSessionRequest chiamato con note: '+note);
      var body = {
          nome: name,
          cognome: surname,
          indirizzo: address,
          partitaiva: null,
          codicefiscale: CF,  
          pagamento: "stripe",  
          prefisso: prefisso,
          telefono: telefono,  
          riforestazione: quotaR,
          smaltimento_plastica: quotaP,
          progetto_sociale: quotaS
      }
      this.logger.log(body);
      if(name!=null && surname!=null && address!=null && telefono!=null){
        return this.sendSessionRequest_Post(body);
      }else{
        return throwError("BODY non conforme");
      }
  }

  private sendSessionRequest_Post(body: any) { 
    return this.http
      .post<{res: string}>(
        this.url,
        body,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  requestStripeConfig(){
    this.logger.log('BuyersOrders.requestStripeConfig lanciato');  
    return this.stripeConfig();
  }

  private stripeConfig(){
    return this.http
      .get<StripeConfig>(
        this.stripeConfig_url,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          /*params: params,*/                                     
          responseType: 'json',
          observe: 'response'
        }
      ).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      );
  }

}