import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Prodotti } from '../models/Prodotti.model';
import { ConstantUtilsService } from './constant-utils.service';

@Injectable()
export class CategoryProductsService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService) {}
  
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/category-products/');
  error = new Subject<string>();

  // HTTP GET
  fetchProductsByParams(categoryID: number){
    // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
    /*let searchParams = new HttpParams();
    searchParams = searchParams.append('print', 'pretty');
    searchParams = searchParams.append('custom', 'key');*/ 
    const params = null;
    // TODO take search params 
    const url = this.defaultUrl + categoryID + "/"
    return this.fetchProducts(url, params); 
  }

  fetchProductsByURL(url: string){
    return this.fetchProducts(url, null)
  }

  fetchProducts(url, searchParams){

    return this.http
    .get<Prodotti>(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

}
