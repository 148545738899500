// CONVERT TOKEN OPTIONS
export const CT_LOGIN_IF_EXISTS = true;
export const CT_ERROR_IF_EXISTS = false;

/***************************
 *  RESULT SUCCESS VALUES  *
 ***************************/

// SOCIAL LOGIN - GENERAL
export const SL_RESULT_SUCCESS = true;
export const SL_RESULT_ERROR = false;

// EMPLACE - LOGIN
export const E_L_RESULT_SUCCESS = true;
export const E_L_RESULT_ERROR = false;

// EMPLACE - GET USER DATA
export const E_GUD_RESULT_SUCCESS = true;
export const E_GUD_RESULT_ERROR = false;

// EMPLACE - REFRESH TOKEN
export const E_RT_RESULT_SUCCESS = true;
export const E_RT_RESULT_ERROR = false;

// EMPLACE - CONVERT SERVER CODE
export const E_CSC_RESULT_SUCCESS = true;
export const E_CSC_RESULT_ERROR = false;

// EMPLACE - CONVERT TOKEN
export const E_CT_RESULT_SUCCESS = true;
export const E_CT_RESULT_ERROR = false;

// EMPLACE - REGISTRATION
export const E_R_RESULT_SUCCESS = true;
export const E_R_RESULT_ERROR = false;

// GOOGLE - LOGIN
export const G_L_RESULT_SUCCESS = true;
export const G_L_RESULT_ERROR = false;

// GOOGLE - REGISTRATION
export const G_R_RESULT_SUCCESS = true;
export const G_R_RESULT_ERROR = false;

// FACEBOOK - LOGIN
export const F_L_RESULT_SUCCESS = true;
export const F_L_RESULT_ERROR = false;

// FACEBOOK - LOGIN
export const F_R_RESULT_SUCCESS = true;
export const F_R_RESULT_ERROR = false;

// APPLE - LOGIN
export const A_L_RESULT_SUCCESS = true;
export const A_L_RESULT_ERROR = false;

// APPLE - REGISTRATION
export const A_R_RESULT_SUCCESS = true;
export const A_R_RESULT_ERROR = false;

/***************************
 *   RESULT CODES VALUES   *
 ***************************/
export const SL_CODE_GENERIC_ERROR = "SL_CODE_GENERIC_ERROR"
export const SL_CODE_UNEXPECTED_BACKEND = "SL_CODE_UNEXPECTED_BACKEND"
export const SL_CODE_SUCCESS = "SL_CODE_SUCCESS"

export const E_CODE_ERROR_CONNECTION = "E_CODE_ERROR_CONNECTION"

// EMPLACE LOGIN
export const E_CODE_L_NOT_FOUND = "E_CODE_L_NOT_FOUND"
export const E_CODE_L_SUCCESS = "E_CODE_L_SUCCESS"

// EMPLACE GET USER DATA
export const E_CODE_GUD_TOKEN_INVALID = "E_CODE_GUD_NOT_FOUND"
export const E_CODE_GUD_SUCCESS = "E_CODE_GUD_SUCCESS"

// EMPLACE - REFRESH TOKEN
export const E_CODE_RT_SUCCESS = "E_CODE_RT_SUCCESS";
export const E_CODE_RT_TOKEN_INVALID = "E_CODE_RT_TOKEN_INVALID";

// EMPLACE - CONVERT SERVER CODE
export const E_CODE_CSC_SUCCESS = "E_CODE_CSC_SUCCESS";
export const E_CODE_CSC_CODE_INVALID = "E_CODE_CSC_CODE_INVALID";

// EMPLACE - CONVERT TOKEN
export const E_CT_CODE_SUCCESS = "E_CT_CODE_SUCCESS";
export const E_CT_CODE_INVALID_TOKEN = "E_CT_CODE_INVALID_TOKEN";
export const E_CT_CODE_ALREADY_EXISTS = "E_CT_CODE_ALREADY_EXISTS";
export const E_CT_CODE_USER_NOT_FOUND = "E_CT_CODE_USER_NOT_FOUND";
export const E_CT_CODE_GENERIC_ERROR = "E_CT_CODE_GENERIC_ERROR";

// EMPLACE - REGISTRATION
export const E_CODE_R_ALREADY_REGISTERED = "E_CODE_R_ALREADY_REGISTERED"
export const E_CODE_R_SUCCESS = "E_CODE_R_SUCCESS"

// GOOGLE LOGIN
export const G_L_CODE_SUCCESS = "G_L_CODE_SUCCESS";
export const G_L_CODE_GENERIC_ERROR = "G_L_CODE_GENERIC_ERROR";
export const G_L_CODE_POPUP_CLOSED = "G_L_CODE_POPUP_CLOSED";

// FACEBOOK LOGIN
export const F_L_CODE_SUCCESS = "F_L_CODE_SUCCESS";
export const F_L_CODE_GENERIC_ERROR = "F_L_CODE_GENERIC_ERROR";
export const F_L_CODE_POPUP_CLOSED = "F_L_CODE_POPUP_CLOSED";