<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
    <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
      Ordine
    </ion-title>

    <ion-buttons slot="start">
      <ion-back-button defaultHref="myprofile/vendor/orders"></ion-back-button>
    </ion-buttons>

    <ion-title class="ion-margin" *ngIf="!platform.is('ios')" style="max-width: 110px; margin: auto;">Ordine</ion-title>

    <ion-buttons slot="end" class="cart-btn">
      <ion-button (click)="toHome()">
        <ion-icon slot="icon-only" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="background-color: #def7df;">

  <ion-spinner class="first-order-spinner" name="bubbles"  *ngIf="isFetching"></ion-spinner>

  <div *ngIf="!isFetching && !error && !error404" style="padding: 12px;">
      <ion-grid>
        <ion-row>
          <ion-text><strong>Dettaglio dell'ordine</strong></ion-text>
        </ion-row>
        <ion-row>
          <!-- Versione ordine cliente -->
          <!--<ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
            <p class="sub-title">Nome: {{ orderDetail.nome }} </p>
            <p class="sub-title">Cognome: {{ orderDetail.cognome }} </p>
            <p class="sub-title">Telefono: {{ orderDetail.telefono }} </p>
            <p class="sub-title">Spedito da: {{ orderDetail.indirizzo['città'] }} </p>
          </ion-col>-->
          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
            <p class="sub-title" style="margin-bottom: 8px;">Ordine numero: </p>
            <p class="sub-title-order-id" style="margin-bottom: 24px;"><ion-text color="sea" style="font-size: 16px;"> {{ orderDetail.id.substring(0,8) }}</ion-text> </p>

            <!-- Eliminato poichè il venditore vede un totale che potrebbe essere maggiore del suo (per ordini con + venditori)
            <p class="sub-title">Costo: <strong>{{ orderDetail.costo_totale }} €</strong></p> -->

            <p class="sub-title" style="cursor: pointer;" (click)="toIstruzioni()">
              <ion-text color="danger" style="font-size: 16px;"><u>Istruzioni di spedizione</u></ion-text>
            </p>

            <p class="sub-title" style="cursor: pointer;" (click)="toShippingForm(null,0)">
              <ion-text color="sea"><u>Dati di spedizione e del cliente</u></ion-text>
            </p>
          </ion-col>
          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
            <p class="sub-title" style="margin-bottom: 8px;">Data di acquisto: </p>
            <p class="sub-title" style="margin-bottom: 24px;"><strong>{{ orderDetail.data_acquisto | date:"shortDate":"it-IT"}} alle ore {{orderDetail.data_acquisto | date:"shortTime":"it-IT"}}</strong> </p>

            <p class="sub-title" (click)="shippingInfoPopup()">Tutti i prodotti sono stati spediti? <ion-badge *ngIf="!allItemsShipped" color="sea" style="margin-left: 6px; margin-bottom: -4px; cursor: pointer;">No</ion-badge> <ion-badge *ngIf="allItemsShipped" color="primary" style="margin-left: 6px; margin-bottom: -4px;">Si</ion-badge></p>
            <p class="sub-title" (click)="deliveryInfoPopup()">Tutti i prodotti sono stati consegnati? <ion-badge *ngIf="!allItemsDelivered" color="sea" style="margin-left: 6px; margin-bottom: -4px; cursor: pointer;">No</ion-badge> <ion-badge *ngIf="allItemsDelivered" color="primary" style="margin-left: 6px; margin-bottom: -4px;">Si</ion-badge></p>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <p class="sub-title" *ngIf="orderDetail.note!=null" style="margin-bottom: 8px;">Note: </p>
            <p class="sub-title" *ngIf="orderDetail.note!=null"> {{ orderDetail.note }} </p>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="orderItems && orderItems[0] && orderItems[0].stato=='In Preparazione'">
          <ion-col>
            <p class="sub-title" style="margin-bottom: 8px; cursor: pointer;" (click)="toShippingForm($event,0)">
              <ion-text color="primary" style="font-size: 16px;"><u>Clicca su <strong>Spedisci</strong> per contrassegnare come spediti i tuoi prodotti.</u></ion-text>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
  </div>

  <!-- NO ORDER AREA -->
  <div class="user-order-area" *ngIf="!isFetching && (error || error404)">
    <ion-grid>
      <ion-row>
        <ion-col 
          size-xs="6" size-sm="4" size-md="4" size-lg="4" size-xl="4"
          offset-xs="3" offset-sm="4" offset-md="4" offset-lg="4" offset-xl="4">

          <img *ngIf="error404 && !error" src="assets/images/no-vendor-products.svg" class="error-resize-orders">

          <img *ngIf="error" src="assets/error/cavolo.jpg" class="error-resize-orders">
        </ion-col>

        <ion-col
          size-xs="10" size-sm="6" size-md="4" size-lg="4" size-xl="4"
          offset-xs="1" offset-sm="3" offset-md="4" offset-lg="4" offset-xl="4">
          <div *ngIf="error404 && !error">
            <p>Ordine non trovato.</p>
            <p>
              Torna alla <a (click)="manageOrderNotFound()">Home</a> ed inizia ad acquistare su Emplace!
            </p>
          </div>

          <div *ngIf="error">
            <p class="order-title"> Cavolo, qualcosa è andato storto! </p>
            <p class="order-title"> Verifica la tua connessione e riprova </p>
            <ion-fab horizontal="center" slot="fixed" class="retry-orders">
              <ion-fab-button  color="primary" (click)="onReloadOrderDetail()">
                <ion-icon name="repeat-sharp"></ion-icon>
              </ion-fab-button>
            </ion-fab>
          </div>

        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- ORDER ITEMS AREA -->
  <div *ngIf="hasItems">
    <ion-grid>
      <ion-row>
        <ion-col style="padding-left: 12px;">
          <ion-text *ngIf="!isFetching && !error && !error404"><strong>Nell'ordine: </strong></ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" 
        *ngFor="let vOrderItem of orderItems; index as index_i">
          <ion-card class="order-data">
            <ion-card-content class="order-data">
                <div class="uoi-content" *ngIf="!vOrderItem.assistance">
                  <div>
                    <ion-text color="primary">
                      <p class="item-text-wrap">{{vOrderItem.prodotto.nome}}</p>
                    </ion-text>
                    <ion-text>
                      <p>Totale: <strong>{{vOrderItem.costo_elemento}} €</strong></p>
                    </ion-text>

                    <ion-fab vertical="top" horizontal="end" *ngIf="vOrderItem.stato=='In Preparazione'" style="margin-top: 6px; margin-right: 2px;">
                      <ion-badge color="primary" (click)="toShippingForm($event, index_i)" style="padding: 12px; cursor: pointer;"> 
                        Spedisci
                      </ion-badge>
                    </ion-fab>
          
                    <!-- User order item - Order elements area -->
                    <div class="uoi-elements-area ion-margin-top">
          
                      <div class="uoi-element">
                        <div class="uoi-element-first-column">
                          <img src="{{ constantUtils.getFullURL(vOrderItem.prodotto.immagini[0]) }}" 
                            class="uoi-element-img" *ngIf="vOrderItem.prodotto.immagini.length > 0"
                            onerror="this.src='assets/placeholders/product.jpg'"/> 
          
                          <img src="assets/placeholders/product.jpg" *ngIf="vOrderItem.prodotto.immagini.length === 0" />
                        </div>
                        <div class="uoi-element-second-column">
                          <ion-grid>
                            <ion-row>
                              <ion-col class="stato">
                                <ion-badge color="sea" class="stato" *ngIf="vOrderItem.stato!='Spedito' && vOrderItem.stato!='In Preparazione'"> {{ vOrderItem.stato }} </ion-badge>
                                <ion-badge color="sea" class="stato-spedito" *ngIf="vOrderItem.stato=='Spedito' || vOrderItem.stato=='In Preparazione'" (click)="toShippingForm(null,index_i)"> {{ vOrderItem.stato }} </ion-badge>
                                <p style="margin: 2px; font-size: 14px; margin-bottom: 8px; cursor: pointer;" *ngIf="vOrderItem.stato=='Spedito'"><a (click)="toDeliveryPopup()">E' stato consegnato?</a></p>
                              </ion-col>
                            </ion-row>
                            <ion-row>
                              <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                                <ion-text>
                                  <p>Quantità: <strong>{{vOrderItem['quantità']}}</strong></p>
                                </ion-text>
                              </ion-col>
                              <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                                <ion-text>
                                  <p>Prezzo per unità:</p>
                                  <p>{{vOrderItem.prezzo_vetrina}} €</p>
                                </ion-text>
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </div>
                      </div>
          
                    </div>
                  </div>
                </div>

                <div class="uoi-content-ass" *ngIf="vOrderItem.assistance">
                  <ion-grid class="ass">
                    <ion-row class="ass">
                      <ion-col size="12" class="ass">
                        <p>Scrivi una email al nostro team, verrai ricontattato al più presto!</p>
                        <a [href]="emailstring">{{email}}</a>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>

                <div class="uoi-bottom">
                  <ion-badge *ngIf="!vOrderItem.assistance" color="secondary" (click)="toProductDetail(vOrderItem.prodotto.id)" class="ass-product"> Vai al prodotto </ion-badge>
                  <ion-badge *ngIf="!vOrderItem.assistance" color="secondary" (click)="requestAssistance(vOrderItem.id)" class="ass"> Assistenza </ion-badge>
                  <ion-badge *ngIf="vOrderItem.assistance" color="secondary" (click)="enoughAssistance(vOrderItem.id)" class="ass"> Chiudi </ion-badge>
                </div>
              
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>