import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { SignalComponent } from './signal.component';
import { SignalService } from '../shared/services/signal.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: SignalComponent, pathMatch: "full"},
    ])
  ],
  providers: [SignalService],
  declarations: [SignalComponent],
  exports: []
})
export class SignalModule {}