import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ShippingAddressComponent } from './shipping-address.component';
import { UserPositionsService } from 'src/app/shared/services/user-positions.service';
import { ShippingAddressEditComponent } from '../shipping-address-edit-data/shipping-address-edit-data.component';
import { SADeleteAddressPopover } from './shipping-address-popover/sa-delete-address-popover.component';
import { AddressUtilsService } from 'src/app/shared/services/addressUtils.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule
  ],
  providers: [UserPositionsService, AddressUtilsService],
  declarations: [ShippingAddressComponent, ShippingAddressEditComponent, SADeleteAddressPopover],
  exports: [ShippingAddressComponent]
})
export class ShippingAddressModule {}